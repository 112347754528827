import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { Link, withRouter } from 'react-router-dom'

import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Media,
  Collapse,
} from 'reactstrap'
import {
  formatDate,
  formatTimeOnly,
  isToday,
  isTomrrow,
  isLate,
} from '../../helpers/date_formatter'

import '../GlistenServiceOrderCard/serviceordercard.scss'
import nocarimage from '../../assets/images/no-car-image.svg'
import {
  approveOrder,
  rejectNewOrder,
  assignWork,
  startWorkOrder,
  endWorkOrder,
  reviewWorkOrder,
} from 'store/actions'
import { connect } from 'react-redux'

const GlistenServiceOrderCard = (props) => {
  //   const serviceName = props.order.service_catalog.reduce(
  //     (accum, curr) => (accum == '' ? '' : accum + ', ') + curr.name,
  //     ''
  //   )

  const [serv_col, setCol] = useState(false)

  const dueTomrrwMorn = (due_date) => {
    var tomorrow = new Date()
    tomorrow.setDate(tomorrow.getDate() + 1)

    if (
      tomorrow.getDate() == due_date.getDate() &&
      tomorrow.getMonth() == due_date.getMonth() &&
      tomorrow.getFullYear() == due_date.getFullYear()
    ) {
      if (due_date.getHours() == 10) {
        if (due_date.getMinutes() <= 29) {
          return true
        } else {
          return false
        }
      } else if (due_date.getHours() < 10) {
        return true
      }
    } else {
      return false
    }
  }

  // Checks if order is due today OR before 10:30AM the following day OR is currently late
  const isDueSoon = (pickup_date) => {
    if (
      isToday(pickup_date) ||
      dueTomrrwMorn(pickup_date) ||
      isLate(pickup_date)
    ) {
      return true
    } else {
      return false
    }
  }

  const dueDateLabel = (pickup_date) => {
    // Due date has passed and order is currently late
    if (props.order.status == 'Rejected') {
      return props.t('ORDER REJECTED')
    } else if (
      isLate(pickup_date) &&
      !(props.order.status == 'Completed') &&
      !(props.order.status == 'WorkReviewed')
    ) {
      return props.t('IS LATE ')
    }
    // Due date is current day
    else if (isToday(pickup_date)) {
      return props.t('TODAY ') + `${formatTimeOnly(pickup_date)}`
    }

    // Due date is tomorrow
    else if (isTomrrow(pickup_date)) {
      return props.t('TOMORROW ') + `${formatTimeOnly(pickup_date)}`
    }
    // Due date is in past and is a completed order
    else if (
      isLate(pickup_date) &&
      (props.order.status == 'Completed' ||
        props.order.status == 'WorkReviewed')
    ) {
      return formatDate(pickup_date)
    }
    // Due date is any day after tomorrow aka future due date
    else {
      return formatDate(pickup_date)
    }
  }

  const priorityLabel = (idx) => {
    switch (idx) {
      case '1':
        return `${props.t('WAITER')} - `
      case '2':
        return `${props.t('SPOT DELIVERY')} - `
      case '3':
        return `${props.t('EOD')} - `
      case '4':
        return `${props.t('FILL')} - `
      case '5':
        return `${props.t('APPOINTMENT')} - `
      default:
        return ''
    }
  }

  const assignGroupImg = (group_name) => {
    if (group_name.replaceAll(' ', '').toUpperCase() == 'CARWASH') {
      return 'illust-icons/ic-car wash.svg'
    } else if (
      group_name.replaceAll(' ', '').toUpperCase() == 'SERVICEDETAIL'
    ) {
      return 'illust-icons/ic-service detail.svg'
    } else if (group_name.replaceAll(' ', '').toUpperCase() == 'CARDELIVERY') {
      return 'illust-icons/ic-car delivery.svg'
    } else if (group_name.replaceAll(' ', '').toUpperCase() == 'POLISH') {
      return 'illust-icons/ic-polish.svg'
    } else if (group_name.replaceAll(' ', '').toUpperCase() == 'NEWCARPREP') {
      return 'illust-icons/ic-new car prep.svg'
    } else if (
      group_name.replaceAll(' ', '').toUpperCase() == 'COMPLETERECONDITION'
    ) {
      return 'illust-icons/ic-complete recondition.svg'
    } else {
      return 'illust-icons/ic-custom category.svg'
    }
  }

  const cardAcceptReject = () => {
    var title =
      props.t('Requested by ') +
      props.order.created_by.first_name +
      ' ' +
      props.order.created_by.last_name +
      ' - ' +
      props.order.created_by.department
    props.showModalApprove(!props.isOpenApprove)
    props.setSelectedOrder(props.order)
    props.setSelectedOrderTitle(title)
    props.setSelectedOrderBtn(props.t('Submit'))
  }

  const cardAssign = () => {
    props.showModalAssign(!props.isOpenAssign)
    props.setSelectedOrder(props.order)
    props.setSelectedOrderTitle(props.t('Assign Detailer to Order'))
    props.setSelectedOrderBtn(props.t('Assign / Reject'))
  }

  const cardStartJob = () => {
    props.showModalStartEndReview(!props.isOpenStartEndReview)
    props.setSelectedOrder(props.order)
    props.setSelectedOrderTitle(props.t('Start Job'))
    props.setSelectedOrderBtn(props.t('Start Job'))
  }

  const cardEndJob = () => {
    props.showModalStartEndReview(!props.isOpenStartEndReview)
    props.setSelectedOrder(props.order)
    props.setSelectedOrderTitle(props.t('End Job'))
    props.setSelectedOrderBtn(props.t('End Job'))
  }

  const cardReviewWork = () => {
    var title =
      props.t('Work Completed by ') +
      props.order.assign_to.first_name +
      ' ' +
      props.order.assign_to.last_name
    props.showModalStartEndReview(!props.isOpenStartEndReview)
    props.setSelectedOrder(props.order)
    props.setSelectedOrderTitle(title)
    props.setSelectedOrderBtn(props.t('Approve Complete'))
  }

  const cardActionButton = () => {
    return props.order.service_catalog &&
      (props.order.status == 'Completed' ||
        props.order.status == 'WorkReviewed') ? (
      <></>
    ) : (
      <Row className='row-margin-fix'>
        <Col
          lg='12'
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '0rem',
          }}
        >
          {/* Dealership APPROVE/REJECT action */}
          {props.userFeat.includes('ftl-dealership') &&
            (props.userFeat.includes('ftl-manager') ||
              props.userFeat.includes('ftl-executive') ||
              props.userFeat.includes('ftl-admin')) &&
            props.order.status == 'New' && (
              <Button
                className='prim-action btn glisten-btn'
                style={{ marginBottom: '1.125rem' }}
                onClick={() => {
                  cardAcceptReject()
                }}
              >
                {props.t('APPROVE / REJECT')}
              </Button>
            )}

          {/* Detailer ASSIGN action */}
          {props.userFeat.includes('ftl-detailshop') &&
            (props.userFeat.includes('ftl-manager') ||
              props.userFeat.includes('ftl-executive') ||
              props.userFeat.includes('ftl-admin')) &&
            (props.order.status == 'Approved' ||
              props.order.status == 'New') && (
              <Button
                className='prim-action btn glisten-btn'
                style={{ marginBottom: '1.125rem' }}
                onClick={() => {
                  cardAssign()
                }}
              >
                {props.t('ASSIGN / REJECT')}
              </Button>
            )}

          {/* Detailer START JOB action */}
          {props.userFeat.includes('ftl-detailshop') &&
            props.userFeat.includes('ftl-employee') &&
            props.order.status == 'WorkAssigned' &&
            props.username == props.order.assign_to.username && (
              <Button
                className='prim-action btn glisten-btn'
                style={{ marginBottom: '1.125rem' }}
                onClick={() => {
                  cardStartJob()
                }}
              >
                {props.t('START JOB')}
              </Button>
            )}

          {/* Detailer END JOB action */}
          {props.userFeat.includes('ftl-detailshop') &&
            (props.userFeat.includes('ftl-employee') ||
              props.userFeat.includes('ftl-admin')) &&
            props.order.status == 'WorkStarted' &&
            (props.username == props.order.assign_to.username ||
              props.userFeat.includes('ftl-admin')) && (
              <Button
                className='prim-action btn glisten-btn'
                style={{ marginBottom: '1.125rem' }}
                onClick={() => {
                  cardEndJob()
                }}
              >
                {props.t('END JOB')}
              </Button>
            )}

          {/* Detailer REVIEW WORK action */}
          {props.userFeat.includes('ftl-detailshop') &&
            (props.userFeat.includes('ftl-manager') ||
              props.userFeat.includes('ftl-executive') ||
              props.userFeat.includes('ftl-admin')) &&
            props.order.status == 'WorkEnded' && (
              <Button
                className='prim-action btn glisten-btn'
                style={{ marginBottom: '1.125rem' }}
                onClick={() => {
                  cardReviewWork()
                }}
              >
                {props.t('REVIEW WORK')}
              </Button>
            )}
        </Col>
      </Row>
    )
  }

  const cardDetailsButton = () => {
    return (
      <Row className='row-margin-fix'>
        <Col
          lg='12'
          className={
            props.order.status == 'WorkEnded' &&
            props.userFeat.includes('ftl-detailshop')
              ? 'review-card-btn'
              : 'default-card-btn'
          }
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '0rem',
          }}
        >
          <Link
            to={`/serviceOrderDetail?orderId=${props.order.data_key}`}
            className='alt-action'
          >
            {props.t('VIEW DETAILS')}
          </Link>
        </Col>
      </Row>
    )
  }

  return (
    <>
      <Card
        className={
          props.order.status == 'Completed' ||
          props.order.status == 'WorkReviewed' ||
          props.order.status == 'Rejected'
            ? 'wb-card'
            : isLate(new Date(props.order.pickup_date_time))
            ? 'wb-card-late'
            : 'wb-card'
        }
        style={{ alignSelf: 'center' }}
      >
        <CardBody style={{ padding: '0rem' }}>
          <Row className='row-margin-fix'>
            <Col
              lg='12'
              style={{
                display: 'flex',
                flexDirection: 'column',
                padding: '0rem',
              }}
            >
              <Label
                className={
                  props.order.status == 'Rejected'
                    ? 'ident-label'
                    : props.order.status == 'Completed' ||
                      props.order.status == 'WorkReviewed'
                    ? props.order.data_timestamp < props.order.pickup_date_time
                      ? 'ident-label-done'
                      : 'ident-label-done-late'
                    : isDueSoon(new Date(props.order.pickup_date_time))
                    ? 'ident-label-urgent'
                    : 'ident-label'
                }
              >
                {props.order.customer_tag
                  ? props.t('CUSTOMER TAG')
                  : props.order.stock
                  ? props.t('STOCK NUMBER')
                  : props.order.vehicle.data_key
                  ? props.t('VIN')
                  : 'No Identifier?'}
              </Label>
            </Col>
          </Row>
          <Row className='row-margin-fix'>
            <Col
              lg='12'
              style={{
                display: 'flex',
                flexDirection: 'column',
                padding: '0rem',
              }}
            >
              <Label
                className={
                  props.order.status == 'Completed' ||
                  props.order.status == 'WorkReviewed' ||
                  props.order.status == 'Rejected'
                    ? 'due-time'
                    : isDueSoon(new Date(props.order.pickup_date_time))
                    ? 'due-time-urgent'
                    : 'due-time'
                }
              >
                {!(props.order.status == 'Rejected') &&
                  priorityLabel(props.order.priority)}
                {dueDateLabel(new Date(props.order.pickup_date_time))}
              </Label>
            </Col>
          </Row>
          <Row className='row-margin-fix'>
            <Col
              lg='12'
              style={{
                display: 'flex',
                flexDirection: 'column',
                padding: '0rem',
              }}
            >
              <Label className='ident-value'>
                {props.order.customer_tag
                  ? props.order.customer_tag
                  : props.order.stock
                  ? props.order.stock
                  : props.order.vehicle.data_key
                  ? props.order.vehicle.data_key
                  : 'ERROR'}
              </Label>
            </Col>
          </Row>

          {/* IMAGE, YEAR, MAKE, MODEL */}
          <Row
            style={{
              marginBottom: '1.875rem',
              marginLeft: '0rem',
              marginRight: '0rem',
            }}
          >
            <Col
              xs='5'
              style={{
                display: 'flex',
                flexDirection: 'column',
                padding: '0rem',
              }}
            >
              {props.order.images.length == 0 ? (
                <Media src={nocarimage} className='card-image'></Media>
              ) : (
                <Media
                  src={props.order.images[0].image_url.thumbnail}
                  className='card-image'
                ></Media>
              )}
            </Col>
            <Col
              xs='7'
              style={{
                display: 'flex',
                flexDirection: 'column',
                padding: '0rem',
                justifyContent: 'center',
              }}
            >
              <Label className='year-make-label'>
                {props.order.vehicle.year} {props.order.vehicle.make}
              </Label>
              <Label
                className='model-label'
                //style={{ marginBottom: '2.313rem' }}
              >
                {props.order.vehicle.model}
              </Label>
            </Col>
          </Row>

          {/* CARD BUTTONS (standard)*/}
          {/* TO DO: logic to check user type/status to show correct CTA */}
          {props.userFeat.includes('ftl-dealership') ||
          (!(props.order.status == 'WorkEnded') &&
            props.userFeat.includes('ftl-detailshop')) ? (
            <Row className='row-margin-fix'>
              <Col
                lg='12'
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  padding: '0rem',
                }}
              >
                {cardActionButton()}
                {cardDetailsButton()}
              </Col>
            </Row>
          ) : (
            <></>
          )}

          {/* CARD BUTTONS (review variant) */}
          {props.order.status == 'WorkEnded' &&
          props.userFeat.includes('ftl-detailshop') ? (
            <Row className={'row-margin-fix review-row'}>
              <Col
                lg='12'
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  padding: '0rem',
                }}
              >
                <Row className='row-margin-fix'>
                  <Col
                    lg='12'
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      padding: '0rem',
                      alignItems: 'center',
                    }}
                  >
                    <Label className='review-row-title'>
                      {props.order.service_catalog.length}{' '}
                      {props.order.service_catalog.length > 1
                        ? props.t('SERVICES COMPLETED')
                        : props.t('SERVICE COMPLETED')}
                    </Label>
                  </Col>
                </Row>
                {cardActionButton()}
                {cardDetailsButton()}
              </Col>
            </Row>
          ) : (
            <></>
          )}

          {/* CARD EXPAND BUTTON */}
          {serv_col ||
          props.order.status == 'Completed' ||
          props.order.status == 'WorkReviewed' ||
          props.order.status == 'Rejected' ||
          ((props.order.status == 'Completed' ||
            props.order.status == 'WorkReviewed' ||
            props.order.status == 'WorkEnded') &&
            props.userFeat.includes('ftl-detailshop')) ? (
            <></>
          ) : (
            <Row className={'row-margin-fix'}>
              <Col
                lg='12'
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  padding: '0rem',
                }}
              >
                <Button
                  className='link-action btn glisten-btn'
                  onClick={() => setCol(!serv_col)}
                  style={{ marginBottom: '1.25rem' }}
                >
                  {props.t('LEARN MORE')}
                </Button>
              </Col>
            </Row>
          )}

          {/* Card Expand Content */}
          <Collapse isOpen={serv_col} className={'accordion-collapse'}>
            {/* ASSIGNED only for Detailer users for status WorkAssigned + WorkStarted */}
            {(props.order.status == 'WorkAssigned' ||
              props.order.status == 'WorkStarted') &&
            props.userFeat.includes('ftl-detailshop') ? (
              <Row className='row-margin-fix'>
                <Col
                  lg='12'
                  style={{
                    padding: '0rem',
                  }}
                >
                  <Label className='info-row-title'>
                    {props.t('Detailer')}
                    {'\u003A'}
                  </Label>
                  <Label className='info-row-info'>
                    {props.order.assign_to ? (
                      props.order.assign_to.first_name +
                      ' ' +
                      props.order.assign_to.last_name
                    ) : (
                      <></>
                    )}
                  </Label>
                  <div
                    className='align-self-center'
                    style={{
                      alignSelf: 'center',
                      height: '0.063rem',
                      width: '24.875rem',
                      marginRight: '1.5rem',
                      marginLeft: '1.5rem',
                      background: '#E6E6E6',
                    }}
                  ></div>
                </Col>
              </Row>
            ) : (
              <></>
            )}

            {/* SERVICES for all Dealership users */}
            {/* SERVICES in NEW or IN PROGRESS column only for Detailer users */}
            {props.userFeat.includes('ftl-dealership') ||
            ((props.order.status == 'New' ||
              props.order.status == 'Approved' ||
              props.order.status == 'WorkAssigned' ||
              props.order.status == 'WorkStarted') &&
              props.userFeat.includes('ftl-detailshop')) ? (
              <Row className='row-margin-fix'>
                <Col
                  lg='12'
                  style={{
                    padding: '0rem',
                  }}
                >
                  <Row className='row-margin-fix'>
                    <Col
                      lg='12'
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        padding: '0rem',
                      }}
                    >
                      <Label className='info-row-title'>
                        {props.order.service_catalog.length > 1
                          ? props.t('Services') + '\u003A'
                          : props.t('Service') + '\u003A'}
                      </Label>
                    </Col>
                  </Row>
                  {Array.from(props.order.service_catalog).map(
                    (catalog, idx) => {
                      return (
                        <Row className='row-margin-fix' key={idx}>
                          <Col
                            lg='12'
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              padding: '0rem',
                              alignItems: 'center',
                            }}
                          >
                            <Media
                              src={assignGroupImg(catalog.category)}
                              style={{
                                marginTop: '0.625rem',
                                marginBottom: '0.625rem',
                                marginLeft: '4.813rem',
                                marginRight: '1.875rem',
                              }}
                            ></Media>
                            <Label className='service-label'>
                              {catalog.name}
                            </Label>
                          </Col>
                        </Row>
                      )
                    }
                  )}
                  <div
                    className='align-self-center'
                    style={{
                      alignSelf: 'center',
                      height: '0.063rem',
                      width: '24.875rem',
                      marginTop: '1.563rem',
                      marginRight: '1.5rem',
                      marginLeft: '1.5rem',
                      background: '#E6E6E6',
                    }}
                  ></div>
                </Col>
              </Row>
            ) : (
              <></>
            )}

            {/* ORDER NOTE for all Dealership users */}
            {/* ORDER NOTE in NEW or IN PROGRESS column only for Detailer users */}
            {props.userFeat.includes('ftl-dealership') ||
            ((props.order.status == 'New' ||
              props.order.status == 'Approved' ||
              props.order.status == 'WorkAssigned' ||
              props.order.status == 'WorkStarted') &&
              props.userFeat.includes('ftl-detailshop') &&
              props.order.note) ? (
              <Row
                style={{ marginTop: '1rem', marginRight: '0', marginLeft: '0' }}
              >
                <Col
                  lg='12'
                  style={{
                    padding: '0rem',
                  }}
                >
                  <Row className='row-margin-fix'>
                    <Col
                      lg='12'
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        padding: '0rem',
                      }}
                    >
                      <Label className='info-row-title'>
                        {props.t('Order Note') + '\u003A'}
                      </Label>
                    </Col>
                    <Col
                      lg='12'
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        padding: '0rem',
                      }}
                    >
                      <Label className='note-body'>{props.order.note}</Label>
                    </Col>
                    <Col
                      lg='12'
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        padding: '0rem',
                      }}
                    >
                      {' '}
                      <div
                        className='align-self-center'
                        style={{
                          alignSelf: 'center',
                          height: '0.063rem',
                          width: '24.875rem',
                          marginRight: '1.5rem',
                          marginLeft: '1.5rem',
                          marginTop: '1rem',
                          background: '#E6E6E6',
                        }}
                      ></div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            ) : (
              <></>
            )}

            {/* REQUESTER if in NEW column for all users except for Detailer Employee*/}
            {/* REQUESTER in all columns for all Dealership users*/}
            {((props.order.status == 'New' ||
              props.order.status == 'Approved' ||
              props.order.status == 'WorkAssigned') &&
              !(
                props.userFeat.includes('ftl-detailshop') &&
                props.userFeat.includes('ftl-employee')
              )) ||
            props.userFeat.includes('ftl-dealership') ? (
              <Row className='row-margin-fix'>
                <Col
                  lg='12'
                  style={{
                    padding: '0rem',
                  }}
                >
                  <Label className='info-row-title'>
                    {props.t('Requester')}
                    {'\u003A'}
                  </Label>
                  <Label className='info-row-info'>
                    {props.order.created_by && props.order.created_by.first_name
                      ? props.order.created_by.first_name +
                        ' ' +
                        props.order.created_by.last_name
                      : props.order.created_by}
                  </Label>
                  <div
                    className='align-self-center'
                    style={{
                      alignSelf: 'center',
                      height: '0.063rem',
                      width: '24.875rem',
                      marginRight: '1.5rem',
                      marginLeft: '1.5rem',
                      background: '#E6E6E6',
                    }}
                  ></div>
                </Col>
              </Row>
            ) : (
              <></>
            )}

            {/* DEPARTMENT if in NEW column for all users */}
            {/* DEPARTMENT if in every column for Dealership users */}
            {props.order.status == 'New' ||
            props.order.status == 'Approved' ||
            props.order.status == 'WorkAssigned' ||
            props.userFeat.includes('ftl-dealership') ? (
              <Row className='row-margin-fix'>
                <Col
                  lg='12'
                  style={{
                    padding: '0rem',
                  }}
                >
                  <Label className='info-row-title'>
                    {props.t('Department')}
                    {'\u003A'}
                  </Label>
                  <Label className='info-row-info'>
                    {props.order.created_by &&
                    props.order.created_by.department ? (
                      props.order.created_by.department
                    ) : (
                      <></>
                    )}
                  </Label>
                  <div
                    className='align-self-center'
                    style={{
                      alignSelf: 'center',
                      height: '0.063rem',
                      width: '24.875rem',
                      marginRight: '1.5rem',
                      marginLeft: '1.5rem',
                      background: '#E6E6E6',
                    }}
                  ></div>
                </Col>
              </Row>
            ) : (
              <></>
            )}

            {/* CARD COLLAPSE BUTTON */}
            {!serv_col ? (
              <></>
            ) : (
              <Row className={'row-margin-fix'}>
                <Col
                  lg='12'
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '0rem',
                  }}
                >
                  <Button
                    className='link-action btn glisten-btn'
                    onClick={() => setCol(!serv_col)}
                    style={{ marginTop: '1.25rem', marginBottom: '1.25rem' }}
                  >
                    {props.t('VIEW LESS')}
                  </Button>
                </Col>
              </Row>
            )}
          </Collapse>
        </CardBody>
      </Card>
    </>
  )
}

GlistenServiceOrderCard.propTypes = {
  t: PropTypes.any,
  order: PropTypes.any,
  org: PropTypes.string,
  tenant: PropTypes.string,
  userFeat: PropTypes.any,
  username: PropTypes.any,
  onApproveOrder: PropTypes.func,
  onRejectNewOrder: PropTypes.func,
  onAssignOrder: PropTypes.func,
  onStartWorkOrder: PropTypes.func,
  onEndWorkOrder: PropTypes.func,
  onReviewWorkOrder: PropTypes.func,
  isOpenStartEndReview: PropTypes.bool,
  showModalStartEndReview: PropTypes.func,
  isOpenApprove: PropTypes.bool,
  showModalApprove: PropTypes.func,
  isOpenAssign: PropTypes.bool,
  showModalAssign: PropTypes.func,
  setSelectedOrder: PropTypes.func,
  setSelectedOrderTitle: PropTypes.func,
  setSelectedOrderBtn: PropTypes.func,
}

const mapStateToProps = ({ login }) => ({
  tenant: login.tenant,
  org: login.org,
  userFeat: login.features,
  username: login.username,
})

const mapDispatchToProps = (dispatch) => ({
  onApproveOrder: (org, tenentId, orderKey) =>
    dispatch(approveOrder(org, tenentId, orderKey)),
  onRejectNewOrder: (org, tenantId, orderKey) =>
    dispatch(rejectNewOrder(org, tenantId, orderKey)),
  onAssignOrder: (org, tenentId, orderKey, targetEmployeeKey) =>
    dispatch(assignWork(org, tenentId, orderKey, targetEmployeeKey)),
  onStartWorkOrder: (org, tenentId, orderKey) =>
    dispatch(startWorkOrder(org, tenentId, orderKey)),
  onEndWorkOrder: (org, tenentId, orderKey) =>
    dispatch(endWorkOrder(org, tenentId, orderKey)),
  onReviewWorkOrder: (org, tenentId, orderKey) =>
    dispatch(reviewWorkOrder(org, tenentId, orderKey)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(GlistenServiceOrderCard)))
