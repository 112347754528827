import { takeEvery, put, call } from 'redux-saga/effects'

import { GET_DASHBOARD } from './actionTypes'
import { getDashboardSuccess, getDashboardFail } from './actions'

//Include Both Helper File with needed methods
import { getDashboard } from '../../../helpers/fakebackend_helper'

function* fetchDashboard() {
  console.log('saga::fetchDashboard()')

  try {
    const response = yield call(getDashboard)
    yield put(getDashboardSuccess(response))
  } catch (error) {
    yield put(getDashboardFail(error))
  }
}

export function* financeSaga() {
  yield takeEvery(GET_DASHBOARD, fetchDashboard)
}

export default financeSaga
