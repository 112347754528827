import React from 'react'
import PropTypes from 'prop-types'
import { Collapse, Media, Table } from 'reactstrap'
import NumberFormat from 'react-number-format'
import { useFormContext } from 'react-hook-form'

import '../GlistenServiceBox/glistenservicebox.scss'

const GlistenServiceBox = (props) => {
  const methods = useFormContext()

  var xor = methods.watch('services')
    ? methods
        .watch('services')
        .filter(
          (n) => props.items.map((item) => item.data_key).indexOf(n) !== -1
        )
    : []

  const validateCheckboxes = () => {
    return props.validateSelect
  }

  return (
    <div className='accordion service-box' id='accordion'>
      <div className='accordion-item'>
        <h2 className='accordion-header' id='headingOne'>
          <button
            className={
              props.items && xor.length > 0
                ? props.alwaysOpen || props.isOpen
                  ? 'accordion-button fw-medium selected'
                  : 'accordion-button fw-medium collapsed selected'
                : props.alwaysOpen || props.isOpen
                ? 'accordion-button fw-medium accord-btn'
                : 'accordion-button fw-medium collapsed accord-btn'
            }
            type='button'
            onClick={() => props.toggleOpen(!props.isOpen)}
            style={{
              cursor: 'pointer',
              paddingTop: '0.5rem',
              paddingBottom: '0.5rem',
              paddingLeft: '0.5rem',
            }}
          >
            <Media
              src={props.image}
              style={{
                marginTop: '0.875rem',
                marginBottom: '1.063rem',
                marginLeft: '1.188rem',
                marginRight: '1.25rem',
              }}
            ></Media>

            <span className='group-title'>{props.title}</span>
            <span>
              {props.items && xor.length > 0 ? (
                <span className='select-counter '>+{xor.length}</span>
              ) : (
                <></>
              )}
            </span>
          </button>
        </h2>

        <Collapse
          isOpen={props.alwaysOpen || props.isOpen}
          className={
            props.items && xor.length > 0
              ? 'accordion-collapse selected'
              : 'accordion-collapse'
          }
        >
          <div className='accordion-body'>
            <Table borderless>
              <tbody>
                {props.items &&
                  props.items.map((item, idx) => (
                    <tr className='form-check' key={idx}>
                      {props.editable && (
                        <td>
                          <input
                            id='services'
                            name='services'
                            className='form-check-input'
                            type='checkbox'
                            defaultValue={item.data_key}
                            {...methods.register('services', {
                              validate: validateCheckboxes,
                            })}
                          />
                        </td>
                      )}
                      <td className='price-data'>
                        <label
                          className='form-check-label price'
                          htmlFor='defaultCheck1'
                        >
                          <NumberFormat
                            value={item.retail_cost}
                            displayType={'text'}
                            fixedDecimalScale={true}
                            decimalScale={2}
                            thousandSeparator={true}
                            prefix={'$'}
                            style={{ marginRight: '1rem', fontWeight: '800' }}
                          />
                        </label>
                      </td>
                      <td
                        style={{
                          paddingLeft: '0rem',
                          paddingTop: '0.813rem',
                        }}
                      >
                        <label className='service-label'>{item.name}</label>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        </Collapse>
      </div>
    </div>
  )
}

GlistenServiceBox.propTypes = {
  title: PropTypes.string,
  items: PropTypes.any,
  image: PropTypes.any,
  alwaysOpen: PropTypes.bool,
  editable: PropTypes.bool,
  validateSelect: PropTypes.bool,
  isOpen: PropTypes.bool,
  toggleOpen: PropTypes.func,
}

export default GlistenServiceBox
