/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import {
  changeLayout,
  changeTopbarTheme,
  changeLayoutWidth,
  getTenantData,
} from '../../store/actions'

// Other Layout related Component
import Navbar from './Navbar'
import Header from './Header'

class Layout extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
    }
    this.state = {
      isMenuOpened: false,
      pageTitle: '',
      showNavBack: false,
    }
    this.setPageTitle.bind(this)
    this.setShowNavBack.bind(this)
  }

  componentDidMount() {
    this.props.onGetTenantData(this.props.org, this.props.tenant)

    if (this.props.isPreloader === true) {
      document.getElementById('preloader').style.display = 'block'
      document.getElementById('status').style.display = 'block'

      setTimeout(function () {
        document.getElementById('preloader').style.display = 'none'
        document.getElementById('status').style.display = 'none'
      }, 2500)
    } else {
      document.getElementById('preloader').style.display = 'none'
      document.getElementById('status').style.display = 'none'
    }

    // Scrollto 0,0
    window.scrollTo(0, 0)

    const title = this.props.location.pathname
    let currentage = title.charAt(1).toUpperCase() + title.slice(2)

    document.title = currentage + ' | Glisten'

    this.props.onChangeLayout('horizontal')
    this.props.onChangeTopbarTheme('light')
    this.props.onChangeLayoutWidth('fluid')
  }

  setPageTitle = (title) => {
    this.setState({ pageTitle: title })
  }

  setShowNavBack = (show) => {
    this.setState({ showNavBack: show })
  }

  render() {
    const childrenWithProps = React.Children.map(
      this.props.children,
      (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, {
            setPageTitle: this.setPageTitle,
            setShowNavBack: this.setShowNavBack,
          })
        }
        return child
      }
    )

    return (
      <React.Fragment>
        <div id='preloader'>
          <div id='status'>
            <div className='spinner-chase'>
              <div className='chase-dot' />
              <div className='chase-dot' />
              <div className='chase-dot' />
              <div className='chase-dot' />
              <div className='chase-dot' />
              <div className='chase-dot' />
            </div>
          </div>
        </div>

        <div id='layout-wrapper'>
          <Header
            title={this.props.name}
            logo={this.props.logo}
            mobileTitle={this.state.pageTitle}
            showNavBack={this.state.showNavBack}
          />

          <Navbar menuOpen={this.state.isMenuOpened} />
          <div className='main-content'>{childrenWithProps}</div>
        </div>
      </React.Fragment>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.object,
  isPreloader: PropTypes.any,
  layoutWidth: PropTypes.any,
  location: PropTypes.object,
  topbarTheme: PropTypes.any,
  onChangeLayout: PropTypes.func,
  onChangeTopbarTheme: PropTypes.func,
  onChangeLayoutWidth: PropTypes.func,
  onGetTenantData: PropTypes.func,
  org: PropTypes.string,
  tenant: PropTypes.string,
  userFeat: PropTypes.any,
  logo: PropTypes.string,
  name: PropTypes.string,
}

const mapStatetoProps = ({ Layout, login, tenant }) => ({
  Layout,
  org: login.org,
  userFeat: login.features,
  tenant: login.tenant,
  logo: tenant.logo,
  name: tenant.name,
})

const mapDispatchToProps = (dispatch) => ({
  onChangeLayout: (layout) => dispatch(changeLayout(layout)),
  onChangeTopbarTheme: (theme) => dispatch(changeTopbarTheme(theme)),
  onChangeLayoutWidth: (layoutWidth) =>
    dispatch(changeLayoutWidth(layoutWidth)),
  onGetTenantData: (org, tenant) => dispatch(getTenantData(org, tenant)),
})

export default connect(mapStatetoProps, mapDispatchToProps)(withRouter(Layout))
