/* eslint-disable no-unused-vars */
import React from 'react'
import ReactApexChart from 'react-apexcharts'
import { Row, Col } from 'reactstrap'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

const WorkflowMetricsGraph = (props) => {
  const options = {
    chart: { zoom: { enabled: !1 }, toolbar: { show: !1 } },
    //colors: props.data.colors,
    dataLabels: { enabled: !1 },
    stroke: { width: [2, 2, 2], curve: 'straight', dashArray: [0, 0, 0] },
    markers: { size: 0, hover: { sizeOffset: 6 } },
    // xaxis: {
    //   categories: props.data.categories,
    // },
    tooltip: {
      y: [
        {
          title: {
            formatter: function (e) {
              return e
            },
          },
        },
        {
          title: {
            formatter: function (e) {
              return e
            },
          },
        },
        {
          title: {
            formatter: function (e) {
              return e
            },
          },
        },
      ],
    },
    grid: { borderColor: '#f1f1f1' },
  }

  return (
    <Row>
      <Col xl='12'>
        {/* {props.data.series && props.data.series.length > 0 && (
          <ReactApexChart
            options={options}
            series={props.data.series}
            type='line'
            height='320'
          />
        )} */}
      </Col>
    </Row>
  )
}

WorkflowMetricsGraph.propTypes = {
  t: PropTypes.any,
  data: PropTypes.any,
}

export default withTranslation()(WorkflowMetricsGraph)
