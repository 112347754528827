const mapOrderStatus = (status, t) => {
  switch (status) {
    case 'completed':
      return t('Completed')
    case 'in_progress':
      return t('In Progress')
    case 'new':
      return t('New')
  }
}

const formatTime = (timestamp) => {
  var date = new Date(timestamp)
  const humanDateFormat = date.toLocaleString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: 'numeric',
    minute: 'numeric',
  })
  return humanDateFormat
}

const formatPhoneNumber = (value) => {
  if (!value) return value

  const phoneNumber = value.replace(/[^\d]/g, '')

  const phoneNumberLength = phoneNumber.length
  if (phoneNumberLength < 4) return phoneNumber

  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`
  }

  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
    3,
    6
  )}-${phoneNumber.slice(6, 10)}`
}

export { mapOrderStatus, formatTime, formatPhoneNumber }
