/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Modal, Button } from 'reactstrap'

import { withTranslation } from 'react-i18next'

import { ReactComponent as BackIcon } from '../../assets/images/ic-chev left tiny.svg'

import { ReactComponent as Logo } from '../../assets/images/ic-close.svg'
import { useForm, FormProvider } from 'react-hook-form'
import { isMobile } from 'react-device-detect'

import '../ModalWithDynamicContent/modaldynamiccontent.scss'

const ModalWithDynamicContent = (props) => {
  const methods = useForm()

  const onSubmit = (data) => {
    props.onSubmit(props.currentContent + 1, data)
  }

  useEffect(() => {
    methods.reset({})
    props.onSubmit(0, null)
  }, [props.isOpen])

  return (
    <FormProvider {...methods}>
      <Modal
        size='xl'
        isOpen={props.isOpen}
        toggle={() => {
          //props.tog_center()
        }}
        style={!isMobile ? {} : { paddingTop: '0rem' }}
        centered={true}
      >
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className='modal-header glisten-modal-header'>
            <div>
              <h3 className='glisten-modal-title'>{props.title}</h3>
            </div>
            <Button
              className='btn close-btn'
              onClick={() => {
                props.onClose()
                props.showModal(false)
              }}
            >
              <Logo />
            </Button>
          </div>

          <div
            className='modal-body glisten-modal-body'
            style={{ msOverflowY: 'scroll', maxHeight: '40em' }}
          >
            {props.content}
          </div>

          <div
            className='glisten-modal-footer'
            style={{ flexDirection: 'row' }}
          >
            <div style={{ alignSelf: 'flex-start' }}>
              {props.showBack ? (
                <Button
                  color='link'
                  className='btn back-link'
                  style={{ float: 'left' }}
                  onClick={() => props.onSubmit(props.currentContent - 1, null)}
                  disabled={props.submitBtnDisabled}
                >
                  <BackIcon style={{ marginRight: '1rem' }} />
                  {props.t('Back')}
                </Button>
              ) : (
                <></>
              )}
            </div>

            <div>
              <input
                type='submit'
                className='btn glisten-btn prim-mod-action'
                disabled={props.submitBtnDisabled}
                value={props.submitBtnTitle}
              />

              {props.showAltBtn ? (
                <button
                  className='alt-mod-action'
                  disabled={props.submitBtnDisabled}
                >
                  {props.altBtnTitle}
                </button>
              ) : (
                <></>
              )}
            </div>
          </div>
        </form>
      </Modal>
    </FormProvider>
  )
}

ModalWithDynamicContent.propTypes = {
  t: PropTypes.any,
  title: PropTypes.string,
  content: PropTypes.element,
  isOpen: PropTypes.bool,
  tog_center: PropTypes.func,
  showModal: PropTypes.func,
  showBack: PropTypes.bool,
  showAltBtn: PropTypes.bool,
  altBtnTitle: PropTypes.string,
  submitBtnTitle: PropTypes.string,
  submitBtnDisabled: PropTypes.bool,
  wipeVehicleInfo: PropTypes.func,
  wipeOrderInfo: PropTypes.func,
  currentContent: PropTypes.number,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
}

export default withTranslation()(ModalWithDynamicContent)
