/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types'
import React from 'react'

import { Switch, BrowserRouter as Router } from 'react-router-dom'

import { connect } from 'react-redux'

// Import Routes all
import { authRoutes, userRoutes } from './routes/allRoutes'

// Import all middleware
import Authmiddleware from './routes/middleware/Authmiddleware'

// layouts Format
import NonAuthLayout from './components/NonAuthLayout'
import HorizontalLayout from './components/HorizontalLayout/'

// Import scss
import './assets/scss/theme.scss'

import { firebase } from 'firebase'

// Activating fake backend
// import fakeBackend from './helpers/AuthType/fakeBackend'
// fakeBackend()
// console.log = function () {}

const App = (props) => {
  function getLayout() {
    let layoutCls = HorizontalLayout
    return layoutCls
  }

  const Layout = getLayout()

  firebase()

  return (
    <React.Fragment>
      <Router>
        <Switch>
          {authRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
            />
          ))}

          {userRoutes
            .filter(
              (route, idx) =>
                route.group.length == 0 ||
                (props.userFeat &&
                  props.userFeat.filter(
                    (element) => route.group && route.group.includes(element)
                  ).length > 0)
            )
            .map((route, idx) => {
              return (
                <Authmiddleware
                  path={route.path}
                  layout={Layout}
                  component={route.component}
                  key={idx}
                  isAuthProtected={true}
                  exact
                />
              )
            })}
        </Switch>
      </Router>
    </React.Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any,
  userFeat: PropTypes.any,
}

const mapStateToProps = ({ Layout, login }) => {
  return {
    layout: Layout,
    userFeat: login.features,
  }
}

export default connect(mapStateToProps, null)(App)
