/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { Row, Col, Modal, Button, FormGroup, Label } from 'reactstrap'

// Styling
import '../EditService/editservice.scss'

//i18n
import { withTranslation } from 'react-i18next'

import { connect } from 'react-redux'

import {
  updateServiceCatalogData,
  deleteServiceCatalogData,
} from 'store/actions'

import { ReactComponent as Logo } from '../../../assets/images/ic-close.svg'
import { useForm } from 'react-hook-form'

import { sendPageViewEvent } from '../../../firebase'

const dollarPrefix = '$'
const EditService = (props) => {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm()

  useEffect(() => {
    sendPageViewEvent('Edit Service')
  }, [])

  const onSubmit = (evt) => {
    const reqInput = {
      name: evt.name,
      description: evt.description,
      retail_cost: parseFloat(evt.retailcost.substr(dollarPrefix.length)),
      internal_cost: parseFloat(evt.internalcost.substr(dollarPrefix.length)),
      admin_cost: parseFloat(evt.admincost.substr(dollarPrefix.length)),
      category: evt.category,
    }
    props.onUpdateServiceCatalog(
      props.org,
      props.targetTenant.data_key,
      props.tenant,
      props.serviceCatalogId,
      reqInput
    )
    props.showModal(false)
  }

  const handleDelete = (evt) => {
    evt.preventDefault()
    props.onDeleteServiceCatalog(
      props.org,
      props.targetTenant.data_key,
      props.tenant,
      props.serviceCatalogId
    )
    props.showModal(false)
  }

  return (
    <Modal
      size='xl'
      isOpen={props.isOpen}
      toggle={() => {
        props.showModal(false)
      }}
      centered={true}
      onClosed={() => reset()}
    >
      <div className='modal-header glisten-modal-header'>
        <div>
          <h3 className='glisten-modal-title'>{props.title}</h3>
        </div>

        <button
          className='close-btn'
          onClick={() => {
            props.showModal(false)
          }}
        >
          <Logo />
        </button>
      </div>

      <form onSubmit={handleSubmit(onSubmit)} className='form-horizontal'>
        <div
          className='modal-body glisten-modal-body'
          style={{ paddingTop: '1.188rem' }}
        >
          <Row>
            <Col lg='5'>
              <Label
                className={
                  watch('category') && watch('category').length > 0
                    ? 'glisten-textfield-label'
                    : 'glisten-textfield-label glisten-textfield-label-hidden'
                }
              >
                {props.t('Service Category')}
              </Label>
              <input
                id='category'
                name='category'
                label={props.t('Service Category')}
                className='form-control glisten-textfield'
                placeholder={props.t('Service Category')}
                defaultValue={props.serviceCatToEdit}
                {...register('category', {
                  required: props.t('Please enter category name.'),
                })}
              />
              {errors.category?.message && <p>{errors.category.message}</p>}
            </Col>
            <Col lg='5'>
              <Label
                className={
                  watch('name') && watch('name').length > 0
                    ? 'glisten-textfield-label'
                    : 'glisten-textfield-label glisten-textfield-label-hidden'
                }
              >
                {props.t('Service Name')}
              </Label>
              <input
                id='name'
                name='name'
                label={props.t('Service Name')}
                className='form-control glisten-textfield'
                placeholder={props.t('Service Name')}
                defaultValue={props.serviceNameToEdit}
                {...register('name', {
                  required: props.t('Please enter service name.'),
                })}
              />
              {errors.name?.message && <p>{errors.name.message}</p>}
            </Col>
          </Row>
          <Row>
            <Col lg='3' style={{ paddingTop: '1.25rem' }}>
              <Label
                className={
                  watch('retailcost') && watch('retailcost').length > 0
                    ? 'glisten-textfield-label'
                    : 'glisten-textfield-label glisten-textfield-label-hidden'
                }
              >
                {props.t('Retail Cost')}
              </Label>
              <input
                id='retailcost'
                name='retailcost'
                label={props.t('Retail Cost')}
                className='form-control glisten-textfield'
                placeholder={props.t('Retail Cost')}
                defaultValue={dollarPrefix + props.retailCostToEdit}
                {...register('retailcost', {
                  required: props.t('Please enter retail cost.'),
                  pattern: {
                    value: /(\d+(?:\.\d+)?)/,
                    message: 'Please enter valid retail cost',
                  },
                })}
                onChange={(e) => {
                  const input = e.target.value
                  e.target.value =
                    dollarPrefix +
                    (input.indexOf(dollarPrefix) == 0
                      ? input.substr(dollarPrefix.length)
                      : input)
                }}
              />
              {errors.retailcost?.message && <p>{errors.retailcost.message}</p>}
            </Col>
            <Col lg='3' style={{ paddingTop: '1.25rem' }}>
              <Label
                className={
                  watch('internalcost') && watch('internalcost').length > 0
                    ? 'glisten-textfield-label'
                    : 'glisten-textfield-label glisten-textfield-label-hidden'
                }
              >
                {props.t('Internal Cost')}
              </Label>
              <input
                id='internalcost'
                name='internalcost'
                label={props.t('Internal Cost')}
                className='form-control glisten-textfield'
                placeholder={props.t('Internal Cost')}
                defaultValue={dollarPrefix + props.internalCostToEdit}
                {...register('internalcost', {
                  required: props.t('Please enter internal cost.'),
                  pattern: {
                    value: /(\d+(?:\.\d+)?)/,
                    message: 'Please enter valid internal cost',
                  },
                })}
                onChange={(e) => {
                  const input = e.target.value
                  e.target.value =
                    dollarPrefix +
                    (input.indexOf(dollarPrefix) == 0
                      ? input.substr(dollarPrefix.length)
                      : input)
                }}
              />
              {errors.internalcost?.message && (
                <p>{errors.internalcost.message}</p>
              )}
            </Col>
            <Col lg='3' style={{ paddingTop: '1.25rem' }}>
              <Label
                className={
                  watch('admincost') && watch('admincost').length > 0
                    ? 'glisten-textfield-label'
                    : 'glisten-textfield-label glisten-textfield-label-hidden'
                }
              >
                {props.t('Admin Cost')}
              </Label>
              <input
                id='admincost'
                name='admincost'
                label={props.t('Admin Cost')}
                className='form-control glisten-textfield'
                placeholder={props.t('Admin Cost')}
                defaultValue={dollarPrefix + props.adminCostToEdit}
                {...register('admincost', {
                  required: props.t('Please enter admin cost.'),
                  pattern: {
                    value: /(\d+(?:\.\d+)?)/,
                    message: 'Please enter valid admin cost',
                  },
                })}
                onChange={(e) => {
                  const input = e.target.value
                  e.target.value =
                    dollarPrefix +
                    (input.indexOf(dollarPrefix) == 0
                      ? input.substr(dollarPrefix.length)
                      : input)
                }}
              />
              {errors.admincost?.message && <p>{errors.admincost.message}</p>}
            </Col>
          </Row>
          <Row>
            <Col lg='10' style={{ paddingTop: '1.25rem' }}>
              <Label
                className={
                  watch('description') && watch('description').length > 0
                    ? 'glisten-textfield-label'
                    : 'glisten-textfield-label glisten-textfield-label-hidden'
                }
              >
                {props.t('Description')}
              </Label>
              <input
                id='description'
                name='description'
                label={props.t('Description')}
                className='form-control glisten-textfield'
                placeholder={props.t('Description')}
                defaultValue={props.descriptionToEdit}
                {...register('description', {
                  required: props.t('Please enter service description.'),
                })}
              />
              {errors.description?.message && (
                <p>{errors.description.message}</p>
              )}
            </Col>
          </Row>
        </div>
        <div className='modal-footer glisten-modal-footer'>
          <div>
            <FormGroup>
              <input
                className='btn glisten-btn prim-mod-action'
                type='submit'
                value={props.submitBtnTitle}
              />
              <Button
                className='btn glisten-btn alt-mod-action'
                onClick={(evt) => {
                  handleDelete(evt)
                }}
              >
                {props.secondBtnTitle}
              </Button>
            </FormGroup>
          </div>
        </div>
      </form>
    </Modal>
  )
}

EditService.propTypes = {
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  showModal: PropTypes.func,
  submitBtnTitle: PropTypes.string,
  submitBtnDisabled: PropTypes.bool,
  secondBtnTitle: PropTypes.string,
  t: PropTypes.any,
  serviceCatalogId: PropTypes.string,
  serviceCatToEdit: PropTypes.string,
  serviceNameToEdit: PropTypes.string,
  retailCostToEdit: PropTypes.string,
  internalCostToEdit: PropTypes.string,
  adminCostToEdit: PropTypes.string,
  descriptionToEdit: PropTypes.string,
  onUpdateServiceCatalog: PropTypes.func,
  onDeleteServiceCatalog: PropTypes.func,
  org: PropTypes.string,
  tenant: PropTypes.string,
  targetTenant: PropTypes.any,
  userFeat: PropTypes.any,
}

const mapStateToProps = ({ tenant, login }) => ({
  tenant: login.tenant,
  targetTenant: tenant.targetTenant,
  org: login.org,
  userFeat: login.features,
})

const mapDispatchToProps = (dispatch) => ({
  onUpdateServiceCatalog: (
    org,
    detailTenant,
    dealershipTenant,
    serviceCatalogId,
    serviceCatalog
  ) =>
    dispatch(
      updateServiceCatalogData(
        org,
        detailTenant,
        dealershipTenant,
        serviceCatalogId,
        serviceCatalog
      )
    ),
  onDeleteServiceCatalog: (
    org,
    detailTenant,
    dealershipTenant,
    serviceCatalogId
  ) =>
    dispatch(
      deleteServiceCatalogData(
        org,
        detailTenant,
        dealershipTenant,
        serviceCatalogId
      )
    ),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(EditService))
