import React from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom'
import { verifyJWT } from 'store/auth/login/aws-cognito'
import { connect } from 'react-redux'
import { logout } from 'store/actions'
import { withTranslation } from 'react-i18next'

const validateAccessToken = (token) => {
  try {
    if (verifyJWT(token)) {
      return true
    } else {
      return false
    }
  } catch (e) {
    return false
  }
}

const Authmiddleware = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  jwtToken,
  location,
  onLogout,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => {
      if (
        (isAuthProtected && jwtToken == null) ||
        (isAuthProtected && jwtToken != null && !validateAccessToken(jwtToken))
      ) {
        onLogout()

        return (
          <Redirect to={{ pathname: '/login', state: { from: location } }} />
        )
      }

      return (
        <Layout>
          <Component {...props} />
        </Layout>
      )
    }}
  />
)

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
  org: PropTypes.string,
  tenant: PropTypes.string,
  userFeat: PropTypes.any,
  jwtToken: PropTypes.string,
  onLogout: PropTypes.func,
}

const mapStateToProps = ({ login }) => ({
  tenant: login.tenant,
  org: login.org,
  userFeat: login.features,
  jwtToken: login.jwtToken,
})

const mapDispatchToProps = (dispatch) => ({
  onLogout: () => dispatch(logout()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Authmiddleware))
