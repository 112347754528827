import PropTypes from 'prop-types'
import React from 'react'
import { withTranslation } from 'react-i18next'
import { Row, Col, Card, CardBody, Progress } from 'reactstrap'
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import NumberFormat from 'react-number-format'

const ServiceTable = (props) => {
  return (
    <Row>
      <Col xl='12'>
        <Card>
          <CardBody>
            <div className='table-rep-plugin'>
              <div
                className='table-responsive mb-0'
                data-pattern='priority-columns'
              >
                <Table id='tech-companies-1' className='table'>
                  <Thead>
                    <Tr>
                      <Th>{props.t('Service')}</Th>
                      <Th data-priority='1'>{props.t('Volume')}</Th>
                      <Th data-priority='3'>{props.t('Total Sales')}</Th>
                      <Th data-priority='1'>{props.t('Dealer Cost')}</Th>
                      <Th data-priority='6'>{props.t('% of Work')}</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {props.items &&
                      props.items.map((item, idx) => (
                        <Tr key={idx}>
                          <Th>
                            <span className='co-name'>{item.name}</span>
                          </Th>
                          <Td>
                            <NumberFormat
                              value={item.vol}
                              displayType={'text'}
                              fixedDecimalScale={true}
                              decimalScale={0}
                              thousandSeparator={true}
                            />
                          </Td>
                          <Td>
                            <NumberFormat
                              value={item.totalSales}
                              displayType={'text'}
                              fixedDecimalScale={true}
                              decimalScale={0}
                              thousandSeparator={true}
                              prefix={'$'}
                            />
                          </Td>
                          <Td>
                            <NumberFormat
                              value={item.dealerCost}
                              displayType={'text'}
                              fixedDecimalScale={true}
                              decimalScale={0}
                              thousandSeparator={true}
                              prefix={'$'}
                            />
                          </Td>
                          <Td>
                            <div>
                              <Progress
                                value={item.perWork}
                                style={{ height: '1rem' }}
                              >
                                <NumberFormat
                                  value={item.perWork}
                                  displayType={'text'}
                                  fixedDecimalScale={true}
                                  decimalScale={0}
                                  thousandSeparator={true}
                                  suffix={'%'}
                                />
                              </Progress>
                            </div>
                          </Td>
                        </Tr>
                      ))}
                  </Tbody>
                </Table>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

ServiceTable.propTypes = {
  t: PropTypes.any,
  items: PropTypes.list,
}

export default withTranslation()(ServiceTable)
