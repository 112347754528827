import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import MetaTags from 'react-meta-tags'
import { Container, Row, Col, Button } from 'reactstrap'
import { isMobile } from 'react-device-detect'

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb'

//i18n
import { withTranslation } from 'react-i18next'
import AddNewService from 'pages/AddNewService'
import GlistenInfoBox from '../../components/GlistenInfoBox'

import DashboardFilter from './DashboardFilter'
import ServiceTable from './ServiceTable'
import WorkflowMetricsGraph from './WorkflowMetricsGraph'
import DurationStatusBox from './DurationStatusBox'
import GlistenOrderUpdateBox from '../../components/GlistenOrderUpdateBox'
import GlistenTopPerformerTable from '../../components/GlistenTopPerformerTable'

import { connect } from 'react-redux'

import { getDashboard } from 'store/actions'
import { sendPageViewEvent } from '../../firebase'

const Dashboard = (props) => {
  const { onGetDashboard } = props

  useEffect(() => {
    onGetDashboard()
    sendPageViewEvent('Dashboard')
  }, [onGetDashboard])

  const [modal_center, setmodal_center] = useState(false)

  var title = !isMobile
    ? props.loggedInUserFirstName + "'s " + props.t('Dashboard')
    : props.t('Dashboard')
  props.setPageTitle(title)

  function tog_center() {
    setmodal_center(!modal_center)
    removeBodyCss()
  }

  function removeBodyCss() {
    document.body.classList.add('no_padding')
  }

  const DashboardButtonComponent = () => (
    <div>
      <Button
        data-toggle='modal'
        data-target='.bs-example-modal-center'
        color='dark'
        className='prim-btn-big'
        style={{ float: 'right' }}
        onClick={() => {
          tog_center()
        }}
      >
        {props.t('New Order')}
      </Button>
    </div>
  )

  return (
    <React.Fragment>
      <div className='breadsrumb-content'>
        <Breadcrumbs
          title={props.t('Home')}
          breadcrumbItem={title}
          showBack={false}
          filter={<DashboardButtonComponent />}
        />
      </div>
      <div className='page-content'>
        <MetaTags>
          <title>Home | {title}</title>
        </MetaTags>
        <Container fluid style={{ maxWidth: '95%', paddingTop: '1rem' }}>
          <Row>
            <Col xl='5'>
              <h3>{props.t('Service Analytics')}</h3>
            </Col>
            <Col xl='7' style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <DashboardFilter />
            </Col>
          </Row>

          <hr className='mt-4' />

          <Row>
            <Col xl='2'>
              <GlistenInfoBox
                title={props.t('Unused Capacity')}
                type='dollar'
                value={
                  props.summary &&
                  props.summary.capacity &&
                  props.summary.capacity.value
                }
                unitValue={
                  props.summary &&
                  props.summary.capacity &&
                  props.summary.capacity.unit
                }
              />
            </Col>

            <Col xl='2'>
              <GlistenInfoBox
                title={props.t('Gross Revenue')}
                type='dollar'
                value={
                  props.summary &&
                  props.summary.revenue &&
                  props.summary.revenue.value
                }
                badgeValue={
                  props.summary &&
                  props.summary.revenue &&
                  props.summary.revenue.change
                }
              />
            </Col>

            <Col xl='2'>
              <GlistenInfoBox
                title={props.t('Gross Profit')}
                type='dollar'
                value={
                  props.summary &&
                  props.summary.profit &&
                  props.summary.profit.value
                }
                badgeValue={
                  props.summary &&
                  props.summary.profit &&
                  props.summary.profit.change
                }
              />
            </Col>

            <Col xl='2'>
              <GlistenInfoBox
                title={props.t('Average Duration')}
                type='normal'
                value={
                  props.summary &&
                  props.summary.duration &&
                  props.summary.duration.value
                }
              />
            </Col>

            <Col xl='2'>
              <GlistenInfoBox
                title={props.t('Service Delivered')}
                type='normal'
                value={
                  props.summary &&
                  props.summary.delivered &&
                  props.summary.delivered.value
                }
              />
            </Col>
          </Row>

          <hr className='mt-4' />

          <Row>
            <Col xl='7'>
              <Row>
                <Col xl='12'>
                  <h4>{props.t('Popular Services')}</h4>
                </Col>
                <Col xl='12' style={{ paddingTop: '1rem' }}>
                  <ServiceTable items={props.popularServices} />
                </Col>
              </Row>

              <Row style={{ paddingTop: '2rem' }}>
                <Col xl='12'>
                  <h4>{props.t('Latest Updates')}</h4>
                </Col>
                <Col xl='12' style={{ paddingTop: '1rem' }}>
                  {props.updates &&
                    props.updates.map((item, idx) => (
                      <GlistenOrderUpdateBox
                        key={idx}
                        user={item.name}
                        timestamp={item.timestamp}
                        message={item.message}
                        image={item.avatarImg}
                      />
                    ))}
                </Col>
              </Row>
            </Col>
            <Col xl='5'>
              <Row>
                <Col xl='12'>
                  <h4>{props.t('Workflow Metrics')}</h4>
                </Col>
              </Row>
              <Row>
                <Col xl='12' style={{ paddingTop: '1rem' }}>
                  <WorkflowMetricsGraph data={props.workflows} />
                </Col>
              </Row>
              <Row style={{ paddingTop: '2rem' }}>
                <Col xl='12'>
                  <h4>{props.t('Average Duration')}</h4>
                </Col>
              </Row>

              <Row>
                <Col xl='4'>
                  <DurationStatusBox
                    title={'5 Mins'}
                    context={
                      props.workflows &&
                      props.workflows.series &&
                      props.workflows.series[0].name
                    }
                    color={
                      props.workflows &&
                      props.workflows.colors &&
                      props.workflows.colors[0]
                    }
                    isUp={true}
                  />
                </Col>
                <Col xl='4'>
                  <DurationStatusBox
                    title={'10 Mins'}
                    context={
                      props.workflows &&
                      props.workflows.series &&
                      props.workflows.series[1].name
                    }
                    color={
                      props.workflows &&
                      props.workflows.colors &&
                      props.workflows.colors[1]
                    }
                    isUp={true}
                  />
                </Col>
                <Col xl='4'>
                  <DurationStatusBox
                    title={'30 Mins'}
                    context={
                      props.workflows &&
                      props.workflows.series &&
                      props.workflows.series[2].name
                    }
                    color={
                      props.workflows &&
                      props.workflows.colors &&
                      props.workflows.colors[2]
                    }
                    isUp={false}
                  />
                </Col>
              </Row>

              <>
                {' '}
                <Row style={{ paddingTop: '2rem' }}>
                  <Col xl='12'>
                    <h4>{props.t('Top Performing Detailer')}</h4>
                  </Col>
                </Row>
                <Row>
                  <Col xl='12'>
                    <GlistenTopPerformerTable items={props.topPerformers} />
                  </Col>
                </Row>
              </>
            </Col>
          </Row>
        </Container>
      </div>

      <AddNewService
        isOpen={modal_center}
        tog_center={tog_center}
        setmodal_center={setmodal_center}
      />
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  setPageTitle: PropTypes.func,
  t: PropTypes.any,
  loggedInUsername: PropTypes.string,
  loggedInUserFeatures: PropTypes.list,
  loggedInUserTenant: PropTypes.string,
  loggedInUserFirstName: PropTypes.string,
  loggedInUserLastName: PropTypes.string,
  summary: PropTypes.any,
  popularServices: PropTypes.list,
  workflows: PropTypes.list,
  updates: PropTypes.list,
  topPerformers: PropTypes.list,
  onGetDashboard: PropTypes.func,
}

const mapStateToProps = ({ dashboard, login }) => ({
  loggedInUsername: login.username,
  loggedInUserFeatures: login.features,
  loggedInUserTenant: login.tenant,
  loggedInUserFirstName: login.firstName,
  loggedInUserLastName: login.lastName,
  summary: dashboard.summary,
  popularServices: dashboard.popularServices,
  workflows: dashboard.workflows,
  updates: dashboard.updates,
  topPerformers: dashboard.topPerformers,
})

const mapDispatchToProps = (dispatch) => ({
  onGetDashboard: () => dispatch(getDashboard()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Dashboard))
