/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import {
  AuthenticationDetails,
  CognitoUserPool,
  CognitoUser,
} from 'amazon-cognito-identity-js'
import { decode, verify } from 'jsonwebtoken'
import jwkToBuffer from 'jwk-to-pem'
import * as requestPromise from 'request-promise'

var cognitoUser

const poolData =
  process.env.REACT_APP_BACKEND_ENV === 'local'
    ? {
        UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
        ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
        endpoint: process.env.REACT_APP_COGNITO_ENDPOINT,
      }
    : {
        UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
        ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
      }

const getwebToken = async () => {
  const jwkWebToken =
    process.env.REACT_APP_BACKEND_ENV === 'local'
      ? {
          keys: [
            {
              alg: 'RS256',
              e: 'AQAB',
              kid: 'CognitoLocal',
              kty: 'RSA',
              n:
                '2uLO7yh1_6Icfd89V3nNTc_qhfpDN7vEmOYlmJQlc9_RmOns26lg88fXXFntZESwHOm7_homO2Ih6NOtu4P5eskGs8d8VQMOQfF4YrP-pawVz-gh1S7eSvzZRDHBT4ItUuoiVP1B9HN_uScKxIqjmitpPqEQB_o2NJv8npCfqUAU-4KmxquGtjdmfctswSZGdz59M3CAYKDfuvLH9_vV6TRGgbUaUAXWC2WJrbbEXzK3XUDBrmF3Xo-yw8f3SgD3JOPl3HaaWMKL1zGVAsge7gQaGiJBzBurg5vwN61uDGGz0QZC1JqcUTl3cZnrx_L8isIR7074SJEuljIZRnCcjQ',
              use: 'sig',
            },
            {
              alg: 'RS256',
              e: 'AQAB',
              kid: 'CognitoLocal',
              kty: 'RSA',
              n:
                '2uLO7yh1_6Icfd89V3nNTc_qhfpDN7vEmOYlmJQlc9_RmOns26lg88fXXFntZESwHOm7_homO2Ih6NOtu4P5eskGs8d8VQMOQfF4YrP-pawVz-gh1S7eSvzZRDHBT4ItUuoiVP1B9HN_uScKxIqjmitpPqEQB_o2NJv8npCfqUAU-4KmxquGtjdmfctswSZGdz59M3CAYKDfuvLH9_vV6TRGgbUaUAXWC2WJrbbEXzK3XUDBrmF3Xo-yw8f3SgD3JOPl3HaaWMKL1zGVAsge7gQaGiJBzBurg5vwN61uDGGz0QZC1JqcUTl3cZnrx_L8isIR7074SJEuljIZRnCcjQ',
              use: 'sig',
            },
          ],
        }
      : // : {"keys":[{"alg":"RS256","e":"AQAB","kid":"NPZxZodqQLqT/wv8aet5Sy5m7BSc8z8Ws7PFjQstsSA=","kty":"RSA","n":"veSe3LhZY0SgUSlnNxZdBW4nW72G5r4hVY6cTyvyDxgy59XTPvyxGAcj4bOE9aCBZ5DkM83I3dWqJVC59Ori-rPHRLYsEhvNlGabIvyh0Q-t1dE5brrXZIpGXvKxcqqDLaGIS0De4RXcriLEPZhfGnqIVrAZk-O2ePnsQyOULG-F4xzEDtZwFikOb4OZlATvtp1ssd7cNhrppyMZueWYVbnC9VtGdZ7p9htsvH52Sw4OgeHX1LSqJVj7Q2BBe6xFaF4_rIfoYPyNW8xTAvHCdZDobZ61T182MJzLrzqi1xrSAPpMgwxydqrvrTsImw-RzZTBkTuUzVqeeHduccKVVQ","use":"sig"},{"alg":"RS256","e":"AQAB","kid":"tU+UxzS5xwg6fPqySIiQySq/ZLgOfYB7dBHtYz2qmxQ=","kty":"RSA","n":"tznw4DztSR3OGfqkKQPDUnxJoXwterxDsiq7BCTgrBOm7doGFOySoK8dOMKo8p_1__NYFEDMw1KYPVaRvCvlhN0FGij26gdkYUGJRukLmQbkTOXx2RHwM-O1wi_fTIE6ZNtvX6DRqkfjaTQHcyfsNp-pjYePx89r3IhkW2IIRXGtcCZVF8eITiYaqj1LwspmJGB7xTEHx70eGrFCKDiimJcyx7K_iQMCS5c5W8lKqZIGfDIoQ8i-k8CaDlKvYkDbnKPSM41fMSv8f66YqqhoXP9DJ349whmsMZVuK0g41RSRU-DtKgEEBkbjMWfoq0q5NtYY0sDEG4-2BF5a5N5NUw","use":"sig"}]}
        await requestPromise({
          uri: `https://cognito-idp.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/${poolData.UserPoolId}/.well-known/jwks.json`,
          json: true,
        })

  return jwkWebToken
}

export const verifyJWT = (token) => {
  return getwebToken().then((body) => {
    try {
      var pems = {}
      var keys = body['keys']

      for (var i = 0; i < keys.length; i++) {
        var key_id = keys[i].kid
        var modulus = keys[i].n
        var exponent = keys[i].e
        var key_type = keys[i].kty
        var jwk = { kty: key_type, n: modulus, e: exponent }
        pems[key_id] = jwkToBuffer(jwk)
      }

      var decodedJwt = decode(token, { complete: true })
      if (!decodedJwt) {
        console.log('ERROR decodedJWT Empty')
        throw Error('JWT Decode Failed')
      }
      var kid = decodedJwt.header.kid
      var pem = pems[kid]
      if (!pem) {
        console.log('ERROR pem Empty')
        throw Error('Pem Error')
      }

      const claims = verify(token, pem)
      return claims
    } catch (e) {
      return null
    }
  })
}

export const authenticateUser = (username, password) => {
  var authenticationData = {
    Username: username,
    Password: password,
  }
  var authenticationDetails = new AuthenticationDetails(authenticationData)

  var userPool = new CognitoUserPool(poolData)

  var userData = {
    Username: username,
    Pool: userPool,
  }

  cognitoUser = new CognitoUser(userData)
  cognitoUser.signOut()

  cognitoUser.setAuthenticationFlowType('USER_PASSWORD_AUTH')

  return new Promise((resolve, reject) => {
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: function (result) {
        const accessToken = result.getAccessToken().getJwtToken()

        process.env.REACT_APP_BACKEND_ENV === 'local'
          ? verifyJWT(accessToken)
              .then((result) => {
                console.log('profileData Received::' + result)
                console.log(result)
                const response = {
                  jwtToken: accessToken,
                  username: result['username'],
                  firstName: result['given_name'],
                  lastName: result['family_name'],
                  org: result['org'],
                  tenant: result['tenant'],
                  email: result['email'],
                  features: result['features'] ? result['features'] : [],
                }
                resolve(response)
              })
              .catch((err) => reject(err))
          : resolve({
              jwtToken: accessToken,
              username: result.getIdToken().payload['custom:username'],
              firstName: result.getIdToken().payload['given_name'],
              lastName: result.getIdToken().payload['family_name'],
              org: result.getIdToken().payload['custom:org'],
              tenant: result.getIdToken().payload['custom:tenant'],
              email: result.getIdToken().payload['email'],
              features: result.getIdToken().payload['custom:features']
                ? result.getIdToken().payload['custom:features']
                : [],
            })
      },

      onFailure: function (err) {
        reject(err)
      },
    })
  })
}

export const resendConfirmation = (username) => {
  var userPool = new CognitoUserPool(poolData)
  var userData = {
    Username: username,
    Pool: userPool,
  }
  cognitoUser = new CognitoUser(userData)
  cognitoUser.getSession((err, session) => {})

  return new Promise((resolve, reject) => {
    cognitoUser.resendConfirmationCode((err, res) => {
      if (err) {
        reject(err)
      } else {
        resolve(res)
      }
    })
  })
}

export const confirmRegistration = (username, code) => {
  var userPool = new CognitoUserPool(poolData)
  var userData = {
    Username: username,
    Pool: userPool,
  }
  cognitoUser = new CognitoUser(userData)
  cognitoUser.getSession((err, session) => {})

  return new Promise((resolve, reject) => {
    cognitoUser.confirmRegistration(code, true, (err, result) => {
      if (err) {
        reject(err)
      } else {
        resolve(result)
      }
    })
  })
}

export const forgotPassword = (username) => {
  var userPool = new CognitoUserPool(poolData)
  var userData = {
    Username: username,
    Pool: userPool,
  }
  var cognitoUser = new CognitoUser(userData)

  return new Promise((resolve, reject) => {
    cognitoUser.forgotPassword({
      onSuccess: (data) => {
        resolve(data)
      },
      onFailure: (err) => {
        reject(err)
      },
    })
  })
}

export const confirmPassword = (username, code, password) => {
  var userPool = new CognitoUserPool(poolData)
  var userData = {
    Username: username,
    Pool: userPool,
  }
  cognitoUser = new CognitoUser(userData)

  return new Promise((resolve, reject) => {
    cognitoUser.confirmPassword(code, password, {
      onSuccess: () => {
        resolve('SUCCESS')
      },
      onFailure: (err) => {
        reject(err)
      },
    })
  })
}

export const changePassword = (newPassword, oldPassword) => {
  var userPool = new CognitoUserPool(poolData)
  var cognitoUser = userPool.getCurrentUser()
  cognitoUser.getSession((err, session) => {})

  return new Promise((resolve, reject) => {
    cognitoUser.changePassword(oldPassword, newPassword, (err, result) => {
      if (err) {
        reject(err)
      } else {
        resolve(result)
      }
    })
  })
}

export const signOut = () => {
  var userPool = new CognitoUserPool(poolData)
  var cognitoUser = userPool.getCurrentUser()
  cognitoUser.getSession((err, session) => {})

  return new Promise((resolve, reject) => {
    cognitoUser.signOut(() => resolve(true))
  })
}
