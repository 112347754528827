import {
  GET_FINANCE_DATA,
  GET_FINANCE_DATA_SUCCESS,
  GET_FINANCE_DATA_FAIL,
  FINANCE_FILTER_SEARCH_BY_LAST_6_VIN,
  FINANCE_SORT_BY_COMPLETED_ON,
  FINANCE_SORT_BY_PRICE,
} from './actionTypes'

const initialState = {
  isLoading: false,
  completedOrders: [],
  financeData: [],
  orderDetail: null,
  error: {},
}

const finance = (state = initialState, action) => {
  switch (action.type) {
    case GET_FINANCE_DATA:
      console.log('reducer::GET_FINANCE_DATA action.payload=' + action.payload)

      state = {
        completedOrders: [],
        financeData: [],
        orderDetail: null,
        isLoading: true,
      }
      break

    case GET_FINANCE_DATA_SUCCESS:
      console.log('reducer::GET_FINANCE_DATA action.payload=' + action.payload)

      var completedOrder = action.payload.serviceordersnapshot

      state = {
        ...state,
        isLoading: false,
        completedOrders: completedOrder,
        financeData: completedOrder.sort((a, b) =>
          a.timestamp < b.timestamp ? 1 : -1
        ),
      }
      break

    case GET_FINANCE_DATA_FAIL:
      console.log(
        'reducer::GET_ALL_ORDERS_FAIL action.payload=' + action.payload
      )

      state = {
        ...state,
        isLoading: false,
        error: action.payload,
      }
      break

    case FINANCE_FILTER_SEARCH_BY_LAST_6_VIN:
      console.log(
        'reducer::FINANCE_FILTER_SEARCH_BY_LAST_6_VIN action.payload=' +
          action.payload.lastVin
      )
      var filteredWith6Vin =
        action.payload.lastVin == ''
          ? state.completedOrders
          : state.completedOrders.filter((curr) =>
              curr.vehicle.data_key.slice(-6).startsWith(action.payload.lastVin)
            )

      if (state.filterStartDate != null && state.filterEndDate != null) {
        filteredWith6Vin = filteredWith6Vin.filter(
          (curr) =>
            curr.timestamp > state.filterStartDate &&
            curr.timestamp < state.filterEndDate
        )
      }

      state = {
        ...state,
        isLoading: false,
        filter6vin: action.payload.lastVin,
        financeData: filteredWith6Vin,
      }
      break

    case FINANCE_SORT_BY_COMPLETED_ON:
      console.log(
        'reducer::FINANCE_FILTER_SEARCH_BY_COMPLETED_ON action.payload=' +
          JSON.stringify(action.payload)
      )

      var sortedByCompletedFinanceData = state.financeData.sort((a, b) =>
        a.timestamp > b.timestamp && action.payload.byAscending ? 1 : -1
      )
      state = {
        ...state,
        isLoading: false,
        financeData: sortedByCompletedFinanceData,
      }
      break

    case FINANCE_SORT_BY_PRICE:
      console.log(
        'reducer::FINANCE_FILTER_SEARCH_BY_COMPLETED_ON action.payload=' +
          action.payload
      )
      var sortedByPrice = state.financeData.sort((a, b) =>
        a.service_catalog.reduce((accum, curr) => accum + curr.retail_cost, 0) >
          b.service_catalog.reduce(
            (accum, curr) => accum + curr.retail_cost,
            0
          ) && action.payload.byAscending
          ? 1
          : -1
      )

      state = {
        ...state,
        isLoading: false,
        financeData: sortedByPrice,
      }
      break

    default:
      break
  }
  return state
}

export default finance
