import { takeEvery, put, call, delay } from 'redux-saga/effects'
import { SUBMIT_SERVICE_ORDER } from './actionTypes'
import { submitServiceOrderSuccess, submitServiceOrderyFail } from './actions'
import { getAllOrders, delayLoading } from '../order/actions'

//Include Both Helper File with needed methods
import { callSubmitServiceOrder } from '../../../helpers/fakebackend_helper'

const manualDelay = 3000
const orderDefaultFrom = new Date().setDate(new Date().getDate() - 60) // HEK: Temporarly only... should be 10 days

function* fetchSubmitServiceOrder({ payload: { org, tenant, order } }) {
  console.log('saga::fetchSubmitServiceOrder()')

  try {
    const response = yield call(callSubmitServiceOrder, org, tenant, order)
    if (response.error) {
      yield put(submitServiceOrderyFail(response.error))
    } else {
      yield put(delayLoading())
      yield put(submitServiceOrderSuccess(response))
      yield delay(manualDelay)
      yield put(getAllOrders(org, tenant, orderDefaultFrom))
    }
  } catch (error) {
    yield put(submitServiceOrderyFail(error))
  }
}

export function* serviceSaga() {
  yield takeEvery(SUBMIT_SERVICE_ORDER, fetchSubmitServiceOrder)
}

export default serviceSaga
