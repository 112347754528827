/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { getTokenKey } from 'firebase'
import { addDeviceInfo } from 'store/actions'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

const Notifications = (props) => {
  const [isTokenFound, setTokenFound] = useState(false)

  console.log('Token found', isTokenFound)

  // To load once
  useEffect(() => {
    let data

    async function tokenFunc() {
      data = await getTokenKey(setTokenFound)
      if (data) {
        props.onAddDeviceInfo(props.org, props.tenant, props.username, data)
        console.log('Token is', data)
      }
      return data
    }

    tokenFunc()
  }, [setTokenFound])

  return <></>
}

Notifications.propTypes = {
  org: PropTypes.string,
  tenant: PropTypes.string,
  username: PropTypes.any,
  onAddDeviceInfo: PropTypes.func,
}

const mapStateToProps = ({ login }) => ({
  tenant: login.tenant,
  org: login.org,
  userFeat: login.features,
  username: login.username,
})

const mapDispatchToProps = (dispatch) => ({
  onAddDeviceInfo: (org, tenant, username, deviceUuid) =>
    dispatch(addDeviceInfo(org, tenant, username, deviceUuid)),
})

Notifications.propTypes = {}

export default connect(mapStateToProps, mapDispatchToProps)(Notifications)
