import {
  GET_PROFILE_DATA,
  GET_PROFILE_DATA_SUCCESS,
  GET_PROFILE_DATA_FAIL,
  UPDATE_PROFILE_DATA,
  UPDATE_PROFILE_DATA_SUCCESS,
  UPDATE_PROFILE_DATA_FAIL,
  ADD_PROFILE_DATA,
  ADD_PROFILE_DATA_SUCCESS,
  ADD_PROFILE_DATA_FAIL,
} from './actionTypes'

export const getProfileData = (org, tenant, username) => {
  console.log('actions::getProfileData()')
  return {
    type: GET_PROFILE_DATA,
    payload: { org, tenant, username },
  }
}

export const updateProfileData = (org, tenant, username, profileData) => {
  console.log('actions::updateProfile()')
  return {
    type: UPDATE_PROFILE_DATA,
    payload: { org, tenant, username, profileData },
  }
}

export const getProfileDateSuccess = (profileRecord) => {
  console.log('actions::getProfileDateSuccess() profileRecord=' + profileRecord)
  return {
    type: GET_PROFILE_DATA_SUCCESS,
    payload: profileRecord,
  }
}

export const getProfileDateFail = (error) => {
  console.log('actions::getProfileDateFail() error=' + error)
  return {
    type: GET_PROFILE_DATA_FAIL,
    payload: error,
  }
}

export const updateProfileSuccess = (refKey, profileData) => {
  console.log('actions::updateProfileSuccess()')
  return {
    type: UPDATE_PROFILE_DATA_SUCCESS,
    payload: { refKey, profileData },
  }
}

export const updateProfileFail = (error) => {
  console.log('actions::getProfileDateFail() error=' + error)
  return {
    type: UPDATE_PROFILE_DATA_FAIL,
    payload: error,
  }
}

export const addProfileData = (profileData) => {
  console.log('actions::addProfileData()')
  return {
    type: ADD_PROFILE_DATA,
    payload: profileData,
  }
}

export const addProfileSuccess = () => {
  console.log('actions::addProfileSuccess()')
  return {
    type: ADD_PROFILE_DATA_SUCCESS,
  }
}

export const addProfileFail = (error) => {
  console.log('actions::addProfileFail() error=' + error)
  return {
    type: ADD_PROFILE_DATA_FAIL,
    payload: error,
  }
}
