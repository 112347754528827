/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { Row, Col, Modal, Label } from 'reactstrap'

// Styling
import '../EditDealership/editdealership.scss'

//i18n
import { withTranslation } from 'react-i18next'

import { ReactComponent as Logo } from '../../../assets/images/ic-close.svg'
import { useForm, FormProvider } from 'react-hook-form'
import GlistenDropdown from '../../../components/GlistenDropdown'

import { sendPageViewEvent } from '../../../firebase'
import { STATES } from 'common/contants'

const EditDealership = (props) => {
  const methods = useForm()

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = methods

  const onSubmit = (evt) => {
    const tenant = {
      type: props.tenant_type,
      name: evt.dealershipName,
      addressOne: evt.dealershipAddress,
      city: evt.dealershipCity,
      zip: evt.dealershipZipcode,
      state: evt.dealershipState,
      department: props.department,
      logo: props.logo,
    }
    if (props.tenant_type == 'Dealership') {
      tenant['detailshop'] = props.targetTenant
    } else if (props.tenant_type == 'Detailshop') {
      tenant['dealership'] = props.targetTenant
    }

    console.log('*******************')
    console.log(tenant)
    console.log('*******************')

    props.onSubmit(tenant)
    props.showModal(false)
  }

  useEffect(() => {
    sendPageViewEvent('Edit Dealership')
  }, [])

  return (
    <FormProvider {...methods}>
      <Modal
        size='xl'
        isOpen={props.isOpen}
        toggle={() => {
          props.tog_center()
        }}
        centered={true}
      >
        <div className='modal-header glisten-modal-header'>
          <div>
            <h3 className='glisten-modal-title'>{props.title}</h3>
          </div>

          <button
            className='close-btn'
            onClick={() => {
              props.showModal(false)
            }}
          >
            <Logo />
          </button>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className='form-horizontal'>
          <div
            className='modal-body glisten-modal-body'
            style={{ paddingTop: '1.188rem' }}
          >
            <Row>
              <Col lg='5'>
                {props.userFeat.includes('ftl-dealership') && (
                  <Label
                    className={
                      watch('dealershipName') &&
                      watch('dealershipName').length > 0
                        ? 'glisten-textfield-label'
                        : 'glisten-textfield-label glisten-textfield-label-hidden'
                    }
                  >
                    {props.t('Dealership Name')}
                  </Label>
                )}

                {props.userFeat.includes('ftl-detailshop') && (
                  <Label
                    className={
                      watch('dealershipName') &&
                      watch('dealershipName').length > 0
                        ? 'glisten-textfield-label'
                        : 'glisten-textfield-label glisten-textfield-label-hidden'
                    }
                  >
                    {props.t('Detail Shop Name')}
                  </Label>
                )}

                {props.userFeat.includes('ftl-dealership') && (
                  <input
                    id='dealershipName'
                    name='dealershipName'
                    label={props.t('Department Name')}
                    className='form-control glisten-textfield'
                    placeholder={props.t('Dealership Name')}
                    defaultValue={props.name}
                    {...register('dealershipName', {
                      required: props.t('Please enter dealership name.'),
                    })}
                  />
                )}
                {errors.dealershipName?.message &&
                  props.userFeat.includes('ftl-dealership') && (
                    <p>{errors.dealershipName.message}</p>
                  )}

                {props.userFeat.includes('ftl-detailshop') && (
                  <input
                    id='dealershipName'
                    name='dealershipName'
                    label={props.t('Department Name')}
                    className='form-control glisten-textfield'
                    placeholder={props.t('Detail Shop Name')}
                    defaultValue={props.name}
                    {...register('dealershipName', {
                      required: props.t('Please enter detail shop name.'),
                    })}
                  />
                )}
                {errors.dealershipName?.message &&
                  props.userFeat.includes('ftl-detailshop') && (
                    <p>{errors.dealershipName.message}</p>
                  )}
              </Col>
              <Col lg='5'>
                <Label
                  className={
                    watch('dealershipAddress') &&
                    watch('dealershipAddress').length > 0
                      ? 'glisten-textfield-label'
                      : 'glisten-textfield-label glisten-textfield-label-hidden'
                  }
                >
                  {props.t('Address 1')}
                </Label>
                <input
                  id='dealershipAddress'
                  name='dealershipAddress'
                  label={props.t('Address 1')}
                  className='form-control glisten-textfield'
                  placeholder={props.t('Address 1')}
                  defaultValue={props.address}
                  {...register('dealershipAddress', {
                    required: props.t('Please enter dealership address.'),
                  })}
                />
                {errors.dealershipAddress?.message && (
                  <p>{errors.dealershipAddress.message}</p>
                )}
              </Col>
            </Row>
            <Row>
              <Col lg='5'>
                <Label
                  className={
                    watch('dealershipCity') &&
                    watch('dealershipCity').length > 0
                      ? 'glisten-textfield-label'
                      : 'glisten-textfield-label glisten-textfield-label-hidden'
                  }
                  style={{ marginTop: '2.813rem' }}
                >
                  {props.t('City')}
                </Label>
                <input
                  id='dealershipCity'
                  name='dealershipCity'
                  label={props.t('City')}
                  className='form-control glisten-textfield'
                  placeholder={props.t('City')}
                  defaultValue={props.city}
                  {...register('dealershipCity', {
                    required: props.t('Please enter dealership city.'),
                  })}
                />
                {errors.dealershipCity?.message && (
                  <p>{errors.dealershipCity.message}</p>
                )}
              </Col>
              <Col lg='5'>
                <Label
                  className={
                    watch('dealershipZipcode') &&
                    watch('dealershipZipcode').length > 0
                      ? 'glisten-textfield-label'
                      : 'glisten-textfield-label glisten-textfield-label-hidden'
                  }
                  style={{ marginTop: '2.813rem' }}
                >
                  {props.t('ZIP Code')}
                </Label>
                <input
                  id='dealershipZipcode'
                  name='dealershipZipcode'
                  label={props.t('ZIP Code')}
                  className='form-control glisten-textfield'
                  placeholder={props.t('ZIP Code')}
                  defaultValue={props.zip}
                  {...register('dealershipZipcode', {
                    required: props.t('Please enter dealership zip code.'),
                  })}
                />
                {errors.dealershipZipcode?.message && (
                  <p>{errors.dealershipZipcode.message}</p>
                )}
              </Col>
            </Row>
            <Row style={{ marginBottom: '8.313rem' }}>
              <Col lg='5'>
                <Label
                  className={'glisten-textfield-label'}
                  style={{ marginTop: '2.813rem' }}
                >
                  {props.t('State')}
                </Label>
                <GlistenDropdown
                  formRegistragionName='dealershipState'
                  placeholder={props.t('State')}
                  items={STATES}
                  value={props.state}
                  validationMsg={props.t('Select a State')}
                />
              </Col>
            </Row>
          </div>

          <div className='modal-footer glisten-modal-footer'>
            <div>
              <input
                className='btn glisten-btn prim-mod-action'
                type='submit'
                value={props.submitBtnTitle}
              />
            </div>
          </div>
        </form>
      </Modal>
    </FormProvider>
  )
}

EditDealership.propTypes = {
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  tog_center: PropTypes.func,
  showModal: PropTypes.func,
  submitBtnTitle: PropTypes.string,
  submitBtnDisabled: PropTypes.bool,
  t: PropTypes.any,
  logo: PropTypes.string,
  name: PropTypes.string,
  address: PropTypes.string,
  city: PropTypes.string,
  zip: PropTypes.string,
  state: PropTypes.string,
  targetTenant: PropTypes.string,
  department: PropTypes.any,
  tenant_type: PropTypes.string,
  serviceCatalog: PropTypes.any,
  onSubmit: PropTypes.func,
  userFeat: PropTypes.any,
}

export default withTranslation()(EditDealership)
