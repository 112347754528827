/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { Row, Col, Label, Collapse } from 'reactstrap'

import PropTypes from 'prop-types'

import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import GlistenServiceBox from '../../../components/GlistenServiceBox'
import GlistenOrderSummary from '../../../components/GlistenOrderSummary'

import { updateSelectedService, getTenantData } from 'store/actions'

import { useFormContext } from 'react-hook-form'
import { sendPageViewEvent } from '../../../firebase'

import '../SelectService/selectservice.scss'

const dollarPrefix = '$'
const SelectService = (props) => {
  const { onGetTenantData } = props
  const [isOpen, setIsOpen] = useState(
    [...props.services, false, false].map((_) => false)
  )
  const [customServiceName, setCustomServiceName] = useState('')
  const [customServiceDescription, setCustomServiceDescription] = useState('')
  const [customServicePrice, setCustomServicePrice] = useState('')

  const [customServiceError, setCustomServiceError] = useState({})

  const methods = useFormContext()
  const {
    formState: { errors },
    setValue,
  } = methods

  useEffect(() => {
    onGetTenantData(props.org, props.tenant)
    sendPageViewEvent('Select Service')
  }, [onGetTenantData])

  const assignGroupImg = (group_name) => {
    if (group_name.replaceAll(' ', '').toUpperCase() == 'CARWASH') {
      return 'illust-icons/ic-car wash.svg'
    } else if (
      group_name.replaceAll(' ', '').toUpperCase() == 'SERVICEDETAIL'
    ) {
      return 'illust-icons/ic-service detail.svg'
    } else if (group_name.replaceAll(' ', '').toUpperCase() == 'CARDELIVERY') {
      return 'illust-icons/ic-car delivery.svg'
    } else if (group_name.replaceAll(' ', '').toUpperCase() == 'POLISH') {
      return 'illust-icons/ic-polish.svg'
    } else if (group_name.replaceAll(' ', '').toUpperCase() == 'NEWCARPREP') {
      return 'illust-icons/ic-new car prep.svg'
    } else if (
      group_name.replaceAll(' ', '').toUpperCase() == 'COMPLETERECONDITION'
    ) {
      return 'illust-icons/ic-complete recondition.svg'
    } else if (
      group_name.replaceAll(' ', '').toUpperCase() == 'CUSTOMSERVICES'
    ) {
      return 'illust-icons/ic-custom service.svg'
    } else {
      return 'illust-icons/ic-custom category.svg'
    }
  }

  var customs = methods.watch('customs') ? methods.watch('customs') : []

  const addCustomService = () => {
    var error = {}
    if (customServiceName.trim() == '') {
      error['servicename'] = 'Please enter service name'
    }

    if (customServiceDescription.trim() == '') {
      error['servicedesc'] = props.t('Please enter service description')
    }

    const price = customServicePrice.trim().replace('$', '')
    if (price.trim() == '') {
      error['serviceprice'] = props.t('Please enter service price')
    }
    if (price.trim() != '' && isNaN(parseFloat(price))) {
      error['serviceprice'] = props.t('Price format is $1,000.00')
    }

    setCustomServiceError(error)

    if (Object.keys(error).length === 0) {
      const customServiceId = Math.random().toString(36).slice(2)
      setValue('customs', [
        ...customs,
        {
          data_key: customServiceId,
          name: customServiceName,
          description: customServiceDescription,
          retail_cost: parseFloat(price),
        },
      ])

      setValue('services', [
        ...(methods.watch('services') ? methods.watch('services') : []),
        customServiceId,
      ])

      setCustomServiceName('')
      setCustomServiceDescription('')
      setCustomServicePrice('')
    }
  }

  var customMap = new Map()
  customMap.set(props.t('Custom Services'), customs)

  var watchSelections = methods.watch('services')
  var validSelection =
    watchSelections && watchSelections.length > 0 ? true : false

  return (
    <>
      <Row>
        <Col md={6}>
          {/* Service Catalog Group Card(s) */}
          {Array.from(props.services.keys()).map((catalog, idx) => {
            return (
              <Row style={{ marginBottom: '1.25rem' }} key={idx}>
                <Col md={11}>
                  <GlistenServiceBox
                    editable={true}
                    items={props.services.get(catalog)}
                    title={catalog}
                    image={assignGroupImg(catalog)}
                    validateSelect={validSelection}
                    isOpen={isOpen[idx]}
                    toggleOpen={(v) =>
                      setIsOpen(isOpen.map((_, t) => (t == idx ? v : false)))
                    }
                  />
                </Col>
              </Row>
            )
          })}

          {/* Custom Service Card */}
          {customs && customs.length > 0 ? (
            <Row style={{ marginBottom: '1.25rem' }}>
              <Col md={11}>
                <GlistenServiceBox
                  editable={true}
                  items={customMap.get(props.t('Custom Services'))}
                  title={props.t('Custom Services')}
                  image={assignGroupImg(props.t('Custom Services'))}
                  validateSelect={validSelection}
                  isCustom={true}
                  isOpen={isOpen[isOpen.length - 2]}
                  toggleOpen={(v) =>
                    setIsOpen(
                      isOpen.map((_, t) => (t == isOpen.length - 2 ? v : false))
                    )
                  }
                />
              </Col>
            </Row>
          ) : (
            <></>
          )}

          {/* Add New Custom Service Card */}
          <Row style={{ marginBottom: '1.25rem' }}>
            <Col md={11}>
              <div className='accordion service-box' id='accordion'>
                <div className='accordion-item'>
                  <h2 className='accordion-header' id='headingOne'>
                    <button
                      className={
                        isOpen[isOpen.length - 1]
                          ? 'accordion-button fw-medium add-cust-top'
                          : 'accordion-button fw-medium collapsed add-cust-top'
                      }
                      type='button'
                      onClick={(v) => {
                        setIsOpen(
                          isOpen.map((_, t) =>
                            t == isOpen.length - 1
                              ? !isOpen[isOpen.length - 1]
                              : false
                          )
                        )
                      }}
                      style={{
                        cursor: 'pointer',
                        paddingTop: '0.5rem',
                        paddingBottom: '0.5rem',
                        paddingLeft: '0.5rem',
                      }}
                    >
                      <span className='group-title'>
                        {props.t('Add New Custom Service')}
                      </span>
                    </button>
                  </h2>

                  <Collapse
                    isOpen={isOpen[isOpen.length - 1]}
                    className={'accordion-collapse'}
                  >
                    <div className='accordion-body'>
                      <Row>
                        <Col md={12}>
                          <Label
                            className={
                              methods.watch('customservicename') &&
                              methods.watch('customservicename').length > 0
                                ? 'glisten-textfield-label'
                                : 'glisten-textfield-label glisten-textfield-label-hidden'
                            }
                          >
                            {props.t('Service Name')}
                          </Label>
                          <input
                            id='customservicename'
                            name='customservicename'
                            placeholder={props.t('Service Name')}
                            className='form-control glisten-textfield'
                            style={{ paddingLeft: '1.438rem' }}
                            value={customServiceName}
                            onChange={(e) =>
                              setCustomServiceName(e.target.value)
                            }
                          />
                          {customServiceError &&
                            customServiceError.servicename && (
                              <p style={{ marginBottomBottom: '0rem' }}>
                                {customServiceError.servicename}
                              </p>
                            )}
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <Label
                            className={
                              methods.watch('customservicedesc') &&
                              methods.watch('customservicedesc').length > 0
                                ? 'glisten-textfield-label'
                                : 'glisten-textfield-label glisten-textfield-label-hidden'
                            }
                          >
                            {props.t('Description')}
                          </Label>

                          <input
                            id='customservicedesc'
                            name='customservicedesc'
                            placeholder={props.t('Description')}
                            className='form-control glisten-textfield'
                            style={{ paddingLeft: '1.438rem' }}
                            value={customServiceDescription}
                            onChange={(e) =>
                              setCustomServiceDescription(e.target.value)
                            }
                          />
                          {customServiceError &&
                            customServiceError.servicedesc && (
                              <p style={{ marginBottomBottom: '0rem' }}>
                                {customServiceError.servicedesc}
                              </p>
                            )}
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <Label
                            className={
                              methods.watch('customserviceprice') &&
                              methods.watch('customserviceprice').length > 0
                                ? 'glisten-textfield-label'
                                : 'glisten-textfield-label glisten-textfield-label-hidden'
                            }
                          >
                            {props.t('Price')}
                          </Label>

                          <input
                            id='customserviceprice'
                            name='customserviceprice'
                            placeholder={props.t('Price')}
                            className='form-control glisten-textfield'
                            style={{ paddingLeft: '1.438rem' }}
                            value={customServicePrice}
                            onChange={(e) => {
                              const input = e.target.value
                              e.target.value =
                                dollarPrefix +
                                (input.indexOf(dollarPrefix) == 0
                                  ? input.substr(dollarPrefix.length)
                                  : input)
                              setCustomServicePrice(e.target.value)
                            }}
                          />
                          {customServiceError &&
                            customServiceError.serviceprice && (
                              <p style={{ marginBottomBottom: '0rem' }}>
                                {customServiceError.serviceprice}
                              </p>
                            )}
                        </Col>
                      </Row>
                      <Row
                        style={{ marginTop: '1.563rem', marginBottom: '1rem' }}
                      >
                        <Col md={12}>
                          <div
                            style={{ display: 'flex', flexDirection: 'column' }}
                          >
                            <input
                              type='button'
                              className='btn glisten-btn prim-btn-sm'
                              onClick={() => addCustomService()}
                              value={props.t('ADD TO ORDER')}
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Collapse>
                </div>
              </div>
            </Col>
          </Row>
        </Col>

        <Col md={5}>
          {/* Order Summary */}
          <GlistenOrderSummary
            items={
              methods.watch('services')
                ? [props.allServices, customs]
                    .flat()
                    .filter(
                      (item) =>
                        methods.watch('services').indexOf(item.data_key) !== -1
                    )
                : []
            }
          />

          {/* Note */}
          <div
            className='form-horizontal'
            style={{
              marginTop: '0.625rem',
              marginBottom: '5.625rem',
              marginLeft: '1.438rem',
            }}
          >
            <Label
              className={
                methods.watch('note') && methods.watch('note').length > 0
                  ? 'glisten-textfield-label'
                  : 'glisten-textfield-label glisten-textfield-label-hidden'
              }
            >
              {props.t('Notes (optional)')}
            </Label>
            <input
              id='note'
              name='note'
              className='form-control glisten-textfield'
              placeholder={props.t('Notes (optional)')}
              defaultValue=''
              {...methods.register('note', {
                maxLength: 128,
                message: props.t('Maximum 128 characters'),
              })}
            />
            {errors.note?.message && (
              <p style={{ marginBottomBottom: '0rem' }}>
                {errors.note.message}
              </p>
            )}
          </div>
        </Col>
      </Row>
    </>
  )
}

SelectService.propTypes = {
  t: PropTypes.any,
  onUpdateSelectedService: PropTypes.func,
  allServices: PropTypes.any,
  services: PropTypes.any,
  selectedServices: PropTypes.any,
  customServices: PropTypes.any,
  onGetTenantData: PropTypes.func,
  onAddCustomService: PropTypes.func,
  onToggleCustomService: PropTypes.func,
  org: PropTypes.string,
  tenant: PropTypes.string,
  username: PropTypes.string,
  userFeat: PropTypes.any,
}

const mapStateToProps = ({ service, login, tenant }) => {
  let allService = new Map()
  tenant.service_catalog.forEach((item) => {
    if (allService.get(item.category) == null) {
      allService.set(item.category, [item])
    } else {
      allService.get(item.category).push(item)
    }
  })

  return {
    allServices: tenant.service_catalog,
    services: allService,
    tenant: login.tenant,
    username: login.username,
    org: login.org,
    userFeat: login.features,
    selectedServices: service.selectedServices,
  }
}

const mapDispatchToProps = (dispatch) => ({
  onUpdateSelectedService: (type, updatedServices) =>
    dispatch(updateSelectedService(type, updatedServices)),
  onGetTenantData: (org, tenant) => dispatch(getTenantData(org, tenant)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(SelectService))
