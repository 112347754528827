import { takeEvery, put, call, delay } from 'redux-saga/effects'

import {
  GET_ALL_ORDERS,
  GET_ORDER_BY_KEY,
  APPROVE_ORDER,
  REJECT_ORDER,
  ASSIGN_WORK,
  START_WORK,
  END_WORK,
  REVIEW_WORK,
  UPLOAD_IMAGE,
  ADD_COMMENT_TO_ORDER,
  DELETE_IMAGE,
} from './actionTypes'
import {
  getAllOrders,
  getAllOrdersSuccess,
  getAllOrdersFail,
  getOrderByKey,
  getOrderByKeySuccess,
  getOrderByKeyFail,
  approveOrderSuccess,
  approveOrderFail,
  rejectNewOrderSuccess,
  rejectNewOrderFail,
  assignWorkSuccess,
  assignWorkFail,
  startWorkOrderSuccess,
  startWorkOrderFail,
  endWorkOrderSuccess,
  endWorkOrderFail,
  reviewWorkOrderSuccess,
  reviewWorkOrderFail,
  uploadImageSuccess,
  uploadImageFail,
  addCommentToOrderSuccess,
  addCommentToOrderFail,
  deleteImageSuccess,
  deleteImageFail,
} from './actions'

import {
  getAllOrdersAPI,
  getOrderByKeyAPI,
  approveOrder,
  assignWorkOrder,
  startWorkOrder,
  endWorkOrder,
  reviewWorkOrder,
  uploadImageToOrder,
  addComment,
  rejectNewOrderApi,
  deleteImageFromOrder,
} from '../../../helpers/fakebackend_helper'

const manualDelay = 2500
const orderDefaultFrom = new Date().setDate(new Date().getDate() - 60) // HEK: Temporarly only... should be 10 days

function* fetchAllOrders({ payload: { org, tenant } }) {
  console.log('saga::fetchAllOrders()')

  try {
    const response = yield call(getAllOrdersAPI, org, tenant, orderDefaultFrom)
    yield put(getAllOrdersSuccess(response))
  } catch (error) {
    yield put(getAllOrdersFail(error))
  }
}

function* callAddComment({ payload: { org, tenant, orderKey, message } }) {
  console.log(
    'saga::callAddComment() orderKey = ' + orderKey + ', message = ' + message
  )

  try {
    const response = yield call(addComment, org, tenant, orderKey, message)
    yield delay(manualDelay)
    yield put(addCommentToOrderSuccess(response))
    yield put(getAllOrders(org, tenant, orderDefaultFrom))
  } catch (error) {
    yield put(addCommentToOrderFail(error))
  }
}

function* callAssignWorkOrder({
  payload: { org, tenant, orderKey, targetEmployeeKey },
}) {
  console.log(
    'saga::callAssignWorkOrder() orderKey = ' +
      orderKey +
      ', targetEmployeeKey = ' +
      targetEmployeeKey
  )

  try {
    const response = yield call(
      assignWorkOrder,
      org,
      tenant,
      orderKey,
      targetEmployeeKey
    )
    yield delay(manualDelay)
    yield put(assignWorkSuccess(response))
    yield put(getAllOrders(org, tenant, orderDefaultFrom))
  } catch (error) {
    yield put(assignWorkFail(error))
  }
}

function* callApproveOrder({ payload: { org, tenant, orderKey } }) {
  console.log('saga::callApproveOrder() orderKey = ' + orderKey)

  try {
    const response = yield call(approveOrder, org, tenant, orderKey)
    yield delay(manualDelay)
    yield put(approveOrderSuccess(response))
    yield put(getAllOrders(org, tenant, orderDefaultFrom))
  } catch (error) {
    yield put(approveOrderFail(error))
  }
}

function* callRejectNewOrder({ payload: { org, tenant, orderKey } }) {
  console.log('saga::callRejectNewOrder() orderKey = ' + orderKey)

  try {
    const response = yield call(rejectNewOrderApi, org, tenant, orderKey)
    yield delay(manualDelay)
    yield put(rejectNewOrderSuccess(response))
    yield put(getAllOrders(org, tenant, orderDefaultFrom))
  } catch (error) {
    yield put(rejectNewOrderFail(error))
  }
}

function* callStartOrder({ payload: { org, tenant, orderKey } }) {
  console.log('saga::callStartOrder() orderKey = ' + orderKey)

  try {
    const response = yield call(startWorkOrder, org, tenant, orderKey)
    yield delay(manualDelay)
    yield put(startWorkOrderSuccess(response))
    yield put(getAllOrders(org, tenant, orderDefaultFrom))
  } catch (error) {
    yield put(startWorkOrderFail(error))
  }
}

function* callEndOrder({ payload: { org, tenant, orderKey } }) {
  console.log('saga::callEndOrder() orderKey = ' + orderKey)

  try {
    const response = yield call(endWorkOrder, org, tenant, orderKey)
    yield delay(manualDelay)
    yield put(endWorkOrderSuccess(response))
    yield put(getAllOrders(org, tenant, orderDefaultFrom))
  } catch (error) {
    yield put(endWorkOrderFail(error))
  }
}

function* callReviewWorkOrder({ payload: { org, tenant, orderKey } }) {
  console.log('saga::callReviewWorkOrder() orderKey = ' + orderKey)

  try {
    const response = yield call(reviewWorkOrder, org, tenant, orderKey)
    yield delay(manualDelay)
    yield put(reviewWorkOrderSuccess(response))
    yield put(getAllOrders(org, tenant, orderDefaultFrom))
  } catch (error) {
    yield put(reviewWorkOrderFail(error))
  }
}

function* callUploadOrderImage({
  payload: { org, tenant, orderKey, vin, original, thumbnail },
}) {
  console.log('saga::callUploadOrderImage() orderKey = ' + orderKey)

  try {
    const response = yield call(
      uploadImageToOrder,
      org,
      tenant,
      orderKey,
      vin,
      original,
      thumbnail
    )
    yield delay(manualDelay)
    yield put(uploadImageSuccess(response))
    yield put(getOrderByKey(org, tenant, orderKey))
  } catch (error) {
    yield put(uploadImageFail(error))
  }
}

function* callDeleteOrderImage({
  payload: { org, tenant, orderKey, vin, imageKey },
}) {
  console.log('saga::callDeleteOrderImage() imageKey = ' + imageKey)

  try {
    const response = yield call(
      deleteImageFromOrder,
      org,
      tenant,
      orderKey,
      vin,
      imageKey
    )
    yield delay(500)
    yield put(deleteImageSuccess(response))
    yield put(getOrderByKey(org, tenant, orderKey))
  } catch (error) {
    yield put(deleteImageFail(error))
  }
}

function* fetchOrderByKey({ payload: { org, tenant, orderKey } }) {
  console.log('saga::fetchOrderByKey()')

  try {
    const response = yield call(getOrderByKeyAPI, org, tenant, orderKey)
    yield put(getOrderByKeySuccess(response))
  } catch (error) {
    yield put(getOrderByKeyFail(error))
  }
}

export function* financeSaga() {
  yield takeEvery(GET_ALL_ORDERS, fetchAllOrders)
  yield takeEvery(GET_ORDER_BY_KEY, fetchOrderByKey)
  yield takeEvery(APPROVE_ORDER, callApproveOrder)
  yield takeEvery(ASSIGN_WORK, callAssignWorkOrder)
  yield takeEvery(START_WORK, callStartOrder)
  yield takeEvery(END_WORK, callEndOrder)
  yield takeEvery(REVIEW_WORK, callReviewWorkOrder)
  yield takeEvery(UPLOAD_IMAGE, callUploadOrderImage)
  yield takeEvery(ADD_COMMENT_TO_ORDER, callAddComment)
  yield takeEvery(REJECT_ORDER, callRejectNewOrder)
  yield takeEvery(DELETE_IMAGE, callDeleteOrderImage)
}

export default financeSaga
