/* eslint-disable no-unused-vars */
import React from 'react'
import PropTypes from 'prop-types'
import { Card, CardBody, CardText, CardImg, Col, Row } from 'reactstrap'
import { withTranslation } from 'react-i18next'
import { formatDate } from '../../helpers/date_formatter'

const GlistenOrderUpdateBox = (props) => {
  let humanDateFormat = formatDate(new Date(props.timestamp))

  return (
    <Card style={{ marginTop: '-0.5rem' }}>
      <CardBody>
        <Row>
          <Col md='1'>
            <CardImg
              className='rounded-circle header-profile-user'
              src={props.image}
            />
          </Col>
          <Col md='11'>
            <Row>
              <Col lg='12'>
                <CardText>
                  <h7 style={{ fontWeight: 600 }}>
                    {props.user} - {props.t('Posted on')} {humanDateFormat}
                  </h7>
                </CardText>
              </Col>
              <Col lg='12' style={{ paddingTop: '0.5rem' }}>
                <CardText>
                  <h6>{props.message}</h6>
                </CardText>
              </Col>
            </Row>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

GlistenOrderUpdateBox.propTypes = {
  t: PropTypes.any,
  user: PropTypes.string,
  timestamp: PropTypes.any,
  message: PropTypes.string,
  image: PropTypes.string,
}

export default withTranslation()(GlistenOrderUpdateBox)
