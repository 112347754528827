import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Card, CardBody, Label } from 'reactstrap'
import { withTranslation } from 'react-i18next'
import { formatDateV2 } from '../../helpers/date_formatter'
import SendUpdateBox from './SendUpdateBox'
import { addCommentToOrder } from 'store/actions'
import { connect } from 'react-redux'
import './updatesbox.scss'
import { isMobile } from 'react-device-detect'

const UpdateItem = (props) => {
  let humanDateFormat = formatDateV2(
    new Date(props.item && props.item.data_timestamp)
  )
  return (
    <>
      {!isMobile ? (
        <Col
          md={{ size: 1, offset: 2 }}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div
            className='align-self-center'
            style={{
              height: '1.563rem',
              width: '0.125rem',
              background: '#000000',
              marginLeft: '1.563rem',
              marginRight: '1.563rem',
            }}
          ></div>
          {/* {props.index == 0 ? (
          <></>
        ) : (
          <div
            className='align-self-center'
            style={{
              height: '1.563rem',
              width: '0.125rem',
              background: '#000000',
              marginLeft: '1.563rem',
              marginRight: '1.563rem',
            }}
          ></div>
        )} */}
          <div
            className='align-self-center'
            style={{
              height: '0.625rem',
              width: '0.625rem',
              backgroundColor: '#000000',
              borderRadius: '50%',
              display: 'inline-block',
            }}
          ></div>
          {props.index + 1 == props.length ? (
            <></>
          ) : (
            <div
              className='align-self-center'
              style={{
                height: '3rem',
                width: '0.125rem',
                background: '#000000',
                marginLeft: '1.563rem',
                marginRight: '1.563rem',
              }}
            ></div>
          )}
        </Col>
      ) : (
        <></>
      )}
      <Col md='8'>
        <Row style={{ marginLeft: '0.2rem' }}>
          <Col md='12'>
            <Label className='update_message'>
              {props.item && props.item.message}
            </Label>
          </Col>
          <Col md='12' style={{ display: 'flex', alignItems: 'center' }}>
            <div className='update_info'>
              {props.orderDetail && props.orderDetail.customer_tag
                ? props.orderDetail.customer_tag
                : props.orderDetail && props.orderDetail.stock
                ? props.orderDetail.stock
                : props.orderDetail &&
                  props.orderDetail.vehicle &&
                  props.orderDetail.vehicle.data_key
                ? props.orderDetail.vehicle.data_key
                : '00000'}{' '}
              - {humanDateFormat}
            </div>
          </Col>
        </Row>
      </Col>
    </>
  )
}

UpdateItem.propTypes = {
  t: PropTypes.any,
  item: PropTypes.any,
  orderDetail: PropTypes.any,
  index: PropTypes.any,
  length: PropTypes.any,
}

const GlistenUpdatesBox = (props) => {
  const [modal_center, setmodal_center] = useState(false)

  function tog_center() {
    setmodal_center(!modal_center)
    removeBodyCss()
  }

  function removeBodyCss() {
    document.body.classList.add('no_padding')
  }
  return (
    <Card
      style={{
        height: '100%',
        padding: '0rem',
        boxShadow: '0 0 0 0 rgba(0, 0, 0, 0.18)',
      }}
    >
      <CardBody style={{ padding: '0rem' }}>
        {/* <Row className='row-margin-fix'>
          <Col md='12' style={!isMobile ? { marginTop: '1rem' } : {}}>
            <h4 className='upd-col-title'>{props.t('Updates')}</h4>
          </Col>
        </Row> */}

        {/* Not allowing updates for initial beta */}
        {/* TO DO: Implement new look for message updates entries */}
        {/* <Row className='row-margin-fix'>
          <Col
            md='12'
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignContent: 'center',
            }}
          >
            {props.canSendUpdate && (
              <Button
                className='btn glisten-btn sec-btn-big'
                onClick={() => tog_center()}
              >
                {' '}
                {props.t('SEND UPDATE')}
              </Button>
              
            )}
          </Col>
        </Row> */}
        <Row className='row-margin-fix'>
          <Col md='12' style={!isMobile ? { marginTop: '2.5rem' } : {}}>
            {props.orderDetail &&
              props.orderDetail.comments
                .sort((a, b) => (a.data_timestamp < b.data_timestamp ? 1 : -1))
                .map((item, idx) => (
                  <Row key={idx}>
                    <UpdateItem
                      length={props.orderDetail.comments.length}
                      index={idx}
                      t={props.t}
                      item={item}
                      orderDetail={props.orderDetail}
                    />
                  </Row>
                ))}
          </Col>
        </Row>
      </CardBody>
      <SendUpdateBox
        isOpen={modal_center}
        tog_center={tog_center}
        showModal={setmodal_center}
        onUpdate={(message) => {
          props.onAddComment(
            props.org,
            props.tenant,
            props.orderDetail.data_key,
            message
          )
        }}
      />
    </Card>
  )
}

GlistenUpdatesBox.propTypes = {
  t: PropTypes.any,
  canSendUpdate: PropTypes.bool,
  orderDetail: PropTypes.any,
  org: PropTypes.string,
  tenant: PropTypes.string,
  userFeat: PropTypes.any,
  username: PropTypes.any,
  onAddComment: PropTypes.func,
}

const mapStateToProps = ({ login, order }) => ({
  tenant: login.tenant,
  org: login.org,
  userFeat: login.features,
  username: login.username,
  orderDetail: order.orderDetail,
})

const mapDispatchToProps = (dispatch) => ({
  onAddComment: (org, tenentId, orderKey, message) =>
    dispatch(addCommentToOrder(org, tenentId, orderKey, message)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(GlistenUpdatesBox))
