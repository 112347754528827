import { takeEvery, put, call } from 'redux-saga/effects'

import {
  GET_USERS_DATA,
  UPDATE_USER_DATA,
  ADD_USER_DATA,
  DELETE_USER_DATA,
  ADD_DEVICE_FOR_USER,
} from './actionTypes'
import {
  getUsersDataSuccess,
  getUsersDataFail,
  updateUserDataSuccess,
  updateUserDataFail,
  addUserDataSuccess,
  addUserDataFail,
  deleteUserDataSuccess,
  deleteUserDataFail,
  addDeviceInfoSuccess,
  addDeviceInfoFail,
} from './actions'

import {
  getUsersData,
  updateProfileRecord,
  addProfileRecord,
  deleteUserData,
  addDeviceInfoForUser,
} from '../../../helpers/fakebackend_helper'

function* fetchUsersData({ payload: { org, tenant } }) {
  console.log('saga::fetchUsersData()')
  try {
    const response = yield call(getUsersData, org, tenant)
    yield put(getUsersDataSuccess(response))
  } catch (error) {
    yield put(getUsersDataFail(error))
  }
}

function* updateUserData({ payload: { org, tenant, userData } }) {
  console.log('saga::updateUsersData()')
  try {
    const response = yield call(
      updateProfileRecord,
      org,
      tenant,
      userData.username,
      {
        firstName: userData.first_name,
        lastName: userData.last_name,
        email: userData.email,
        phoneNumber: userData.phone_number,
        department: userData.department,
        role: userData.role,
      }
    )
    yield put(updateUserDataSuccess(response, userData))
  } catch (error) {
    yield put(updateUserDataFail(error))
  }
}

function* addUserData({ payload: { org, tenant, userData } }) {
  console.log('saga::addUsersData()')
  try {
    const response = yield call(addProfileRecord, org, tenant, {
      firstName: userData.first_name,
      lastName: userData.last_name,
      email: userData.email,
      phoneNumber: userData.phone_number,
      department: userData.department,
      role: userData.role,
    })
    yield put(addUserDataSuccess(response, userData))
  } catch (error) {
    yield put(addUserDataFail(error))
  }
}

function* delUserData({ payload: { org, tenant, userData } }) {
  console.log('saga::delUserData()')
  try {
    yield call(deleteUserData, org, tenant, userData.username)
    yield put(deleteUserDataSuccess(userData))
  } catch (error) {
    yield put(deleteUserDataFail(error))
  }
}

function* addDeviceData({ payload: { org, tenant, username, deviceUuid } }) {
  console.log('saga::addDeviceData()')
  try {
    yield call(addDeviceInfoForUser, org, tenant, username, deviceUuid)
    yield put(addDeviceInfoSuccess(deviceUuid))
  } catch (error) {
    yield put(addDeviceInfoFail(error))
  }
}

export function* usersSaga() {
  yield takeEvery(GET_USERS_DATA, fetchUsersData)
  yield takeEvery(UPDATE_USER_DATA, updateUserData)
  yield takeEvery(ADD_USER_DATA, addUserData)
  yield takeEvery(DELETE_USER_DATA, delUserData)
  yield takeEvery(ADD_DEVICE_FOR_USER, addDeviceData)
}

export default usersSaga
