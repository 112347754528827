import React from 'react'
import PropTypes from 'prop-types'
import NumberFormat from 'react-number-format'
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import { withTranslation } from 'react-i18next'

const GlistenTopPerformerTable = (props) => {
  return (
    <>
      <div className='table-rep-plugin'>
        <div className='table-responsive mb-0' data-pattern='priority-columns'>
          <Table id='tech-companies-1' className='table'>
            <Thead>
              <Tr style={{ fontSize: '0.6rem' }}>
                <Th>Detailer</Th>
                <Th data-priority='1' style={{ textAlign: 'right' }}>
                  Efficiency
                </Th>
                <Th data-priority='2' style={{ textAlign: 'right' }}>
                  Total Hrs
                </Th>
                <Th data-priority='3' style={{ textAlign: 'right' }}>
                  Completed
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {props.items &&
                props.items.map((item, idx) => (
                  <Tr key={idx}>
                    <Th>
                      <img
                        style={{ marginRight: '0.5rem' }}
                        className='rounded-circle header-profile-user'
                        src={item.image}
                      />
                      {item.name}
                    </Th>
                    <Td style={{ textAlign: 'right', verticalAlign: 'middle' }}>
                      <NumberFormat
                        value={item.efficiency}
                        displayType={'text'}
                        fixedDecimalScale={true}
                        decimalScale={1}
                        thousandSeparator={true}
                      />
                    </Td>
                    <Td style={{ textAlign: 'right', verticalAlign: 'middle' }}>
                      <NumberFormat
                        value={item.totalHr}
                        displayType={'text'}
                        fixedDecimalScale={true}
                        decimalScale={0}
                        thousandSeparator={true}
                      />
                    </Td>
                    <Td style={{ textAlign: 'right', verticalAlign: 'middle' }}>
                      <NumberFormat
                        value={item.completed}
                        displayType={'text'}
                        fixedDecimalScale={true}
                        decimalScale={0}
                        thousandSeparator={true}
                      />
                    </Td>
                  </Tr>
                ))}
            </Tbody>
          </Table>
        </div>
      </div>
    </>
  )
}

GlistenTopPerformerTable.propTypes = {
  t: PropTypes.any,
  items: PropTypes.list,
}

export default withTranslation()(GlistenTopPerformerTable)
