export const ADD_DEVICE_FOR_USER = 'ADD_DEVICE_FOR_USER'
export const ADD_DEVICE_FOR_USER_SUCCESS = 'ADD_DEVICE_FOR_USER_SUCCESS'
export const ADD_DEVICE_FOR_USER_FAIL = 'ADD_DEVICE_FOR_USER_FAIL'

export const GET_USERS_DATA = 'GET_USERS_DATA'
export const GET_USERS_DATA_SUCCESS = 'GET_USERS_DATA_SUCCESS'
export const GET_USERS_DATA_FAIL = 'GET_USERS_DATA_FAIL'

export const UPDATE_USER_DATA = 'UPDATE_USER_DATA'
export const UPDATE_USER_DATA_SUCCESS = 'UPDATE_USER_DATA_SUCCESS'
export const UPDATE_USER_DATA_FAIL = 'UPDATE_USER_DATA_FAIL'

export const ADD_USER_DATA = 'ADD_USER_DATA'
export const ADD_USER_DATA_SUCCESS = 'ADD_USER_DATA_SUCCESS'
export const ADD_USER_DATA_FAIL = 'ADD_USER_DATA_FAIL'

export const DELETE_USER_DATA = 'DELETE_USER_DATA'
export const DELETE_USER_DATA_SUCCESS = 'DELETE_USER_DATA_SUCCESS'
export const DELETE_USER_DATA_FAIL = 'DELETE_USER_DATA_FAIL'

export const SORT_USERS_DATA_BY_FIRST_NAME = 'SORT_USERS_DATA_BY_FIRST_NAME'
export const SORT_USERS_DATA_BY_LAST_NAME = 'SORT_USERS_DATA_BY_LAST_NAME'
export const SORT_USERS_DATA_BY_DEPARTMENT = 'SORT_USERS_DATA_BY_DEPARTMENT'
export const SORT_USERS_DATA_BY_ACCOUNT_TYPE = 'SORT_USERS_DATA_BY_ACCOUNT_TYPE'
