import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import MetaTags from 'react-meta-tags'
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Label,
  Button,
  Media,
} from 'reactstrap'
import { connect } from 'react-redux'

import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'

import { Link, withRouter } from 'react-router-dom'

import NumberFormat from 'react-number-format'

//i18n
import { withTranslation } from 'react-i18next'

import { getTenantData } from 'store/actions'

//Edit Image
import edit_logo from '../../assets/images/ic-edit.svg'

import AddService from './AddService'
import EditService from './EditService'

import LoadingOverlay from 'react-loading-overlay'
import { sendPageViewEvent } from '../../firebase'

const EditServices = (props) => {
  const { onGetTenantData, serviceUpdated } = props

  var title = props.t('Service Info')

  useEffect(() => {
    sendPageViewEvent('Service Info')
    onGetTenantData(props.org, props.tenant)
  }, [])

  // This will call get tenant data action again when serviceUpdated value changes to true
  useEffect(() => {
    if (serviceUpdated) onGetTenantData(props.org, props.tenant)
  }, [serviceUpdated])

  //modals displayed from this page
  const [modal_edit, setmodal_edit] = useState(false)
  const [modal_add, setmodal_add] = useState(false)

  //specific service info to edit when button clicked
  const [selectedServiceCat, setServiceCatSel] = useState('')
  const [selectedServiceName, setServiceNameSel] = useState('')
  const [selectedRetailCost, setRetailCostSel] = useState('')
  const [selectedIntrnCost, setIntrnCostSel] = useState('')
  const [selectedAdminCost, setAdminCostSel] = useState('')
  const [selectedDescription, setDescriptionSel] = useState('')
  const [selectedServiceId, setSelectedServiceId] = useState('')

  function tog_edit(item) {
    setServiceCatSel(item.category)
    setServiceNameSel(item.name)
    setRetailCostSel(item.retail_cost)
    setIntrnCostSel(item.internal_cost)
    setAdminCostSel(item.admin_cost)
    setDescriptionSel(item.description)
    setSelectedServiceId(item.data_key)
    setmodal_edit(!modal_edit)
    removeBodyCss()
  }

  function tog_add() {
    setmodal_add(!modal_add)
    removeBodyCss()
  }

  function removeBodyCss() {
    document.body.classList.add('no_padding')
  }

  return (
    <React.Fragment>
      <div className='breadsrumb-content'>
        <div className='breadcrumb-header' style={{ minHeight: '7.188rem' }}>
          <Row>
            <Col xl={2}>
              <div
                className='page-title-box'
                style={{ paddingTop: '2.563rem' }}
              >
                <div
                  style={{
                    fontSize: '1.5rem',
                    fontWeight: '500',
                    letterSpacing: '2.7px',
                    fontFamily: 'Poppins',
                    color: '#333333',
                  }}
                >
                  {props.t('My Account')}
                </div>
              </div>
            </Col>
            <Col xl={10} style={{ paddingTop: '3.063rem' }}>
              <Link to='myprofile' className='account-link'>
                {props.t('My Profile')}
              </Link>
              <Link to='resetpassword' className='account-link'>
                {props.t('Change Password')}
              </Link>
              {(props.userFeat.includes('ftl-admin') ||
                props.userFeat.includes('ftl-executive')) && (
                <Link to='users' className='account-link'>
                  {props.t('Users')}
                </Link>
              )}
              {(props.userFeat.includes('ftl-admin') ||
                props.userFeat.includes('ftl-executive')) && (
                <Link to='dealershipinfo' className='account-link'>
                  {props.t('Dealership Info')}
                </Link>
              )}
              {(props.userFeat.includes('ftl-admin') ||
                props.userFeat.includes('ftl-executive')) && (
                <Link to='serviceinfo' className='account-link-sel'>
                  {props.t('Service Info')}
                </Link>
              )}
            </Col>
          </Row>
        </div>
      </div>
      <div className='page-content page-background'>
        <MetaTags>
          <title>Home | {title}</title>
        </MetaTags>
        <Container fluid style={{ width: '90%' }}>
          <Row style={{ paddingTop: '3.313rem' }}>
            <Col lg='12'>
              <Card>
                <CardBody
                  style={{
                    padding: '0rem',
                  }}
                >
                  <Row>
                    <Col xl='6'>
                      <Label className='subpage-title'>
                        {props.t('Services')}
                      </Label>
                    </Col>
                    <Col xl='6'>
                      <Button
                        color='dark'
                        className='prim-btn-sm'
                        style={{
                          float: 'right',
                          marginTop: '1.625rem',
                          marginRight: '1.875rem',
                        }}
                        onClick={() => {
                          tog_add()
                        }}
                        // onClick={() =>
                        //   onAddNewService(
                        //     props.org,
                        //     props.targetTenant.data_key,
                        //     props.tenant,
                        //     {
                        //       // SERVICE DATA
                        //     }
                        //   )
                        // }
                      >
                        {props.t('Add Service')}
                      </Button>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: '2.25rem' }}>
                    <Col xl='12'>
                      <div
                        className='table-rep-plugin'
                        style={{ marginLeft: '1.25rem' }}
                      >
                        <div
                          className='table-responsive mb-0'
                          data-pattern='priority-columns'
                        >
                          <Table id='tech-companies-1' className='table'>
                            <Thead>
                              <Tr>
                                <Th className='table-headers'>
                                  {props.t('Service Category')}
                                </Th>
                                <Th data-priority='1' className='table-headers'>
                                  {props.t('Service Name')}
                                </Th>
                                <Th data-priority='3' className='table-headers'>
                                  {props.t('Description')}
                                </Th>
                                <Th data-priority='1' className='table-headers'>
                                  {props.t('Retail Cost')}
                                </Th>
                                <Th data-priority='3' className='table-headers'>
                                  {props.t('Internal Cost')}
                                </Th>
                                <Th data-priority='3' className='table-headers'>
                                  {props.t('Admin Cost')}
                                </Th>
                                <Th data-priority='6' className='table-headers'>
                                  {props.t('Actions')}
                                </Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              {props.services.map((item, idx) => {
                                return (
                                  <Tr key={idx}>
                                    <Td className='table-cells'>
                                      {item.category}
                                    </Td>
                                    <Td className='table-cells'>{item.name}</Td>
                                    <Td className='table-cells'>
                                      {item.description}
                                    </Td>
                                    <Td className='table-cells'>
                                      <NumberFormat
                                        value={item.retail_cost}
                                        displayType={'text'}
                                        prefix={'$'}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                      />
                                    </Td>
                                    <Td className='table-cells'>
                                      <NumberFormat
                                        value={item.internal_cost}
                                        displayType={'text'}
                                        prefix={'$'}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                      />
                                    </Td>
                                    <Td className='table-cells'>
                                      <NumberFormat
                                        value={item.admin_cost}
                                        displayType={'text'}
                                        prefix={'$'}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                      />
                                    </Td>
                                    <Td className='table-cells'>
                                      <Row
                                        onClick={() => {
                                          tog_edit(item)
                                        }}
                                      >
                                        <Col lg={3} style={{ padding: 0 }}>
                                          <Media
                                            src={edit_logo}
                                            style={{
                                              paddingLeft: '0.125rem',
                                              paddingRight: '0.125rem',
                                              marginTop: '0.625rem',
                                              marginRight: '0.625rem',
                                            }}
                                          ></Media>
                                        </Col>
                                        <Col lg={9} style={{ padding: 0 }}>
                                          <Label style={{ float: 'left' }}>
                                            {props.t('Edit')}
                                          </Label>
                                        </Col>
                                      </Row>
                                    </Td>
                                  </Tr>
                                )
                              })}
                            </Tbody>
                          </Table>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <LoadingOverlay active={props.loading} spinner>
        <EditService
          title={props.t('Edit Service')}
          submitBtnTitle={props.t('Save')}
          submitBtnDisabled={false}
          secondBtnTitle={props.t('Delete')}
          serviceCatToEdit={selectedServiceCat}
          serviceNameToEdit={selectedServiceName}
          retailCostToEdit={selectedRetailCost}
          internalCostToEdit={selectedIntrnCost}
          adminCostToEdit={selectedAdminCost}
          descriptionToEdit={selectedDescription}
          serviceCatalogId={selectedServiceId}
          isOpen={modal_edit}
          showModal={setmodal_edit}
        />
      </LoadingOverlay>

      <LoadingOverlay active={props.loading} spinner>
        <AddService
          title={props.t('Add New Service')}
          submitBtnTitle={props.t('Submit')}
          submitBtnDisabled={false}
          isOpen={modal_add}
          showModal={setmodal_add}
        />
      </LoadingOverlay>
    </React.Fragment>
  )
}

EditServices.propTypes = {
  loading: PropTypes.any,
  t: PropTypes.any,
  services: PropTypes.any,
  selectedServiceCat: PropTypes.string,
  selectedServiceName: PropTypes.string,
  selectedRetailCost: PropTypes.string,
  selectedIntrnCost: PropTypes.string,
  selectedAdminCost: PropTypes.string,
  selectedDescription: PropTypes.string,
  onGetTenantData: PropTypes.func,
  showModal: PropTypes.func,
  org: PropTypes.string,
  targetTenant: PropTypes.any,
  tenant: PropTypes.string,
  userFeat: PropTypes.any,
  serviceUpdated: PropTypes.bool,
}

const mapStateToProps = ({ tenant, login }) => ({
  serviceUpdated: tenant.serviceUpdated,
  services: tenant.service_catalog,
  tenant: login.tenant,
  targetTenant: tenant.targetTenant,
  org: login.org,
  userFeat: login.features,
})

const mapDispatchToProps = (dispatch) => ({
  onGetTenantData: (org, tenant) => dispatch(getTenantData(org, tenant)),
  //   onAddNewService: (org, detailTenant, dealershipTenant, serviceCatalog) =>
  //     dispatch(
  //       addServiceCatalogData(org, detailTenant, dealershipTenant, serviceCatalog)
  //     ),
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(EditServices))
)
