/* eslint-disable no-unused-vars */
import { takeEvery, put, call } from 'redux-saga/effects'

import { GET_PROFILE_DATA, UPDATE_PROFILE_DATA } from './actionTypes'
import {
  getProfileDateSuccess,
  getProfileDateFail,
  updateProfileSuccess,
  updateProfileFail,
} from './actions'

//Include Both Helper File with needed methods
import {
  getProfileRecord,
  updateProfileRecord,
} from '../../../helpers/fakebackend_helper'

function* fetchProfileData({ payload: { org, tenant, username } }) {
  console.log('saga::fetchProfileData()')

  try {
    const response = yield call(getProfileRecord, org, tenant, username)
    yield put(getProfileDateSuccess(response))
  } catch (error) {
    yield put(getProfileDateFail(error))
  }
}

function* updateProfileData({
  payload: { org, tenant, username, profileData },
}) {
  console.log('saga::updateProfileData()')

  try {
    const response = yield call(
      updateProfileRecord,
      org,
      tenant,
      username,
      profileData
    )
    yield put(updateProfileSuccess(response, profileData))
  } catch (error) {
    yield put(updateProfileFail(error))
  }
}

export function* profileSaga() {
  yield takeEvery(GET_PROFILE_DATA, fetchProfileData)
  yield takeEvery(UPDATE_PROFILE_DATA, updateProfileData)
}

export default profileSaga
