import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { Row, Col, Button, Label } from 'reactstrap'

// Styling
import '../DeleteUser/deleteuser.scss'

//i18n
import { withTranslation } from 'react-i18next'

import { connect } from 'react-redux'

import { deleteUserData } from 'store/actions'

import { sendPageViewEvent } from '../../../../firebase'

const DeleteUser = (props) => {
  useEffect(() => {
    sendPageViewEvent('Delete User Info')
  }, [])

  const { onDeleteUserData } = props

  const handleSubmit = (evt) => {
    evt.preventDefault()
    onDeleteUserData(props.org, props.tenant, {
      username: props.usernameForDelete,
      first_name: props.firstNameForDelete,
      last_name: props.lastNameForDelete,
      email: props.emailForDelete,
      phone_number: props.phoneForDelete,
      department: props.deptForDelete,
      role: props.roleForDelete,
    })
    props.showModal(false)
    props.setContent(props.currentContent - 1)
  }

  return (
    <>
      <div
        className='modal-body glisten-modal-body'
        style={{ paddingTop: '1.188rem' }}
      >
        <Row>
          <Col lg='10'>
            <Label className='body-text'>
              {props.t(
                'Deleting will permanently remove the ability to log in as this user. Deleting this user will NOT delete past records/analytics.'
              )}
            </Label>
          </Col>
        </Row>
        <Row style={{ marginBottom: '5rem' }}>
          <Col lg='3' style={{ paddingTop: '1.25rem' }}>
            <div>
              <Label className='profile-label'>{props.t('Name')}</Label>
            </div>
            <div>
              <Label className='profile-input-label'>
                {props.firstNameForDelete} {props.lastNameForDelete}
              </Label>
            </div>
          </Col>
          <Col lg='3' style={{ paddingTop: '1.25rem' }}>
            <div>
              <Label className='profile-label'>{props.t('Department')}</Label>
            </div>
            <div>
              <Label className='profile-input-label'>
                {props.deptForDelete}
              </Label>
            </div>
          </Col>
          <Col lg='3' style={{ paddingTop: '1.25rem' }}>
            <div>
              <Label className='profile-label'>{props.t('Role')}</Label>
            </div>
            <div>
              <Label className='profile-input-label'>
                {props.roleForDelete}
              </Label>
            </div>
          </Col>
        </Row>
      </div>
      <div className='modal-footer glisten-modal-footer'>
        <div>
          <Button
            className='btn glisten-btn prim-mod-action'
            disabled={props.submitBtnDisabled}
            onClick={(evt) => {
              handleSubmit(evt)
            }}
          >
            {props.submitBtnTitle}
          </Button>
          <Button
            className='btn glisten-btn alt-mod-action'
            onClick={() => props.setContent(props.currentContent - 1)}
          >
            {props.secondBtnTitle}
          </Button>
        </div>
      </div>
    </>
  )
}

DeleteUser.propTypes = {
  title: PropTypes.string,
  submitBtnTitle: PropTypes.string,
  submitBtnDisabled: PropTypes.bool,
  secondBtnTitle: PropTypes.string,
  firstNameForDelete: PropTypes.string,
  lastNameForDelete: PropTypes.string,
  phoneForDelete: PropTypes.string,
  deptForDelete: PropTypes.string,
  roleForDelete: PropTypes.string,
  emailForDelete: PropTypes.string,
  usernameForDelete: PropTypes.string,
  isOpen: PropTypes.bool,
  currentContent: PropTypes.any,
  setContent: PropTypes.func,
  showModal: PropTypes.func,
  t: PropTypes.any,
  onDeleteUserData: PropTypes.func,
  org: PropTypes.string,
  tenant: PropTypes.string,
  userFeat: PropTypes.list,
}

const mapStateToProps = ({ login }) => ({
  tenant: login.tenant,
  org: login.org,
  userFeat: login.features,
})

const mapDispatchToProps = (dispatch) => ({
  onDeleteUserData: (org, tenant, userData) =>
    dispatch(deleteUserData(org, tenant, userData)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(DeleteUser))
