import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { Row, Col, Modal, Label } from 'reactstrap'

// Styling
import '../EditDepartment/editdepartment.scss'

//i18n
import { withTranslation } from 'react-i18next'

import { ReactComponent as Logo } from '../../../assets/images/ic-close.svg'
import { useForm } from 'react-hook-form'

import { sendPageViewEvent } from '../../../firebase'

const EditDepartment = (props) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm()

  const onSubmit = (evt) => {
    props.onSubmit(evt.departmentName)
    props.showModal(false)
  }

  useEffect(() => {
    sendPageViewEvent('Edit Department')
  }, [])

  return (
    <Modal
      size='xl'
      isOpen={props.isOpen}
      toggle={() => {
        props.tog_dept()
      }}
      centered={true}
    >
      <div className='modal-header glisten-modal-header'>
        <div>
          <h3 className='glisten-modal-title'>{props.title}</h3>
        </div>

        <button
          className='close-btn'
          onClick={() => {
            props.showModal(false)
          }}
        >
          <Logo />
        </button>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className='form-horizontal'>
        <div
          className='modal-body glisten-modal-body'
          style={{ paddingTop: '1.188rem' }}
        >
          <Row style={{ marginBottom: '8.313rem' }}>
            <Col lg='5'>
              <Label
                className={
                  watch('departmentName') && watch('departmentName').length > 0
                    ? 'glisten-textfield-label'
                    : 'glisten-textfield-label glisten-textfield-label-hidden'
                }
              >
                {props.t('Department Name')}
              </Label>
              <input
                id='departmentName'
                name='departmentName'
                label={props.t('Department Name')}
                className='form-control glisten-textfield'
                placeholder={props.t('Department Name')}
                defaultValue={props.deptToEdit}
                {...register('departmentName', {
                  required: props.t('Please enter department name.'),
                })}
              />
              {errors.departmentName?.message && (
                <p>{errors.departmentName.message}</p>
              )}
            </Col>
          </Row>
        </div>

        <div className='modal-footer glisten-modal-footer'>
          <div>
            <input
              className='btn glisten-btn prim-mod-action'
              type='submit'
              value={props.submitBtnTitle}
            />
            {/* HIDE BUTTON FOR NOW, WILL ADD BACK AFTER MVP */}
            {/* <Button
                                className='btn glisten-btn alt-mod-action'
                                onClick={(evt) => {
                                    handleDelete(evt)
                                }}
                                >
                                {props.secondBtnTitle}
                                </Button> */}
          </div>
        </div>
      </form>
    </Modal>
  )
}

EditDepartment.propTypes = {
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  tog_dept: PropTypes.func,
  showModal: PropTypes.func,
  submitBtnTitle: PropTypes.string,
  submitBtnDisabled: PropTypes.bool,
  secondBtnTitle: PropTypes.string,
  t: PropTypes.any,
  deptToEdit: PropTypes.string,
  onSubmit: PropTypes.func,
  onDelete: PropTypes.func,
}

export default withTranslation()(EditDepartment)
