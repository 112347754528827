import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import EditUser from './EditUser'
import DeleteUser from './DeleteUser'
import TwoBtnDynamicModal from 'components/TwoBtnDynamicModal'
import { sendPageViewEvent } from '../../../firebase'

const ChangeUserInfo = (props) => {
  const [currContentIdx, setCurrContentIdx] = useState(0)
  const [title, setTitle] = useState(props.t('Enter User'))
  const [submitBtnTitle, setSubmitBtnTitle] = useState(props.t('Save'))
  const [secondBtnTitle, setSecondBtnTitle] = useState(props.t('Delete'))
  useEffect(() => {
    sendPageViewEvent('Change User Info')
  }, [])

  const changeContent = (idx) => {
    setCurrContentIdx(idx)

    switch (idx) {
      case 0:
        setTitle(props.t('Enter User'))
        setSubmitBtnTitle(props.t('Save'))
        setSecondBtnTitle(props.t('Delete'))
        break
      case 1:
        setTitle(props.t('Are you sure you want to delete this user?'))
        setSubmitBtnTitle(props.t('Delete'))
        setSecondBtnTitle(props.t('Cancel'))
        break
    }
  }

  return (
    <TwoBtnDynamicModal
      title={title}
      isOpen={props.isOpen}
      showModal={props.showModal}
      currentContent={currContentIdx}
      setContent={changeContent}
      content={(() => {
        switch (currContentIdx) {
          case 0:
            return (
              <EditUser
                title={title}
                submitBtnTitle={submitBtnTitle}
                secondBtnTitle={secondBtnTitle}
                firstNameToEdit={props.firstNameToEdit}
                lastNameToEdit={props.lastNameToEdit}
                phoneToEdit={props.phoneToEdit}
                deptToEdit={props.deptToEdit}
                roleToEdit={props.roleToEdit}
                emailForEdit={props.emailForEdit}
                usernameForEdit={props.usernameForEdit}
                currentContent={currContentIdx}
                setContent={changeContent}
                isOpen={props.isOpen}
                showModal={props.showModal}
              />
            )
          case 1:
            return (
              <DeleteUser
                title={title}
                submitBtnTitle={submitBtnTitle}
                secondBtnTitle={secondBtnTitle}
                firstNameForDelete={props.firstNameToEdit}
                lastNameForDelete={props.lastNameToEdit}
                phoneForDelete={props.phoneToEdit}
                deptForDelete={props.deptToEdit}
                roleForDelete={props.roleToEdit}
                emailForDelete={props.emailForEdit}
                usernameForDelete={props.usernameForEdit}
                currentContent={currContentIdx}
                setContent={changeContent}
                isOpen={props.isOpen}
                showModal={props.showModal}
              />
            )
        }
      })()}
    />
  )
}

ChangeUserInfo.propTypes = {
  t: PropTypes.any,
  isOpen: PropTypes.bool,
  loading: PropTypes.any,
  modal_edit: PropTypes.bool,
  setmodal_edit: PropTypes.func,
  firstNameToEdit: PropTypes.string,
  lastNameToEdit: PropTypes.string,
  phoneToEdit: PropTypes.string,
  deptToEdit: PropTypes.string,
  roleToEdit: PropTypes.string,
  emailForEdit: PropTypes.string,
  usernameForEdit: PropTypes.string,
  showModal: PropTypes.func,
}

const mapStateToProps = ({ vehicle }) => ({
  loading: vehicle.loading,
})

export default connect(mapStateToProps)(withTranslation()(ChangeUserInfo))
