/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types'
import React, { useEffect, useRef } from 'react'
import LoadingOverlay from 'react-loading-overlay'

import {
  Table,
  Row,
  Col,
  Modal,
  Button,
  FormGroup,
  Label,
  Media,
} from 'reactstrap'

import { useReactToPrint } from 'react-to-print'

// Styling
import '../FinanceOrderDetail/financeorder.scss'

//i18n
import { withTranslation } from 'react-i18next'

import { connect, useState } from 'react-redux'

import { formatTime } from '../../../helpers/util'

import { ReactComponent as Logo } from '../../../assets/images/ic-close.svg'

import nocarimage from '../../../assets/images/no-car-image.svg'
import localorderimage2 from '../../../assets/images/lr pic 2.svg'
import localorderimage3 from '../../../assets/images/lr pic 3.svg'

import { getOrderByKey } from 'store/actions'
import { sendPageViewEvent } from '../../../firebase'
import { Link, withRouter } from 'react-router-dom'

const FinanceOrderDetail = (props) => {
  useEffect(() => {
    sendPageViewEvent('Finance Order Detail')
  }, [])

  const componentRef = useRef()
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  })

  const getPageMargins = () => {
    return `@page { margin: 50 0 50 50 !important; }`
  }

  const calcTotalCost = () => {
    var serviceTotalCost = 0
    if (props.orderDetail.service_catalog == null) {
      return null
    } else {
      props.orderDetail.service_catalog.forEach((item) => {
        serviceTotalCost += item.retail_cost
      })
      return serviceTotalCost
    }
  }

  const fullName = (first, last) => {
    var full = first + ' ' + last
    return full
  }

  const displayProfileImage = () => {
    if (
      props.orderDetail == null ||
      props.orderDetail.images == null ||
      props.orderDetail.images.length == 0
    ) {
      return (
        // eslint-disable-next-line no-extra-semi
        <Media
          src={nocarimage}
          style={{
            display: 'block',
            maxWidth: '100%',
            height: 'auto',
            width: '83%',
            borderRadius: '8px',
          }}
        ></Media>
      )
    } else {
      return (
        <Media
          src={
            props.orderDetail.images[props.orderDetail.images.length - 1]
              .image_url.original
          }
          style={{
            display: 'block',
            maxWidth: '100%',
            height: 'auto',
            width: '83%',
            borderRadius: '8px',
          }}
        ></Media>
      )
    }
  }

  const displayOrderServices = () => {
    return props.orderDetail.service_catalog.map((item, idx) => {
      return (
        <tr style={{ border: '1px solid #DFDFDF' }} key={idx}>
          <td
            className='table-cells'
            style={{
              paddingTop: '1.25rem',
              paddingLeft: '1.25rem',
              paddingBottom: '1.25rem',
            }}
          >
            <div>
              <Label
                style={{
                  fontSize: '0.875rem',
                  letterSpacing: '0.084rem',
                  lineHeight: '1.438rem',
                  color: '#000000',
                  fontWeight: '200',
                  marginBottom: '0rem',
                }}
              >
                {item.name}
                {/* Service Wash */}
              </Label>
            </div>
            <div style={{ paddingTop: '0.469rem' }}>
              <Label
                style={{
                  fontSize: '0.75rem',
                  letterSpacing: '0.084rem',
                  lineHeight: '1.438rem',
                  color: '#000000',
                  fontWeight: '200',
                  marginBottom: '0rem',
                }}
              >
                {item.description}
                {/* A lengthy description that describes what this service
                  entails. */}
              </Label>
            </div>
          </td>
          <td
            className=''
            style={{
              fontSize: '0.875rem',
              letterSpacing: '0.099rem',
              lineHeight: '1.25rem',
              color: '#000000',
              fontWeight: '200',
              paddingTop: '2.188rem',
              paddingRight: '2.313rem',
              textAlign: 'right',
            }}
          >
            ${item.retail_cost}
            {/* $25.00 */}
          </td>
        </tr>
      )
    })
  }

  const createRowList = () => {
    let rows = {}
    let counter = 1
    props.orderDetail.images.forEach((item, idx) => {
      rows[counter] = rows[counter] ? [...rows[counter]] : []
      if (idx % 3 === 0 && idx !== 0) {
        counter++
        rows[counter] = rows[counter] ? [...rows[counter]] : []
        rows[counter].push(item)
      } else {
        rows[counter].push(item)
      }
    })
    return rows
  }

  const displayOrderImages = () => {
    if (props.orderDetail.images == null) {
      return null
    } else {
      let rows = createRowList()
      return (
        <>
          {Object.keys(rows).map((row) => {
            return (
              <Row key={row} style={{ marginTop: '1.25rem' }}>
                {rows[row].map((item) => {
                  return (
                    <Col xl='3' key={item}>
                      <Media
                        src={item.image_url.original}
                        style={{
                          display: 'block',
                          maxWidth: '100%',
                          height: 'auto',
                          width: '100%',
                        }}
                      ></Media>
                    </Col>
                  )
                })}
              </Row>
            )
          })}
        </>
      )
    }
  }

  const displayOrderImagesPDF = () => {
    if (props.orderDetail.images == null) {
      return null
    } else {
      let rows = createRowList()
      return (
        <>
          {Object.keys(rows).map((row) => {
            return (
              <Row key={row} style={{ marginTop: '1.25rem' }}>
                {rows[row].map((item) => {
                  return (
                    <div className={'col-print-3'} key={item}>
                      <Media
                        src={item.image_url.original}
                        style={{
                          display: 'block',
                          maxWidth: '100%',
                          height: 'auto',
                          width: '100%',
                        }}
                      ></Media>
                    </div>
                  )
                })}
              </Row>
            )
          })}
        </>
      )
    }
  }

  return (
    <LoadingOverlay active={props.loading} spinner>
      <Modal
        scrollable={true}
        size='xl'
        isOpen={props.isOpen}
        onOpened={() => {
          props.onGetOrderByKey(props.org, props.tenant, props.orderKey)
        }}
        toggle={() => {
          props.showModal(false)
        }}
        centered={true}
      >
        <div className='modal-header glisten-modal-header'>
          <div>
            <h3 className='glisten-modal-title'>{props.title}</h3>
          </div>

          <button
            className='close-btn'
            onClick={() => {
              props.showModal(false)
            }}
          >
            <Logo />
          </button>
        </div>

        <div
          className='modal-body glisten-modal-body'
          style={{ paddingTop: '1.188rem' }}
        >
          <Row>
            <Col xl='7'>
              <Row>
                <Col xl='12'>
                  {props.orderDetail && props.orderDetail.vehicle ? (
                    <Label
                      style={{
                        fontFamily: 'Poppins',
                        fontWeight: '600',
                        fontSize: '1.5rem',
                        letterSpacing: '0.169rem',
                        color: '#333333',
                        marginTop: '2.688',
                        marginBottom: '0rem',
                      }}
                    >
                      {props.orderDetail.vehicle.year}{' '}
                      {props.orderDetail.vehicle.make}
                    </Label>
                  ) : (
                    <></>
                  )}
                </Col>
              </Row>
              <Row>
                <Col xl='12'>
                  <Label
                    style={{
                      fontFamily: 'Poppins',
                      fontWeight: '600',
                      fontSize: '1.5rem',
                      letterSpacing: '0.169rem',
                      color: '#333333',
                      marginBottom: '0rem',
                    }}
                  >
                    {/* TO DO: Pre-pend the car year onto this string */}
                    {props.orderDetail == null
                      ? null
                      : props.orderDetail.vehicle.model}
                  </Label>
                </Col>
              </Row>
              <Row>
                <Col xl='12'>
                  <Label
                    style={{
                      fontFamily: 'Poppins',
                      fontWeight: '200',
                      fontSize: '1.125rem',
                      letterSpacing: '0.127rem',
                      color: '#333333',
                      marginTop: '1rem',
                      marginBottom: '0rem',
                    }}
                  >
                    {props.orderDetail == null
                      ? null
                      : props.t(props.orderDetail.vehicle.color)}
                  </Label>
                </Col>
              </Row>
              <Row>
                <Col xl='10'>
                  <Table striped style={{ marginTop: '2.813rem' }}>
                    <tbody>
                      <tr style={{ backgroundColor: '#EEEEEE' }}>
                        <td className='thin-table-cell'>{props.t('VIN')}</td>
                        <td
                          className='thin-table-cell'
                          style={{ fontWeight: '200', textAlign: 'right' }}
                        >
                          {props.orderDetail == null ||
                          props.orderDetail.vehicle.data_key == ''
                            ? null
                            : props.orderDetail.vehicle.data_key}
                        </td>
                      </tr>
                      <tr>
                        <td className='thin-table-cell'>
                          {props.t('Stock Number')}
                        </td>
                        <td
                          className='thin-table-cell'
                          style={{ fontWeight: '200', textAlign: 'right' }}
                        >
                          {props.orderDetail == null ||
                          props.orderDetail.stock == ''
                            ? props.t('N/A')
                            : props.orderDetail.stock}
                        </td>
                      </tr>
                      <tr style={{ backgroundColor: '#EEEEEE' }}>
                        <td className='thin-table-cell'>
                          {props.t('PO Number')}
                        </td>
                        <td
                          className='thin-table-cell'
                          style={{ fontWeight: '200', textAlign: 'right' }}
                        >
                          {props.orderDetail == null ||
                          props.orderDetail.po_number == ''
                            ? props.t('N/A')
                            : props.orderDetail.po_number}
                        </td>
                      </tr>
                      <tr>
                        <td className='thin-table-cell'>
                          {props.t('Customer Tag')}
                        </td>
                        <td
                          className='thin-table-cell'
                          style={{ fontWeight: '200', textAlign: 'right' }}
                        >
                          {props.orderDetail == null ||
                          props.orderDetail.customer_tag == ''
                            ? props.t('N/A')
                            : props.orderDetail.customer_tag}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Col>
            <Col xl='5'>{displayProfileImage()}</Col>
          </Row>

          <Row>
            <Col xl='7'>
              <Label
                style={{
                  fontFamily: 'Poppins',
                  fontWeight: '200',
                  fontSize: '1.125rem',
                  letterSpacing: '0.127rem',
                  lineHeight: '1.563rem',
                  color: '#000000',
                  marginTop: '2.688rem',
                }}
              >
                {props.t('SERVICES REQUESTED')}
              </Label>
            </Col>
          </Row>
          <Row>
            <Col xl='11'>
              <Table style={{ marginTop: '1.438rem' }}>
                <tbody>
                  {props.orderDetail == null ? null : displayOrderServices()}
                </tbody>
              </Table>
            </Col>
          </Row>
          <Row>
            <Col xl='7' style={{ borderBottom: '1px solid #DFDFDF' }}>
              <div style={{ marginTop: '1.438rem' }}>
                <Label
                  style={{
                    fontSize: '0.875rem',
                    letterSpacing: '0.099rem',
                    lineHeight: '1.25rem',
                    color: '#000000',
                    fontWeight: '400',
                    marginLeft: '1.25rem',
                    marginBottom: '0rem',
                  }}
                >
                  {props.orderDetail == null
                    ? null
                    : props.orderDetail.note == null
                    ? null
                    : props.t('Order Note')}
                </Label>
              </div>
              <div
                style={{ paddingTop: '0.469rem', paddingBottom: '3.125rem' }}
              >
                <Label
                  style={{
                    fontSize: '0.875rem',
                    letterSpacing: '0.099rem',
                    lineHeight: '1.438rem',
                    color: '#000000',
                    fontWeight: '200',
                    marginLeft: '1.25rem',
                    marginBottom: '0rem',
                  }}
                >
                  {props.orderDetail == null
                    ? null
                    : props.orderDetail.note == null
                    ? null
                    : props.orderDetail.note}
                  {/* The note that was attached to order when order was intially
                created will be displayed here if it exists. */}
                </Label>
              </div>
            </Col>
            <Col
              xl='4'
              className='text-end'
              style={{ borderBottom: '1px solid #DFDFDF' }}
            >
              <Label
                style={{
                  fontSize: '1rem',
                  letterSpacing: '0.113rem',
                  lineHeight: '1.438rem',
                  color: '#000000',
                  fontWeight: '200',
                  marginBottom: '0rem',
                  paddingTop: '1.874rem',
                }}
              >
                {props.t('TOTAL')}
              </Label>
              <Label
                style={{
                  fontSize: '1.5rem',
                  letterSpacing: '0.169rem',
                  lineHeight: '2.063rem',
                  color: '#000000',
                  fontWeight: '500',
                  paddingTop: '1.563rem',
                  paddingRight: '1.25rem',
                  marginLeft: '1.25rem',
                  marginBottom: '0rem',
                  textAlign: 'right',
                }}
              >
                ${props.orderDetail == null ? null : calcTotalCost()}
                {/* $100.00 */}
              </Label>
            </Col>
          </Row>
          <Row>
            <Col
              xl='6'
              style={{
                borderBottom: '1px solid #DFDFDF',
                paddingBottom: '3.125rem',
              }}
            >
              <Table borderless style={{ marginTop: '2.813rem' }}>
                <tbody>
                  <tr>
                    <td
                      className='time-stamp-info'
                      style={{
                        fontWeight: '500',
                        // paddingLeft: '3.75rem',
                        // paddingRight: '0rem',
                        //textAlign: 'right',
                      }}
                    >
                      {props.t('SUBMITTED')}
                    </td>
                    <td
                      className='time-stamp-info'
                      style={{ fontWeight: '200', paddingRight: '0rem' }}
                    >
                      {props.orderDetail == null
                        ? null
                        : formatTime(props.orderDetail.original_created_at)}
                      {/* 10:31 AM 08/22/21 */}
                    </td>
                  </tr>
                  <tr>
                    <td
                      className='time-stamp-info'
                      style={{
                        fontWeight: '500',
                        paddingTop: '0.563rem',
                        // paddingLeft: '3.75rem',
                        // paddingRight: '0rem',
                        //textAlign: 'right',
                      }}
                    >
                      {props.t('CREATED BY')}
                    </td>
                    <td
                      className='time-stamp-info'
                      style={{
                        fontWeight: '200',
                        paddingTop: '0.563rem',
                        paddingRight: '0rem',
                      }}
                    >
                      {props.orderDetail == null
                        ? null
                        : fullName(
                            props.orderDetail.created_by.first_name,
                            props.orderDetail.created_by.last_name
                          )}
                      {/* Piers Babu */}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
            <Col
              xl='6'
              style={{
                borderBottom: '1px solid #DFDFDF',
                paddingBottom: '3.125rem',
              }}
            >
              <Table borderless style={{ marginTop: '2.813rem' }}>
                <tbody>
                  <tr>
                    <td
                      className='time-stamp-info'
                      style={{ fontWeight: '500' }}
                    >
                      {props.t('REQUESTED FOR')}
                    </td>
                    <td
                      className='time-stamp-info'
                      style={{
                        fontWeight: '200',
                        //textAlign: 'right',
                        paddingRight: '2.25rem',
                      }}
                    >
                      {props.orderDetail == null
                        ? null
                        : formatTime(props.orderDetail.pickup_date_time)}
                      {/* 4:00 AM 08/23/21 */}
                    </td>
                  </tr>
                  <tr>
                    <td
                      className='time-stamp-info'
                      style={{
                        fontWeight: '500',
                        //paddingLeft: '3.75rem',
                        paddingTop: '0.563rem',
                      }}
                    >
                      {props.t('COMPLETED')}
                    </td>
                    <td
                      className='time-stamp-info'
                      style={{
                        fontWeight: '200',
                        paddingTop: '0.563rem',
                        //textAlign: 'right',
                        paddingRight: '2.25rem',
                      }}
                    >
                      {props.orderDetail == null
                        ? null
                        : formatTime(props.orderDetail.data_timestamp)}
                      {/* 4:00 AM 08/22/21 */}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
          {props.orderDetail == null || props.orderDetail.images.length == 0 ? (
            <></>
          ) : (
            <>
              <Row style={{ marginBottom: '1.875rem' }}>
                <Col xl='7'>
                  <Label
                    style={{
                      fontFamily: 'Poppins',
                      fontWeight: '200',
                      fontSize: '1.125rem',
                      letterSpacing: '0.127rem',
                      lineHeight: '1.563rem',
                      color: '#000000',
                      marginTop: '2.688rem',
                    }}
                  >
                    {props.orderDetail == null
                      ? null
                      : props.orderDetail.images == null
                      ? null
                      : props.t('Work Order Images')}
                  </Label>
                </Col>
              </Row>
              <>{displayOrderImages()}</>
            </>
          )}
        </div>

        <div className='modal-footer glisten-modal-footer'>
          <div>
            <FormGroup>
              <Button
                className='btn glisten-btn prim-mod-action'
                disabled={props.submitBtnDisabled}
                onClick={handlePrint}
              >
                {props.submitBtnTitle}
              </Button>
              {props.orderDetail && props.orderDetail.data_key ? (
                <Link
                  to={`/serviceOrderDetail?orderId=${props.orderDetail.data_key}`}
                  className='btn glisten-btn alt-mod-action'
                >
                  {props.secondBtnTitle}
                </Link>
              ) : (
                <></>
              )}
            </FormGroup>
          </div>
        </div>

        {/* CODE BELOW is specifically for the pdf print view */}

        <div className={'d-none d-print-inline'} ref={componentRef}>
          <style>{getPageMargins()}</style>
          <Row>
            <div className={'col-print-7'}>
              <Row>
                <div className={'col-print-12'}>
                  {props.orderDetail && props.orderDetail.vehicle ? (
                    <Label
                      style={{
                        fontFamily: 'Poppins',
                        fontWeight: '600',
                        fontSize: '1.5rem',
                        letterSpacing: '0.169rem',
                        color: '#333333',
                        marginTop: '2.688',
                        marginBottom: '0rem',
                      }}
                    >
                      {props.orderDetail.vehicle.year}{' '}
                      {props.orderDetail.vehicle.make}
                    </Label>
                  ) : (
                    <></>
                  )}
                </div>
              </Row>
              <Row>
                <div className={'col-print-12'}>
                  <Label
                    style={{
                      fontFamily: 'Poppins',
                      fontWeight: '600',
                      fontSize: '1.5rem',
                      letterSpacing: '0.169rem',
                      color: '#333333',
                      marginBottom: '0rem',
                    }}
                  >
                    {props.orderDetail == null
                      ? null
                      : props.orderDetail.vehicle.model}
                  </Label>
                </div>
              </Row>
              <Row>
                <div className={'col-print-12'}>
                  <Label
                    style={{
                      fontFamily: 'Poppins',
                      fontWeight: '200',
                      fontSize: '1.125rem',
                      letterSpacing: '0.127rem',
                      color: '#333333',
                      marginTop: '1rem',
                      marginBottom: '0rem',
                    }}
                  >
                    {props.orderDetail == null
                      ? null
                      : props.t(props.orderDetail.vehicle.color)}
                  </Label>
                </div>
              </Row>
              <Row>
                <div className={'col-print-10'}>
                  <Table striped style={{ marginTop: '2.813rem' }}>
                    <tbody>
                      <tr style={{ backgroundColor: '#EEEEEE' }}>
                        <td className='thin-table-cell'>{props.t('VIN')}</td>
                        <td
                          className='thin-table-cell'
                          style={{ fontWeight: '200', textAlign: 'right' }}
                        >
                          {props.orderDetail == null ||
                          props.orderDetail.vehicle.data_key == ''
                            ? null
                            : props.orderDetail.vehicle.data_key}
                        </td>
                      </tr>
                      <tr>
                        <td className='thin-table-cell'>
                          {props.t('Stock Number')}
                        </td>
                        <td
                          className='thin-table-cell'
                          style={{ fontWeight: '200', textAlign: 'right' }}
                        >
                          {props.orderDetail == null ||
                          props.orderDetail.stock == ''
                            ? props.t('N/A')
                            : props.orderDetail.stock}
                        </td>
                      </tr>
                      <tr style={{ backgroundColor: '#EEEEEE' }}>
                        <td className='thin-table-cell'>
                          {props.t('PO Number')}
                        </td>
                        <td
                          className='thin-table-cell'
                          style={{ fontWeight: '200', textAlign: 'right' }}
                        >
                          {props.orderDetail == null ||
                          props.orderDetail.po_number == ''
                            ? props.t('N/A')
                            : props.orderDetail.po_number}
                        </td>
                      </tr>
                      <tr>
                        <td className='thin-table-cell'>
                          {props.t('Customer Tag')}
                        </td>
                        <td
                          className='thin-table-cell'
                          style={{ fontWeight: '200', textAlign: 'right' }}
                        >
                          {props.orderDetail == null ||
                          props.orderDetail.customer_tag == ''
                            ? props.t('N/A')
                            : props.orderDetail.customer_tag}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </Row>
            </div>
            <div className={'col-print-5'}>{displayProfileImage()}</div>
          </Row>
          <Row>
            <div className={'col-print-7'}>
              <Label
                style={{
                  fontFamily: 'Poppins',
                  fontWeight: '200',
                  fontSize: '1.125rem',
                  letterSpacing: '0.127rem',
                  lineHeight: '1.563rem',
                  color: '#000000',
                  marginTop: '2.688rem',
                }}
              >
                {props.t('SERVICES REQUESTED')}
              </Label>
            </div>
          </Row>
          <Row>
            <div className={'col-print-11'}>
              <Table style={{ marginTop: '1.438rem' }}>
                <tbody>
                  {props.orderDetail == null ? null : displayOrderServices()}
                </tbody>
              </Table>
            </div>
          </Row>
          <Row>
            <div
              className={'col-print-7'}
              style={{ borderBottom: '1px solid #DFDFDF' }}
            >
              <div style={{ marginTop: '1.438rem' }}>
                <Label
                  style={{
                    fontSize: '0.875rem',
                    letterSpacing: '0.099rem',
                    lineHeight: '1.25rem',
                    color: '#000000',
                    fontWeight: '400',
                    marginLeft: '1.25rem',
                    marginBottom: '0rem',
                  }}
                >
                  {props.orderDetail == null
                    ? null
                    : props.orderDetail.note == null
                    ? null
                    : props.t('Order Note')}
                </Label>
              </div>
              <div
                style={{ paddingTop: '0.469rem', paddingBottom: '3.125rem' }}
              >
                <Label
                  style={{
                    fontSize: '0.875rem',
                    letterSpacing: '0.099rem',
                    lineHeight: '1.438rem',
                    color: '#000000',
                    fontWeight: '200',
                    marginLeft: '1.25rem',
                    marginBottom: '0rem',
                  }}
                >
                  {props.orderDetail == null
                    ? null
                    : props.orderDetail.note == null
                    ? null
                    : props.orderDetail.note}
                </Label>
              </div>
            </div>
            <div
              className='text-end col-print-4'
              style={{ borderBottom: '1px solid #DFDFDF' }}
            >
              <Label
                style={{
                  fontSize: '1rem',
                  letterSpacing: '0.113rem',
                  lineHeight: '1.438rem',
                  color: '#000000',
                  fontWeight: '200',
                  marginBottom: '0rem',
                  paddingTop: '1.874rem',
                }}
              >
                {props.t('TOTAL')}
              </Label>
              <Label
                style={{
                  fontSize: '1.5rem',
                  letterSpacing: '0.169rem',
                  lineHeight: '2.063rem',
                  color: '#000000',
                  fontWeight: '500',
                  paddingTop: '1.563rem',
                  paddingRight: '1.25rem',
                  marginLeft: '1.25rem',
                  marginBottom: '0rem',
                  textAlign: 'right',
                }}
              >
                ${props.orderDetail == null ? null : calcTotalCost()}
              </Label>
            </div>
          </Row>
          <Row>
            <div
              className={'col-print-5'}
              style={{
                borderBottom: '1px solid #DFDFDF',
                paddingBottom: '3.125rem',
                //pageBreakBefore: 'always',
              }}
            >
              <Table borderless style={{ marginTop: '2.813rem' }}>
                <tbody>
                  <tr>
                    <td
                      className='time-stamp-info'
                      style={{
                        fontWeight: '500',
                        paddingRight: '0rem',
                      }}
                    >
                      {props.t('SUBMITTED')}
                    </td>
                    <td
                      className='time-stamp-info'
                      style={{ fontWeight: '200', paddingRight: '0rem' }}
                    >
                      {props.orderDetail == null
                        ? null
                        : formatTime(props.orderDetail.original_created_at)}
                    </td>
                  </tr>
                  <tr>
                    <td
                      className='time-stamp-info'
                      style={{
                        fontWeight: '500',
                        //paddingLeft: '3.75rem',
                        paddingTop: '0.563rem',
                        paddingRight: '0rem',
                      }}
                    >
                      {props.t('CREATED BY')}
                    </td>
                    <td
                      className='time-stamp-info'
                      style={{
                        fontWeight: '200',
                        paddingTop: '0.563rem',
                        paddingRight: '0rem',
                      }}
                    >
                      {props.orderDetail == null
                        ? null
                        : fullName(
                            props.orderDetail.created_by.first_name,
                            props.orderDetail.created_by.last_name
                          )}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
            <div
              className={'col-print-6'}
              style={{
                borderBottom: '1px solid #DFDFDF',
                paddingBottom: '3.125rem',
              }}
            >
              <Table borderless style={{ marginTop: '2.813rem' }}>
                <tbody>
                  <tr>
                    <td
                      className='time-stamp-info'
                      style={{
                        fontWeight: '500',
                        //paddingLeft: '3.75rem'
                      }}
                    >
                      {props.t('REQUESTED FOR')}
                    </td>
                    <td
                      className='time-stamp-info'
                      style={{
                        fontWeight: '200',
                        textAlign: 'left',
                        paddingRight: '2.25rem',
                      }}
                    >
                      {props.orderDetail == null
                        ? null
                        : formatTime(props.orderDetail.pickup_date_time)}
                    </td>
                  </tr>
                  <tr>
                    <td
                      className='time-stamp-info'
                      style={{
                        fontWeight: '500',
                        //paddingLeft: '3.75rem',
                        paddingTop: '0.563rem',
                      }}
                    >
                      {props.t('COMPLETED')}
                    </td>
                    <td
                      className='time-stamp-info'
                      style={{
                        fontWeight: '200',
                        paddingTop: '0.563rem',
                        textAlign: 'left',
                        paddingRight: '2.25rem',
                      }}
                    >
                      {props.orderDetail == null
                        ? null
                        : formatTime(props.orderDetail.data_timestamp)}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </Row>
          <Row
            style={{
              marginBottom: '1.875rem',
              //pageBreakBefore: 'always',
            }}
          >
            <div className={'col-print-7'}>
              <Label
                style={{
                  fontFamily: 'Poppins',
                  fontWeight: '200',
                  fontSize: '1.125rem',
                  letterSpacing: '0.127rem',
                  lineHeight: '1.563rem',
                  color: '#000000',
                  marginTop: '2.688rem',
                }}
              >
                {props.orderDetail == null
                  ? null
                  : props.orderDetail.images == null ||
                    props.orderDetail.images.length < 2
                  ? null
                  : props.t('Work Order Images')}
              </Label>
            </div>
          </Row>
          {props.orderDetail == null || props.orderDetail.images.length < 2
            ? null
            : displayOrderImagesPDF()}
        </div>
      </Modal>
    </LoadingOverlay>
  )
}

FinanceOrderDetail.propTypes = {
  t: PropTypes.any,
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  showModal: PropTypes.func,
  submitBtnTitle: PropTypes.string,
  submitBtnDisabled: PropTypes.bool,
  secondBtnTitle: PropTypes.string,
  orderDetail: PropTypes.any,
  orderKey: PropTypes.string,
  onGetOrderByKey: PropTypes.func,
  loading: PropTypes.any,
  loggedInUsername: PropTypes.string,
  loggedInUserRole: PropTypes.string,
  loggedInUserType: PropTypes.string,
  org: PropTypes.string,
  tenant: PropTypes.string,
  userFeat: PropTypes.any,
}

const mapStateToProps = ({ login, order }) => ({
  loading: order.isLoading,
  loggedInUsername: login.username,
  loggedInUserRole: login.role,
  loggedInUserType: login.type,
  tenant: login.tenant,
  org: login.org,
  userFeat: login.features,
  orderDetail: order.orderDetail,
})

const mapDispatchToProps = (dispatch) => ({
  onGetOrderByKey: (org, tenant, orderKey) =>
    dispatch(getOrderByKey(org, tenant, orderKey)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(FinanceOrderDetail)))
