import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'reactstrap'
import { useHistory } from 'react-router-dom'
import { isMobile } from 'react-device-detect'

const Breadcrumb = (props) => {
  let history = useHistory()

  return (
    <div
      className='breadcrumb-header'
      style={isMobile ? {} : { minHeight: '7.188rem' }}
    >
      {!isMobile ? (
        <Row style={{ height: '100%' }}>
          <Col xs={6} className='align-self-center'>
            <div
              style={{
                fontSize: '1.5rem',
                fontWeight: '500',
                letterSpacing: '2.7px',
                fontFamily: 'Poppins',
                color: '#333333',
                paddingLeft: '2.625rem',
              }}
              onClick={() => (props.showBack ? history.goBack() : null)}
            >
              {props.showBack && (
                <button type='button' className='btn btn-sm px-3 font-size-22'>
                  <i className='fa fa-fw fa-angle-left' />
                </button>
              )}
              {props.breadcrumbItem}
            </div>
          </Col>
          <Col
            xs={6}
            className='align-self-center'
            style={{
              paddingRight: '2.875rem',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            {props.filter ? props.filter : ''}
          </Col>
        </Row>
      ) : (
        <Row>
          <Col xs={12}>{props.filter ? props.filter : ''}</Col>
        </Row>
      )}
    </div>
  )
}

Breadcrumb.propTypes = {
  breadcrumbItem: PropTypes.string,
  title: PropTypes.string,
  filter: PropTypes.any,
  showBack: PropTypes.bool,
}

export default Breadcrumb
