import PropTypes from 'prop-types'
import React from 'react'
import { Modal } from 'reactstrap'

// Styling
import '../TwoBtnDynamicModal/twobtndynmod.scss'

//i18n
import { withTranslation } from 'react-i18next'

import { ReactComponent as Logo } from '../../assets/images/ic-close.svg'

const TwoBtnDynamicModal = (props) => {
  return (
    <Modal
      size='xl'
      isOpen={props.isOpen}
      toggle={() => {
        props.showModal(false)
        props.setContent(0)
      }}
      centered={true}
    >
      <div className='modal-header glisten-modal-header'>
        <div>
          <h3 className='glisten-modal-title'>{props.title}</h3>
        </div>

        <button
          className='close-btn'
          onClick={() => {
            props.showModal(false)
            props.setContent(0)
          }}
        >
          <Logo />
        </button>
      </div>

      <div>{props.content}</div>
    </Modal>
  )
}

TwoBtnDynamicModal.propTypes = {
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  showModal: PropTypes.func,
  content: PropTypes.element,
  currentContent: PropTypes.any,
  setContent: PropTypes.func,
  t: PropTypes.any,
}

export default withTranslation()(TwoBtnDynamicModal)
