// Glisten Profile API
export const GET_PROFILE_RECORD =
  '/org/${organization}/tenant/${tenant}/employee/${username}'
export const ADD_DEVICE_UUID_RECORD =
  '/org/${organization}/tenant/${tenant}/employee/${username}/device'
export const UPDATE_PROFILE_RECORD =
  '/org/${organization}/tenant/${tenant}/employee/${username}'
export const ADD_PROFILE_RECORD =
  '/org/${organization}/tenant/${tenant}/employee'
export const DELETE_PROFILE_RECORD =
  '/org/${organization}/tenant/${tenant}/employee/${username}'
export const GET_USERS_RECORD =
  '/org/${organization}/tenant/${tenant}/employee/all'

// Glisten Tenant API
export const GET_TENANT_RECORD = '/org/${organization}/tenant/${tenant}'
export const ADD_SERVICE_CATALOG_RECORD =
  '/org/${organization}/tenant/${detailTenant}/target/${dealershipTenant}/servicecatalog'
export const UPDATE_SERVICE_CATALOG_RECORD =
  '/org/${organization}/tenant/${detailTenant}/target/${dealershipTenant}/servicecatalog/${serviceCatalogId}'
export const DELETE_SERVICE_CATALOG_RECORD =
  '/org/${organization}/tenant/${detailTenant}/target/${dealershipTenant}/servicecatalog/${serviceCatalogId}'

// Glisten Order API
export const GET_ALL_ORDERS =
  '/org/${organization}/tenant/${tenant}/orders/start/${from}'
export const GET_ORDER_BY_KEY =
  '/org/${organization}/tenant/${tenant}/order/${orderId}'
export const SUBMIT_SERVICE_ORDER =
  '/org/${organization}/tenant/${tenant}/order'
export const APPROVE_ORDER =
  '/org/${organization}/tenant/${tenant}/order/${orderKey}/approve'
export const REJECT_NEW_ORDER =
  '/org/${organization}/tenant/${tenant}/order/${orderKey}/reject'
export const ASSIGN_WORK_ORDER =
  '/org/${organization}/tenant/${tenant}/workorder/${orderKey}/assign'
export const START_WORK_ORDER =
  '/org/${organization}/tenant/${tenant}/workorder/${orderKey}/start'
export const END_WORK_ORDER =
  '/org/${organization}/tenant/${tenant}/workorder/${orderKey}/end'
export const REVIEW_WORK_ORDER =
  '/org/${organization}/tenant/${tenant}/workorder/${orderKey}/review'
export const REJECT_WORK_ORDER =
  '/org/${organization}/tenant/${tenant}/workorder/${orderKey}/reject'
export const ADD_COMMENT =
  '/org/${organization}/tenant/${tenant}/order/${orderKey}/comment'
export const UPLOAD_IMAGE_ORDER =
  '/org/${organization}/tenant/${tenant}/order/${orderKey}/vehicle/${vin}/image'
export const DELETE_IMAGE_ORDER =
  '/org/${organization}/tenant/${tenant}/order/${orderKey}/vehicle/${vin}/image/${imageKey}'
// Glisten Finance API
export const GET_FINANCE_DATA =
  '/org/${organization}/tenant/${tenant}/finance/start/${from}/end/${to}'

// Glisten Vehicle API
export const GET_VEHICLE_INFO_BY_VIN =
  '/org/${organization}/tenant/${tenant}/vehicle/${vin}'

// Mock Data
export const GET_DASHBOARD = '/dashboard'

// Vehicle API
export const GET_ALL_VEHICLE_MAKES =
  'https://vpic.nhtsa.dot.gov/api/vehicles/GetMakesForVehicleType/passenger%20car?format=json'
export const GET_ALL_VEHICLE_MODEL_BY_MAKE_AND_YEAR =
  'https://vpic.nhtsa.dot.gov/api/vehicles/getmodelsformakeyear/make/${make}/modelyear/${year}/vehicleType/${vehicleType}?format=json'
