import { all, fork } from 'redux-saga/effects'
import AuthSaga from './auth/login/saga'
import LayoutSaga from './layout/saga'
import vehicleSaga from './glisten/vehicle/saga'
import profileSaga from './glisten/profile/saga'
import dashboardSaga from './glisten/dashboard/saga'
import orderSaga from './glisten/order/saga'
import usersSaga from './glisten/users/saga'
import tenantSaga from './glisten/tenant/saga'
import financeSaga from './glisten/finance/saga'
import serviceSaga from './glisten/service/saga'

export default function* rootSaga() {
  yield all([
    fork(AuthSaga),
    LayoutSaga(),
    fork(vehicleSaga),
    fork(profileSaga),
    fork(dashboardSaga),
    fork(orderSaga),
    fork(usersSaga),
    fork(tenantSaga),
    fork(financeSaga),
    fork(serviceSaga),
  ])
}
