import { takeEvery, put, call } from 'redux-saga/effects'

import {
  SEARCH_VEHICLE_BY_VIN,
  GET_ALL_VEHICLE_MAKE,
  GET_ALL_VEHICLE_MODEL_BY_MAKE_AND_YEAR,
} from './actionTypes'

import {
  getVehicleByVinSuccess,
  getVehicleByVinFail,
  getAllVehicleMakeSuccess,
  getAllVehicleMakeFail,
  getAllVehicleModelByMakeAndYearSuccess,
  getAllVehicleModelByMakeAndYearFail,
} from './actions'

//Include Both Helper File with needed methods
import {
  getVehicleInfoByVin,
  getAllVehicleMakes,
  getAllVehicleModelsByMakeAndYear,
} from '../../../helpers/fakebackend_helper'

function* fetchAllVehicleModel({ payload: { make, year } }) {
  console.log('saga::fetchAllVehicleModel()')

  try {
    const passResponse = yield call(
      getAllVehicleModelsByMakeAndYear,
      make,
      year,
      'passenger'
    )
    const truckResponse = yield call(
      getAllVehicleModelsByMakeAndYear,
      make,
      year,
      'truck'
    )
    const response = [...passResponse.Results, ...truckResponse.Results]
    yield put(getAllVehicleModelByMakeAndYearSuccess(response))
  } catch (error) {
    yield put(getAllVehicleModelByMakeAndYearFail(error))
  }
}

function* fetchAllVehicleMake() {
  console.log('saga::fetchAllVehicleInfo()')

  try {
    const response = yield call(getAllVehicleMakes)
    yield put(getAllVehicleMakeSuccess(response))
  } catch (error) {
    yield put(getAllVehicleMakeFail(error))
  }
}

function* fetchVehicleInfo({ payload: { org, tenantId, vin } }) {
  console.log(
    'saga::fetchVehicleInfo() = ' + org,
    +', ' + tenantId + ', ' + vin
  )

  try {
    const response = yield call(getVehicleInfoByVin, org, tenantId, vin)

    console.log(response.vehicle)

    // TODO:
    if (response) {
      var make = response.vehicle.make.toUpperCase()
      var year = response.vehicle.year
      const passResponse = yield call(
        getAllVehicleModelsByMakeAndYear,
        make,
        year,
        'passenger'
      )

      const truckResponse = yield call(
        getAllVehicleModelsByMakeAndYear,
        make,
        year,
        'truck'
      )

      const models = [...passResponse.Results, ...truckResponse.Results]
      var combResp = { ...response, models }

      yield put(getVehicleByVinSuccess(combResp))
    } else {
      throw new Error('Vehicle Search Failed')
    }
  } catch (error) {
    yield put(getVehicleByVinFail(error))
  }
}

export function* financeSaga() {
  yield takeEvery(SEARCH_VEHICLE_BY_VIN, fetchVehicleInfo)
  yield takeEvery(GET_ALL_VEHICLE_MAKE, fetchAllVehicleMake)
  yield takeEvery(GET_ALL_VEHICLE_MODEL_BY_MAKE_AND_YEAR, fetchAllVehicleModel)
}

export default financeSaga
