import React from 'react'
import PropTypes from 'prop-types'
import { Row, Badge } from 'reactstrap'
import NumberFormat from 'react-number-format'

const GlistenInfoBox = (props) => {
  const calculateValue = () => {
    if (props.type == 'normal') {
      return <h3 style={{ fontWeight: 600 }}>{props.value}</h3>
    } else if (props.type == 'dollar') {
      return (
        <h3 style={{ fontWeight: 600 }}>
          <NumberFormat
            value={props.value}
            fixedDecimalScale={true}
            displayType={'text'}
            thousandSeparator={true}
            prefix={'$'}
          />
        </h3>
      )
    }
  }

  const calculatePostFix = () => {
    if (props.unitValue) {
      return (
        <div style={{ paddingLeft: '0.3rem', paddingTop: '0.5rem' }}>
          <h6>/{props.unitValue}</h6>
        </div>
      )
    }

    if (props.badgeValue) {
      return (
        <div style={{ paddingLeft: '0.3rem', paddingTop: '0.2rem' }}>
          <h5>
            {props.badgeValue > 0 ? (
              <Badge className='me-2 bg-success'>
                {' '}
                +
                <NumberFormat
                  value={props.badgeValue}
                  fixedDecimalScale={true}
                  decimalScale='1'
                  displayType={'text'}
                  thousandSeparator={true}
                />{' '}
              </Badge>
            ) : (
              <Badge className='me-2 bg-danger'>
                {' '}
                <NumberFormat
                  value={props.badgeValue}
                  fixedDecimalScale={true}
                  decimalScale='1'
                  displayType={'text'}
                  thousandSeparator={true}
                />{' '}
              </Badge>
            )}
          </h5>
        </div>
      )
    }
  }

  return (
    <div>
      <Row>
        <h6>{props.title}</h6>
      </Row>
      <Row>
        <div style={{ paddingTop: '0.5rem', display: 'flex' }}>
          {calculateValue()} {calculatePostFix()}
        </div>
      </Row>
    </div>
  )
}

GlistenInfoBox.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.any,
  badgeValue: PropTypes.number,
  unitValue: PropTypes.string,
}

export default GlistenInfoBox
