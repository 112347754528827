import PropTypes from 'prop-types'
import React from 'react'
import { Form } from 'reactstrap'
import { withTranslation } from 'react-i18next'

const DashboardFilter = () => {
  return (
    <div className='filter-box' style={{ display: 'inline-flex' }}>
      <Form className='row gy-2 gx-3 align-items-center'>
        <div className='col-sm-auto'>
          <select
            id='formrow-filterone'
            defaultValue='0'
            className='form-select'
            style={{ marginRight: '1rem' }}
          >
            <option value='0'>ALL EMPLOYEES</option>
            <option value='1'>One</option>
            <option value='2'>Two</option>
            <option value='3'>Three</option>
          </select>
        </div>

        <div className='col-sm-auto'>
          <select
            id='formrow-filtertwo'
            defaultValue='0'
            className='form-select'
            style={{ marginRight: '1rem' }}
          >
            <option value='0'>ALL SERVICES</option>
            <option value='1'>One</option>
            <option value='2'>Two</option>
            <option value='3'>Three</option>
          </select>
        </div>

        <div className='col-sm-auto'>
          <select
            id='formrow-filtertwo'
            defaultValue='0'
            className='form-select'
            style={{ marginRight: '1rem' }}
          >
            <option value='0'>DATE RANGE</option>
            <option value='1'>One</option>
            <option value='2'>Two</option>
            <option value='3'>Three</option>
          </select>
        </div>
      </Form>
    </div>
  )
}

DashboardFilter.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(DashboardFilter)
