import { post, get, put, del } from './api_helper'
import * as url from './url_helper'

const isLocalData = false

const getUsersData = (organization, tenant) => {
  const target_url = !isLocalData
    ? url.GET_USERS_RECORD.replace(/\${organization}/, organization).replace(
        /\${tenant}/,
        tenant
      )
    : url.GET_USERS_RECORD
  console.log('api::getUsersData() target_url=' + target_url)

  return get(target_url).catch((err) => {
    if (err.response) {
      return { error: err.response }
    } else {
      throw err
    }
  })
}

const getProfileRecord = (organization, tenant, username) => {
  const target_url = !isLocalData
    ? url.GET_PROFILE_RECORD.replace(/\${organization}/, organization)
        .replace(/\${tenant}/, tenant)
        .replace(/\${username}/, username)
    : url.GET_PROFILE_RECORD
  console.log('api::getProfileRecord() target_url=' + target_url)

  return get(target_url).catch((err) => {
    if (err.response) {
      return { error: err.response }
    } else {
      throw err
    }
  })
}

const updateProfileRecord = (organization, tenant, username, profileData) => {
  const target_url = !isLocalData
    ? url.UPDATE_PROFILE_RECORD.replace(/\${organization}/, organization)
        .replace(/\${tenant}/, tenant)
        .replace(/\${username}/, username)
    : url.UPDATE_PROFILE_RECORD
  console.log('api::updateProfileRecord() target_url=' + target_url)

  return put(target_url, profileData).catch((err) => {
    if (err.response) {
      return { error: err.response }
    } else {
      throw err
    }
  })
}

const addDeviceInfoForUser = (organization, tenant, username, deviceUuid) => {
  const target_url = !isLocalData
    ? url.ADD_DEVICE_UUID_RECORD.replace(/\${organization}/, organization)
        .replace(/\${tenant}/, tenant)
        .replace(/\${username}/, username)
    : url.ADD_DEVICE_UUID_RECORD
  console.log('api::addDeviceInfoForUser() target_url=' + target_url)

  return post(target_url, { deviceType: 'Web', deviceUuid: deviceUuid }).catch(
    (err) => {
      if (err.response) {
        return { error: err.response }
      } else {
        throw err
      }
    }
  )
}

const reviewWorkOrder = (organization, tenant, orderKey) => {
  const target_url = !isLocalData
    ? url.REVIEW_WORK_ORDER.replace(/\${organization}/, organization)
        .replace(/\${tenant}/, tenant)
        .replace(/\${orderKey}/, orderKey)
    : url.REVIEW_WORK_ORDER
  console.log('api::reviewWorkOrder() target_url=' + target_url)

  return post(target_url, { message: 'work reviewed' }).catch((err) => {
    if (err.response) {
      return { error: err.response }
    } else {
      throw err
    }
  })
}

const addComment = (organization, tenant, orderKey, message) => {
  const target_url = !isLocalData
    ? url.ADD_COMMENT.replace(/\${organization}/, organization)
        .replace(/\${tenant}/, tenant)
        .replace(/\${orderKey}/, orderKey)
    : url.ADD_COMMENT
  console.log(
    'api::addComment() target_url=' + target_url + ', message = ' + message
  )

  return post(target_url, { message: message }).catch((err) => {
    if (err.response) {
      return { error: err.response }
    } else {
      throw err
    }
  })
}

const rejectWorkOrder = (organization, tenant, orderKey, message) => {
  const target_url = !isLocalData
    ? url.REJECT_WORK_ORDER.replace(/\${organization}/, organization)
        .replace(/\${tenant}/, tenant)
        .replace(/\${orderKey}/, orderKey)
    : url.REJECT_WORK_ORDER
  console.log(
    'api::rejectWorkOrder() target_url=' + target_url + ', message = ' + message
  )

  return post(target_url, { message: message }).catch((err) => {
    if (err.response) {
      return { error: err.response }
    } else {
      throw err
    }
  })
}

const deleteImageFromOrder = (
  organization,
  tenant,
  orderKey,
  vin,
  imageKey
) => {
  const target_url = !isLocalData
    ? url.DELETE_IMAGE_ORDER.replace(/\${organization}/, organization)
        .replace(/\${tenant}/, tenant)
        .replace(/\${orderKey}/, orderKey)
        .replace(/\${vin}/, vin)
        .replace(/\${imageKey}/, imageKey)
    : url.DELETE_IMAGE_ORDER
  console.log('api::deleteImageFromOrder() target_url=' + target_url)

  return del(target_url).catch((err) => {
    if (err.response) {
      return { error: err.response }
    } else {
      throw err
    }
  })
}

const uploadImageToOrder = (
  organization,
  tenant,
  orderKey,
  vin,
  original,
  thumbnail
) => {
  const target_url = !isLocalData
    ? url.UPLOAD_IMAGE_ORDER.replace(/\${organization}/, organization)
        .replace(/\${tenant}/, tenant)
        .replace(/\${orderKey}/, orderKey)
        .replace(/\${vin}/, vin)
    : url.UPLOAD_IMAGE_ORDER
  console.log('api::uploadImage() target_url=' + target_url)

  return post(target_url, { original: original, thumbnail: thumbnail }).catch(
    (err) => {
      if (err.response) {
        return { error: err.response }
      } else {
        throw err
      }
    }
  )
}

const startWorkOrder = (organization, tenant, orderKey) => {
  const target_url = !isLocalData
    ? url.START_WORK_ORDER.replace(/\${organization}/, organization)
        .replace(/\${tenant}/, tenant)
        .replace(/\${orderKey}/, orderKey)
    : url.START_WORK_ORDER
  console.log('api::startWorkOrder() target_url=' + target_url)

  return post(target_url, { message: 'work started' }).catch((err) => {
    if (err.response) {
      return { error: err.response }
    } else {
      throw err
    }
  })
}

const endWorkOrder = (organization, tenant, orderKey) => {
  const target_url = !isLocalData
    ? url.END_WORK_ORDER.replace(/\${organization}/, organization)
        .replace(/\${tenant}/, tenant)
        .replace(/\${orderKey}/, orderKey)
    : url.END_WORK_ORDER
  console.log('api::endWorkOrder() target_url=' + target_url)

  return post(target_url, { message: 'work ended' }).catch((err) => {
    if (err.response) {
      return { error: err.response }
    } else {
      throw err
    }
  })
}

const approveOrder = (organization, tenant, orderKey) => {
  const target_url = !isLocalData
    ? url.APPROVE_ORDER.replace(/\${organization}/, organization)
        .replace(/\${tenant}/, tenant)
        .replace(/\${orderKey}/, orderKey)
    : url.APPROVE_ORDER
  console.log('api::approveOrder() target_url=' + target_url)

  return post(target_url, { message: 'order approved' }).catch((err) => {
    if (err.response) {
      return { error: err.response }
    } else {
      throw err
    }
  })
}

const rejectNewOrderApi = (organization, tenant, orderKey) => {
  const target_url = !isLocalData
    ? url.REJECT_NEW_ORDER.replace(/\${organization}/, organization)
        .replace(/\${tenant}/, tenant)
        .replace(/\${orderKey}/, orderKey)
    : url.REJECT_NEW_ORDER
  console.log('api::rejectNewOrderApi() target_url=' + target_url)

  return post(target_url, { message: 'order rejected' }).catch((err) => {
    if (err.response) {
      return { error: err.response }
    } else {
      throw err
    }
  })
}

const assignWorkOrder = (organization, tenant, orderKey, targetEmployeeKey) => {
  const target_url = !isLocalData
    ? url.ASSIGN_WORK_ORDER.replace(/\${organization}/, organization)
        .replace(/\${tenant}/, tenant)
        .replace(/\${orderKey}/, orderKey)
    : url.ASSIGN_WORK_ORDER
  console.log('api::approveOrder() target_url=' + target_url)

  return post(target_url, {
    message: 'work order assigned to worker',
    assignWorkerKey: targetEmployeeKey,
  }).catch((err) => {
    if (err.response) {
      return { error: err.response }
    } else {
      throw err
    }
  })
}

const callSubmitServiceOrder = (organization, tenant, orderData) => {
  const target_url = !isLocalData
    ? url.SUBMIT_SERVICE_ORDER.replace(
        /\${organization}/,
        organization
      ).replace(/\${tenant}/, tenant)
    : url.SUBMIT_SERVICE_ORDER
  console.log('api::SUBMIT_SERVICE_ORDER() target_url=' + target_url)
  console.log(
    'api::SUBMIT_SERVICE_ORDER() orderData=' + JSON.stringify(orderData)
  )

  return post(target_url, orderData).catch((err) => {
    if (err.response) {
      return { error: err.response }
    } else {
      throw err
    }
  })
}

const addProfileRecord = (organization, tenant, profileData) => {
  const target_url = !isLocalData
    ? url.ADD_PROFILE_RECORD.replace(/\${organization}/, organization).replace(
        /\${tenant}/,
        tenant
      )
    : url.ADD_PROFILE_RECORD
  console.log('api::ADD_PROFILE_RECORD() target_url=' + target_url)

  return post(target_url, profileData).catch((err) => {
    if (err.response) {
      return { error: err.response }
    } else {
      throw err
    }
  })
}

const deleteUserData = (organization, tenant, username) => {
  const target_url = !isLocalData
    ? url.DELETE_PROFILE_RECORD.replace(/\${organization}/, organization)
        .replace(/\${tenant}/, tenant)
        .replace(/\${username}/, username)
    : url.DELETE_PROFILE_RECORD
  console.log('api::DELETE_PROFILE_RECORD() target_url=' + target_url)

  return del(target_url).catch((err) => {
    if (err.response) {
      return { error: err.response }
    } else {
      throw err
    }
  })
}

const getTenantRecord = (organization, tenant) => {
  const target_url = !isLocalData
    ? url.GET_TENANT_RECORD.replace(/\${organization}/, organization).replace(
        /\${tenant}/,
        tenant
      )
    : url.GET_TENANT_RECORD
  console.log(
    'api::getTenantRecord() target_url=' +
      target_url +
      ', isLocalData=' +
      isLocalData
  )

  return get(target_url).catch((err) => {
    if (err.response) {
      return { error: err.response }
    } else {
      throw err
    }
  })
}

const addTenantRecord = (organization, tenant) => {
  const target_url = url.GET_TENANT_RECORD.replace(
    /\${organization}/,
    organization
  ).replace(/\${tenant}/, tenant)
  console.log('api::addTenantRecord() target_url=' + target_url)

  return post(url.GET_TENANT_RECORD, tenant)
}

const addServiceCatalog = (
  organization,
  detailTenant,
  dealershipTenant,
  serviceCatalog
) => {
  const target_url = !isLocalData
    ? url.ADD_SERVICE_CATALOG_RECORD.replace(/\${organization}/, organization)
        .replace(/\${detailTenant}/, detailTenant)
        .replace(/\${dealershipTenant}/, dealershipTenant)
    : url.ADD_SERVICE_CATALOG_RECORD

  console.log('api::addServiceCatalog() target_url=' + target_url)

  return post(target_url, serviceCatalog).catch((err) => {
    if (err.response) {
      return { error: err.response }
    } else {
      throw err
    }
  })
}

const updateServiceCatalog = (
  organization,
  detailTenant,
  dealershipTenant,
  serviceCatalogId,
  serviceCatalog
) => {
  const target_url = !isLocalData
    ? url.UPDATE_SERVICE_CATALOG_RECORD.replace(
        /\${organization}/,
        organization
      )
        .replace(/\${detailTenant}/, detailTenant)
        .replace(/\${dealershipTenant}/, dealershipTenant)
        .replace(/\${serviceCatalogId}/, serviceCatalogId)
    : url.UPDATE_SERVICE_CATALOG_RECORD

  console.log('api::updateServiceCatalog() target_url=' + target_url)

  return put(target_url, serviceCatalog).catch((err) => {
    if (err.response) {
      return { error: err.response }
    } else {
      throw err
    }
  })
}

const deleteServiceCatalog = (
  organization,
  detailTenant,
  dealershipTenant,
  serviceCatalogId
) => {
  const target_url = !isLocalData
    ? url.DELETE_SERVICE_CATALOG_RECORD.replace(
        /\${organization}/,
        organization
      )
        .replace(/\${detailTenant}/, detailTenant)
        .replace(/\${dealershipTenant}/, dealershipTenant)
        .replace(/\${serviceCatalogId}/, serviceCatalogId)
    : url.DELETE_SERVICE_CATALOG_RECORD

  console.log('api::deleteServiceCatalog() target_url=' + target_url)

  return del(target_url).catch((err) => {
    if (err.response) {
      return { error: err.response }
    } else {
      throw err
    }
  })
}

const updateTenantRecord = (organization, tenant, tenantData) => {
  const target_url = !isLocalData
    ? url.GET_TENANT_RECORD.replace(/\${organization}/, organization).replace(
        /\${tenant}/,
        tenant
      )
    : url.GET_TENANT_RECORD
  console.log(
    'api::updateTenantRecord() target_url=' +
      target_url +
      ', isLocalData=' +
      isLocalData
  )

  return put(target_url, tenantData).catch((err) => {
    if (err.response) {
      return { error: err.response }
    } else {
      throw err
    }
  })
}

// Get finance records
const getVehicleInfoByVin = (organization, tenant, vin) => {
  const target_url = !isLocalData
    ? url.GET_VEHICLE_INFO_BY_VIN.replace(/\${organization}/, organization)
        .replace(/\${tenant}/, tenant)
        .replace(/\${vin}/, vin)
    : url.GET_ALL_ORDERS
  console.log('api::getVehicleInfoByVin() target_url=' + target_url)

  return get(target_url)
}

const getDashboard = () => {
  console.log('api::getDashboard()')
  return get(url.GET_DASHBOARD).catch((err) => {
    if (err.response) {
      return { error: err.response }
    } else {
      throw err
    }
  })
}

const getAllOrdersAPI = (organization, tenant, from) => {
  const target_url = !isLocalData
    ? url.GET_ALL_ORDERS.replace(/\${organization}/, organization)
        .replace(/\${tenant}/, tenant)
        .replace(/\${from}/, from)
    : url.GET_ALL_ORDERS
  console.log('api::getAllOrdersAPI() target_url=' + target_url)

  return get(target_url).catch((err) => {
    if (err.response) {
      return { error: err.response }
    } else {
      throw err
    }
  })
}

const getOrderByKeyAPI = (organization, tenant, orderId) => {
  const target_url = !isLocalData
    ? url.GET_ORDER_BY_KEY.replace(/\${organization}/, organization)
        .replace(/\${tenant}/, tenant)
        .replace(/\${orderId}/, orderId)
    : url.GET_ORDER_BY_KEY
  console.log('api::getOrderByKey() target_url=' + target_url)

  return get(target_url).catch((err) => {
    if (err.response) {
      return { error: err.response }
    } else {
      throw err
    }
  })
}

const getFinanceData = (organization, tenant, from, to) => {
  const target_url = !isLocalData
    ? url.GET_FINANCE_DATA.replace(/\${organization}/, organization)
        .replace(/\${tenant}/, tenant)
        .replace(/\${from}/, from)
        .replace(/\${to}/, to)
    : url.GET_ALL_ORDERS
  console.log('api::getFinanceData() target_url=' + target_url)

  return get(target_url).catch((err) => {
    if (err.response) {
      return { error: err.response }
    } else {
      throw err
    }
  })
}

const getAllVehicleMakes = () => {
  console.log('api::getAllVehicleMakes() url=' + url.GET_ALL_VEHICLE_MAKES)
  return get(url.GET_ALL_VEHICLE_MAKES).catch((err) => {
    if (err.response) {
      return { error: err.response }
    } else {
      throw err
    }
  })
}

const getAllVehicleModelsByMakeAndYear = (make, year, type) => {
  const target_url = url.GET_ALL_VEHICLE_MODEL_BY_MAKE_AND_YEAR.replace(
    /\${make}/,
    make
  )
    .replace(/\${year}/, year)
    .replace(/\${vehicleType}/, type)
  console.log('api::decodeVinInfo() target_url=' + target_url)
  return get(target_url).catch((err) => {
    if (err.response) {
      return { error: err.response }
    } else {
      throw err
    }
  })
}

export {
  getFinanceData,
  getVehicleInfoByVin,
  getUsersData,
  getProfileRecord,
  getDashboard,
  getAllOrdersAPI,
  getAllVehicleMakes,
  getAllVehicleModelsByMakeAndYear,
  updateProfileRecord,
  addProfileRecord,
  getTenantRecord,
  addTenantRecord,
  updateTenantRecord,
  deleteUserData,
  addServiceCatalog,
  updateServiceCatalog,
  deleteServiceCatalog,
  getOrderByKeyAPI,
  callSubmitServiceOrder,
  approveOrder,
  rejectNewOrderApi,
  assignWorkOrder,
  startWorkOrder,
  endWorkOrder,
  reviewWorkOrder,
  rejectWorkOrder,
  uploadImageToOrder,
  addComment,
  addDeviceInfoForUser,
  deleteImageFromOrder,
}
