export const GET_ALL_ORDERS = 'GET_ALL_ORDERS'
export const GET_ALL_ORDERS_SUCCESS = 'GET_ALL_ORDERS_SUCCESS'
export const GET_ALL_ORDERS_FAIL = 'GET_ALL_ORDERS_FAIL'

export const DELAY_LOADING = 'DELAY_LOADING'

export const GET_ORDER_BY_KEY = 'GET_ORDER_BY_KEY'
export const GET_ORDER_BY_KEY_SUCCESS = 'GET_ORDER_BY_KEY_SUCCESS'
export const GET_ORDER_BY_KEY_FAIL = 'GET_ORDER_BY_KEY_FAIL'

export const APPROVE_ORDER = 'APPROVE_ORDER'
export const APPROVE_ORDER_SUCCESS = 'APPROVE_ORDER_SUCCESS'
export const APPROVE_ORDER_FAIL = 'APPROVE_ORDER_FAIL'

export const REJECT_ORDER = 'REJECT_ORDER'
export const REJECT_ORDER_SUCCESS = 'REJECT_ORDER_SUCCESS'
export const REJECT_ORDER_FAIL = 'REJECT_ORDER_FAIL'

export const ASSIGN_WORK = 'ASSIGN_WORK'
export const ASSIGN_WORK_SUCCESS = 'ASSIGN_WORK_SUCCESS'
export const ASSIGN_WORK_FAIL = 'ASSIGN_WORK_FAIL'

export const START_WORK = 'START_WORK'
export const START_WORK_SUCCESS = 'START_WORK_SUCCESS'
export const START_WORK_FAIL = 'START_WORK_FAIL'

export const END_WORK = 'END_WORK'
export const END_WORK_SUCCESS = 'END_WORK_SUCCESS'
export const END_WORK_FAIL = 'END_WORK_FAIL'

export const REVIEW_WORK = 'REVIEW_WORK'
export const REVIEW_WORK_SUCCESS = 'REVIEW_WORK_SUCCESS'
export const REVIEW_WORK_FAIL = 'REVIEW_WORK_FAIL'

export const UPLOAD_IMAGE = 'UPLOAD_IMAGE'
export const UPLOAD_IMAGE_SUCCESS = 'UPLOAD_IMAGE_SUCCESS'
export const UPLOAD_IMAGE_FAIL = 'UPLOAD_IMAGE_FAIL'

export const DELETE_IMAGE = 'DELETE_IMAGE'
export const DELETE_IMAGE_SUCCESS = 'DELETE_IMAGE_SUCCESS'
export const DELETE_IMAGE_FAIL = 'DELETE_IMAGE_FAIL'

export const ADD_COMMENT_TO_ORDER = 'ADD_COMMENT_TO_ORDER'
export const ADD_COMMENT_TO_ORDER_SUCCESS = 'ADD_COMMENT_TO_ORDER_SUCCESS'
export const ADD_COMMENT_TO_ORDER_FAIL = 'ADD_COMMENT_TO_ORDER_FAIL'

export const ORDER_FILTER_SEARCH_BY_ID_NUM = 'ORDER_FILTER_SEARCH_BY_ID_NUM,'
