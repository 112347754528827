import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import NumberFormat from 'react-number-format'
import { withTranslation } from 'react-i18next'
import { formatDateOnly } from '../../helpers/date_formatter'
import './orderhistorytable.scss'

import { ReactComponent as ArrowDownIcon } from '../../assets/images/ic-arrow down.svg'
import { ReactComponent as ArrowUpIcon } from '../../assets/images/ic-arrow up.svg'

const GlistenOrderHistory = (props) => {
  const [dateSort, setDateSort] = useState(false)
  const [priceSort, setPriceSort] = useState(null)

  const onSortHistoryDataByDate = (targetsort) => {
    props.history.sort((a, b) =>
      a.data_timestamp > b.data_timestamp
        ? targetsort
          ? 1
          : -1
        : targetsort
        ? -1
        : 1
    )
  }

  const onSortHistoryDataByPrice = (targetsort) => {
    props.history.sort((a, b) =>
      a.service_catalog.reduce((accum, curr) => accum + curr.retail_cost, 0) >
        b.service_catalog.reduce(
          (accum, curr) => accum + curr.retail_cost,
          0
        ) && targetsort
        ? 1
        : -1
    )
  }

  const sortData = (type) => {
    if (type == 'price') {
      const targetSort = priceSort == null ? true : !priceSort
      setPriceSort(targetSort)
      onSortHistoryDataByPrice(targetSort)
      setDateSort(null)
    } else if (type == 'date') {
      const targetSort = dateSort == null ? true : !dateSort
      setDateSort(targetSort)
      onSortHistoryDataByDate(targetSort)
      setPriceSort(null)
    }
  }

  return (
    <>
      <div className='table-rep-plugin'>
        <div className='table-responsive mb-0' data-pattern='priority-columns'>
          <Table id='tech-companies-1' className='table row-margin-fix'>
            <Thead>
              <Tr>
                <Th className='table-headers' onClick={() => sortData('date')}>
                  {props.t('Date')}
                  {dateSort ? (
                    <ArrowDownIcon className='selected-sort' />
                  ) : dateSort === null ? (
                    <ArrowDownIcon className='unselected-sort' />
                  ) : (
                    <ArrowUpIcon className='selected-sort' />
                  )}
                  {/* <div onClick={() => sortData('date')}>
                    {props.t('Date')} &nbsp;
                    <SortIcon type='date' value={dateSort} />
                  </div> */}
                </Th>
                <Th className='table-headers' data-priority='1'>
                  {props.t('Service')}
                </Th>
                <Th className='table-headers' data-priority='3'>
                  <div onClick={() => sortData('price')}>
                    {props.t('Price')}
                    {priceSort ? (
                      <ArrowDownIcon className='selected-sort' />
                    ) : priceSort === null ? (
                      <ArrowDownIcon className='unselected-sort' />
                    ) : (
                      <ArrowUpIcon className='selected-sort' />
                    )}
                  </div>
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {props.history.map((item, idx) => {
                let totalPrice = item.service_catalog
                  .map((t) => t.retail_cost)
                  .reduce((prev, curr) => prev + curr, 0)
                let serviceName = item.service_catalog.reduce(
                  (accum, curr) =>
                    (accum == '' ? '' : accum + ', ') + curr.name,
                  ''
                )
                return (
                  <Tr key={idx}>
                    <Td className='table-cells'>
                      <span className='co-name'>
                        {formatDateOnly(item.data_timestamp)}
                      </span>
                    </Td>
                    <Td className='table-cells'>{serviceName}</Td>
                    <Td className='table-cells'>
                      <NumberFormat
                        value={totalPrice}
                        displayType={'text'}
                        fixedDecimalScale={true}
                        decimalScale={2}
                        thousandSeparator={true}
                        prefix={'$'}
                      />
                    </Td>
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
        </div>
      </div>
    </>
  )
}

GlistenOrderHistory.propTypes = {
  t: PropTypes.any,
  history: PropTypes.any,
}

export default withTranslation()(GlistenOrderHistory)
