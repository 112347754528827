import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import { Row, Col, Button, FormGroup, Modal, Label } from 'reactstrap'
import DayPicker, { DateUtils } from 'react-day-picker'
import 'react-day-picker/lib/style.css'

// Styling
import '../TimeFilter/timefilter.scss'

//i18n
import { withTranslation } from 'react-i18next'

import { ReactComponent as Logo } from '../../../assets/images/ic-close.svg'
import { sendPageViewEvent } from '../../../firebase'

const numberOfMonths = 2

const TimeFilter = (props) => {
  useEffect(() => {
    sendPageViewEvent('Finance Time Filter')
  }, [])

  const [from, setFrom] = useState()
  const [to, setTo] = useState()
  const displayCurr = new Date()

  const handleDayClick = (day) => {
    const range = DateUtils.addDayToRange(day, { from, to })
    setFrom(range.from)
    setTo(range.to)
  }

  const handleResetClick = () => {
    setFrom('')
    setTo('')
  }

  const modifiers = { start: from, end: to }

  const handleSubmit = (evt) => {
    if (from == null || to == null || from == '' || to == '') {
      var today = new Date()
      var priorDate = new Date().setDate(today.getDate() - 30)
      props.onSelectDate(priorDate, today.getTime()) // HEK

      props.setRange(`All Time`)
    } else {
      var printFromDate = new Date(from)
      var printToDate = new Date(to)
      printFromDate.setTime(printFromDate.getTime() - 86399990 / 2)
      printToDate.setTime(printToDate.getTime() + 86399990 / 2)

      console.log(printFromDate)
      console.log(printToDate)

      evt.preventDefault()
      props.onSelectDate(printFromDate.getTime(), printToDate.getTime())
      printToDate.setDate(printToDate.getDate())
      props.setRange(
        `${printFromDate.toLocaleDateString(
          'en-US'
        )} - ${printToDate.toLocaleDateString('en-US')}`
      )
    }

    props.showModal(false)
  }

  return (
    <Modal
      size='xl'
      isOpen={props.isOpen}
      toggle={() => {
        props.showModal(false)
      }}
      centered={true}
    >
      <div className='modal-header glisten-modal-header'>
        <div>
          <h3 className='glisten-modal-title'>{props.title}</h3>
        </div>

        <button
          className='close-btn'
          onClick={() => {
            props.showModal(false)
          }}
        >
          <Logo />
        </button>
      </div>
      <div
        className='modal-body glisten-modal-body'
        style={{ paddingTop: '1.188rem' }}
      >
        <Row style={{ marginTop: '1.875rem', marginBottom: '1.25rem' }}>
          <Col lg='8'>
            <div
              style={{ float: 'none', margin: '0 auto', width: 'fit-content' }}
            >
              <Label className='selection-label'>
                {!from && !to && props.t('Please select the first day.')}
                {from && !to && props.t('Please select the last day.')}
                {from &&
                  to &&
                  `Selected from ${from.toLocaleDateString()} to
                ${to.toLocaleDateString()}`}
                {from && to && (
                  <Button className='reset-link' onClick={handleResetClick}>
                    Reset
                  </Button>
                )}
              </Label>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg='12'>
            <DayPicker
              className='Selectable'
              month={displayCurr}
              toMonth={displayCurr}
              disabledDays={[{ after: displayCurr }]}
              numberOfMonths={numberOfMonths}
              selectedDays={[from, { from, to }]}
              modifiers={modifiers}
              onDayClick={handleDayClick}
            />
          </Col>
        </Row>
      </div>

      <div className='modal-footer glisten-modal-footer'>
        <div>
          <FormGroup>
            <Button
              className='btn glisten-btn prim-mod-action'
              disabled={props.submitBtnDisabled}
              onClick={(evt) => {
                handleSubmit(evt)
              }}
            >
              {props.submitBtnTitle}
            </Button>
          </FormGroup>
        </div>
      </div>
    </Modal>
  )
}

TimeFilter.propTypes = {
  t: PropTypes.any,
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  tog_center: PropTypes.func,
  showModal: PropTypes.func,
  submitBtnTitle: PropTypes.string,
  submitBtnDisabled: PropTypes.bool,
  onSelectDate: PropTypes.func,
  setRange: PropTypes.func,
}

export default withTranslation()(TimeFilter)
