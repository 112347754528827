/* eslint-disable no-undef */
import { initializeApp } from 'firebase/app'
import { getPerformance } from 'firebase/performance'
import {
  getMessaging,
  onMessage,
  getToken,
  isSupported,
} from 'firebase/messaging'
import { getAnalytics, logEvent } from 'firebase/analytics'

var app = null

const firebase = () => {
  if (process.env.REACT_APP_FIREBASE_ENABLED === 'true') {
    const firebaseConfig = {
      apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
      authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
      projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
      storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_FIREBASE_APP_ID,
      measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
    }
    if (app == null) app = initializeApp(firebaseConfig)
    return {
      perf: getPerformance(app),
      analytic: getAnalytics(app),
    }
  } else {
    return { perf: null, analytic: null }
  }
}

const { REACT_APP_VAPID_KEY } = process.env
const publicKey = REACT_APP_VAPID_KEY

const getTokenKey = async (setTokenFound) => {
  let currentToken = ''
  if (process.env.REACT_APP_FIREBASE_ENABLED === 'true') {
    const supported = await isSupported()
    if (supported) {
      try {
        currentToken = await getToken(getMessaging(app), {
          vapidKey: publicKey,
        })
        if (currentToken) {
          setTokenFound(true)
        } else {
          setTokenFound(false)
        }
      } catch (error) {
        console.log('An error occurred while retrieving token. ', error)
      }
    }
  }
  return currentToken
}

const sendPageViewEvent = (title) => {
  if (process.env.REACT_APP_FIREBASE_ENABLED === 'true') {
    app &&
      logEvent(getAnalytics(app), 'page_view', {
        page_title: title,
      })
  }
}

const onMessageListener = () =>
  isSupported().then((supported) => {
    if (supported) {
      return new Promise((resolve) => {
        if (process.env.REACT_APP_FIREBASE_ENABLED === 'true') {
          onMessage(getMessaging(app), (payload) => {
            console.log('message received: ' + JSON.stringify(payload))
            const resp = {
              username: payload.data.username,
              title: payload.data.title,
              body: payload.data.body,
            }
            resolve(resp)
          })
        }
      })
    } else {
      return new Promise(() => {})
    }
  })

export { firebase, sendPageViewEvent, getTokenKey, onMessageListener }
