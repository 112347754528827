/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import MetaTags from 'react-meta-tags'
import {
  Container,
  Row,
  Col,
  Label,
  Card,
  CardBody,
  Button,
  Alert,
} from 'reactstrap'
import { connect } from 'react-redux'
import { changePassword, initChangePassword } from 'store/actions'

// Styling
import '../../pages/ResetPassword/resetpassword.scss'

import { Link, withRouter } from 'react-router-dom'

//i18n
import { withTranslation } from 'react-i18next'
import { sendPageViewEvent } from '../../firebase'
import { useForm } from 'react-hook-form'
import LoadingOverlay from 'react-loading-overlay'
import { isMobile } from 'react-device-detect'

const ChangePassword = (props) => {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm()
  const password = useRef({})
  password.current = watch('newPassword', '')
  const [passwordChangedTriggered, setPasswordChangedTriggered] = useState(
    false
  )
  const onSubmit = (evt) => {
    props.onInitChangePassword()
    props.onChangePassword(props.email, evt.current, evt.newPassword)
    setPasswordChangedTriggered(true)
    reset()
  }

  useEffect(() => {
    sendPageViewEvent('Reset Password')
    props.onInitChangePassword()
  }, [])

  var title = props.t('Reset Password')

  return (
    <LoadingOverlay active={props.loading} spinner>
      <div className='breadsrumb-content'>
        <div
          className='breadcrumb-header'
          style={!isMobile ? { minHeight: '7.188rem' } : {}}
        >
          <Row>
            <Col xl={2}>
              <div
                className='page-title-box'
                style={!isMobile ? { paddingTop: '2.563rem' } : {}}
              >
                <div
                  style={{
                    fontSize: '1.5rem',
                    fontWeight: '500',
                    letterSpacing: '2.7px',
                    fontFamily: 'Poppins',
                    color: '#333333',
                  }}
                >
                  {props.t('My Account')}
                </div>
              </div>
            </Col>
            <Col
              xl={10}
              style={
                !isMobile
                  ? { paddingTop: '3.063rem' }
                  : { paddingTop: '1.063rem' }
              }
            >
              <Link to='myprofile' className='account-link'>
                {props.t('My Profile')}
              </Link>
              <Link to='resetpassword' className='account-link-sel'>
                {props.t('Change Password')}
              </Link>
              {(props.userFeat.includes('ftl-admin') ||
                props.userFeat.includes('ftl-executive')) && (
                <Link to='users' className='account-link'>
                  {props.t('Users')}
                </Link>
              )}
              {(props.userFeat.includes('ftl-admin') ||
                props.userFeat.includes('ftl-executive')) && (
                <Link to='dealershipinfo' className='account-link'>
                  {props.t('Dealership Info')}
                </Link>
              )}
              {(props.userFeat.includes('ftl-admin') ||
                props.userFeat.includes('ftl-executive')) && (
                <Link to='serviceinfo' className='account-link'>
                  {props.t('Service Info')}
                </Link>
              )}
            </Col>
          </Row>
        </div>
      </div>

      <div className='page-content page-background'>
        <MetaTags>
          <title>Home | {title}</title>
        </MetaTags>

        <Container fluid style={!isMobile ? { width: '50%' } : {}}>
          <Row
            style={
              !isMobile
                ? { paddingTop: '3.313rem' }
                : { paddingTop: '1.313rem' }
            }
          >
            <Col lg='12'>
              <Card>
                <CardBody
                  style={!isMobile ? { padding: '0rem' } : { padding: '1rem' }}
                >
                  <form
                    onSubmit={handleSubmit(onSubmit)}
                    className='form-horizontal'
                  >
                    {props.error &&
                    typeof props.error === 'string' &&
                    props.changePasswordStatus != 'INITIATED' ? (
                      <Alert color='danger' style={{ textAlign: 'center' }}>
                        {props.t(
                          'Cannot change the password at this time. Please contact the administrator.'
                        )}
                      </Alert>
                    ) : null}

                    {props.error == null &&
                    passwordChangedTriggered &&
                    props.changePasswordStatus != 'INITIATED' ? (
                      <Alert color='success' style={{ textAlign: 'center' }}>
                        {props.t('Password is changed successfully')}
                      </Alert>
                    ) : null}
                    <Row>
                      <Col lg={{ size: 6, offset: 3 }}>
                        <Label className='section-label'>
                          {props.t('Current Password')}
                        </Label>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        lg={{ size: 6, offset: 3 }}
                        style={isMobile ? { marginTop: '1.313rem' } : {}}
                      >
                        <Label
                          className={
                            watch('current') && watch('current').length > 0
                              ? 'glisten-textfield-label'
                              : 'glisten-textfield-label glisten-textfield-label-hidden'
                          }
                        >
                          {props.t('Current Password')}
                        </Label>
                        <input
                          type='password'
                          id='current'
                          name='current'
                          label={props.t('Current Password')}
                          className='form-control glisten-textfield'
                          placeholder={props.t('Current Password')}
                          defaultValue=''
                          {...register('current', {
                            required: props.t('Please enter current password.'),
                          })}
                        />
                        {errors.current?.message && (
                          <p>{errors.current.message}</p>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={{ size: 6, offset: 3 }}>
                        <Label className='newpass-section-label'>
                          {props.t('New Password')}
                        </Label>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={{ size: 6, offset: 3 }}>
                        <Label className='body-text'>
                          {props.t('Password should contain the following')}
                        </Label>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        lg={{ size: 6, offset: 3 }}
                        style={{ marginTop: '2.5rem' }}
                      >
                        <Label className='sm-body-text'>
                          · {props.t('At least 8 characters')}
                        </Label>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={{ size: 6, offset: 3 }}>
                        <Label className='sm-body-text'>
                          ·{' '}
                          {props.t(
                            'At least one uppercase and lowercase letter'
                          )}
                        </Label>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={{ size: 6, offset: 3 }}>
                        <Label className='sm-body-text'>
                          · {props.t('At least one character and one number')}
                        </Label>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={{ size: 6, offset: 3 }}>
                        <Label className='sm-body-text'>
                          ·{' '}
                          {props.t(
                            'At least one special character, e.g., ! @ # ? ]'
                          )}
                        </Label>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={{ size: 6, offset: 3 }}>
                        <Label
                          className={
                            watch('new') && watch('new').length > 0
                              ? 'glisten-textfield-label'
                              : 'glisten-textfield-label glisten-textfield-label-hidden'
                          }
                        >
                          {props.t('New Password')}
                        </Label>
                        <input
                          type='password'
                          id='newPassword'
                          name='newPassword'
                          label={props.t('New Password')}
                          className='form-control glisten-textfield'
                          placeholder={props.t('New Password')}
                          defaultValue=''
                          {...register('newPassword', {
                            required: props.t('Please enter new password.'),
                          })}
                        />
                        {errors.new?.message && <p>{errors.new.message}</p>}
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={{ size: 6, offset: 3 }}>
                        <Label
                          className={
                            watch('renew') && watch('renew').length > 0
                              ? 'glisten-textfield-label'
                              : 'glisten-textfield-label glisten-textfield-label-hidden'
                          }
                        >
                          {props.t('Confirm New Password')}
                        </Label>
                        <input
                          type='password'
                          id='confirmpassword'
                          name='confirmpassword'
                          label={props.t('Confirm New Password')}
                          className='form-control glisten-textfield'
                          placeholder={props.t('Confirm New Password')}
                          defaultValue=''
                          {...register('confirmpassword', {
                            required: props.t(
                              'Please enter new password again.'
                            ),
                            validate: (value) =>
                              value === password.current ||
                              'The passwords do not match',
                          })}
                        />
                        {errors.confirmpassword?.message && (
                          <p>{errors.confirmpassword.message}</p>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={{ size: 12, offset: 0 }}>
                        <Button
                          color='dark'
                          className='prim-btn-big'
                          style={
                            !isMobile
                              ? {
                                  float: 'left',
                                  marginTop: '3.125rem',
                                  marginBottom: '3.125rem',
                                }
                              : {
                                  float: 'right',
                                  marginTop: '3.125rem',
                                  marginBottom: '3.125rem',
                                }
                          }
                        >
                          {props.t('Update')}
                        </Button>
                      </Col>
                    </Row>
                  </form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </LoadingOverlay>
  )
}

ChangePassword.propTypes = {
  t: PropTypes.any,
  userFeat: PropTypes.any,
  email: PropTypes.string,
  loading: PropTypes.bool,
  error: PropTypes.string,
  changePasswordStatus: PropTypes.string,
  onChangePassword: PropTypes.func,
  onInitChangePassword: PropTypes.func,
}

const mapStateToProps = ({ login }) => ({
  email: login.email,
  loading: login.loading,
  userFeat: login.features,
  error: login.error,
  changePasswordStatus: login.changePasswordStatus,
})

const mapDispatchToProps = (dispatch) => ({
  onChangePassword: (email, oldPassword, newPassword) =>
    dispatch(changePassword(email, oldPassword, newPassword)),
  onInitChangePassword: () => dispatch(initChangePassword()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(ChangePassword)))
