import React from 'react'
import PropTypes from 'prop-types'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { isMobile } from 'react-device-detect'

const ReactNotificationComponent = ({ title, body }) => {
  let hideNotif = title === ''

  if (!hideNotif) {
    toast.info(<Display />)
  }

  function Display() {
    return (
      <div>
        <h4>{title}</h4>
        <h5>{body}</h5>
        <h5>
          <a
            style={{ float: 'right' }}
            onClick={() => {
              window.location.reload()
            }}
          >
            Refresh this Page
          </a>
        </h5>
      </div>
    )
  }

  return (
    <ToastContainer
      style={isMobile ? {} : { width: '50em' }}
      autoClose={false}
      hideProgressBar
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss={false}
      draggable
      type={'success'}
      pauseOnHover={false}
      position={'bottom-right'}
    />
  )
}

ReactNotificationComponent.defaultProps = {
  title: 'Glisten Bot',
  body: 'Some body',
}

ReactNotificationComponent.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
}

export default ReactNotificationComponent
