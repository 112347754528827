import { call, put, takeEvery } from 'redux-saga/effects'
import {
  LOGIN,
  LOGOUT,
  CHANGE_PASSWORD,
  CONFIRM_REGISTRATION,
  RESEND_REGISTRATION_CODE,
  FORGOT_PASSWORD,
  CONFIRM_NEW_PASSWORD,
} from './actionTypes'
import {
  loginSuccess,
  loginFail,
  logoutSuccess,
  logoutFail,
  changePasswordSuccess,
  changePasswordFail,
  confirmRegistrationSuccess,
  confirmRegistrationFail,
  resendConfirmationCodeSuccess,
  resendConfirmationCodeFail,
  forgotPasswordSuccess,
  forgotPasswordFail,
  confirmPasswordSuccess,
  confirmPasswordFail,
} from './actions'
import {
  authenticateUser,
  changePassword,
  confirmRegistration,
  signOut,
  resendConfirmation,
  forgotPassword,
  confirmPassword,
} from './aws-cognito'

function* loginUser({ payload: { username, password } }) {
  console.log('saga:loginUser()')
  try {
    const response = yield call(authenticateUser, username, password)
    yield put(loginSuccess(response))
  } catch (error) {
    yield put(loginFail(error))
  }
}

function* logoutUser() {
  console.log('saga:logoutUser()')
  try {
    const response = yield call(signOut)
    yield put(logoutSuccess(response))
  } catch (error) {
    yield put(logoutFail(error))
  }
}

function* updatePassword({ payload: { newPassword, oldPassword } }) {
  console.log('saga:changePassword()')
  try {
    const response = yield call(changePassword, newPassword, oldPassword)
    yield put(changePasswordSuccess(response))
  } catch (error) {
    yield put(changePasswordFail(error))
  }
}

function* confirmRegistrationCode({ payload: { username, code } }) {
  console.log('saga:confirmRegistration()')
  try {
    const response = yield call(confirmRegistration, username, code)
    yield put(confirmRegistrationSuccess(response))
  } catch (error) {
    yield put(confirmRegistrationFail(error))
  }
}

function* resendRegistrationCode({ payload: { username } }) {
  console.log('saga:resendRegistrationCode()')
  try {
    const response = yield call(resendConfirmation, username)
    yield put(resendConfirmationCodeSuccess(response))
  } catch (error) {
    yield put(resendConfirmationCodeFail(error))
  }
}

function* triggerForgotPassword({ payload: { username } }) {
  console.log('saga:triggerForgotPassword()')
  try {
    const response = yield call(forgotPassword, username)
    yield put(forgotPasswordSuccess(response))
  } catch (error) {
    yield put(forgotPasswordFail(error))
  }
}

function* triggerConfirmPassword({ payload: { username, code, password } }) {
  console.log('saga:triggerConfirmPassword()')
  try {
    const response = yield call(confirmPassword, username, code, password)
    yield put(confirmPasswordSuccess(response))
  } catch (error) {
    yield put(confirmPasswordFail(error))
  }
}

function* authSaga() {
  yield takeEvery(LOGIN, loginUser)
  yield takeEvery(LOGOUT, logoutUser)
  yield takeEvery(CHANGE_PASSWORD, updatePassword)
  yield takeEvery(CONFIRM_REGISTRATION, confirmRegistrationCode)
  yield takeEvery(RESEND_REGISTRATION_CODE, resendRegistrationCode)
  yield takeEvery(FORGOT_PASSWORD, triggerForgotPassword)
  yield takeEvery(CONFIRM_NEW_PASSWORD, triggerConfirmPassword)
}

export default authSaga
