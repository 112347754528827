import {
  GET_DASHBOARD,
  GET_DASHBOARD_SUCCESS,
  GET_DASHBOARD_FAIL,
} from './actionTypes'

export const getDashboard = () => {
  console.log('actions::getDashboard()')
  return {
    type: GET_DASHBOARD,
  }
}

export const getDashboardSuccess = (dashboard) => {
  console.log('actions::getDashboardSuccess() dashboard=' + dashboard)
  return {
    type: GET_DASHBOARD_SUCCESS,
    payload: dashboard,
  }
}

export const getDashboardFail = (error) => {
  console.log('actions::getDashboardFail() error=' + error)
  return {
    type: GET_DASHBOARD_FAIL,
    payload: error,
  }
}
