import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import MetaTags from 'react-meta-tags'
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Media,
} from 'reactstrap'
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'
import Breadcrumbs from '../../components/Common/Breadcrumb'
import { withTranslation } from 'react-i18next'
import NumberFormat from 'react-number-format'
import { isMobile } from 'react-device-detect'
import FinanceFilter from './FinanceFilter'
//import CreateReport from './CreateReport'
import { connect } from 'react-redux'
import {
  getFinanceData,
  sortFinanceDataByCompletedOn,
  sortFinanceDataByPrice,
  filterFinanceDataByLast6Vin,
  getAllOrders,
} from 'store/actions'

import { formatTime } from '../../helpers/util'
import LoadingOverlay from 'react-loading-overlay'

import { ReactComponent as ArrowDownIcon } from '../../assets/images/ic-arrow down.svg'
import { ReactComponent as ArrowUpIcon } from '../../assets/images/ic-arrow up.svg'

import details_icon from '../../assets/images/ic-details.svg'
import no_results from '../../assets/images/ic-no results.svg'

import FinanceOrderDetails from './FinanceOrderDetail'
import FinanceInvoice from './FinanceInvoice'

import { useForm, FormProvider } from 'react-hook-form'

//styling
import '../Finance/finance.scss'
import TimeFilter from './TimeFilter'
import { sendPageViewEvent } from '../../firebase'

const Finance = (props) => {
  const {
    onGetFinanceData,
    onSortFinanceDataByCompletedOn,
    onSortFinanceDataByPrice,
    onFilterFinanceDataByLast6Vin,
    onGetAllOrders,
  } = props

  const methods = useForm()

  var watchSelections = methods.watch('services')

  const [priceSort, setPriceSort] = useState(null)
  const [completedOnSort, setCompletedOnSort] = useState(true)

  const [modal_center, setmodal_center] = useState(false)

  const [modal_details, setmodal_details] = useState(false)
  const [orderSelected, setOrderSelected] = useState(null)

  const [modal_invoice, setmodal_invoice] = useState(false)
  const [selectedOrders, setSelectedOrders] = useState([])
  const [selectedServiceQTY, setSelectedServiceQTY] = useState([])
  const [filterDate, setFilterDate] = useState('All Time')

  const [vinFilterBy, setVinNumber] = useState('')

  function tog_details(order) {
    setOrderSelected(order.data_key)
    setmodal_details(!modal_details)
    removeBodyCss()
  }

  function removeBodyCss() {
    document.body.classList.add('no_padding')
  }

  var title = props.t('Finance')

  useEffect(() => {
    var today = new Date()
    var priorDate = new Date().setDate(today.getDate() - 30)
    onGetFinanceData(props.org, props.tenant, priorDate, today.getTime())
    props.setPageTitle(title)
    sendPageViewEvent('Finance')
  }, [onGetFinanceData])

  useEffect(() => {
    onGetAllOrders(props.org, props.tenant)
  }, [onGetAllOrders])

  class InvoiceService {
    constructor(service) {
      this.category = service.category
      this.name = service.name
      this.cost = service.retail_cost
      this.quantity = 1
    }
  }

  const sortData = (type) => {
    if (type == 'price') {
      const targetSort = priceSort == null ? true : !priceSort
      setPriceSort(targetSort)
      onSortFinanceDataByPrice(targetSort)
      setCompletedOnSort(null)
    } else if (type == 'completedOn') {
      const targetSort = completedOnSort == null ? true : !completedOnSort
      setCompletedOnSort(targetSort)
      onSortFinanceDataByCompletedOn(targetSort)
      setPriceSort(null)
    }
  }

  const formatVINDisplay = (vin_slice) => {
    //vinFilterBy is '' then display normal
    //if vin_slice starts with vin_slice, highlight
    if (vinFilterBy == '') {
      return <Td className='table-cells'>{vin_slice}</Td>
    } else if (vin_slice.startsWith(vin_slice)) {
      return (
        <Td className='table-cells'>
          <span className='vin-prom'>{vinFilterBy}</span>
          {vin_slice.slice(vinFilterBy.length)}
        </Td>
      )
    }
  }

  function generateInvoice(orderKeys) {
    //create an array of order objects from array of keys
    var orders = orderKeys.map((key) =>
      props.completedOrders.find(({ data_key }) => data_key === key)
    )
    setSelectedOrders(orders)

    //create service order objects for invoice quantity calculations
    var selected_services = []

    orders.forEach((order) => {
      var temp = []
      order.service_catalog.forEach((service) => {
        if (selected_services.length == 0) {
          selected_services.push(new InvoiceService(service))
        } else {
          var found_match = false
          selected_services.forEach((logged_service) => {
            if (
              logged_service.category == service.category &&
              logged_service.name == service.name
            ) {
              logged_service.quantity = logged_service.quantity + 1
              found_match = true
            } else {
              null
            }
          })
          !found_match ? temp.push(new InvoiceService(service)) : null
        }
      })
      selected_services.push(...temp)
      temp = []
    })
    setSelectedServiceQTY(selected_services)

    //the following ensure state immeadiately updates
    setSelectedOrders((state) => {
      console.log(state)
      return state
    })
    setmodal_invoice(!modal_invoice)
  }

  // function tog_details(order) {
  //   setOrderSelected(order.data_key)
  //   setmodal_details(!modal_details)
  //   removeBodyCss()
  // }

  const FinanceButtonComponent = () => (
    <div>
      <Button
        color='dark'
        className={
          watchSelections && watchSelections.length > 0
            ? 'prim-btn-big'
            : 'disabled-prim-btn-big'
        }
        style={{ float: 'right' }}
        onClick={() => {
          const getTheseOrders = methods.getValues('services')
          getTheseOrders && getTheseOrders.length > 0
            ? generateInvoice(getTheseOrders)
            : null
        }}
      >
        {props.t('Generate Invoice')}
      </Button>
    </div>
  )

  var totalSales = 0
  props.financeData.forEach((item) => {
    item.service_catalog.forEach((sc) => {
      totalSales += sc.retail_cost
    })
  })

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <div className='breadsrumb-content'>
          <Breadcrumbs
            title={props.t('Home')}
            breadcrumbItem={title}
            showBack={false}
            filter={<FinanceButtonComponent />}
          />
        </div>
        <div className='page-content page-background'>
          <MetaTags>
            <title>
              {props.t('Home')} | {title}
            </title>
          </MetaTags>

          <Container fluid style={{ maxWidth: '95%' }}>
            <Row>
              <Col
                xl={6}
                className='align-self-center'
                style={
                  isMobile
                    ? {
                        display: 'flex',
                        justifyContent: 'flex-start',
                        paddingTop: '1rem',
                        paddingBottom: '1rem',
                      }
                    : {
                        display: 'flex',
                        justifyContent: 'flex-start',
                      }
                }
              >
                <FinanceFilter
                  onVinSearch={(vin) => {
                    onFilterFinanceDataByLast6Vin(vin)
                  }}
                  showModal={setmodal_center}
                  dateRange={filterDate}
                  setVIN={setVinNumber}
                  vin={vinFilterBy}
                />
              </Col>
              <Col
                xl={6}
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <div
                  className='align-self-center'
                  style={{ display: 'inline-flex' }}
                >
                  <div
                    style={{
                      fontFamily: 'Poppins',
                      fontWeight: '400',
                      fontSize: '1rem',
                      letterSpacing: '0.113rem',
                      lineHeight: '1.813rem',
                      color: '#333333',
                      marginRight: '0.875rem',
                    }}
                  >
                    {props.t('Total Sales')}
                  </div>
                  <div>
                    <NumberFormat
                      style={{
                        fontFamily: 'Poppins',
                        fontWeight: '500',
                        textTransform: 'uppercase',
                        fontSize: '1.313rem',
                        letterSpacing: '2.36px',
                        color: '#333333',
                        marginRight: '1.625rem',
                      }}
                      value={totalSales}
                      fixedDecimalScale={true}
                      decimalScale={2}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={'$'}
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xl='12'>
                <LoadingOverlay active={props.loading} spinner>
                  <Card>
                    <CardBody>
                      <div className='table-rep-plugin'>
                        <div
                          className='table-responsive mb-0'
                          data-pattern='priority-columns'
                        >
                          <Table id='tech-companies-1' className='table'>
                            <Thead>
                              <Tr>
                                <Th className='table-headers'>
                                  {/* <Input
                                    type='checkbox'
                                    style={{ verticalAlign: 'middle' }}
                                  ></Input> */}
                                </Th>
                                <Th
                                  className='table-headers'
                                  onClick={() => sortData('completedOn')}
                                >
                                  {props.t('Completed On')}
                                  {completedOnSort ? (
                                    <ArrowDownIcon className='selected-sort' />
                                  ) : completedOnSort === null ? (
                                    <ArrowDownIcon className='unselected-sort' />
                                  ) : (
                                    <ArrowUpIcon className='selected-sort' />
                                  )}
                                </Th>
                                <Th className='table-headers'>
                                  {props.t('Customer Tag')}
                                </Th>
                                <Th className='table-headers' data-priority='1'>
                                  {props.t('Last 6 of VIN')}
                                </Th>
                                <Th className='table-headers' data-priority='3'>
                                  {props.t('Stock #')}
                                </Th>
                                <Th className='table-headers' data-priority='1'>
                                  {props.t('Service(s)')}
                                </Th>
                                <Th className='table-headers' data-priority='3'>
                                  <div onClick={() => sortData('price')}>
                                    {props.t('Price')}
                                    {priceSort ? (
                                      <ArrowDownIcon className='selected-sort' />
                                    ) : priceSort === null ? (
                                      <ArrowDownIcon className='unselected-sort' />
                                    ) : (
                                      <ArrowUpIcon className='selected-sort' />
                                    )}
                                  </div>
                                </Th>
                                <Th className='table-headers' data-priority='6'>
                                  {props.t('Action')}
                                </Th>
                              </Tr>
                            </Thead>

                            <Tbody>
                              {props.financeData.map((item) => {
                                return (
                                  <>
                                    <Tr key={`${item.data_key}`}>
                                      <Td className='table-cells'>
                                        <input
                                          id='services'
                                          name='services'
                                          className='form-check-input'
                                          type='checkbox'
                                          defaultValue={item.data_key}
                                          {...methods.register('services')}
                                        />
                                      </Td>
                                      <Td className='table-cells'>
                                        {formatTime(item.data_timestamp)}
                                      </Td>
                                      <Td className='table-cells'>
                                        {item.customer_tag}
                                      </Td>
                                      {/* <Td>
                                      {item.vehicle.data_key.slice(-6)}
                                      </Td> */}
                                      {formatVINDisplay(
                                        item.vehicle.data_key.slice(-6)
                                      )}
                                      <Td className='table-cells'>
                                        {item.stock}
                                      </Td>
                                      <Td className='table-cells'>
                                        {item.service_catalog.reduce(
                                          (accum, curr) =>
                                            (accum == '' ? '' : accum + ', ') +
                                            curr.name,
                                          ''
                                        )}
                                      </Td>
                                      <Td className='table-cells'>
                                        <NumberFormat
                                          value={item.service_catalog.reduce(
                                            (accum, curr) =>
                                              accum + curr.retail_cost,
                                            0
                                          )}
                                          displayType={'text'}
                                          fixedDecimalScale={true}
                                          decimalScale={2}
                                          thousandSeparator={true}
                                          prefix={'$'}
                                        />
                                      </Td>
                                      <Td className='table-cells'>
                                        <Row
                                          onClick={() => {
                                            tog_details(item)
                                          }}
                                        >
                                          <Col
                                            xl={12}
                                            style={{
                                              padding: 0,
                                              display: 'inline-flex',
                                            }}
                                          >
                                            <Media
                                              src={details_icon}
                                              style={{
                                                paddingLeft: '0.125rem',
                                                paddingRight: '0.125rem',
                                                marginRight: '0.625rem',
                                              }}
                                            ></Media>
                                            <Label
                                              style={{ marginBottom: '0rem' }}
                                            >
                                              {props.t('View Details')}
                                            </Label>
                                          </Col>
                                        </Row>
                                      </Td>
                                    </Tr>
                                  </>
                                )
                              })}
                            </Tbody>
                          </Table>
                        </div>
                      </div>
                      {props.financeData.length === 0 && !props.loading ? (
                        <React.Fragment>
                          <div
                            style={{ display: 'flex', flexDirection: 'column' }}
                          >
                            <Media
                              src={no_results}
                              style={{ alignSelf: 'center' }}
                            ></Media>
                            <Label
                              style={{
                                fontFamily: 'Poppins',
                                fontWeight: '500',
                                textTransform: 'uppercase',
                                fontSize: '1.875rem',
                                letterSpacing: '2.36px',
                                color: '#333333',
                                marginTop: '3.438rem',
                                alignSelf: 'center',
                              }}
                            >
                              {props.t('Sorry boss, not seeing any orders.')}
                            </Label>
                          </div>
                        </React.Fragment>
                      ) : (
                        <></>
                      )}
                    </CardBody>
                  </Card>
                </LoadingOverlay>
              </Col>
            </Row>
          </Container>
        </div>

        <TimeFilter
          title={props.t('Filter By Date')}
          submitBtnTitle={props.t('Apply')}
          submitBtnDisabled={false}
          isOpen={modal_center}
          showModal={setmodal_center}
          onSelectDate={(from, to) => {
            onGetFinanceData(props.org, props.tenant, from, to)
          }}
          setRange={setFilterDate}
        ></TimeFilter>

        {/* <CreateReport
        isOpen={modal_center}
        tog_center={tog_center}
        setmodal_center={setmodal_center}
      /> */}

        <FinanceOrderDetails
          title={props.t('Service Order Details')}
          submitBtnTitle={props.t('Save As PDF')}
          submitBtnDisabled={false}
          secondBtnTitle={props.t('More Info')}
          orderKey={orderSelected}
          isOpen={modal_details}
          showModal={setmodal_details}
        />

        <FinanceInvoice
          title={props.t('')}
          submitBtnTitle={props.t('Save As PDF')}
          submitBtnDisabled={false}
          orders={selectedOrders}
          serviceQTY={selectedServiceQTY}
          isOpen={modal_invoice}
          showModal={setmodal_invoice}
        />
      </FormProvider>
    </React.Fragment>
  )
}

Finance.propTypes = {
  loading: PropTypes.any,
  t: PropTypes.any,
  setPageTitle: PropTypes.func,
  loggedInUsername: PropTypes.string,
  loggedInUserRole: PropTypes.string,
  loggedInUserType: PropTypes.string,
  loggedInUserFirstName: PropTypes.string,
  summary: PropTypes.any,
  data: PropTypes.any,
  onGetFinanceData: PropTypes.func,
  onSortFinanceDataByCompletedOn: PropTypes.func,
  onSortFinanceDataByPrice: PropTypes.func,
  onFilterFinanceDataByLast6Vin: PropTypes.func,
  onGetAllOrders: PropTypes.func,
  completedOrders: PropTypes.any,
  org: PropTypes.string,
  tenant: PropTypes.string,
  userFeat: PropTypes.any,
  financeData: PropTypes.any,
}

const mapStateToProps = ({ login, finance, order }) => ({
  financeData: finance.financeData,
  loading: finance.isLoading,
  loadingDetails: order.isLoading,
  loggedInUsername: login.username,
  loggedInUserRole: login.role,
  loggedInUserType: login.type,
  loggedInUserFirstName: login.firstName,
  tenant: login.tenant,
  org: login.org,
  userFeat: login.features,
  completedOrders: order.completedOrders,
})

const mapDispatchToProps = (dispatch) => ({
  onGetFinanceData: (org, tenant, from, to) =>
    dispatch(getFinanceData(org, tenant, from, to)),
  onSortFinanceDataByCompletedOn: (byAsending) =>
    dispatch(sortFinanceDataByCompletedOn(byAsending)),
  onSortFinanceDataByPrice: (byAsending) =>
    dispatch(sortFinanceDataByPrice(byAsending)),
  onFilterFinanceDataByLast6Vin: (vin) =>
    dispatch(filterFinanceDataByLast6Vin(vin)),
  onGetAllOrders: (org, tenant) => dispatch(getAllOrders(org, tenant)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Finance))
