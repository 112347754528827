import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import MetaTags from 'react-meta-tags'
import { Container, Row, Col, Card, CardBody, Button, Label } from 'reactstrap'

import Avatar from 'react-avatar'

import { Link, withRouter } from 'react-router-dom'

// Styling
import '../../pages/MyProfile/myprofile.scss'

//i18n
import { withTranslation } from 'react-i18next'

//import parsePhoneNumber from 'libphonenumber-js'

import { connect } from 'react-redux'
import { getProfileData } from 'store/actions'
import LoadingOverlay from 'react-loading-overlay'
import EditProfile from './EditProfile'
import { sendPageViewEvent } from '../../firebase'
import { isMobile } from 'react-device-detect'

//import parsePhoneNumber from 'libphonenumber-js'

const MyProfile = (props) => {
  const { onGetProfileData, profileUpdated } = props

  var title = props.t('My Profile')

  useEffect(() => {
    sendPageViewEvent('My Profiler')
  }, [])

  useEffect(() => {
    onGetProfileData(props.org, props.tenant, props.username)
  }, [onGetProfileData, profileUpdated])

  const [modal_center, setmodal_center] = useState(false)

  function tog_center() {
    setmodal_center(!modal_center)
    removeBodyCss()
  }

  function removeBodyCss() {
    document.body.classList.add('no_padding')
  }

  return (
    <React.Fragment>
      <div className='breadsrumb-content'>
        <div className='breadcrumb-header' style={{ minHeight: '7.188rem' }}>
          <Row>
            <Col xl={2}>
              <div
                className='page-title-box'
                style={!isMobile ? { paddingTop: '2.563rem' } : {}}
              >
                <div
                  style={{
                    fontSize: '1.5rem',
                    fontWeight: '500',
                    letterSpacing: '2.7px',
                    fontFamily: 'Poppins',
                    color: '#333333',
                  }}
                >
                  {props.t('My Account')}
                </div>
              </div>
            </Col>
            <Col
              xl={10}
              style={
                !isMobile
                  ? { paddingTop: '3.063rem' }
                  : { paddingTop: '1.063rem' }
              }
            >
              <Link to='myprofile' className='account-link-sel'>
                {props.t('My Profile')}
              </Link>
              <Link to='resetpassword' className='account-link'>
                {props.t('Change Password')}
              </Link>
              {(props.userFeat.includes('ftl-admin') ||
                props.userFeat.includes('ftl-executive')) && (
                <Link to='users' className='account-link'>
                  {props.t('Users')}
                </Link>
              )}
              {(props.userFeat.includes('ftl-admin') ||
                props.userFeat.includes('ftl-executive')) && (
                <Link to='dealershipinfo' className='account-link'>
                  {props.t('Dealership Info')}
                </Link>
              )}
              {(props.userFeat.includes('ftl-admin') ||
                props.userFeat.includes('ftl-executive')) && (
                <Link to='serviceinfo' className='account-link'>
                  {props.t('Service Info')}
                </Link>
              )}
            </Col>
          </Row>
        </div>
      </div>
      <div className='page-content page-background'>
        <MetaTags>
          <title>Home | {title}</title>
        </MetaTags>
        <Container fluid style={!isMobile ? { width: '52%' } : {}}>
          <Row
            style={
              !isMobile
                ? { paddingTop: '3.313rem' }
                : { paddingTop: '1.313rem' }
            }
          >
            <Col lg='12'>
              <Card>
                <CardBody style={{ padding: '0rem' }}>
                  <Row>
                    <Col lg='4'>
                      <Row>
                        <Col
                          lg='12'
                          style={
                            !isMobile
                              ? {
                                  display: 'flex',
                                  justifyContent: 'center',
                                  marginTop: '4.375rem',
                                }
                              : {
                                  display: 'flex',
                                  justifyContent: 'center',
                                  marginTop: '1.375rem',
                                }
                          }
                        >
                          <Avatar
                            className='avatar'
                            color='#D0D0D0'
                            fgColor='#000000'
                            size='10.938rem'
                            round={true}
                            name={props.firstname + ' ' + props.lastname}
                          ></Avatar>
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          lg='12'
                          style={{ display: 'flex', justifyContent: 'center' }}
                        >
                          <Label className='department-label'>
                            {props.department}
                          </Label>
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          lg='12'
                          style={{ display: 'flex', justifyContent: 'center' }}
                        >
                          <Label className='acccounttype-label'>
                            {props.role}
                          </Label>
                        </Col>
                      </Row>
                    </Col>
                    <Col
                      lg='8'
                      style={!isMobile ? { marginTop: '4.375rem' } : {}}
                    >
                      <Row>
                        <Col lg='12'>
                          <div>
                            <Label className='profile-label'>
                              {props.t('First Name')}
                            </Label>
                          </div>
                          <div>
                            <Label className='profile-input-label'>
                              {props.firstname}
                            </Label>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg='12'>
                          <div>
                            <Label className='profile-label'>
                              {props.t('Last Name')}
                            </Label>
                          </div>
                          <div>
                            <Label className='profile-input-label'>
                              {props.lastname}
                            </Label>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg='12'>
                          <div>
                            <Label className='profile-label'>
                              {props.t('Email Address')}
                            </Label>
                          </div>
                          <div>
                            <Label className='profile-input-label'>
                              {props.email}
                            </Label>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg='12'>
                          <div>
                            <Label className='profile-label'>
                              {props.t('Cell Phone')}
                            </Label>
                          </div>
                          <div>
                            <Label className='profile-input-label'>
                              {/* {parsePhoneNumber(props.phone).formatNational()} */}
                              {props.phone}
                            </Label>
                          </div>
                        </Col>
                      </Row>
                      <Row
                        style={{
                          marginTop: '2.5rem',
                          marginBottom: '3.125rem',
                        }}
                      >
                        <Col lg='12'>
                          <Button
                            color='dark'
                            className='prim-btn-big'
                            style={
                              !isMobile ? { float: 'left' } : { float: 'right' }
                            }
                            onClick={() => {
                              tog_center()
                            }}
                          >
                            {props.t('Edit')}
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <LoadingOverlay active={props.loading} spinner>
        <EditProfile
          title={props.t('Edit Profile')}
          submitBtnTitle={props.t('Save')}
          isOpen={modal_center}
          tog_center={tog_center}
          setmodal_center={setmodal_center}
          showModal={setmodal_center}
        />
      </LoadingOverlay>
    </React.Fragment>
  )
}

MyProfile.propTypes = {
  t: PropTypes.any,
  firstname: PropTypes.string,
  lastname: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  department: PropTypes.string,
  role: PropTypes.string,
  org: PropTypes.string,
  tenant: PropTypes.string,
  username: PropTypes.string,
  userFeat: PropTypes.any,
  onGetProfileData: PropTypes.func,
  loading: PropTypes.any,
  profileUpdated: PropTypes.bool,
}

const mapStateToProps = ({ profile, login }) => ({
  loading: profile.isLoading,
  firstname: profile.firstname,
  lastname: profile.lastname,
  email: profile.email,
  phone: profile.phone,
  department: profile.department,
  role: profile.role,
  tenant: login.tenant,
  username: login.username,
  org: login.org,
  userFeat: login.features,
  profileUpdated: profile.profileUpdated,
})

const mapDispatchToProps = (dispatch) => ({
  onGetProfileData: (org, tenant, username) =>
    dispatch(getProfileData(org, tenant, username)),
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(MyProfile))
)
