import {
  GET_ALL_ORDERS,
  GET_ALL_ORDERS_SUCCESS,
  GET_ALL_ORDERS_FAIL,
  GET_ORDER_BY_KEY,
  GET_ORDER_BY_KEY_SUCCESS,
  GET_ORDER_BY_KEY_FAIL,
  APPROVE_ORDER,
  APPROVE_ORDER_SUCCESS,
  APPROVE_ORDER_FAIL,
  REJECT_ORDER,
  REJECT_ORDER_SUCCESS,
  REJECT_ORDER_FAIL,
  ASSIGN_WORK,
  ASSIGN_WORK_SUCCESS,
  ASSIGN_WORK_FAIL,
  START_WORK,
  START_WORK_SUCCESS,
  START_WORK_FAIL,
  END_WORK,
  END_WORK_SUCCESS,
  END_WORK_FAIL,
  REVIEW_WORK,
  REVIEW_WORK_SUCCESS,
  REVIEW_WORK_FAIL,
  UPLOAD_IMAGE,
  UPLOAD_IMAGE_SUCCESS,
  UPLOAD_IMAGE_FAIL,
  DELETE_IMAGE,
  DELETE_IMAGE_SUCCESS,
  DELETE_IMAGE_FAIL,
  ADD_COMMENT_TO_ORDER,
  ADD_COMMENT_TO_ORDER_SUCCESS,
  ADD_COMMENT_TO_ORDER_FAIL,
  ORDER_FILTER_SEARCH_BY_ID_NUM,
  DELAY_LOADING,
} from './actionTypes'

export const delayLoading = () => {
  console.log('actions::delayLoading()')
  return {
    type: DELAY_LOADING,
    payload: {},
  }
}

export const addCommentToOrder = (org, tenant, orderKey, message) => {
  console.log('actions::addCommentToOrder()')
  return {
    type: ADD_COMMENT_TO_ORDER,
    payload: { org, tenant, orderKey, message },
  }
}

export const addCommentToOrderSuccess = (uuid) => {
  console.log('actions::addCommentToOrderSuccess()')
  return {
    type: ADD_COMMENT_TO_ORDER_SUCCESS,
    payload: { uuid },
  }
}

export const addCommentToOrderFail = (error) => {
  console.log('actions::addCommentToOrderFail()')
  return {
    type: ADD_COMMENT_TO_ORDER_FAIL,
    payload: { error },
  }
}

export const uploadImage = (
  org,
  tenant,
  orderKey,
  vin,
  original,
  thumbnail
) => {
  console.log('actions::uploadImage()')
  return {
    type: UPLOAD_IMAGE,
    payload: { org, tenant, orderKey, vin, original, thumbnail },
  }
}

export const uploadImageSuccess = (uuid) => {
  console.log('actions::uploadImageSuccess()')
  return {
    type: UPLOAD_IMAGE_SUCCESS,
    payload: { uuid },
  }
}

export const uploadImageFail = (error) => {
  console.log('actions::uploadImageFail()')
  return {
    type: UPLOAD_IMAGE_FAIL,
    payload: { error },
  }
}

export const deleteImage = (org, tenant, orderKey, vin, imageKey) => {
  console.log('actions::deleteImage()')
  return {
    type: DELETE_IMAGE,
    payload: { org, tenant, orderKey, vin, imageKey },
  }
}

export const deleteImageSuccess = (uuid) => {
  console.log('actions::deleteImageSuccess()')
  return {
    type: DELETE_IMAGE_SUCCESS,
    payload: { uuid },
  }
}

export const deleteImageFail = (error) => {
  console.log('actions::deleteImageFail()')
  return {
    type: DELETE_IMAGE_FAIL,
    payload: { error },
  }
}

export const reviewWorkOrder = (org, tenant, orderKey) => {
  console.log('actions::reviewWorkOrder() orderKey = ' + orderKey)
  return {
    type: REVIEW_WORK,
    payload: { org, tenant, orderKey },
  }
}

export const reviewWorkOrderSuccess = (uuid) => {
  console.log('actions::reviewWorkOrderSuccess()')
  return {
    type: REVIEW_WORK_SUCCESS,
    payload: { uuid },
  }
}

export const reviewWorkOrderFail = (error) => {
  console.log('actions::reviewWorkOrderFail()')
  return {
    type: REVIEW_WORK_FAIL,
    payload: { error },
  }
}

export const endWorkOrder = (org, tenant, orderKey) => {
  console.log('actions::endWorkOrder() orderKey = ' + orderKey)
  return {
    type: END_WORK,
    payload: { org, tenant, orderKey },
  }
}

export const endWorkOrderSuccess = (uuid) => {
  console.log('actions::endWorkOrderSuccess()')
  return {
    type: END_WORK_SUCCESS,
    payload: { uuid },
  }
}

export const endWorkOrderFail = (error) => {
  console.log('actions::endWorkOrderFail()')
  return {
    type: END_WORK_FAIL,
    payload: { error },
  }
}

export const startWorkOrder = (org, tenant, orderKey) => {
  console.log('actions::startWorkOrder() orderKey = ' + orderKey)
  return {
    type: START_WORK,
    payload: { org, tenant, orderKey },
  }
}

export const startWorkOrderSuccess = (uuid) => {
  console.log('actions::startWorkOrderSuccess()')
  return {
    type: START_WORK_SUCCESS,
    payload: { uuid },
  }
}

export const startWorkOrderFail = (error) => {
  console.log('actions::startWorkOrderFail()')
  return {
    type: START_WORK_FAIL,
    payload: { error },
  }
}

export const assignWork = (org, tenant, orderKey, targetEmployeeKey) => {
  console.log(
    'actions::assignWork() orderKey = ' +
      orderKey +
      ', targetEmployeeKey = ' +
      targetEmployeeKey
  )
  return {
    type: ASSIGN_WORK,
    payload: { org, tenant, orderKey, targetEmployeeKey },
  }
}

export const assignWorkSuccess = (uuid) => {
  console.log('actions::assignWorkSuccess()')
  return {
    type: ASSIGN_WORK_SUCCESS,
    payload: { uuid },
  }
}

export const assignWorkFail = (error) => {
  console.log('actions::assignWorkFail()')
  return {
    type: ASSIGN_WORK_FAIL,
    payload: { error },
  }
}

export const approveOrder = (org, tenant, orderKey) => {
  console.log('actions::approveOrder() orderKey = ' + orderKey)
  return {
    type: APPROVE_ORDER,
    payload: { org, tenant, orderKey },
  }
}

export const approveOrderSuccess = (uuid) => {
  console.log('actions::approveOrderSuccess()')
  return {
    type: APPROVE_ORDER_SUCCESS,
    payload: { uuid },
  }
}

export const approveOrderFail = (error) => {
  console.log('actions::approveOrderFail()')
  return {
    type: APPROVE_ORDER_FAIL,
    payload: { error },
  }
}

export const rejectNewOrder = (org, tenant, orderKey) => {
  console.log('actions::rejectNewOrder() orderKey = ' + orderKey)
  return {
    type: REJECT_ORDER,
    payload: { org, tenant, orderKey },
  }
}

export const rejectNewOrderSuccess = (uuid) => {
  console.log('actions::rejectNewOrderSuccess()')
  return {
    type: REJECT_ORDER_SUCCESS,
    payload: { uuid },
  }
}

export const rejectNewOrderFail = (error) => {
  console.log('actions::rejectNewOrderFail()')
  return {
    type: REJECT_ORDER_FAIL,
    payload: { error },
  }
}

export const getOrderByKey = (org, tenant, orderKey) => {
  console.log('actions::getOrderByKey()')
  return {
    type: GET_ORDER_BY_KEY,
    payload: { org, tenant, orderKey },
  }
}

export const getOrderByKeySuccess = (order) => {
  console.log('actions::getOrderByKeySuccess()')
  return {
    type: GET_ORDER_BY_KEY_SUCCESS,
    payload: { order },
  }
}

export const getOrderByKeyFail = (error) => {
  console.log('actions::getOrderByKeyFail()')
  return {
    type: GET_ORDER_BY_KEY_FAIL,
    payload: { error },
  }
}

export const getAllOrders = (org, tenant) => {
  console.log('actions::getAllOrders()')
  return {
    type: GET_ALL_ORDERS,
    payload: { org, tenant },
  }
}

export const getAllOrdersSuccess = (orders) => {
  console.log('actions::getAllOrdersSuccess() orders=' + orders)
  return {
    type: GET_ALL_ORDERS_SUCCESS,
    payload: orders,
  }
}

export const getAllOrdersFail = (error) => {
  console.log('actions::getAllOrdersSuccess() error=' + error)
  return {
    type: GET_ALL_ORDERS_FAIL,
    payload: error,
  }
}

export const filterOrderDataByIdNum = (idNumber) => {
  console.log('actions::filterOrderDataByIdNum()')
  return {
    type: ORDER_FILTER_SEARCH_BY_ID_NUM,
    payload: { idNumber },
  }
}
