import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import { Collapse } from 'reactstrap'
import { Link, withRouter } from 'react-router-dom'
import { toggleLeftmenu } from '../../store/actions'
import classname from 'classnames'
import { isMobile } from 'react-device-detect'
import { withTranslation } from 'react-i18next'

import { connect } from 'react-redux'
import LanguageDropdown from '../CommonForBoth/TopbarDropdown/LanguageDropdown'

const Navbar = (props) => {
  const [myaccount, setmyaccount] = useState(false)

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen()
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen()
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        )
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      }
    }
  }
  useEffect(() => {
    var matchingMenuItem = null
    var ul = document.getElementById('navigation')
    var items = ul.getElementsByTagName('a')
    for (var i = 0; i < items.length; ++i) {
      if (props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem)
    }
  })
  function activateParentDropdown(item) {
    item.classList.add('active')
    const parent = item.parentElement
    if (parent) {
      parent.classList.add('active') // li
      const parent2 = parent.parentElement
      parent2.classList.add('active') // li
      const parent3 = parent2.parentElement
      if (parent3) {
        parent3.classList.add('active') // li
        const parent4 = parent3.parentElement
        if (parent4) {
          parent4.classList.add('active') // li
          const parent5 = parent4.parentElement
          if (parent5) {
            parent5.classList.add('active') // li
            const parent6 = parent5.parentElement
            if (parent6) {
              parent6.classList.add('active') // li
            }
          }
        }
      }
    }
    return false
  }

  return (
    <React.Fragment>
      <div
        className='topnav'
        style={
          isMobile && props.leftMenu
            ? {
                marginTop: '4rem',
                paddingTop: '2rem',
                minHeight: '-webkit-fill-available',
              }
            : { marginTop: '0rem' }
        }
      >
        <div
          className='container-fluid'
          style={
            isMobile
              ? {
                  maxWidth: '90%',
                  display: 'flex',
                  justifyContent: 'flex-start',
                }
              : {
                  maxWidth: '90%',
                  display: 'flex',
                  justifyContent: 'flex-end',
                }
          }
        >
          <nav
            className='navbar navbar-light navbar-expand-lg topnav-menu'
            id='navigation'
          >
            <Collapse
              isOpen={props.leftMenu}
              className='navbar-collapse'
              id='topnav-menu-content'
            >
              <ul className='navbar-nav'>
                {(props.userFeat.includes('ftl-accounting') ||
                  props.userFeat.includes('ftl-executive')) && (
                  <li className='nav-item dropdown'>
                    <Link
                      className='nav-link dropdown-toggle arrow-none'
                      to='dashboard'
                      onClick={() => props.toggleLeftmenu(false)}
                      style={
                        isMobile
                          ? {
                              fontFamily: 'Poppins',
                              fontSize: '1.3rem',
                              letterSpacing: '1.8px',
                              paddingTop: '0rem',
                              paddingBottom: '0.8rem',
                            }
                          : {
                              fontFamily: 'Poppins',
                              fontSize: '0.875rem',
                              letterSpacing: '1.8px',
                              paddingTop: '0rem',
                              paddingBottom: '0rem',
                            }
                      }
                    >
                      {props.t('Home')} {props.menuOpen}
                    </Link>
                  </li>
                )}

                {(props.userFeat.includes('ftl-detailshop') ||
                  props.userFeat.includes('ftl-dealership')) && (
                  <li className='nav-item dropdown'>
                    <Link
                      className='nav-link dropdown-toggle arrow-none'
                      to='whiteboard'
                      onClick={() => props.toggleLeftmenu(false)}
                      style={
                        isMobile
                          ? {
                              fontFamily: 'Poppins',
                              fontSize: '1.3rem',
                              letterSpacing: '1.8px',
                              paddingTop: '0rem',
                              paddingBottom: '0.8rem',
                            }
                          : {
                              fontFamily: 'Poppins',
                              fontSize: '0.875rem',
                              letterSpacing: '1.8px',
                              paddingTop: '0rem',
                              paddingBottom: '0rem',
                            }
                      }
                    >
                      {props.t('Whiteboard')} {props.menuOpen}
                    </Link>
                  </li>
                )}

                {(props.userFeat.includes('ftl-accounting') ||
                  props.userFeat.includes('ftl-executive')) && (
                  <li className='nav-item dropdown'>
                    <Link
                      className='nav-link dropdown-toggle arrow-none'
                      to='finance'
                      onClick={() => props.toggleLeftmenu(false)}
                      style={
                        isMobile
                          ? {
                              fontFamily: 'Poppins',
                              fontSize: '1.3rem',
                              letterSpacing: '1.8px',
                              paddingTop: '0rem',
                              paddingBottom: '0.8rem',
                            }
                          : {
                              fontFamily: 'Poppins',
                              fontSize: '0.875rem',
                              letterSpacing: '1.8px',
                              paddingTop: '0rem',
                              paddingBottom: '0rem',
                            }
                      }
                    >
                      {props.t('Finance')} {props.menuOpen}
                    </Link>
                  </li>
                )}

                <li className='nav-item dropdown'>
                  <Link
                    className='nav-link dropdown-toggle arrow-none'
                    to='addnewservice'
                    onClick={(e) => {
                      e.preventDefault()
                      setmyaccount(!myaccount)
                    }}
                    style={
                      isMobile
                        ? {
                            fontFamily: 'Poppins',
                            fontSize: '1.3rem',
                            letterSpacing: '1.8px',
                            paddingTop: '0rem',
                            paddingBottom: '0.8rem',
                          }
                        : {
                            fontFamily: 'Poppins',
                            fontSize: '0.875rem',
                            letterSpacing: '1.8px',
                            paddingTop: '0rem',
                            paddingBottom: '0rem',
                          }
                    }
                  >
                    {props.t('My Account')} {props.menuOpen}
                    {!isMobile && <div className='arrow-down'></div>}
                  </Link>
                  <div
                    className={classname('dropdown-menu', {
                      show: isMobile ? true : myaccount,
                    })}
                  >
                    <Link
                      to='myprofile'
                      className='dropdown-item'
                      onClick={() => props.toggleLeftmenu(false)}
                      style={
                        isMobile
                          ? {
                              fontFamily: 'Poppins',
                              fontSize: '1.2rem',
                              letterSpacing: '1.24px',
                              paddingBottom: '0.6rem',
                            }
                          : {
                              fontFamily: 'Poppins',
                              fontSize: '0.6875rem',
                              letterSpacing: '1.24px',
                            }
                      }
                    >
                      {props.t('My Profile')}
                    </Link>
                    <Link
                      to='resetpassword'
                      className='dropdown-item'
                      onClick={() => props.toggleLeftmenu(false)}
                      style={
                        isMobile
                          ? {
                              fontFamily: 'Poppins',
                              fontSize: '1.2rem',
                              letterSpacing: '1.24px',
                              paddingBottom: '0.6rem',
                            }
                          : {
                              fontFamily: 'Poppins',
                              fontSize: '0.6875rem',
                              letterSpacing: '1.24px',
                            }
                      }
                    >
                      {props.t('Reset Password')}
                    </Link>
                    {(props.userFeat.includes('ftl-admin') ||
                      props.userFeat.includes('ftl-executive')) && (
                      <Link
                        to='users'
                        className='dropdown-item'
                        onClick={() => props.toggleLeftmenu(false)}
                        style={
                          isMobile
                            ? {
                                fontFamily: 'Poppins',
                                fontSize: '1.2rem',
                                letterSpacing: '1.24px',
                                paddingBottom: '0.6rem',
                              }
                            : {
                                fontFamily: 'Poppins',
                                fontSize: '0.6875rem',
                                letterSpacing: '1.24px',
                              }
                        }
                      >
                        {props.t('Users')}
                      </Link>
                    )}

                    {(props.userFeat.includes('ftl-admin') ||
                      props.userFeat.includes('ftl-executive')) &&
                      props.userFeat.includes('ftl-dealership') && (
                        <Link
                          to='dealershipinfo'
                          className='dropdown-item'
                          onClick={() => props.toggleLeftmenu(false)}
                          style={
                            isMobile
                              ? {
                                  fontFamily: 'Poppins',
                                  fontSize: '1.2rem',
                                  letterSpacing: '1.24px',
                                  paddingBottom: '0.6rem',
                                }
                              : {
                                  fontFamily: 'Poppins',
                                  fontSize: '0.6875rem',
                                  letterSpacing: '1.24px',
                                }
                          }
                        >
                          {props.t('Dealership Info')}
                        </Link>
                      )}

                    {(props.userFeat.includes('ftl-admin') ||
                      props.userFeat.includes('ftl-executive')) &&
                      props.userFeat.includes('ftl-detailshop') && (
                        <Link
                          to='dealershipinfo'
                          className='dropdown-item'
                          onClick={() => props.toggleLeftmenu(false)}
                          style={
                            isMobile
                              ? {
                                  fontFamily: 'Poppins',
                                  fontSize: '1.2rem',
                                  letterSpacing: '1.24px',
                                  paddingBottom: '0.6rem',
                                }
                              : {
                                  fontFamily: 'Poppins',
                                  fontSize: '0.6875rem',
                                  letterSpacing: '1.24px',
                                }
                          }
                        >
                          {props.t('Detail Shop Info')}
                        </Link>
                      )}

                    {props.userFeat.includes('ftl-dealership') &&
                      (props.userFeat.includes('ftl-admin') ||
                        props.userFeat.includes('ftl-executive')) && (
                        <Link
                          to='serviceInfo'
                          className='dropdown-item'
                          onClick={() => props.toggleLeftmenu(false)}
                          style={
                            isMobile
                              ? {
                                  fontFamily: 'Poppins',
                                  fontSize: '1.2rem',
                                  letterSpacing: '1.24px',
                                  paddingBottom: '0.6rem',
                                }
                              : {
                                  fontFamily: 'Poppins',
                                  fontSize: '0.6875rem',
                                  letterSpacing: '1.24px',
                                }
                          }
                        >
                          {props.t('Service Info')}
                        </Link>
                      )}

                    <Link
                      to='logout'
                      className='dropdown-item'
                      onClick={() => props.toggleLeftmenu(false)}
                      style={
                        isMobile
                          ? {
                              fontFamily: 'Poppins',
                              fontSize: '1.2rem',
                              letterSpacing: '1.24px',
                              paddingBottom: '0.6rem',
                            }
                          : {
                              fontFamily: 'Poppins',
                              fontSize: '0.6875rem',
                              letterSpacing: '1.24px',
                            }
                      }
                    >
                      {props.t('Logout')}
                    </Link>
                  </div>
                </li>
              </ul>
            </Collapse>
            <div className='d-flex align-items-center'>
              {!isMobile && <LanguageDropdown />}
              <div className='dropdown d-none d-lg-inline-block ms-1'>
                <button
                  type='button'
                  className='btn header-item noti-icon waves-effect'
                  style={{ paddingTop: '1rem' }}
                  onClick={() => {
                    toggleFullscreen()
                  }}
                  data-toggle='fullscreen'
                >
                  <i className='bx bx-fullscreen' />
                </button>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </React.Fragment>
  )
}

Navbar.propTypes = {
  leftMenu: PropTypes.any,
  location: PropTypes.any,
  menuOpen: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
  t: PropTypes.any,
  org: PropTypes.string,
  tenant: PropTypes.string,
  userFeat: PropTypes.any,
}

const mapStatetoProps = ({ Layout, login }) => ({
  leftMenu: Layout.leftMenu,
  org: login.org,
  userFeat: login.features,
  tenant: login.tenant,
})

export default connect(mapStatetoProps, { toggleLeftmenu })(
  withRouter(withTranslation()(Navbar))
)
