/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import { Row, Col } from 'reactstrap'
import GlistenServiceOrderCard from '../GlistenServiceOrderCard'
import ScrollMenu from 'react-horizontal-scrolling-menu'

import './style.scss'

const GlistenHorizontalKanban = (props) => {
  const [selected, setSelected] = useState('item-0')

  const MenuItem = ({ item, selected }) => {
    return (
      <div
        style={{ width: '500px', paddingRight: '0px' }}
        className={`menu-item ${selected ? 'active' : ''}`}
      >
        <GlistenServiceOrderCard
          key={item.orderNumber}
          order={item}
          loggedInUserRole={props.loggedInUserRole}
          loggedInUserType={props.loggedInUserType}
        />
      </div>
    )
  }

  MenuItem.propTypes = {
    item: PropTypes.any,
    selected: PropTypes.any,
  }

  const Menu = (list, selected) =>
    list.map((el, idx) => {
      return (
        <MenuItem
          style={{ width: '100%' }}
          item={el}
          key={`item-${idx}`}
          selected={selected}
        />
      )
    })

  const Arrow = ({ text, className }) => {
    return <div className={className}>{text}</div>
  }

  Arrow.propTypes = {
    text: PropTypes.string,
    className: PropTypes.string,
  }

  const ArrowLeft = Arrow({ text: '<', className: 'arrow-prev' })

  const ArrowRight = Arrow({ text: '>', className: 'arrow-next' })

  const menuItems = Menu(props.completedOrders, selected)

  return (
    <>
      <Row>
        <Col xl='12'>
          <ScrollMenu
            data={menuItems}
            arrowLeft={ArrowLeft}
            arrowRight={ArrowRight}
            selected={selected}
            onSelect={setSelected}
          />
        </Col>
      </Row>
    </>
  )
}

GlistenHorizontalKanban.propTypes = {
  t: PropTypes.any,
  completedOrders: PropTypes.any,
  loggedInUserRole: PropTypes.string,
  loggedInUserType: PropTypes.string,
}

export default withTranslation()(GlistenHorizontalKanban)
