import { takeEvery, put, call } from 'redux-saga/effects'

import {
  GET_TENANT_DATA,
  UPDATE_TENANT_DATA,
  ADD_TENANT_DATA,
  ADD_SERVICE_CATALOG_DATA,
  UPDATE_SERVICE_CATALOG_DATA,
  DELETE_SERVICE_CATALOG_DATA,
} from './actionTypes'
import {
  getTenantDateSuccess,
  getTenantDateFail,
  updateTenantSuccess,
  updateTenantFail,
  addTenantSuccess,
  addTenantFail,
  addServiceCatalogSuccess,
  addServiceCatalogFail,
  updateServiceCatalogSuccess,
  updateServiceCatalogFail,
  deleteServiceCatalogSuccess,
  deleteServiceCatalogFail,
} from './actions'

//Include Both Helper File with needed methods
import {
  getTenantRecord,
  updateTenantRecord,
  addTenantRecord,
  addServiceCatalog,
  updateServiceCatalog,
  deleteServiceCatalog,
} from '../../../helpers/fakebackend_helper'

function* fetchTenantData({ payload: { org, tenant } }) {
  console.log('saga::fetchTenantData()')

  try {
    const response = yield call(getTenantRecord, org, tenant)
    yield put(getTenantDateSuccess(response))
  } catch (error) {
    yield put(getTenantDateFail(error))
  }
}

function* updateTenantData({ payload: { org, tenant, tenantData } }) {
  console.log('saga::updateTenantData()')

  try {
    const response = yield call(updateTenantRecord, org, tenant, tenantData)
    yield put(updateTenantSuccess(response, tenantData))
  } catch (error) {
    yield put(updateTenantFail(error))
  }
}

function* addTenantData({ payload: { org, tenant, tenantData } }) {
  console.log('saga::updateTenantData()')

  try {
    const response = yield call(addTenantRecord, org, tenant, tenantData)
    yield put(addTenantSuccess(response))
  } catch (error) {
    yield put(addTenantFail(error))
  }
}

function* addServicecatalogData({
  payload: { org, detailTenant, dealershipTenant, serviceCatalogData },
}) {
  console.log('saga::addServicecatalogData()')

  try {
    const response = yield call(
      addServiceCatalog,
      org,
      detailTenant,
      dealershipTenant,
      {
        name: serviceCatalogData.name,
        description: serviceCatalogData.description,
        retailCost: serviceCatalogData.retail_cost,
        internalCost: serviceCatalogData.internal_cost,
        adminCost: serviceCatalogData.admin_cost,
        category: serviceCatalogData.category,
      }
    )
    yield put(addServiceCatalogSuccess(response, serviceCatalogData))
  } catch (error) {
    yield put(addServiceCatalogFail(error))
  }
}

function* updateServicecatalogData({
  payload: {
    org,
    detailTenant,
    dealershipTenant,
    serviceCatalogId,
    serviceCatalogData,
  },
}) {
  console.log('saga::updateServicecatalogData()')

  try {
    const response = yield call(
      updateServiceCatalog,
      org,
      detailTenant,
      dealershipTenant,
      serviceCatalogId,
      {
        name: serviceCatalogData.name,
        description: serviceCatalogData.description,
        retailCost: serviceCatalogData.retail_cost,
        internalCost: serviceCatalogData.internal_cost,
        adminCost: serviceCatalogData.admin_cost,
        category: serviceCatalogData.category,
      }
    )
    yield put(updateServiceCatalogSuccess(response, serviceCatalogData))
  } catch (error) {
    yield put(updateServiceCatalogFail(error))
  }
}

function* deleteServicecatalogData({
  payload: { org, detailTenant, dealershipTenant, serviceCatalogId },
}) {
  console.log('saga::deleteServicecatalogData()')

  try {
    const response = yield call(
      deleteServiceCatalog,
      org,
      detailTenant,
      dealershipTenant,
      serviceCatalogId
    )
    yield put(deleteServiceCatalogSuccess(response))
  } catch (error) {
    yield put(deleteServiceCatalogFail(error))
  }
}

export function* tenantSaga() {
  yield takeEvery(GET_TENANT_DATA, fetchTenantData)
  yield takeEvery(UPDATE_TENANT_DATA, updateTenantData)
  yield takeEvery(ADD_TENANT_DATA, addTenantData)
  yield takeEvery(ADD_SERVICE_CATALOG_DATA, addServicecatalogData)
  yield takeEvery(UPDATE_SERVICE_CATALOG_DATA, updateServicecatalogData)
  yield takeEvery(DELETE_SERVICE_CATALOG_DATA, deleteServicecatalogData)
}

export default tenantSaga
