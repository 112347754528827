import {
  GET_ALL_VEHICLE_MAKE,
  GET_ALL_VEHICLE_MAKE_SUCCESS,
  GET_ALL_VEHICLE_MAKE_FAIL,
  GET_ALL_VEHICLE_MODEL_BY_MAKE_AND_YEAR,
  GET_ALL_VEHICLE_MODEL_BY_MAKE_AND_YEAR_SUCCESS,
  GET_ALL_VEHICLE_MODEL_BY_MAKE_AND_YEAR_FAIL,
  SEARCH_VEHICLE_BY_VIN,
  SEARCH_VEHICLE_BY_VIN_SUCCESS,
  SEARCH_VEHICLE_BY_VIN_FAIL,
  CLEAR_VEHICLE_SEARCH_RESULT,
} from './actionTypes'

export const clearVehicleSearchResult = () => {
  console.log('actions::clearVehicleSearchResult()')

  return {
    type: CLEAR_VEHICLE_SEARCH_RESULT,
  }
}

export const getAllVehicleMake = () => {
  console.log('actions::getAllVehicleMake()')

  return {
    type: GET_ALL_VEHICLE_MAKE,
  }
}

export const getAllVehicleMakeSuccess = (allVehicleMake) => {
  console.log(
    'actions::getAllVehicleMakeSuccess() allVehicleMake=' + allVehicleMake
  )

  return {
    type: GET_ALL_VEHICLE_MAKE_SUCCESS,
    payload: allVehicleMake,
  }
}

export const getAllVehicleMakeFail = (error) => {
  console.log('actions::getAllVehicleMakeFail() error=' + error)

  return {
    type: GET_ALL_VEHICLE_MAKE_FAIL,
    payload: error,
  }
}

export const getAllVehicleModelByMakeAndYear = (make, year) => {
  console.log('actions::getAllVehicleModelByMakeAndYear()')

  return {
    type: GET_ALL_VEHICLE_MODEL_BY_MAKE_AND_YEAR,
    payload: { make, year },
  }
}

export const getAllVehicleModelByMakeAndYearSuccess = (allVehicleModel) => {
  console.log(
    'actions::getAllVehicleModelByMakeAndYearSuccess() allVehicleModel=' +
      allVehicleModel
  )

  return {
    type: GET_ALL_VEHICLE_MODEL_BY_MAKE_AND_YEAR_SUCCESS,
    payload: allVehicleModel,
  }
}

export const getAllVehicleModelByMakeAndYearFail = (error) => {
  console.log('actions::getAllVehicleModelByMakeAndYearFail() error=' + error)

  return {
    type: GET_ALL_VEHICLE_MODEL_BY_MAKE_AND_YEAR_FAIL,
    payload: error,
  }
}

export const getVehicleByVin = (org, tenantId, vin) => {
  console.log(
    'actions::getVehicleByVin() param=' + org + ', ' + tenantId + ', ' + vin
  )

  return {
    type: SEARCH_VEHICLE_BY_VIN,
    payload: { org, tenantId, vin },
  }
}

export const getVehicleByVinSuccess = (vehicleInfo) => {
  console.log('actions::getVehicleByVinSuccess() vehicleInfo=' + vehicleInfo)
  return {
    type: SEARCH_VEHICLE_BY_VIN_SUCCESS,
    payload: vehicleInfo,
  }
}

export const getVehicleByVinFail = (error) => {
  console.log('actions::getVehicleByVinFail() error=' + error)
  return {
    type: SEARCH_VEHICLE_BY_VIN_FAIL,
    payload: error,
  }
}
