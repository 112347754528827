/* eslint-disable react/display-name */
import React from 'react'
import { Redirect } from 'react-router-dom'

// Authentication related pages
import Login from '../pages/Authentication/Login'
import Logout from '../pages/Authentication/Logout'

import Dashboard from '../pages/Dashboard'
import Whiteboard from '../pages/Whiteboard'
import Finance from '../pages/Finance'
import AddNewService from '../pages/AddNewService'
import ResetPassword from '../pages/ResetPassword'
import MyProfile from '../pages/MyProfile'
import Users from '../pages/Users'
import DealershipInfo from '../pages/DealershipInfo'
import ServiceInfo from '../pages/ServiceInfo'
import ServiceOrderDetail from '../pages/ServiceOrderDetail'
import ForgotPassword from 'pages/Authentication/ForgotPassword'

const userRoutes = [
  {
    path: '/dashboard',
    component: Dashboard,
    group: ['ftl-admin', 'ftl-executive'],
  },
  { path: '/whiteboard', component: Whiteboard, group: [] },
  {
    path: '/finance',
    component: Finance,
    group: ['ftl-accounting', 'ftl-admin', 'ftl-executive'],
  },
  { path: '/addnewservice', component: AddNewService, group: [] },
  { path: '/myprofile', component: MyProfile, group: [] },
  { path: '/resetpassword', component: ResetPassword, group: [] },
  { path: '/users', component: Users, group: ['ftl-admin', 'ftl-executive'] },
  {
    path: '/dealershipinfo',
    component: DealershipInfo,
    group: ['ftl-admin', 'ftl-executive'],
  },
  {
    path: '/serviceInfo',
    component: ServiceInfo,
    group: ['ftl-admin', 'ftl-executive'],
  },
  { path: '/serviceOrderDetail', component: ServiceOrderDetail, group: [] },

  // this route should be at the end of all other routes
  {
    path: '/',
    exact: true,
    component: () => <Redirect to='/login' />,
    group: [],
  },
]

const authRoutes = [
  { path: '/login', component: Login, group: [] },
  { path: '/forgotpassword', component: ForgotPassword, group: [] },
  { path: '/logout', component: Logout, group: [] },
]

export { userRoutes, authRoutes }
