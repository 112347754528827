/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { Form, Button, Input } from 'reactstrap'
import { withTranslation } from 'react-i18next'
import { sendPageViewEvent } from '../../../firebase'

//styling
import '../FinanceFilter/financefilter.scss'

import { ReactComponent as SearchIcon } from '../../../assets/images/ic-search.svg'
import { ReactComponent as ChevDownIcon } from '../../../assets/images/ic-chev down tiny.svg'

const FinanceFilter = (props) => {
  useEffect(() => {
    sendPageViewEvent('Create Report')
  }, [])

  const handleSubmit = (evt) => {
    evt.preventDefault()
  }

  const handleVINSearch = (value) => {
    props.setVIN(value)
    props.onVinSearch(value)
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
      <label
        style={{
          fontFamily: 'Poppins',
          fontWeight: '500',
          textTransform: 'uppercase',
          fontSize: '0.875rem',
          letterSpacing: '0.099rem',
          color: '#333333',
          marginTop: '2.625rem',
          marginBottom: '2.625rem',
          marginLeft: '1.625rem',
        }}
      >
        {props.t('Filter By')}
      </label>

      <div
        className='align-self-center'
        style={{ display: 'flex', flexDirection: 'row' }}
      >
        <Form
          className='glsn-box'
          style={{ marginLeft: '1.625rem' }}
          onClick={() => {
            props.showModal(true)
          }}
          onSubmit={handleSubmit}
        >
          <Input
            className='search-inpt'
            type='button'
            value={props.dateRange}
          />
          <Button className='finance-inpt-icon'>
            <ChevDownIcon />
          </Button>
        </Form>

        <div
          className='align-self-center'
          style={{
            height: '2.25rem',
            width: '0.063rem',
            background: '#D0D0D0',
            marginLeft: '1.563rem',
            marginRight: '1.563rem',
          }}
        ></div>

        <Form className='glsn-box' onSubmit={handleSubmit}>
          <Input
            className='search-inpt'
            type='text'
            value={props.vin}
            placeholder='Last 6 of VIN'
            maxLength='6'
            onChange={(event) =>
              handleVINSearch(event.target.value.toUpperCase())
            }
          />
          <Button
            className='finance-inpt-icon'
            style={{ paddingRight: '1.375rem' }}
          >
            <SearchIcon />
          </Button>
        </Form>
      </div>
    </div>
  )
}

FinanceFilter.propTypes = {
  t: PropTypes.any,
  onVinSearch: PropTypes.func,
  showModal: PropTypes.func,
  dateRange: PropTypes.any,
  setVIN: PropTypes.func,
  vin: PropTypes.any,
}

export default withTranslation()(FinanceFilter)
