import {
  GET_TENANT_DATA,
  GET_TENANT_DATA_SUCCESS,
  GET_TENANT_DATA_FAIL,
  UPDATE_TENANT_DATA,
  UPDATE_TENANT_DATA_SUCCESS,
  UPDATE_TENANT_DATA_FAIL,
  ADD_TENANT_DATA,
  ADD_TENANT_DATA_SUCCESS,
  ADD_TENANT_DATA_FAIL,
  ADD_SERVICE_CATALOG_DATA,
  ADD_SERVICE_CATALOG_DATA_SUCCESS,
  ADD_SERVICE_CATALOG_DATA_FAIL,
  UPDATE_SERVICE_CATALOG_DATA,
  UPDATE_SERVICE_CATALOG_DATA_SUCCESS,
  UPDATE_SERVICE_CATALOG_DATA_FAIL,
  DELETE_SERVICE_CATALOG_DATA,
  DELETE_SERVICE_CATALOG_DATA_SUCCESS,
  DELETE_SERVICE_CATALOG_DATA_FAIL,
} from './actionTypes'

export const getTenantData = (org, tenant) => {
  console.log('actions::getTenantData()')
  return {
    type: GET_TENANT_DATA,
    payload: { org, tenant },
  }
}

export const updateTenantData = (org, tenant, tenantData) => {
  console.log('actions::updateTenant()')
  return {
    type: UPDATE_TENANT_DATA,
    payload: { org, tenant, tenantData },
  }
}

export const getTenantDateSuccess = (tenantRecord) => {
  console.log('actions::getTenantDateSuccess() profileRecord=' + tenantRecord)
  return {
    type: GET_TENANT_DATA_SUCCESS,
    payload: tenantRecord,
  }
}

export const getTenantDateFail = (error) => {
  console.log('actions::getTenantDateFail() error=' + error)
  return {
    type: GET_TENANT_DATA_FAIL,
    payload: error,
  }
}

export const updateTenantSuccess = (refKey, tenantData) => {
  console.log('actions::updateTenantSuccess()')
  return {
    type: UPDATE_TENANT_DATA_SUCCESS,
    payload: { refKey, tenantData },
  }
}

export const updateTenantFail = (error) => {
  console.log('actions::getTenantDateFail() error=' + error)
  return {
    type: UPDATE_TENANT_DATA_FAIL,
    payload: error,
  }
}

export const addTenantData = (org, tenant, tenantData) => {
  console.log('actions::addTenantData()')
  return {
    type: ADD_TENANT_DATA,
    payload: { org, tenant, tenantData },
  }
}

export const addTenantSuccess = () => {
  console.log('actions::addTenantSuccess()')
  return {
    type: ADD_TENANT_DATA_SUCCESS,
  }
}

export const addTenantFail = (error) => {
  console.log('actions::addTenantFail() error=' + error)
  return {
    type: ADD_TENANT_DATA_FAIL,
    payload: error,
  }
}

export const addServiceCatalogData = (
  org,
  detailTenant,
  dealershipTenant,
  serviceCatalogData
) => {
  console.log('actions::addServiceCatalogData()')
  return {
    type: ADD_SERVICE_CATALOG_DATA,
    payload: { org, detailTenant, dealershipTenant, serviceCatalogData },
  }
}

export const addServiceCatalogSuccess = (refKey, serviceCatalogData) => {
  console.log('actions::addServiceCatalogSuccess()')
  return {
    type: ADD_SERVICE_CATALOG_DATA_SUCCESS,
    payload: { refKey, serviceCatalogData },
  }
}

export const addServiceCatalogFail = (error) => {
  console.log('actions::addServiceCatalogFail() error=' + error)
  return {
    type: ADD_SERVICE_CATALOG_DATA_FAIL,
    payload: error,
  }
}

export const updateServiceCatalogData = (
  org,
  detailTenant,
  dealershipTenant,
  serviceCatalogId,
  serviceCatalogData
) => {
  console.log('actions::updateServiceCatalogData()')
  return {
    type: UPDATE_SERVICE_CATALOG_DATA,
    payload: {
      org,
      detailTenant,
      dealershipTenant,
      serviceCatalogId,
      serviceCatalogData,
    },
  }
}

export const updateServiceCatalogSuccess = (refKey, serviceCatalogData) => {
  console.log('actions::updateServiceCatalogSuccess()')
  return {
    type: UPDATE_SERVICE_CATALOG_DATA_SUCCESS,
    payload: { refKey, serviceCatalogData },
  }
}

export const updateServiceCatalogFail = (error) => {
  console.log('actions::updateServiceCatalogFail() error=' + error)
  return {
    type: UPDATE_SERVICE_CATALOG_DATA_FAIL,
    payload: error,
  }
}

export const deleteServiceCatalogData = (
  org,
  detailTenant,
  dealershipTenant,
  serviceCatalogId
) => {
  console.log('actions::deleteServiceCatalogData()')
  return {
    type: DELETE_SERVICE_CATALOG_DATA,
    payload: { org, detailTenant, dealershipTenant, serviceCatalogId },
  }
}

export const deleteServiceCatalogSuccess = (refKey) => {
  console.log('actions::deleteServiceCatalogSuccess()')
  return {
    type: DELETE_SERVICE_CATALOG_DATA_SUCCESS,
    payload: { refKey },
  }
}

export const deleteServiceCatalogFail = (error) => {
  console.log('actions::deleteServiceCatalogFail() error=' + error)
  return {
    type: DELETE_SERVICE_CATALOG_DATA_FAIL,
    payload: error,
  }
}
