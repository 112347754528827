import { takeEvery, put, call } from 'redux-saga/effects'

import { GET_FINANCE_DATA } from './actionTypes'
import { getFinanceDataSuccess, getFinanceDataFail } from './actions'

//Include Both Helper File with needed methods
import { getFinanceData } from '../../../helpers/fakebackend_helper'

function* fetchAllOrders({ payload: { org, tenant, from, to } }) {
  console.log('saga::fetchAllOrders()')

  try {
    const response = yield call(getFinanceData, org, tenant, from, to)
    yield put(getFinanceDataSuccess(response))
  } catch (error) {
    yield put(getFinanceDataFail(error))
  }
}

export function* financeSaga() {
  yield takeEvery(GET_FINANCE_DATA, fetchAllOrders)
}

export default financeSaga
