import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { logout } from '../../store/actions'

const Logout = (props) => {
  useEffect(() => {
    props.logout()
  })
  props.history.push('/')

  return <></>
}

Logout.propTypes = {
  history: PropTypes.object,
  logout: PropTypes.func,
}

export default withRouter(connect(null, { logout })(Logout))
