/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import axios from 'axios'
import { store } from '../store/index'

//apply base url for axios
const API_URL = process.env.REACT_APP_SERVICE_URL

const axiosApi = axios.create({
  baseURL: API_URL,
})

axiosApi.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
)

export async function get(url, config = {}) {
  axiosApi.defaults.headers.common['Authorization'] =
    'Bearer ' + store.getState().login.jwtToken
  axiosApi.defaults.headers.common['username'] = store.getState().login.username
  axiosApi.defaults.headers.common['tenant'] = store.getState().login.tenant

  return await axiosApi
    .get(url, { ...config })
    .then((response) => response.data)
}

export async function post(url, data, config = {}) {
  axiosApi.defaults.headers.common['Authorization'] =
    'Bearer ' + store.getState().login.jwtToken
  axiosApi.defaults.headers.common['username'] = store.getState().login.username
  axiosApi.defaults.headers.common['tenant'] = store.getState().login.tenant
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then((response) => response.data)
}

export async function put(url, data, config = {}) {
  axiosApi.defaults.headers.common['Authorization'] =
    'Bearer ' + store.getState().login.jwtToken
  axiosApi.defaults.headers.common['username'] = store.getState().login.username
  axiosApi.defaults.headers.common['tenant'] = store.getState().login.tenant
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then((response) => response.data)
}

export async function del(url, config = {}) {
  axiosApi.defaults.headers.common['Authorization'] =
    'Bearer ' + store.getState().login.jwtToken
  axiosApi.defaults.headers.common['username'] = store.getState().login.username
  axiosApi.defaults.headers.common['tenant'] = store.getState().login.tenant
  return await axiosApi
    .delete(url, { ...config })
    .then((response) => response.data)
}
