import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import MetaTags from 'react-meta-tags'
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Label,
  Button,
  Media,
} from 'reactstrap'

//Edit Image
import edit_logo from '../../assets/images/ic-edit.svg'

import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'

// Styling
import '../../pages/DealershipInfo/dealershipinfo.scss'

import { Link, withRouter } from 'react-router-dom'

//i18n
import { withTranslation } from 'react-i18next'

import { connect } from 'react-redux'
import LoadingOverlay from 'react-loading-overlay'
import EditDealership from './EditDealership'
import EditDepartment from './EditDepartment'
import AddDepartment from './AddDepartment'
import { sendPageViewEvent } from '../../firebase'

// Redux
import { getTenantData, updateTenantData } from 'store/actions'
import Compress from 'compress.js'

const DealershipInfo = (props) => {
  const { onGetTenantData, onUpdateTenantData, tenantUpdated } = props

  useEffect(() => {
    onGetTenantData(props.org, props.tenant)
    sendPageViewEvent('Dealership Info')
  }, [onGetTenantData])

  useEffect(() => {
    if (tenantUpdated) onGetTenantData(props.org, props.tenant)
  }, [tenantUpdated])

  const [modal_center, setmodal_center] = useState(false)
  const [modal_add, setmodal_add] = useState(false)
  const [modal_dept, setmodal_dept] = useState(false)

  const [departmentSelected, setDepartmentSelected] = useState(-1)

  function tog_center() {
    setmodal_center(!modal_center)
    removeBodyCss()
  }

  function tog_add() {
    setmodal_add(!modal_add)
    removeBodyCss()
  }

  function tog_dept(item) {
    setmodal_dept(!modal_dept)
    removeBodyCss()
    setDepartmentSelected(item)
  }

  function removeBodyCss() {
    document.body.classList.add('no_padding')
  }

  var title = props.t('Dealership Info')

  return (
    <React.Fragment>
      <div className='breadsrumb-content'>
        <div className='breadcrumb-header' style={{ minHeight: '7.188rem' }}>
          <Row>
            <Col xl={2}>
              <div
                className='page-title-box'
                style={{ paddingTop: '2.563rem' }}
              >
                <div
                  style={{
                    fontSize: '1.5rem',
                    fontWeight: '500',
                    letterSpacing: '2.7px',
                    fontFamily: 'Poppins',
                    color: '#333333',
                  }}
                >
                  {props.t('My Account')}
                </div>
              </div>
            </Col>
            <Col xl={10} style={{ paddingTop: '3.063rem' }}>
              <Link to='myprofile' className='account-link'>
                {props.t('My Profile')}
              </Link>
              <Link to='resetpassword' className='account-link'>
                {props.t('Change Password')}
              </Link>
              {(props.userFeat.includes('ftl-admin') ||
                props.userFeat.includes('ftl-executive')) && (
                <Link to='users' className='account-link'>
                  {props.t('Users')}
                </Link>
              )}
              {(props.userFeat.includes('ftl-admin') ||
                props.userFeat.includes('ftl-executive')) &&
                props.userFeat.includes('ftl-dealership') && (
                  <Link to='dealershipinfo' className='account-link-sel'>
                    {props.t('Dealership Info')}
                  </Link>
                )}
              {(props.userFeat.includes('ftl-admin') ||
                props.userFeat.includes('ftl-executive')) &&
                props.userFeat.includes('ftl-detailshop') && (
                  <Link to='dealershipinfo' className='account-link-sel'>
                    {props.t('Detail Shop Info')}
                  </Link>
                )}
              {(props.userFeat.includes('ftl-admin') ||
                props.userFeat.includes('ftl-executive')) && (
                <Link to='serviceinfo' className='account-link'>
                  {props.t('Service Info')}
                </Link>
              )}
            </Col>
          </Row>
        </div>
      </div>
      <div className='page-content page-background'>
        <MetaTags>
          <title>Home | {title}</title>
        </MetaTags>
        <Container fluid style={{ width: '52%' }}>
          <Row style={{ paddingTop: '3.313rem' }}>
            <Col lg='12'>
              <Card>
                <CardBody style={{ padding: '0rem' }}>
                  <Row>
                    <Col lg='4' style={{ paddingLeft: '6.063rem' }}>
                      <Row>
                        <Col
                          lg='12'
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginTop: '6.063rem',
                          }}
                        >
                          <Media
                            src={props.logo}
                            style={{
                              display: 'block',
                              maxWidth: '100%',
                              height: 'auto',
                              width: '10.938rem',
                              marginBottom: '2.063rem',
                            }}
                          ></Media>
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          lg='12'
                          style={{ display: 'flex', justifyContent: 'center' }}
                        >
                          <input id='fileButton' type='file' hidden />
                          <Button
                            color='dark'
                            type='file'
                            className='secondary-btn-sm'
                            style={{ float: 'left' }}
                            onClick={() => {
                              document.getElementById('fileButton').click()
                              document.getElementById(
                                'fileButton'
                              ).onchange = () => {
                                var files = [
                                  ...document.getElementById('fileButton')
                                    .files,
                                ]
                                files.map((item) => console.log(item))

                                Promise.all([
                                  new Compress().compress(files, {
                                    quality: 1,
                                    maxWidth: 1920,
                                    maxHeight: 1920,
                                    resize: true,
                                    rotate: false,
                                  }),
                                ]).then((results) => {
                                  var tenantData = {
                                    type: props.tenant_type,
                                    name: props.name,
                                    addressOne: props.address,
                                    city: props.city,
                                    zip: props.zip,
                                    state: props.state,
                                    department: props.departments,
                                    logo: props.logo,
                                    logoImage: results[0][0].data,
                                  }
                                  if (props.tenant_type == 'Dealership') {
                                    tenantData['detailshop'] =
                                      props.targetTenant.data_key
                                  } else if (
                                    props.tenant_type == 'Detailshop'
                                  ) {
                                    tenantData['dealership'] =
                                      props.targetTenant.data_key
                                  }

                                  onUpdateTenantData(
                                    props.org,
                                    props.tenant,
                                    tenantData
                                  )
                                })
                              }
                            }}
                          >
                            {props.t('Edit Logo')}
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                    <Col
                      lg='8'
                      style={{ marginTop: '4.375rem', paddingLeft: '6.063rem' }}
                    >
                      <Row>
                        <Col lg='12'>
                          <div>
                            {props.userFeat.includes('ftl-dealership') && (
                              <Label className='profile-label'>
                                {props.t('Dealership Name')}
                              </Label>
                            )}
                            {props.userFeat.includes('ftl-detailshop') && (
                              <Label className='profile-label'>
                                {props.t('Detail Shop Name')}
                              </Label>
                            )}
                          </div>
                          <div>
                            <Label className='profile-input-label'>
                              {props.name}
                            </Label>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg='12'>
                          <div>
                            <Label className='profile-label'>
                              {props.t('Address 1')}
                            </Label>
                          </div>
                          <div>
                            <Label className='profile-input-label'>
                              {props.address}
                            </Label>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg='12'>
                          <div>
                            <Label className='profile-label'>
                              {props.t('City')}
                            </Label>
                          </div>
                          <div>
                            <Label className='profile-input-label'>
                              {props.city}
                            </Label>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg='6'>
                          <div>
                            <Label className='profile-label'>
                              {props.t('ZIP Code')}
                            </Label>
                          </div>
                          <div>
                            <Label className='profile-input-label'>
                              {props.zip}
                            </Label>
                          </div>
                        </Col>
                        <Col lg='6'>
                          <div>
                            <Label className='profile-label'>
                              {props.t('State')}
                            </Label>
                          </div>
                          <div>
                            <Label className='profile-input-label'>
                              {props.state}
                            </Label>
                          </div>
                        </Col>
                      </Row>
                      <Row
                        style={{
                          marginTop: '2.5rem',
                          marginBottom: '3.125rem',
                        }}
                      >
                        <Col lg='12'>
                          <Button
                            color='dark'
                            className='prim-btn-big'
                            style={{ float: 'left' }}
                            onClick={() => {
                              tog_center()
                            }}
                          >
                            {props.t('Edit')}
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <Container fluid style={{ width: '52%' }}>
          <Row style={{ paddingTop: '1rem' }}>
            <Col lg='12'>
              <Card>
                <CardBody style={{ padding: '0rem' }}>
                  <Row>
                    <Col xl='6'>
                      <Label className='subpage-title'>
                        {props.t('Departments')}
                      </Label>
                    </Col>
                    <Col xl='6'>
                      <Button
                        color='dark'
                        className='prim-btn-sm'
                        style={{
                          float: 'right',
                          marginTop: '1.625rem',
                          marginRight: '1.875rem',
                        }}
                        onClick={() => {
                          tog_add()
                        }}
                      >
                        {props.t('Add Department')}
                      </Button>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: '2.063rem' }}>
                    <Col xl='12'>
                      <div
                        className='table-rep-plugin'
                        style={{ marginLeft: '1.25rem' }}
                      >
                        <div
                          className='table-responsive mb-0'
                          data-pattern='priority-columns'
                        >
                          <Table id='tech-companies-1' className='table'>
                            <Thead>
                              <Tr>
                                <Th className='table-headers'>
                                  {props.t('Department')}
                                </Th>
                                <Th data-priority='1' className='table-headers'>
                                  {props.t('Actions')}
                                </Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              {props.departments.map((item, idx) => {
                                return (
                                  <Tr key={idx}>
                                    <Td className='table-cells'>{item}</Td>
                                    <Td className='table-cells'>
                                      <Row
                                        onClick={() => {
                                          tog_dept(idx)
                                        }}
                                      >
                                        <Col lg={1} style={{ padding: 0 }}>
                                          <Media
                                            src={edit_logo}
                                            style={{
                                              paddingLeft: '0.125rem',
                                              paddingRight: '0.125rem',
                                              marginTop: '0.625rem',
                                              marginRight: '0.625rem',
                                            }}
                                          ></Media>
                                        </Col>
                                        <Col lg={11} style={{ padding: 0 }}>
                                          <Label style={{ float: 'left' }}>
                                            {props.t('Edit')}
                                          </Label>
                                        </Col>
                                      </Row>
                                    </Td>
                                  </Tr>
                                )
                              })}
                            </Tbody>
                          </Table>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <LoadingOverlay active={props.loading} spinner>
        <EditDealership
          title={props.t('Edit Detail Shop Information')}
          submitBtnTitle={props.t('Save')}
          isOpen={modal_center}
          tog_center={tog_center}
          showModal={setmodal_center}
          logo={props.logo}
          name={props.name}
          address={props.address}
          city={props.city}
          zip={props.zip}
          state={props.state}
          department={props.departments}
          serviceCatalog={props.services}
          targetTenant={props.targetTenant.data_key}
          tenant_type={props.tenant_type}
          onSubmit={(dealershipInfo) => {
            onUpdateTenantData(props.org, props.tenant, dealershipInfo)
          }}
          userFeat={props.userFeat}
        />
      </LoadingOverlay>

      <LoadingOverlay active={props.loading} spinner>
        <AddDepartment
          title={props.t('Add New Department')}
          submitBtnTitle={props.t('Submit')}
          isOpen={modal_add}
          showModal={setmodal_add}
          onSubmit={(newDepartment) => {
            var tenantInfo = {
              type: props.tenant_type,
              name: props.name,
              addressOne: props.address,
              city: props.city,
              zip: props.zip,
              state: props.state,
              department: [...props.departments, newDepartment],
              logo: props.logo,
            }
            if (props.tenant_type == 'Dealership') {
              tenantInfo['detailshop'] = props.targetTenant.data_key
            } else if (props.tenant_type == 'Detailshop') {
              tenantInfo['dealership'] = props.targetTenant.data_key
            }

            onUpdateTenantData(props.org, props.tenant, tenantInfo)
          }}
        />
      </LoadingOverlay>

      <LoadingOverlay active={props.loading} spinner>
        <EditDepartment
          title={props.t('Edit Department')}
          submitBtnTitle={props.t('Save')}
          secondBtnTitle={props.t('Delete')}
          isOpen={modal_dept}
          tog_dept={tog_dept}
          setmodal_dept={setmodal_dept}
          showModal={setmodal_dept}
          deptToEdit={props.departments[departmentSelected]}
          onSubmit={(newDepartmentName) => {
            var tenantData = {
              type: props.tenant_type,
              logo: props.logo,
              name: props.name,
              addressOne: props.address,
              city: props.city,
              zip: props.zip,
              state: props.state,
              department: [
                ...props.departments.filter(
                  (item, idx) => idx != departmentSelected
                ),
                newDepartmentName,
              ],
            }
            if (props.tenant_type == 'Dealership') {
              tenantData['detailshop'] = props.targetTenant.data_key
            } else if (props.tenant_type == 'Detailshop') {
              tenantData['dealership'] = props.targetTenant.data_key
            }

            onUpdateTenantData(props.org, props.tenant, tenantData)
          }}
          onDelete={() => {
            var tenantData = {
              logo: props.logo,
              name: props.name,
              addressOne: props.address,
              city: props.city,
              zip: props.zip,
              state: props.state,
              department: [
                ...props.departments.filter(
                  (item, idx) => idx != departmentSelected
                ),
              ],
            }
            if (props.tenant_type == 'Dealership') {
              tenantData['detailshop'] = props.targetTenant.data_key
            } else if (props.tenant_type == 'Detailshop') {
              tenantData['dealership'] = props.targetTenant.data_key
            }
            onUpdateTenantData(props.org, props.tenant, tenantData)
          }}
        />
      </LoadingOverlay>
    </React.Fragment>
  )
}

DealershipInfo.propTypes = {
  t: PropTypes.any,
  logo: PropTypes.string,
  name: PropTypes.string,
  address: PropTypes.string,
  city: PropTypes.string,
  zip: PropTypes.string,
  state: PropTypes.string,
  departmentSelected: PropTypes.string,
  departments: PropTypes.any,
  services: PropTypes.any,
  onGetTenantData: PropTypes.func,
  onUpdateTenantData: PropTypes.func,
  targetTenant: PropTypes.any,
  org: PropTypes.string,
  tenant: PropTypes.string,
  userFeat: PropTypes.any,
  loading: PropTypes.any,
  tenant_type: PropTypes.string,
  tenantUpdated: PropTypes.bool,
}

const mapStateToProps = ({ tenant, login }) => ({
  logo: tenant.logo,
  name: tenant.name,
  address: tenant.address_line_one,
  city: tenant.city,
  zip: tenant.zip_code,
  state: tenant.state,
  departments: tenant.department,
  services: tenant.service_catalog,
  targetTenant: tenant.targetTenant,
  tenant_type: tenant.tenant_type,
  tenantUpdated: tenant.tenantUpdated,
  org: login.org,
  userFeat: login.features,
  tenant: login.tenant,
})

const mapDispatchToProps = (dispatch) => ({
  onGetTenantData: (org, tenant) => dispatch(getTenantData(org, tenant)),
  onUpdateTenantData: (org, tenant, tenantData) =>
    dispatch(updateTenantData(org, tenant, tenantData)),
})

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation()(DealershipInfo))
)
