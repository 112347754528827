import { combineReducers } from 'redux'

// Authentication
import login from './auth/login/reducer'
import Layout from './layout/reducer'

// Glisten Users
import users from './glisten/users/reducer'

// Glisten Finance
import vehicle from './glisten/vehicle/reducer'

// Glisten Profile
import profile from './glisten/profile/reducer'

// Glisten Service
import service from './glisten/service/reducer'

// Glisten Dashboard
import dashboard from './glisten/dashboard/reducer'

// Glisten Order
import order from './glisten/order/reducer'

// Glisten Tenant
import tenant from './glisten/tenant/reducer'

// Glisten Finance
import finance from './glisten/finance/reducer'

const rootReducer = combineReducers({
  Layout,
  login,
  vehicle,
  profile,
  service,
  dashboard,
  order,
  users,
  tenant,
  finance,
})

export default rootReducer
