import {
  GET_PROFILE_DATA,
  GET_PROFILE_DATA_SUCCESS,
  GET_PROFILE_DATA_FAIL,
  UPDATE_PROFILE_DATA,
  UPDATE_PROFILE_DATA_SUCCESS,
  UPDATE_PROFILE_DATA_FAIL,
  ADD_PROFILE_DATA,
  ADD_PROFILE_DATA_SUCCESS,
  ADD_PROFILE_DATA_FAIL,
} from './actionTypes'

const initialState = {
  isLoading: false,
  profileUpdated: false,
  firstname: '',
  lastname: '',
  email: '',
  phone: '',
  department: '',
  role: '',
  error: {},
}

const profile = (state = initialState, action) => {
  switch (action.type) {
    case GET_PROFILE_DATA:
      console.log('reducer::GET_PROFILE_DATA action.payload=' + action.payload)

      state = {
        ...state,
        isLoading: true,
      }
      break

    case GET_PROFILE_DATA_SUCCESS:
      console.log(
        'reducer::GET_PROFILE_DATA_SUCCESS action.payload=' + action.payload
      )

      state = {
        isLoading: false,
        profileUpdated: false,
        firstname: action.payload.employee['first_name'],
        lastname: action.payload.employee['last_name'],
        email: action.payload.employee['email'],
        phone: action.payload.employee['phone_number'],
        department: action.payload.employee['department'],
        role: action.payload.employee['role'],
      }
      break

    case GET_PROFILE_DATA_FAIL:
      console.log(
        'reducer::GET_PROFILE_DATA_FAIL action.payload=' + action.payload
      )

      state = {
        ...state,
        isLoading: false,
        error: action.payload,
      }
      break

    case UPDATE_PROFILE_DATA:
      console.log(
        'reducer::UPDATE_PROFILE_DATA action.payload=' + action.payload
      )

      state = {
        ...state,
        isLoading: true,
      }
      break

    case UPDATE_PROFILE_DATA_SUCCESS:
      console.log(
        'reducer::UPDATE_PROFILE_DATA_SUCCESS action.payload=' + action.payload
      )

      state = {
        ...state,
        isLoading: false,
        profileUpdated: false, // later once it is sync... then use this
        firstname: action.payload.profileData['firstName'],
        lastname: action.payload.profileData['lastName'],
        email: action.payload.profileData['email'],
        phone: action.payload.profileData['phoneNumber'],
        department: action.payload.profileData['department'],
        role: action.payload.profileData['role'],
      }
      break

    case UPDATE_PROFILE_DATA_FAIL:
      console.log(
        'reducer::UPDATE_PROFILE_DATA_SUCCESS action.payload=' + action.payload
      )
      state = {
        ...state,
        isLoading: false,
        error: action.payload,
      }
      break

    case ADD_PROFILE_DATA:
      console.log('reducer::ADD_PROFILE_DATA action.payload=' + action.payload)

      state = {
        ...state,
        isLoading: true,
      }
      break

    case ADD_PROFILE_DATA_SUCCESS:
      console.log(
        'reducer::ADD_PROFILE_DATA_SUCCESS action.payload=' + action.payload
      )
      state = {
        ...state,
        isLoading: false,
      }
      break

    case ADD_PROFILE_DATA_FAIL:
      console.log(
        'reducer::ADD_PROFILE_DATA_FAIL action.payload=' + action.payload
      )
      state = {
        ...state,
        isLoading: false,
        error: action.payload,
      }
      break

    default:
      state = { ...state }
      break
  }
  return state
}

export default profile
