/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types'
import React, { useEffect, useRef } from 'react'

import {
  Table,
  Row,
  Col,
  Modal,
  Button,
  FormGroup,
  Label,
  Media,
} from 'reactstrap'

import { useReactToPrint } from 'react-to-print'

import NumberFormat from 'react-number-format'

// Styling
import './financeinvoice.scss'

//i18n
import { withTranslation } from 'react-i18next'

import { connect, useState } from 'react-redux'

import { formatTime } from '../../../helpers/util'

import { ReactComponent as Logo } from '../../../assets/images/ic-close.svg'

import nocarimage from '../../../assets/images/no-car-image.svg'

import { sendPageViewEvent } from '../../../firebase'
import { Link, withRouter } from 'react-router-dom'

const FinanceInvoice = (props) => {
  useEffect(() => {
    sendPageViewEvent('Finance Order Detail')
  }, [])

  const componentRef = useRef()
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  })

  const getPageMargins = () => {
    return `@page { margin: 50 25 50 25 !important; }`
  }

  const calcTotalCost = () => {
    var serviceTotalCost = 0
    if (props.serviceQTY == null) {
      return null
    } else {
      props.serviceQTY.forEach((item) => {
        serviceTotalCost += item.cost * item.quantity
      })
      return serviceTotalCost
    }
  }

  const calcOrderTotal = (order) => {
    var serviceTotalCost = 0
    if (order.service_catalog == null) {
      return null
    } else {
      order.service_catalog.forEach((item) => {
        serviceTotalCost += item.retail_cost
      })
      return serviceTotalCost
    }
  }

  const displayProfileImage = (order) => {
    if (order == null || order.images == null || order.images.length == 0) {
      return (
        // eslint-disable-next-line no-extra-semi
        <Media
          src={nocarimage}
          style={{
            display: 'block',
            maxWidth: '100%',
            height: 'auto',
            width: '83%',
            borderRadius: '8px',
          }}
        ></Media>
      )
    } else {
      return (
        <Media
          src={order.images[order.images.length - 1].image_url.original}
          style={{
            display: 'block',
            maxWidth: '100%',
            height: 'auto',
            width: '83%',
            borderRadius: '8px',
          }}
        ></Media>
      )
    }
  }

  const fullName = (first, last) => {
    var full = first + ' ' + last
    return full
  }

  const displayOrderServices = (order) => {
    return order.service_catalog.map((item) => {
      return (
        <>
          <tr style={{ border: '1px solid #DFDFDF' }}>
            <td
              className='invoice-cells'
              style={{
                paddingTop: '1.25rem',
                paddingLeft: '1.25rem',
                paddingBottom: '1.25rem',
              }}
            >
              <div>
                <Label
                  style={{
                    fontSize: '0.875rem',
                    letterSpacing: '0.084rem',
                    lineHeight: '1.438rem',
                    color: '#000000',
                    fontWeight: '200',
                    marginBottom: '0rem',
                  }}
                >
                  {item.name}
                  {/* Service Wash */}
                </Label>
              </div>
              <div style={{ paddingTop: '0.469rem' }}>
                <Label
                  style={{
                    fontSize: '0.75rem',
                    letterSpacing: '0.084rem',
                    lineHeight: '1.438rem',
                    color: '#000000',
                    fontWeight: '200',
                    marginBottom: '0rem',
                  }}
                >
                  {item.description}
                  {/* A lengthy description that describes what this service
                    entails. */}
                </Label>
              </div>
            </td>
            <td
              className=''
              style={{
                fontSize: '0.875rem',
                letterSpacing: '0.099rem',
                lineHeight: '1.25rem',
                color: '#000000',
                fontWeight: '200',
                paddingTop: '2.188rem',
                paddingRight: '2.313rem',
                textAlign: 'right',
              }}
            >
              ${item.retail_cost}
              {/* $25.00 */}
            </td>
          </tr>
        </>
      )
    })
  }

  return (
    <Modal
      scrollable='true'
      size='xl'
      isOpen={props.isOpen}
      toggle={() => {
        props.showModal(false)
      }}
      centered={true}
    >
      <div className='modal-header glisten-modal-header'>
        <div>
          <h3 className='glisten-modal-title'>{props.title}</h3>
        </div>

        <button
          className='close-btn'
          onClick={() => {
            props.showModal(false)
          }}
        >
          <Logo />
        </button>
      </div>

      <div
        className='modal-body glisten-modal-body'
        style={{ paddingTop: '1.188rem', paddingRight: '4.375rem' }}
      >
        <Row className='row-margin-fix'>
          <Col
            lg='7'
            style={{
              display: 'flex',
              flexDirection: 'column',
              padding: '0rem',
            }}
          >
            <Label className='cleaner-contact'>
              {props.t('Detailer Contact Line 1')}
            </Label>
            <Label className='cleaner-contact'>
              {props.t('Detailer Contact Line 2')}
            </Label>
            <Label className='cleaner-contact'>
              {props.t('Detailer Contact Line 3')}
            </Label>
            <Label className='cleaner-contact'>
              {props.t('Detailer Contact Line 4')}
            </Label>
            <Label className='cleaner-contact'>
              {props.t('Detailer Contact Line 5')}
            </Label>
          </Col>
          <Col
            lg='5'
            style={{
              display: 'flex',
              flexDirection: 'row',
              padding: '0rem',
            }}
          >
            DETAILING LOGO HERE
          </Col>
        </Row>
        <Row className='row-margin-fix'>
          <Col
            lg='12'
            style={{
              display: 'flex',
              flexDirection: 'column',
              padding: '0rem',
            }}
          >
            <Label
              style={{
                fontFamily: 'Poppins',
                fontWeight: '400',
                fontSize: '2rem',
                letterSpacing: '0.225rem',
                lineHeight: '2.813rem',
                color: 'black',
                marginBottom: '1.375rem',
                marginTop: '3.125rem',
              }}
            >
              {props.t('INVOICE')}
            </Label>
          </Col>
        </Row>
        <Row className='row-margin-fix'>
          <Col
            lg='6'
            style={{
              display: 'flex',
              flexDirection: 'row',
              padding: '0rem',
              marginBottom: '0.406rem',
            }}
          >
            <Label
              style={{
                fontFamily: 'Poppins',
                fontWeight: '500',
                fontSize: '1rem',
                letterSpacing: '0.113rem',
                lineHeight: '1.438rem',
                color: 'black',
                marginBottom: '0rem',
              }}
            >
              {props.t('BILL TO')}
            </Label>
          </Col>
          <Col
            lg='6'
            style={{
              display: 'flex',
              flexDirection: 'row',
              padding: '0rem',
              justifyContent: 'flex-end',
            }}
          >
            <Label
              style={{
                fontFamily: 'Poppins',
                fontWeight: '400',
                fontSize: '1rem',
                letterSpacing: '0.113rem',
                lineHeight: '1.438rem',
                color: 'black',
                marginBottom: '0rem',
              }}
            >
              {props.t('INVOICE #')}
            </Label>
            <Label
              style={{
                fontFamily: 'Poppins',
                fontWeight: '400',
                fontSize: '0.875rem',
                letterSpacing: '0.099rem',
                lineHeight: '1.438rem',
                color: 'black',
                marginBottom: '0rem',
                marginLeft: '0.5rem',
                paddingRight: '1.25rem',
              }}
            >
              {props.t('HERE')}
            </Label>
          </Col>
        </Row>
        <Row className='row-margin-fix'>
          <Col
            lg='6'
            style={{
              display: 'flex',
              flexDirection: 'column',
              padding: '0rem',
            }}
          >
            <Label
              style={{
                fontFamily: 'Poppins',
                fontWeight: '400',
                fontSize: '0.875rem',
                letterSpacing: '0.099rem',
                lineHeight: '1.438rem',
                color: 'black',
                marginBottom: '0rem',
              }}
            >
              {props.t('DEALERSHIP LINE 1')}
            </Label>
            <Label
              style={{
                fontFamily: 'Poppins',
                fontWeight: '400',
                fontSize: '0.875rem',
                letterSpacing: '0.099rem',
                lineHeight: '1.438rem',
                color: 'black',
                marginBottom: '0rem',
              }}
            >
              {props.t('DEALERSHIP LINE 2')}
            </Label>
            <Label
              style={{
                fontFamily: 'Poppins',
                fontWeight: '400',
                fontSize: '0.875rem',
                letterSpacing: '0.099rem',
                lineHeight: '1.438rem',
                color: 'black',
                marginBottom: '0rem',
              }}
            >
              {props.t('DEALERSHIP LINE 3')}
            </Label>
          </Col>
          <Col
            lg='6'
            style={{
              display: 'flex',
              flexDirection: 'row',
              padding: '0rem',
              justifyContent: 'flex-end',
            }}
          >
            <Label
              style={{
                fontFamily: 'Poppins',
                fontWeight: '400',
                fontSize: '1rem',
                letterSpacing: '0.113rem',
                lineHeight: '1.438rem',
                color: 'black',
                marginBottom: '0rem',
              }}
            >
              {props.t('DATE')}
            </Label>
            <Label
              style={{
                fontFamily: 'Poppins',
                fontWeight: '400',
                fontSize: '0.875rem',
                letterSpacing: '0.099rem',
                lineHeight: '1.438rem',
                color: 'black',
                marginBottom: '0rem',
                marginLeft: '0.5rem',
                paddingRight: '1.25rem',
              }}
            >
              {props.t('00/00/0000')}
            </Label>
          </Col>
        </Row>
        <Row className='row-margin-fix'>
          <Col
            lg='12'
            style={{
              display: 'flex',
              flexDirection: 'column',
              padding: '0rem',
            }}
          >
            <div
              className='align-self-center'
              style={{
                alignSelf: 'center',
                height: '0.063rem',
                width: '100%',
                marginTop: '2rem',
                marginBottom: '3.375rem',
                background: '#E6E6E6',
              }}
            ></div>
          </Col>
        </Row>

        {/* SERVICE TABLE */}
        <Row className='row-margin-fix'>
          <Col
            lg='12'
            style={{
              display: 'flex',
              flexDirection: 'column',
              padding: '0rem',
            }}
          >
            <Table borderless style={{ marginBottom: '0.438rem' }}>
              <thead>
                <tr>
                  <th className='invoice-header'>{props.t('SERVICE')}</th>
                  <th
                    className='invoice-header'
                    style={{ textAlign: 'center' }}
                  >
                    {props.t('QTY')}
                  </th>
                  <th className='invoice-header' style={{ textAlign: 'right' }}>
                    {props.t('RATE')}
                  </th>
                  <th className='invoice-header' style={{ textAlign: 'right' }}>
                    {props.t('AMOUNT')}
                  </th>
                </tr>
              </thead>
              <tbody>
                {props.serviceQTY.map((item, idx) => {
                  return (
                    <>
                      <tr key={idx}>
                        <td className='invoice-cells'>{item.name}</td>
                        <td
                          className='invoice-cells'
                          style={{ textAlign: 'center' }}
                        >
                          {item.quantity}
                        </td>
                        <td
                          className='invoice-cells'
                          style={{ textAlign: 'right' }}
                        >
                          <NumberFormat
                            value={item.cost}
                            displayType={'text'}
                            fixedDecimalScale={true}
                            decimalScale={2}
                            thousandSeparator={true}
                            prefix={'$'}
                          />
                        </td>
                        <td
                          className='invoice-cells'
                          style={{ textAlign: 'right' }}
                        >
                          <NumberFormat
                            value={item.cost * item.quantity}
                            displayType={'text'}
                            fixedDecimalScale={true}
                            decimalScale={2}
                            thousandSeparator={true}
                            prefix={'$'}
                          />
                        </td>
                      </tr>
                    </>
                  )
                })}
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row
          style={{
            marginBottom: '25%',
            marginTop: '0rem',
            marginLeft: '0rem',
            marginRight: '0rem',
          }}
        >
          <Col xl='6' style={{ padding: '0rem' }}></Col>
          <Col
            xl='6'
            style={{ paddingLeft: '0rem', paddingRight: '0rem' }}
            className='text-end'
          >
            <Label
              style={{
                fontSize: '1rem',
                letterSpacing: '0.113rem',
                lineHeight: '1.438rem',
                color: '#000000',
                fontWeight: '200',
                marginBottom: '0rem',
                paddingTop: '1.874rem',
              }}
            >
              {props.t('BALANCE DUE')}
            </Label>
            <Label
              style={{
                fontSize: '1.5rem',
                letterSpacing: '0.169rem',
                lineHeight: '2.063rem',
                color: '#000000',
                fontWeight: '500',
                paddingTop: '1.563rem',
                paddingRight: '1.25rem',
                marginLeft: '1.25rem',
                marginBottom: '0rem',
                textAlign: 'right',
              }}
            >
              <NumberFormat
                value={calcTotalCost()}
                displayType={'text'}
                fixedDecimalScale={true}
                decimalScale={2}
                thousandSeparator={true}
                prefix={'$'}
              />
            </Label>
          </Col>
        </Row>

        {/* Display All Details For Selected Orders */}
        <Row className='row-margin-fix'>
          <Col lg='12'>
            {props.orders.map((item) => {
              return (
                <>
                  <Row>
                    <Col xl='7'>
                      <Row>
                        <Col xl='12'>
                          {item && item.vehicle ? (
                            <Label
                              style={{
                                fontFamily: 'Poppins',
                                fontWeight: '600',
                                fontSize: '1.5rem',
                                letterSpacing: '0.169rem',
                                color: '#333333',
                                marginTop: '2.688',
                                marginBottom: '0rem',
                              }}
                            >
                              {item.vehicle.year} {item.vehicle.make}
                            </Label>
                          ) : (
                            <></>
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col xl='12'>
                          <Label
                            style={{
                              fontFamily: 'Poppins',
                              fontWeight: '600',
                              fontSize: '1.5rem',
                              letterSpacing: '0.169rem',
                              color: '#333333',
                              marginBottom: '0rem',
                            }}
                          >
                            {/* TO DO: Pre-pend the car year onto this string */}
                            {item == null ? null : item.vehicle.model}
                          </Label>
                        </Col>
                      </Row>
                      <Row>
                        <Col xl='12'>
                          <Label
                            style={{
                              fontFamily: 'Poppins',
                              fontWeight: '200',
                              fontSize: '1.125rem',
                              letterSpacing: '0.127rem',
                              color: '#333333',
                              marginTop: '1rem',
                              marginBottom: '0rem',
                            }}
                          >
                            {item == null ? null : props.t(item.vehicle.color)}
                          </Label>
                        </Col>
                      </Row>
                      <Row>
                        <Col xl='10'>
                          <Table striped style={{ marginTop: '2.813rem' }}>
                            <tbody>
                              <tr style={{ backgroundColor: '#EEEEEE' }}>
                                <td className='thin-table-cell'>
                                  {props.t('VIN')}
                                </td>
                                <td
                                  className='thin-table-cell'
                                  style={{
                                    fontWeight: '200',
                                    textAlign: 'right',
                                  }}
                                >
                                  {item == null || item.vehicle.data_key == ''
                                    ? null
                                    : item.vehicle.data_key}
                                </td>
                              </tr>
                              <tr>
                                <td className='thin-table-cell'>
                                  {props.t('Stock Number')}
                                </td>
                                <td
                                  className='thin-table-cell'
                                  style={{
                                    fontWeight: '200',
                                    textAlign: 'right',
                                  }}
                                >
                                  {item == null || item.stock == ''
                                    ? props.t('N/A')
                                    : item.stock}
                                </td>
                              </tr>
                              <tr style={{ backgroundColor: '#EEEEEE' }}>
                                <td className='thin-table-cell'>
                                  {props.t('PO Number')}
                                </td>
                                <td
                                  className='thin-table-cell'
                                  style={{
                                    fontWeight: '200',
                                    textAlign: 'right',
                                  }}
                                >
                                  {item == null || item.po_number == ''
                                    ? props.t('N/A')
                                    : item.po_number}
                                </td>
                              </tr>
                              <tr>
                                <td className='thin-table-cell'>
                                  {props.t('Customer Tag')}
                                </td>
                                <td
                                  className='thin-table-cell'
                                  style={{
                                    fontWeight: '200',
                                    textAlign: 'right',
                                  }}
                                >
                                  {item == null || item.customer_tag == ''
                                    ? props.t('N/A')
                                    : item.customer_tag}
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </Col>
                      </Row>
                    </Col>
                    <Col xl='5'>{displayProfileImage(item)}</Col>
                  </Row>

                  <Row>
                    <Col xl='7'>
                      <Label
                        style={{
                          fontFamily: 'Poppins',
                          fontWeight: '200',
                          fontSize: '1.125rem',
                          letterSpacing: '0.127rem',
                          lineHeight: '1.563rem',
                          color: '#000000',
                          marginTop: '2.688rem',
                        }}
                      >
                        {props.t('SERVICES REQUESTED')}
                      </Label>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl='11'>
                      <Table style={{ marginTop: '1.438rem' }}>
                        <tbody>
                          {item == null ? null : displayOrderServices(item)}
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                  <Row style={{ borderBottom: '1px solid #DFDFDF' }}>
                    <Col xl='7'>
                      <div style={{ marginTop: '1.438rem' }}>
                        <Label
                          style={{
                            fontSize: '0.875rem',
                            letterSpacing: '0.099rem',
                            lineHeight: '1.25rem',
                            color: '#000000',
                            fontWeight: '400',
                            marginLeft: '1.25rem',
                            marginBottom: '0rem',
                          }}
                        >
                          {item == null
                            ? null
                            : item.note == null
                            ? null
                            : props.t('Order Note')}
                        </Label>
                      </div>
                      <div
                        style={{
                          paddingTop: '0.469rem',
                          paddingBottom: '3.125rem',
                        }}
                      >
                        <Label
                          style={{
                            fontSize: '0.875rem',
                            letterSpacing: '0.099rem',
                            lineHeight: '1.438rem',
                            color: '#000000',
                            fontWeight: '200',
                            marginLeft: '1.25rem',
                            marginBottom: '0rem',
                          }}
                        >
                          {item == null
                            ? null
                            : item.note == null
                            ? null
                            : item.note}
                          {/* The note that was attached to order when order was intially
                created will be displayed here if it exists. */}
                        </Label>
                      </div>
                    </Col>
                    <Col xl='4' className='text-end'>
                      <Label
                        style={{
                          fontSize: '1rem',
                          letterSpacing: '0.113rem',
                          lineHeight: '1.438rem',
                          color: '#000000',
                          fontWeight: '200',
                          marginBottom: '0rem',
                          paddingTop: '1.874rem',
                        }}
                      >
                        {props.t('TOTAL')}
                      </Label>
                      <Label
                        style={{
                          fontSize: '1.5rem',
                          letterSpacing: '0.169rem',
                          lineHeight: '2.063rem',
                          color: '#000000',
                          fontWeight: '500',
                          paddingTop: '1.563rem',
                          paddingRight: '1.25rem',
                          marginLeft: '1.25rem',
                          marginBottom: '0rem',
                          textAlign: 'right',
                        }}
                      >
                        ${item == null ? null : calcOrderTotal(item)}
                        {/* $100.00 */}
                      </Label>
                    </Col>
                  </Row>
                  <Row
                    style={{
                      marginBottom: '25%',
                      marginTop: '0rem',
                      marginLeft: '0rem',
                      marginRight: '0rem',
                    }}
                  >
                    <Col
                      xl='6'
                      style={{
                        borderBottom: '1px solid #DFDFDF',
                        paddingBottom: '3.125rem',
                      }}
                    >
                      <Table borderless style={{ marginTop: '2.813rem' }}>
                        <tbody>
                          <tr>
                            <td
                              className='time-stamp-info'
                              style={{
                                fontWeight: '500',
                                // paddingLeft: '3.75rem',
                                // paddingRight: '0rem',
                                //textAlign: 'right',
                              }}
                            >
                              {props.t('SUBMITTED')}
                            </td>
                            <td
                              className='time-stamp-info'
                              style={{
                                fontWeight: '200',
                                paddingRight: '0rem',
                              }}
                            >
                              {item == null
                                ? null
                                : formatTime(item.original_created_at)}
                              {/* 10:31 AM 08/22/21 */}
                            </td>
                          </tr>
                          <tr>
                            <td
                              className='time-stamp-info'
                              style={{
                                fontWeight: '500',
                                paddingTop: '0.563rem',
                                // paddingLeft: '3.75rem',
                                // paddingRight: '0rem',
                                //textAlign: 'right',
                              }}
                            >
                              {props.t('CREATED BY')}
                            </td>
                            <td
                              className='time-stamp-info'
                              style={{
                                fontWeight: '200',
                                paddingTop: '0.563rem',
                                paddingRight: '0rem',
                              }}
                            >
                              {item == null
                                ? null
                                : fullName(
                                    item.created_by.first_name,
                                    item.created_by.last_name
                                  )}
                              {/* Piers Babu */}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Col>
                    <Col
                      xl='6'
                      style={{
                        borderBottom: '1px solid #DFDFDF',
                        paddingBottom: '3.125rem',
                      }}
                    >
                      <Table borderless style={{ marginTop: '2.813rem' }}>
                        <tbody>
                          <tr>
                            <td
                              className='time-stamp-info'
                              style={{ fontWeight: '500' }}
                            >
                              {props.t('REQUESTED FOR')}
                            </td>
                            <td
                              className='time-stamp-info'
                              style={{
                                fontWeight: '200',
                                //textAlign: 'right',
                                paddingRight: '2.25rem',
                              }}
                            >
                              {item == null
                                ? null
                                : formatTime(item.pickup_date_time)}
                              {/* 4:00 AM 08/23/21 */}
                            </td>
                          </tr>
                          <tr>
                            <td
                              className='time-stamp-info'
                              style={{
                                fontWeight: '500',
                                //paddingLeft: '3.75rem',
                                paddingTop: '0.563rem',
                              }}
                            >
                              {props.t('COMPLETED')}
                            </td>
                            <td
                              className='time-stamp-info'
                              style={{
                                fontWeight: '200',
                                paddingTop: '0.563rem',
                                //textAlign: 'right',
                                paddingRight: '2.25rem',
                              }}
                            >
                              {item == null
                                ? null
                                : formatTime(item.data_timestamp)}
                              {/* 4:00 AM 08/22/21 */}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </>
              )
            })}
          </Col>
        </Row>
      </div>

      <div className='modal-footer glisten-modal-footer'>
        <div>
          <FormGroup>
            <Button
              className='btn glisten-btn prim-mod-action'
              disabled={props.submitBtnDisabled}
              onClick={handlePrint}
            >
              {props.submitBtnTitle}
            </Button>
          </FormGroup>
        </div>
      </div>

      {/* CODE BELOW is specifically for the pdf print view */}
      <div className={'d-none d-print-inline'} ref={componentRef}>
        <style>{getPageMargins()}</style>
        <Row className='row-margin-fix'>
          <div
            className={'col-print-7'}
            style={{
              display: 'flex',
              flexDirection: 'column',
              padding: '0rem',
            }}
          >
            <Label className='cleaner-contact'>
              {props.t('Detailer Contact Line 1')}
            </Label>
            <Label className='cleaner-contact'>
              {props.t('Detailer Contact Line 2')}
            </Label>
            <Label className='cleaner-contact'>
              {props.t('Detailer Contact Line 3')}
            </Label>
            <Label className='cleaner-contact'>
              {props.t('Detailer Contact Line 4')}
            </Label>
            <Label className='cleaner-contact'>
              {props.t('Detailer Contact Line 5')}
            </Label>
          </div>

          <div
            className={'col-print-5'}
            style={{
              display: 'flex',
              flexDirection: 'row',
              padding: '0rem',
            }}
          >
            DETAILING LOGO HERE
          </div>
        </Row>
        <Row className='row-margin-fix'>
          <div
            className={'col-print-12'}
            style={{
              display: 'flex',
              flexDirection: 'column',
              padding: '0rem',
            }}
          >
            <Label
              style={{
                fontFamily: 'Poppins',
                fontWeight: '400',
                fontSize: '2rem',
                letterSpacing: '0.225rem',
                lineHeight: '2.813rem',
                color: 'black',
                marginBottom: '1.375rem',
                marginTop: '3.125rem',
              }}
            >
              {props.t('INVOICE')}
            </Label>
          </div>
        </Row>
        <Row className='row-margin-fix'>
          <div
            className={'col-print-6'}
            style={{
              display: 'flex',
              flexDirection: 'row',
              padding: '0rem',
              marginBottom: '0.406rem',
            }}
          >
            <Label
              style={{
                fontFamily: 'Poppins',
                fontWeight: '500',
                fontSize: '1rem',
                letterSpacing: '0.113rem',
                lineHeight: '1.438rem',
                color: 'black',
                marginBottom: '0rem',
              }}
            >
              {props.t('BILL TO')}
            </Label>
          </div>
          <div
            className={'col-print-6'}
            style={{
              display: 'flex',
              flexDirection: 'row',
              padding: '0rem',
              justifyContent: 'flex-end',
            }}
          >
            <Label
              style={{
                fontFamily: 'Poppins',
                fontWeight: '400',
                fontSize: '1rem',
                letterSpacing: '0.113rem',
                lineHeight: '1.438rem',
                color: 'black',
                marginBottom: '0rem',
              }}
            >
              {props.t('INVOICE #')}
            </Label>
            <Label
              style={{
                fontFamily: 'Poppins',
                fontWeight: '400',
                fontSize: '0.875rem',
                letterSpacing: '0.099rem',
                lineHeight: '1.438rem',
                color: 'black',
                marginBottom: '0rem',
                marginLeft: '0.5rem',
                paddingRight: '1.25rem',
              }}
            >
              {props.t('HERE')}
            </Label>
          </div>
        </Row>
        <Row className='row-margin-fix'>
          <div
            className={'col-print-6'}
            style={{
              display: 'flex',
              flexDirection: 'column',
              padding: '0rem',
            }}
          >
            <Label
              style={{
                fontFamily: 'Poppins',
                fontWeight: '400',
                fontSize: '0.875rem',
                letterSpacing: '0.099rem',
                lineHeight: '1.438rem',
                color: 'black',
                marginBottom: '0rem',
              }}
            >
              {props.t('DEALERSHIP LINE 1')}
            </Label>
            <Label
              style={{
                fontFamily: 'Poppins',
                fontWeight: '400',
                fontSize: '0.875rem',
                letterSpacing: '0.099rem',
                lineHeight: '1.438rem',
                color: 'black',
                marginBottom: '0rem',
              }}
            >
              {props.t('DEALERSHIP LINE 2')}
            </Label>
            <Label
              style={{
                fontFamily: 'Poppins',
                fontWeight: '400',
                fontSize: '0.875rem',
                letterSpacing: '0.099rem',
                lineHeight: '1.438rem',
                color: 'black',
                marginBottom: '0rem',
              }}
            >
              {props.t('DEALERSHIP LINE 3')}
            </Label>
          </div>
          <div
            className={'col-print-6'}
            style={{
              display: 'flex',
              flexDirection: 'row',
              padding: '0rem',
              justifyContent: 'flex-end',
            }}
          >
            <Label
              style={{
                fontFamily: 'Poppins',
                fontWeight: '400',
                fontSize: '1rem',
                letterSpacing: '0.113rem',
                lineHeight: '1.438rem',
                color: 'black',
                marginBottom: '0rem',
              }}
            >
              {props.t('DATE')}
            </Label>
            <Label
              style={{
                fontFamily: 'Poppins',
                fontWeight: '400',
                fontSize: '0.875rem',
                letterSpacing: '0.099rem',
                lineHeight: '1.438rem',
                color: 'black',
                marginBottom: '0rem',
                marginLeft: '0.5rem',
                paddingRight: '1.25rem',
              }}
            >
              {props.t('00/00/0000')}
            </Label>
          </div>
        </Row>
        <Row className='row-margin-fix'>
          <div
            className={'col-print-12'}
            style={{
              display: 'flex',
              flexDirection: 'column',
              padding: '0rem',
            }}
          >
            <div
              className='align-self-center'
              style={{
                alignSelf: 'center',
                height: '0.063rem',
                width: '100%',
                marginTop: '2rem',
                marginBottom: '3.375rem',
                background: '#E6E6E6',
              }}
            ></div>
          </div>
        </Row>

        {/* SERVICE TABLE */}
        <Row className='row-margin-fix'>
          <div
            className={'col-print-12'}
            style={{
              display: 'flex',
              flexDirection: 'column',
              padding: '0rem',
            }}
          >
            <Table borderless style={{ marginBottom: '0.438rem' }}>
              <thead>
                <tr>
                  <th className='invoice-header'>{props.t('SERVICE')}</th>
                  <th
                    className='invoice-header'
                    style={{ textAlign: 'center' }}
                  >
                    {props.t('QTY')}
                  </th>
                  <th className='invoice-header' style={{ textAlign: 'right' }}>
                    {props.t('RATE')}
                  </th>
                  <th className='invoice-header' style={{ textAlign: 'right' }}>
                    {props.t('AMOUNT')}
                  </th>
                </tr>
              </thead>
              <tbody>
                {props.serviceQTY.map((item, idx) => {
                  return (
                    <>
                      <tr key={idx}>
                        <td className='invoice-cells'>{item.name}</td>
                        <td
                          className='invoice-cells'
                          style={{ textAlign: 'center' }}
                        >
                          {item.quantity}
                        </td>
                        <td
                          className='invoice-cells'
                          style={{ textAlign: 'right' }}
                        >
                          <NumberFormat
                            value={item.cost}
                            displayType={'text'}
                            fixedDecimalScale={true}
                            decimalScale={2}
                            thousandSeparator={true}
                            prefix={'$'}
                          />
                        </td>
                        <td
                          className='invoice-cells'
                          style={{ textAlign: 'right' }}
                        >
                          <NumberFormat
                            value={item.cost * item.quantity}
                            displayType={'text'}
                            fixedDecimalScale={true}
                            decimalScale={2}
                            thousandSeparator={true}
                            prefix={'$'}
                          />
                        </td>
                      </tr>
                    </>
                  )
                })}
              </tbody>
            </Table>
          </div>
        </Row>
        <Row
          style={{
            marginBottom: '25%',
            marginTop: '0rem',
            marginLeft: '0rem',
            marginRight: '0rem',
          }}
        >
          <div className={'col-print-6'} style={{ padding: '0rem' }}></div>
          <div
            className={'col-print-6 text-end'}
            style={{ paddingLeft: '0rem', paddingRight: '0rem' }}
            //className='text-end'
          >
            <Label
              style={{
                fontSize: '1rem',
                letterSpacing: '0.113rem',
                lineHeight: '1.438rem',
                color: '#000000',
                fontWeight: '200',
                marginBottom: '0rem',
                paddingTop: '1.874rem',
              }}
            >
              {props.t('BALANCE DUE')}
            </Label>
            <Label
              style={{
                fontSize: '1.5rem',
                letterSpacing: '0.169rem',
                lineHeight: '2.063rem',
                color: '#000000',
                fontWeight: '500',
                paddingTop: '1.563rem',
                paddingRight: '1.25rem',
                marginLeft: '1.25rem',
                marginBottom: '0rem',
                textAlign: 'right',
              }}
            >
              <NumberFormat
                value={calcTotalCost()}
                displayType={'text'}
                fixedDecimalScale={true}
                decimalScale={2}
                thousandSeparator={true}
                prefix={'$'}
              />
            </Label>
          </div>
        </Row>

        {/* Display All Details For Selected Orders */}
        <Row className='row-margin-fix' style={{ pageBreakBefore: 'always' }}>
          <div className={'col-print-12'}>
            {props.orders.map((item) => {
              return (
                <>
                  <Row style={{ pageBreakBefore: 'always' }}>
                    <div className={'col-print-7'}>
                      <Row>
                        <div className={'col-print-12'}>
                          {item && item.vehicle ? (
                            <Label
                              style={{
                                fontFamily: 'Poppins',
                                fontWeight: '600',
                                fontSize: '1.5rem',
                                letterSpacing: '0.169rem',
                                color: '#333333',
                                marginTop: '2.688',
                                marginBottom: '0rem',
                              }}
                            >
                              {item.vehicle.year} {item.vehicle.make}
                            </Label>
                          ) : (
                            <></>
                          )}
                        </div>
                      </Row>
                      <Row>
                        <div className={'col-print-12'}>
                          <Label
                            style={{
                              fontFamily: 'Poppins',
                              fontWeight: '600',
                              fontSize: '1.5rem',
                              letterSpacing: '0.169rem',
                              color: '#333333',
                              marginBottom: '0rem',
                            }}
                          >
                            {item == null ? null : item.vehicle.model}
                          </Label>
                        </div>
                      </Row>
                      <Row>
                        <div className={'col-print-12'}>
                          <Label
                            style={{
                              fontFamily: 'Poppins',
                              fontWeight: '200',
                              fontSize: '1.125rem',
                              letterSpacing: '0.127rem',
                              color: '#333333',
                              marginTop: '1rem',
                              marginBottom: '0rem',
                            }}
                          >
                            {item == null ? null : props.t(item.vehicle.color)}
                          </Label>
                        </div>
                      </Row>
                      <Row>
                        <div className={'col-print-10'}>
                          <Table striped style={{ marginTop: '2.813rem' }}>
                            <tbody>
                              <tr style={{ backgroundColor: '#EEEEEE' }}>
                                <td className='thin-table-cell'>
                                  {props.t('VIN')}
                                </td>
                                <td
                                  className='thin-table-cell'
                                  style={{
                                    fontWeight: '200',
                                    textAlign: 'right',
                                  }}
                                >
                                  {item == null || item.vehicle.data_key == ''
                                    ? null
                                    : item.vehicle.data_key}
                                </td>
                              </tr>
                              <tr>
                                <td className='thin-table-cell'>
                                  {props.t('Stock Number')}
                                </td>
                                <td
                                  className='thin-table-cell'
                                  style={{
                                    fontWeight: '200',
                                    textAlign: 'right',
                                  }}
                                >
                                  {item == null || item.stock == ''
                                    ? props.t('N/A')
                                    : item.stock}
                                </td>
                              </tr>
                              <tr style={{ backgroundColor: '#EEEEEE' }}>
                                <td className='thin-table-cell'>
                                  {props.t('PO Number')}
                                </td>
                                <td
                                  className='thin-table-cell'
                                  style={{
                                    fontWeight: '200',
                                    textAlign: 'right',
                                  }}
                                >
                                  {item == null || item.po_number == ''
                                    ? props.t('N/A')
                                    : item.po_number}
                                </td>
                              </tr>
                              <tr>
                                <td className='thin-table-cell'>
                                  {props.t('Customer Tag')}
                                </td>
                                <td
                                  className='thin-table-cell'
                                  style={{
                                    fontWeight: '200',
                                    textAlign: 'right',
                                  }}
                                >
                                  {item == null || item.customer_tag == ''
                                    ? props.t('N/A')
                                    : item.customer_tag}
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </Row>
                    </div>
                    <div className={'col-print-5'}>
                      {displayProfileImage(item)}
                    </div>
                  </Row>

                  <Row>
                    <div className={'col-print-7'}>
                      <Label
                        style={{
                          fontFamily: 'Poppins',
                          fontWeight: '200',
                          fontSize: '1.125rem',
                          letterSpacing: '0.127rem',
                          lineHeight: '1.563rem',
                          color: '#000000',
                          marginTop: '2.688rem',
                        }}
                      >
                        {props.t('SERVICES REQUESTED')}
                      </Label>
                    </div>
                  </Row>
                  <Row>
                    <div className={'col-print-11'}>
                      <Table style={{ marginTop: '1.438rem' }}>
                        <tbody>
                          {item == null ? null : displayOrderServices(item)}
                        </tbody>
                      </Table>
                    </div>
                  </Row>
                  <Row style={{ borderBottom: '1px solid #DFDFDF' }}>
                    <div className={'col-print-7'}>
                      <div style={{ marginTop: '1.438rem' }}>
                        <Label
                          style={{
                            fontSize: '0.875rem',
                            letterSpacing: '0.099rem',
                            lineHeight: '1.25rem',
                            color: '#000000',
                            fontWeight: '400',
                            marginLeft: '1.25rem',
                            marginBottom: '0rem',
                          }}
                        >
                          {item == null
                            ? null
                            : item.note == null
                            ? null
                            : props.t('Order Note')}
                        </Label>
                      </div>
                      <div
                        style={{
                          paddingTop: '0.469rem',
                          paddingBottom: '3.125rem',
                        }}
                      >
                        <Label
                          style={{
                            fontSize: '0.875rem',
                            letterSpacing: '0.099rem',
                            lineHeight: '1.438rem',
                            color: '#000000',
                            fontWeight: '200',
                            marginLeft: '1.25rem',
                            marginBottom: '0rem',
                          }}
                        >
                          {item == null
                            ? null
                            : item.note == null
                            ? null
                            : item.note}
                          {/* The note that was attached to order when order was intially
                created will be displayed here if it exists. */}
                        </Label>
                      </div>
                    </div>
                    <div className={'col-print-4 text-end'}>
                      <Label
                        style={{
                          fontSize: '1rem',
                          letterSpacing: '0.113rem',
                          lineHeight: '1.438rem',
                          color: '#000000',
                          fontWeight: '200',
                          marginBottom: '0rem',
                          paddingTop: '1.874rem',
                        }}
                      >
                        {props.t('TOTAL')}
                      </Label>
                      <Label
                        style={{
                          fontSize: '1.5rem',
                          letterSpacing: '0.169rem',
                          lineHeight: '2.063rem',
                          color: '#000000',
                          fontWeight: '500',
                          paddingTop: '1.563rem',
                          paddingRight: '1.25rem',
                          marginLeft: '1.25rem',
                          marginBottom: '0rem',
                          textAlign: 'right',
                        }}
                      >
                        ${item == null ? null : calcOrderTotal(item)}
                        {/* $100.00 */}
                      </Label>
                    </div>
                  </Row>
                  <Row
                    style={{
                      marginBottom: '25%',
                      marginTop: '0rem',
                      marginLeft: '0rem',
                      marginRight: '0rem',
                    }}
                  >
                    <div
                      className={'col-print-6'}
                      style={{
                        borderBottom: '1px solid #DFDFDF',
                        paddingBottom: '3.125rem',
                      }}
                    >
                      <Table borderless style={{ marginTop: '2.813rem' }}>
                        <tbody>
                          <tr>
                            <td
                              className='time-stamp-info'
                              style={{
                                fontWeight: '500',
                                // paddingLeft: '3.75rem',
                                paddingRight: '0rem',
                                //textAlign: 'right',
                              }}
                            >
                              {props.t('SUBMITTED')}
                            </td>
                            <td
                              className='time-stamp-info'
                              style={{
                                fontWeight: '200',
                                paddingRight: '0rem',
                              }}
                            >
                              {item == null
                                ? null
                                : formatTime(item.original_created_at)}
                              {/* 10:31 AM 08/22/21 */}
                            </td>
                          </tr>
                          <tr>
                            <td
                              className='time-stamp-info'
                              style={{
                                fontWeight: '500',
                                paddingTop: '0.563rem',
                                // paddingLeft: '3.75rem',
                                paddingRight: '0rem',
                                //textAlign: 'right',
                              }}
                            >
                              {props.t('CREATED BY')}
                            </td>
                            <td
                              className='time-stamp-info'
                              style={{
                                fontWeight: '200',
                                paddingTop: '0.563rem',
                                paddingRight: '0rem',
                              }}
                            >
                              {item == null
                                ? null
                                : fullName(
                                    item.created_by.first_name,
                                    item.created_by.last_name
                                  )}
                              {/* Piers Babu */}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                    <div
                      className={'col-print-6'}
                      style={{
                        borderBottom: '1px solid #DFDFDF',
                        paddingBottom: '3.125rem',
                      }}
                    >
                      <Table borderless style={{ marginTop: '2.813rem' }}>
                        <tbody>
                          <tr>
                            <td
                              className='time-stamp-info'
                              style={{ fontWeight: '500' }}
                            >
                              {props.t('REQUESTED FOR')}
                            </td>
                            <td
                              className='time-stamp-info'
                              style={{
                                fontWeight: '200',
                                //textAlign: 'right',
                                paddingRight: '0rem',
                              }}
                            >
                              {item == null
                                ? null
                                : formatTime(item.pickup_date_time)}
                              {/* 4:00 AM 08/23/21 */}
                            </td>
                          </tr>
                          <tr>
                            <td
                              className='time-stamp-info'
                              style={{
                                fontWeight: '500',
                                //paddingLeft: '3.75rem',
                                paddingTop: '0.563rem',
                              }}
                            >
                              {props.t('COMPLETED')}
                            </td>
                            <td
                              className='time-stamp-info'
                              style={{
                                fontWeight: '200',
                                paddingTop: '0.563rem',
                                //textAlign: 'right',
                                paddingRight: '0rem',
                              }}
                            >
                              {item == null
                                ? null
                                : formatTime(item.data_timestamp)}
                              {/* 4:00 AM 08/22/21 */}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </Row>
                </>
              )
            })}
          </div>
        </Row>
      </div>
    </Modal>
  )
}

FinanceInvoice.propTypes = {
  t: PropTypes.any,
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  showModal: PropTypes.func,
  submitBtnTitle: PropTypes.string,
  submitBtnDisabled: PropTypes.bool,
  secondBtnTitle: PropTypes.string,
  loggedInUsername: PropTypes.string,
  loggedInUserRole: PropTypes.string,
  loggedInUserType: PropTypes.string,
  orders: PropTypes.any,
  serviceQTY: PropTypes.any,
  org: PropTypes.string,
  tenant: PropTypes.string,
  userFeat: PropTypes.any,
}

const mapStateToProps = ({ login }) => ({
  loggedInUsername: login.username,
  loggedInUserRole: login.role,
  loggedInUserType: login.type,
  tenant: login.tenant,
  org: login.org,
  userFeat: login.features,
})

const mapDispatchToProps = (dispatch) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(FinanceInvoice)))
