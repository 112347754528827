/* eslint-disable no-unused-vars */
import {
  UPDATE_SELECTED_SERVICE,
  UPDATE_NOTE_FOR_SERVICE,
  SUBMIT_SERVICE_ORDER,
  SUBMIT_SERVICE_ORDER_SUCCESS,
  SUBMIT_SERVICE_ORDER_FAIL,
  ADD_CUSTOM_SERVICE_TO_ORDER,
  TOGGLE_CUSTOM_SERVICE_TO_ORDER,
} from './actionTypes'

const initialState = {
  selectedServices: [],
  customServices: [],
  note: '',
}

function getRandomInt(max) {
  return Math.floor(Math.random() * max)
}

const service = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_CUSTOM_SERVICE_TO_ORDER:
      console.log(
        'reducer::ADD_CUSTOM_SERVICE_TO_ORDER action.payload=' +
          JSON.stringify(action.payload)
      )

      var updatedItem = state.customServices.map((item) => {
        if (item.id == action.payload.customServiceId) {
          item.selected = !item.selected
        }
        return item
      })

      state = {
        ...state,
        customServices: updatedItem,
      }
      break

    case ADD_CUSTOM_SERVICE_TO_ORDER:
      console.log(
        'reducer::ADD_CUSTOM_SERVICE_TO_ORDER action.payload=' +
          JSON.stringify(action.payload)
      )
      var updated = [
        ...state.customServices,
        {
          name: action.payload.customService.name,
          description: action.payload.customService.description,
          retailCost: parseFloat(action.payload.customService.price),
          id: getRandomInt(9999999),
          selected: true,
        },
      ]

      state = {
        ...state,
        customServices: updated,
      }
      break

    case SUBMIT_SERVICE_ORDER:
      console.log(
        'reducer::SUBMIT_SERVICE_ORDER action.payload=' +
          JSON.stringify(action.payload)
      )

      state = {
        ...state,
        isLoading: true,
        error: null,
      }
      break

    case SUBMIT_SERVICE_ORDER_SUCCESS:
      console.log(
        'reducer::SUBMIT_SERVICE_ORDER_SUCCESS action.payload=' +
          JSON.stringify(action.payload)
      )

      console.log(action.payload.uuid)

      state = {
        ...state,
        isLoading: false,
        error: null,
      }
      break

    case SUBMIT_SERVICE_ORDER_FAIL:
      console.log(
        'reducer::SUBMIT_SERVICE_ORDER_FAIL action.payload=' + action.payload
      )

      state = {
        ...state,
        isLoading: false,
        error:
          action.payload.error.data &&
          action.payload.error.data.body &&
          action.payload.error.data.body.error
            ? action.payload.error.data.body.error
            : { code: '99999', description: 'Generic Error' },
      }
      break

    case UPDATE_SELECTED_SERVICE:
      console.log(
        'reducer::UPDATE_SELECTED_SERVICE action.payload=' + action.payload
      )

      var newSelectedService = []
      if (state.selectedServices.includes(action.payload.selectedService)) {
        newSelectedService = state.selectedServices.filter(
          (item) => item.data_key != action.payload.selectedService.data_key
        )
      } else {
        newSelectedService = [
          ...state.selectedServices,
          action.payload.selectedService,
        ]
      }
      state = { ...state, selectedServices: newSelectedService }
      break

    case UPDATE_NOTE_FOR_SERVICE:
      console.log(
        'reducer::UPDATE_NOTE_FOR_SERVICE action.payload=' + action.payload
      )
      state = {
        ...state,
        note: action.payload,
      }
      break

    default:
      break
  }
  return state
}

export default service
