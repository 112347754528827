import {
  GET_TENANT_DATA,
  GET_TENANT_DATA_SUCCESS,
  GET_TENANT_DATA_FAIL,
  UPDATE_TENANT_DATA,
  UPDATE_TENANT_DATA_SUCCESS,
  UPDATE_TENANT_DATA_FAIL,
  ADD_TENANT_DATA,
  ADD_TENANT_DATA_SUCCESS,
  ADD_TENANT_DATA_FAIL,
  ADD_SERVICE_CATALOG_DATA,
  ADD_SERVICE_CATALOG_DATA_SUCCESS,
  ADD_SERVICE_CATALOG_DATA_FAIL,
  UPDATE_SERVICE_CATALOG_DATA,
  UPDATE_SERVICE_CATALOG_DATA_SUCCESS,
  UPDATE_SERVICE_CATALOG_DATA_FAIL,
  DELETE_SERVICE_CATALOG_DATA,
  DELETE_SERVICE_CATALOG_DATA_SUCCESS,
  DELETE_SERVICE_CATALOG_DATA_FAIL,
} from './actionTypes'

const initialState = {
  serviceUpdated: false,
  tenantUpdated: false,
  isLoading: false,
  logo: '',
  tenant_type: '',
  name: '',
  address_line_one: '',
  city: '',
  zip_code: '',
  state: '',
  targetTenant: {},
  department: [],
  service_catalog: [],
  error: {},
}

const tenant = (state = initialState, action) => {
  switch (action.type) {
    case ADD_SERVICE_CATALOG_DATA:
      console.log(
        'reducer::ADD_SERVICE_CATALOG_DATA action.payload=' + action.payload
      )

      state = {
        ...state,
        isLoading: true,
      }
      break

    case ADD_SERVICE_CATALOG_DATA_SUCCESS:
      console.log(
        'reducer::ADD_SERVICE_CATALOG_DATA_SUCCESS action.payload=' +
          action.payload
      )

      console.log(action.payload.serviceCatalogData)

      state = {
        ...state,
        isLoading: false,
        serviceUpdated: false, // use it later
        service_catalog: [
          ...state.service_catalog,
          action.payload.serviceCatalogData,
        ],
      }
      break

    case ADD_SERVICE_CATALOG_DATA_FAIL:
      console.log(
        'reducer::ADD_SERVICE_CATALOG_DATA_FAIL action.payload=' +
          action.payload
      )

      state = {
        ...state,
        isLoading: false,
        error: action.payload,
      }
      break

    case UPDATE_SERVICE_CATALOG_DATA:
      console.log(
        'reducer::UPDATE_SERVICE_CATALOG_DATA action.payload=' + action.payload
      )

      state = {
        ...state,
        isLoading: true,
      }
      break

    case UPDATE_SERVICE_CATALOG_DATA_SUCCESS:
      console.log(
        'reducer::UPDATE_SERVICE_CATALOG_DATA_SUCCESS action.payload=' +
          action.payload
      )

      console.log(action.payload.serviceCatalogData)

      var serviceCatalogs = state.service_catalog.filter(
        (item) => item.data_key != action.payload.refKey.uuid
      )

      var newServiceCatalog = [
        ...serviceCatalogs,
        action.payload.serviceCatalogData,
      ]
      console.log(newServiceCatalog)
      state = {
        ...state,
        isLoading: false,
        serviceUpdated: false, // use it later
        service_catalog: newServiceCatalog,
      }
      break

    case UPDATE_SERVICE_CATALOG_DATA_FAIL:
      console.log(
        'reducer::UPDATE_SERVICE_CATALOG_DATA_FAIL action.payload=' +
          action.payload
      )

      state = {
        ...state,
        isLoading: false,
        error: action.payload,
      }
      break

    case DELETE_SERVICE_CATALOG_DATA:
      console.log(
        'reducer::DELETE_SERVICE_CATALOG_DATA action.payload=' + action.payload
      )

      state = {
        ...state,
        isLoading: true,
      }
      break

    case DELETE_SERVICE_CATALOG_DATA_SUCCESS:
      console.log(
        'reducer::DELETE_SERVICE_CATALOG_DATA_SUCCESS action.payload=' +
          action.payload
      )

      console.log(action.payload.serviceCatalogData)

      var withoutDeletedUser = state.service_catalog.filter(
        (item) => item.data_key != action.payload.refKey.uuid
      )

      state = {
        ...state,
        isLoading: false,
        serviceUpdated: false, // use it later
        service_catalog: withoutDeletedUser,
      }
      break

    case DELETE_SERVICE_CATALOG_DATA_FAIL:
      console.log(
        'reducer::UPDATE_SERVICE_CATALOG_DATA_FAIL action.payload=' +
          action.payload
      )

      state = {
        ...state,
        isLoading: false,
        error: action.payload,
      }
      break

    case GET_TENANT_DATA:
      console.log('reducer::GET_TENANT_DATA action.payload=' + action.payload)

      state = {
        ...state,
        isLoading: true,
      }
      break

    case GET_TENANT_DATA_SUCCESS:
      console.log(
        'reducer::GET_TENANT_DATA_SUCCESS action.payload=' + action.payload
      )

      state = {
        isLoading: false,
        serviceUpdated: false,
        tenantUpdated: false,
        logo: action.payload.tenant['logo'],
        name: action.payload.tenant['name'],
        address_line_one: action.payload.tenant['address_line_one'],
        city: action.payload.tenant['city'],
        zip_code: action.payload.tenant['zip_code'],
        state: action.payload.tenant['state'],
        department: action.payload.tenant['department'],
        service_catalog: action.payload.tenant['service_catalog'],
        targetTenant: action.payload.tenant['detailshop'].data_key
          ? action.payload.tenant['detailshop']
          : action.payload.tenant['dealership'],
        tenant_type: action.payload.tenant['tenant_type'],
      }
      break

    case GET_TENANT_DATA_FAIL:
      console.log(
        'reducer::GET_TENANT_DATA_FAIL action.payload=' + action.payload
      )

      state = {
        ...state,
        isLoading: false,
        error: action.payload,
      }
      break

    case UPDATE_TENANT_DATA:
      console.log(
        'reducer::UPDATE_TENANT_DATA action.payload=' + action.payload
      )

      state = {
        ...state,
        isLoading: true,
      }
      break

    case UPDATE_TENANT_DATA_SUCCESS:
      console.log(
        'reducer::UPDATE_TENANT_DATA_SUCCESS action.payload=' + action.payload
      )

      state = {
        ...state,
        isLoading: false,
        tenantUpdated: true,
      }

      break

    case UPDATE_TENANT_DATA_FAIL:
      console.log(
        'reducer::UPDATE_TENANT_DATA_SUCCESS action.payload=' + action.payload
      )
      state = {
        ...state,
        isLoading: false,
        error: action.payload,
      }
      break

    case ADD_TENANT_DATA:
      console.log('reducer::ADD_TENANT_DATA action.payload=' + action.payload)

      state = {
        ...state,
        isLoading: true,
      }
      break

    case ADD_TENANT_DATA_SUCCESS:
      console.log(
        'reducer::ADD_TENANT_DATA_SUCCESS action.payload=' + action.payload
      )
      state = {
        ...state,
        isLoading: false,
      }
      break

    case ADD_TENANT_DATA_FAIL:
      console.log(
        'reducer::ADD_TENANT_DATA_FAIL action.payload=' + action.payload
      )
      state = {
        ...state,
        isLoading: false,
        error: action.payload,
      }
      break

    default:
      state = { ...state }
      break
  }
  return state
}

export default tenant
