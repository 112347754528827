import { GET_DASHBOARD_SUCCESS, GET_DASHBOARD_FAIL } from './actionTypes'

const initialState = {
  summary: {},
  popularServices: [],
  workflows: {},
  updates: [],
  topPerformers: [],
  error: {},
}

const dashboard = (state = initialState, action) => {
  switch (action.type) {
    case GET_DASHBOARD_SUCCESS:
      console.log(
        'reducer::GET_DASHBOARD_SUCCESS action.payload=' + action.payload
      )

      state = {
        ...state,
        summary: action.payload.summary,
        popularServices: action.payload.popularServices,
        workflows: action.payload.workFlows,
        updates: action.payload.updates,
        topPerformers: action.payload.topPerformers,
      }
      break

    case GET_DASHBOARD_FAIL:
      console.log(
        'reducer::GET_DASHBOARD_FAIL action.payload=' + action.payload
      )

      state = {
        ...state,
        error: action.payload,
      }
      break

    default:
      break
  }
  return state
}

export default dashboard
