/* eslint-disable no-unused-vars */
import {
  GET_FINANCE_DATA,
  GET_FINANCE_DATA_SUCCESS,
  GET_FINANCE_DATA_FAIL,
  FINANCE_FILTER_SEARCH_BY_LAST_6_VIN,
  FINANCE_SORT_BY_COMPLETED_ON,
  FINANCE_SORT_BY_PRICE,
} from './actionTypes'

export const sortFinanceDataByCompletedOn = (byAscending) => {
  console.log('actions::sortFinanceDataByCompletedOn()')
  return {
    type: FINANCE_SORT_BY_COMPLETED_ON,
    payload: { byAscending },
  }
}

export const sortFinanceDataByPrice = (byAscending) => {
  console.log('actions::sortFinanceDataByPrice()')
  return {
    type: FINANCE_SORT_BY_PRICE,
    payload: { byAscending },
  }
}

export const filterFinanceDataByLast6Vin = (lastVin) => {
  console.log('actions::filterFinanceDataByLast6Vin()')
  return {
    type: FINANCE_FILTER_SEARCH_BY_LAST_6_VIN,
    payload: { lastVin },
  }
}

export const getFinanceData = (org, tenant, from, to) => {
  console.log('actions::getFinanceData()')
  return {
    type: GET_FINANCE_DATA,
    payload: { org, tenant, from, to },
  }
}

export const getFinanceDataSuccess = (orders) => {
  console.log('actions::getAllOrdersSuccess() orders=' + orders)
  return {
    type: GET_FINANCE_DATA_SUCCESS,
    payload: orders,
  }
}

export const getFinanceDataFail = (error) => {
  console.log('actions::getAllOrdersSuccess() error=' + error)
  return {
    type: GET_FINANCE_DATA_FAIL,
    payload: error,
  }
}
