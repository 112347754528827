export const GET_FINANCE_DATA = 'GET_FINANCE_DATA'
export const GET_FINANCE_DATA_SUCCESS = 'GET_FINANCE_DATA_SUCCESS'
export const GET_FINANCE_DATA_FAIL = 'GET_FINANCE_DATA_FAIL'

export const FINANCE_FILTER_SEARCH_BY_LAST_6_VIN =
  'FINANCE_FILTER_SEARCH_BY_LAST_6_VIN'
export const FINANCE_FILTER_SEARCH_BY_COMPLETED_ON =
  'FINANCE_FILTER_SEARCH_BY_COMPLETED_ON'
export const FINANCE_SORT_BY_PRICE = 'FINANCE_SORT_BY_PRICE'

export const FINANCE_SORT_BY_COMPLETED_ON = 'FINANCE_SORT_BY_COMPLETED_ON'
export const FINANCE_FILTER_SEARCH_BY_COMPLETED_ON_SUCCESS =
  'FINANCE_FILTER_SEARCH_BY_COMPLETED_ON_SUCCESS'
export const FINANCE_FILTER_SEARCH_BY_COMPLETED_ON_FAIL =
  'FINANCE_FILTER_SEARCH_BY_COMPLETED_ON_FAIL'
