import {
  ADD_DEVICE_FOR_USER_SUCCESS,
  ADD_DEVICE_FOR_USER_FAIL,
  GET_USERS_DATA_SUCCESS,
  GET_USERS_DATA_FAIL,
  UPDATE_USER_DATA_SUCCESS,
  UPDATE_USER_DATA_FAIL,
  ADD_USER_DATA_SUCCESS,
  ADD_USER_DATA_FAIL,
  DELETE_USER_DATA_SUCCESS,
  DELETE_USER_DATA_FAIL,
  SORT_USERS_DATA_BY_FIRST_NAME,
  SORT_USERS_DATA_BY_LAST_NAME,
  SORT_USERS_DATA_BY_DEPARTMENT,
  SORT_USERS_DATA_BY_ACCOUNT_TYPE,
} from './actionTypes'

const initialState = {
  data: [],
  error: {},
}

const user = (state = initialState, action) => {
  switch (action.type) {
    case GET_USERS_DATA_SUCCESS:
      console.log(
        'reducer::GET_USERS_DATA_SUCCESS action.payload=' + action.payload
      )
      state = {
        ...state,
        data: action.payload.employees,
      }
      break

    case GET_USERS_DATA_FAIL:
      console.log(
        'reducer::GET_USERS_DATA_FAIL action.payload=' + action.payload
      )

      state = {
        ...state,
        error: action.payload,
      }
      break

    case UPDATE_USER_DATA_SUCCESS:
      console.log(
        'reducer::UPDATE_USER_DATA_SUCCESS action.payload=' + action.payload
      )

      var user = state.data.filter(
        (item) => item.email != action.payload.userData.email
      )

      var newUsers = [...user, action.payload.userData]

      state = {
        ...state,
        data: newUsers,
      }
      break

    case UPDATE_USER_DATA_FAIL:
      console.log(
        'reducer::UPDATE_USER_DATA_FAIL action.payload=' + action.payload
      )

      state = {
        ...state,
        error: action.payload,
      }
      break

    case ADD_DEVICE_FOR_USER_SUCCESS:
      console.log(
        'reducer::ADD_DEVICE_FOR_USER_SUCCESS action.payload=' + action.payload
      )

      state = {
        ...state,
      }
      break

    case ADD_DEVICE_FOR_USER_FAIL:
      console.log(
        'reducer::ADD_DEVICE_FOR_USER_FAIL action.payload=' + action.payload
      )

      state = {
        ...state,
      }
      break

    case ADD_USER_DATA_SUCCESS:
      console.log(
        'reducer::ADD_USER_DATA_SUCCESS action.payload=' + action.payload
      )

      var updateUsers = [...state.data, action.payload.userData]

      state = {
        ...state,
        data: updateUsers,
      }
      break

    case ADD_USER_DATA_FAIL:
      console.log(
        'reducer::ADD_USER_DATA_FAIL action.payload=' + action.payload
      )

      state = {
        ...state,
        error: action.payload,
      }
      break

    case DELETE_USER_DATA_SUCCESS:
      console.log(
        'reducer::DELETE_USER_DATA_SUCCESS action.payload=' + action.payload
      )
      var withoutDeletedUser = state.data.filter(
        (item) => item.username != action.payload.username
      )

      state = {
        ...state,
        data: withoutDeletedUser,
      }
      break

    case DELETE_USER_DATA_FAIL:
      console.log(
        'reducer::DELETE_USER_DATA_FAIL action.payload=' + action.payload
      )

      state = {
        ...state,
        error: action.payload,
      }
      break

    case SORT_USERS_DATA_BY_FIRST_NAME:
      console.log(
        'reducer::SORT_USERS_DATA_BY_FIRST_NAME action.payload=' +
          action.payload
      )

      var sortedByFirstNameData = [...state.data]

      sortedByFirstNameData.sort((a, b) =>
        a.status > b.status
          ? action.payload
            ? 1
            : -1
          : action.payload
          ? -1
          : 1
      )

      state = {
        ...state,
        data: sortedByFirstNameData,
      }

      break

    case SORT_USERS_DATA_BY_LAST_NAME:
      console.log(
        'reducer::SORT_USERS_DATA_BY_LAST_NAME action.payload=' + action.payload
      )

      var sortedByLastNameData = [...state.data]

      sortedByLastNameData.sort((a, b) =>
        a.status > b.status
          ? action.payload
            ? 1
            : -1
          : action.payload
          ? -1
          : 1
      )

      state = {
        ...state,
        data: sortedByLastNameData,
      }

      break

    case SORT_USERS_DATA_BY_DEPARTMENT:
      console.log(
        'reducer::SORT_USERS_DATA_BY_DEPARTMENT action.payload=' +
          action.payload
      )

      var sortedByDepartmentData = [...state.data]

      sortedByDepartmentData.sort((a, b) =>
        a.status > b.status
          ? action.payload
            ? 1
            : -1
          : action.payload
          ? -1
          : 1
      )

      state = {
        ...state,
        data: sortedByDepartmentData,
      }

      break

    case SORT_USERS_DATA_BY_ACCOUNT_TYPE:
      console.log(
        'reducer::SORT_USERS_DATA_BY_ACCOUNT_TYPE action.payload=' +
          action.payload
      )

      var sortedByAccountTypeData = [...state.data]

      sortedByAccountTypeData.sort((a, b) =>
        a.status > b.status
          ? action.payload
            ? 1
            : -1
          : action.payload
          ? -1
          : 1
      )

      state = {
        ...state,
        data: sortedByAccountTypeData,
      }

      break

    default:
      break
  }
  return state
}

export default user
