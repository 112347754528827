import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { Row, Col, Button, Label } from 'reactstrap'

// Styling
import '../EditUser/edituser.scss'

//i18n
import { withTranslation } from 'react-i18next'

import { connect } from 'react-redux'

import { updateUserData } from 'store/actions'
import { useForm, FormProvider } from 'react-hook-form'

import { sendPageViewEvent } from '../../../../firebase'
import { ROLES } from 'common/contants'
import GlistenDropdown from 'components/GlistenDropdown'
import { formatPhoneNumber } from 'helpers/util'

const EditUser = (props) => {
  const methods = useForm()

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = methods

  useEffect(() => {
    sendPageViewEvent('Delete Edit Info')
  }, [])

  const { onUpdateUserData } = props

  const onSubmit = (evt) => {
    onUpdateUserData(props.org, props.tenant, {
      first_name: evt.firstname,
      last_name: evt.lastname,
      email: props.emailForEdit,
      phone_number: evt.phone,
      department: evt.department,
      role: evt.role,
      username: props.usernameForEdit,
    })
    props.showModal(false)
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} className='form-horizontal'>
        <div
          className='modal-body glisten-modal-body'
          style={{ paddingTop: '1.188rem' }}
        >
          <Row>
            <Col lg='5'>
              <Label
                className={
                  watch('firstname') && watch('firstname').length > 0
                    ? 'glisten-textfield-label'
                    : 'glisten-textfield-label glisten-textfield-label-hidden'
                }
              >
                {props.t('First Name')}
              </Label>
              <input
                id='firstname'
                name='firstname'
                label={props.t('First Name')}
                className='form-control glisten-textfield'
                placeholder={props.t('First Name')}
                defaultValue={props.firstNameToEdit}
                {...register('firstname', {
                  required: props.t('Please enter fistname.'),
                })}
              />
              {errors.firstname?.message && <p>{errors.firstname.message}</p>}
            </Col>
            <Col lg='5'>
              <Label
                className={
                  watch('lastname') && watch('lastname').length > 0
                    ? 'glisten-textfield-label'
                    : 'glisten-textfield-label glisten-textfield-label-hidden'
                }
              >
                {props.t('Last Name')}
              </Label>
              <input
                id='lastname'
                name='lastname'
                label={props.t('Last Name')}
                className='form-control glisten-textfield'
                placeholder={props.t('Last Name')}
                defaultValue={props.lastNameToEdit}
                {...register('lastname', {
                  required: props.t('Please enter lastname.'),
                })}
              />
              {errors.lastname?.message && <p>{errors.lastname.message}</p>}
            </Col>
          </Row>
          <Row>
            <Col lg='5' style={{ paddingTop: '1.25rem' }}>
              <Label
                className={
                  watch('firstname') && watch('phone').length > 0
                    ? 'phone-textfield-label'
                    : 'glisten-textfield-label glisten-textfield-label-hidden'
                }
              >
                {props.t('Cell Phone')}
              </Label>
              <input
                id='phone'
                name='phone'
                label={props.t('Cell Phone')}
                className='form-control glisten-textfield'
                placeholder={props.t('Cell Phone')}
                defaultValue={props.phoneToEdit}
                {...register('phone', {
                  required: props.t('Please enter cell phone number.'),
                  pattern: {
                    value: /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/,
                    message:
                      'Please enter valid phone number. (eg. 123-456-7890, (123) 456-7890, 123 456 7890, 123.456.7890, +91 (123) 456-7890',
                  },
                })}
                onChange={(e) => {
                  const formattedPhoneNumber = formatPhoneNumber(e.target.value)
                  e.target.value = formattedPhoneNumber
                }}
              />
              {errors.phone?.message && <p>{errors.phone.message}</p>}
            </Col>
          </Row>

          <div>
            <h3 className='glisten-modal-title' style={{ paddingLeft: '0rem' }}>
              {props.t("What is the user's role")}
            </h3>
          </div>

          <Row>
            <Col lg='5'>
              <GlistenDropdown
                formRegistragionName='department'
                placeholder={props.t('Department')}
                items={props.departments.map((item) => ({
                  key: item,
                  value: item,
                }))}
                value={props.deptToEdit}
                validationMsg={props.t('Select a department')}
              />
            </Col>
            <Col lg='5'>
              <GlistenDropdown
                formRegistragionName='role'
                placeholder={props.t('Role')}
                items={ROLES}
                value={props.roleToEdit}
                validationMsg={props.t('Select a Role')}
              />
            </Col>
          </Row>
        </div>
        <div className='modal-footer glisten-modal-footer'>
          <div>
            <input
              className='btn glisten-btn prim-mod-action'
              type='submit'
              value={props.submitBtnTitle}
            />
            <Button
              className='btn glisten-btn alt-mod-action'
              onClick={() => props.setContent(props.currentContent + 1)}
            >
              {props.secondBtnTitle}
            </Button>
          </div>
        </div>
      </form>
    </FormProvider>
  )
}

EditUser.propTypes = {
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  showModal: PropTypes.func,
  submitBtnTitle: PropTypes.string,
  submitBtnDisabled: PropTypes.bool,
  secondBtnTitle: PropTypes.string,
  t: PropTypes.any,
  usernameForEdit: PropTypes.string,
  emailForEdit: PropTypes.string,
  firstNameToEdit: PropTypes.string,
  lastNameToEdit: PropTypes.string,
  phoneToEdit: PropTypes.string,
  deptToEdit: PropTypes.string,
  departments: PropTypes.any,
  role: PropTypes.string,
  roleToEdit: PropTypes.string,
  currentContent: PropTypes.any,
  setContent: PropTypes.func,
  onUpdateUserData: PropTypes.func,
  org: PropTypes.string,
  tenant: PropTypes.string,
  userFeat: PropTypes.any,
}

const mapStateToProps = ({ tenant, login }) => ({
  lastname: tenant.lastname,
  departments: tenant.department,
  tenant: login.tenant,
  username: login.username,
  org: login.org,
  userFeat: login.features,
})

const mapDispatchToProps = (dispatch) => ({
  onUpdateUserData: (org, tenant, userData) =>
    dispatch(updateUserData(org, tenant, userData)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(EditUser))
