export const GET_TENANT_DATA = 'GET_TENANT_DATA'
export const GET_TENANT_DATA_SUCCESS = 'GET_TENANT_DATA_SUCCESS'
export const GET_TENANT_DATA_FAIL = 'GET_TENANT_DATA_FAIL'
export const UPDATE_TENANT_DATA = 'UPDATE_TENANT_DATA'
export const UPDATE_TENANT_DATA_SUCCESS = 'UPDATE_TENANT_DATA_SUCCESS'
export const UPDATE_TENANT_DATA_FAIL = 'UPDATE_TENANT_DATA_FAIL'
export const ADD_TENANT_DATA = 'ADD_TENANT_DATA'
export const ADD_TENANT_DATA_SUCCESS = 'ADD_TENANT_DATA_SUCCESS'
export const ADD_TENANT_DATA_FAIL = 'ADD_TENANT_DATA_FAIL'
export const ADD_SERVICE_CATALOG_DATA = 'ADD_SERVICE_CATALOG_DATA'
export const ADD_SERVICE_CATALOG_DATA_SUCCESS =
  'ADD_SERVICE_CATALOG_DATA_SUCCESS'
export const ADD_SERVICE_CATALOG_DATA_FAIL = 'ADD_SERVICE_CATALOG_DATA_FAIL'
export const UPDATE_SERVICE_CATALOG_DATA = 'UPDATE_SERVICE_CATALOG_DATA'
export const UPDATE_SERVICE_CATALOG_DATA_SUCCESS =
  'UPDATE_SERVICE_CATALOG_DATA_SUCCESS'
export const UPDATE_SERVICE_CATALOG_DATA_FAIL =
  'UPDATE_SERVICE_CATALOG_DATA_FAIL'
export const DELETE_SERVICE_CATALOG_DATA = 'DELETE_SERVICE_CATALOG_DATA'
export const DELETE_SERVICE_CATALOG_DATA_SUCCESS =
  'DELETE_SERVICE_CATALOG_DATA_SUCCESS'
export const DELETE_SERVICE_CATALOG_DATA_FAIL =
  'DELETE_SERVICE_CATALOG_DATA_FAIL'
