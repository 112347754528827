import {
  SUBMIT_SERVICE_ORDER,
  SUBMIT_SERVICE_ORDER_SUCCESS,
  SUBMIT_SERVICE_ORDER_FAIL,
  UPDATE_SELECTED_SERVICE,
  UPDATE_NOTE_FOR_SERVICE,
  ADD_CUSTOM_SERVICE_TO_ORDER,
  TOGGLE_CUSTOM_SERVICE_TO_ORDER,
} from './actionTypes'

export const addCustomServiceToOrder = (customService) => {
  console.log('actions::addCustomServiceToOrder()')
  return {
    type: ADD_CUSTOM_SERVICE_TO_ORDER,
    payload: { customService },
  }
}

export const toggleCustomService = (customServiceId) => {
  console.log('actions::addCustomServiceToOrder()')
  return {
    type: TOGGLE_CUSTOM_SERVICE_TO_ORDER,
    payload: { customServiceId },
  }
}

export const submitServiceOrder = (org, tenant, order) => {
  console.log('actions::submitServiceOrder()')
  return {
    type: SUBMIT_SERVICE_ORDER,
    payload: { org, tenant, order },
  }
}

export const submitServiceOrderSuccess = (orderId) => {
  console.log('actions::submitServiceOrderSuccess()')
  return {
    type: SUBMIT_SERVICE_ORDER_SUCCESS,
    payload: { orderId },
  }
}

export const submitServiceOrderyFail = (error) => {
  console.log('actions::submitServiceOrderyFail()')
  return {
    type: SUBMIT_SERVICE_ORDER_FAIL,
    payload: { error },
  }
}

export const updateSelectedService = (selectedService) => {
  console.log('actions::updateSelectedService()')
  return {
    type: UPDATE_SELECTED_SERVICE,
    payload: { selectedService },
  }
}

export const updateNoteForService = (note) => {
  console.log('actions::updateNoteForService()')
  return {
    type: UPDATE_NOTE_FOR_SERVICE,
    payload: note,
  }
}
