export const LOGIN = 'LOGIN'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'

export const LOGOUT = 'LOGOUT'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const LOGOUT_FAIL = 'LOGOUT_FAIL'

export const RESEND_REGISTRATION_CODE = 'RESEND_REGISTRATION_CODE'
export const RESEND_REGISTRATION_CODE_SUCCESS =
  'RESEND_REGISTRATION_CODE_SUCCESS'
export const RESEND_REGISTRATION_CODE_FAIL = 'RESEND_REGISTRATION_CODE_FAIL'

export const CONFIRM_REGISTRATION = 'CONFIRM_REGISTRATION'
export const CONFIRM_REGISTRATION_SUCCESS = 'CONFIRM_REGISTRATION_SUCCESS'
export const CONFIRM_REGISTRATION_FAIL = 'CONFIRM_REGISTRATION_FAIL'

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD'
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS'
export const CHANGE_PASSWORD_FAIL = 'CHANGE_PASSWORD_FAIL'

export const FORGOT_PASSWORD = 'FORGOT_PASSWORD'
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS'
export const FORGOT_PASSWORD_FAIL = 'FORGOT_PASSWORD_FAIL'

export const CONFIRM_NEW_PASSWORD = 'CONFIRM_NEW_PASSWORD'
export const CONFIRM_NEW_PASSWORD_SUCCESS = 'CONFIRM_NEW_PASSWORD_SUCCESS'
export const CONFIRM_NEW_PASSWORD_FAIL = 'CONFIRM_NEW_PASSWORD_FAIL'

export const START_CHANGE_PASSWORD = 'START_CHANGE_PASSWORD'
export const RESET_ALL = 'RESET_ALL'
