/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

// Redux Store
import { toggleLeftmenu } from '../../store/actions'
import { isMobile } from 'react-device-detect'
import { useHistory } from 'react-router-dom'

//i18n
import { withTranslation } from 'react-i18next'

//css
import '../HorizontalLayout/header.scss'

import Notifications from '../Notifications/Notifications'
import ReactNotificationComponent from '../Notifications/ReactNotification'
import { onMessageListener } from 'firebase'

const Header = (props) => {
  let history = useHistory()
  const [show, setShow] = useState(false)
  const [notification, setNotification] = useState({ title: '', body: '' })

  onMessageListener()
    .then((payload) => {
      setShow(true)
      if (props.username == payload.username) {
        setNotification({
          title: payload.title,
          body: payload.body,
        })
      }
    })
    .catch((err) => console.log('failed: ', err))

  return (
    <React.Fragment>
      <header
        id='page-topbar'
        style={
          !isMobile
            ? {
                boxShadow: '0rem 0.125rem 1rem 0rem rgba(0, 0, 0, 0.09)',
                height: '6.375rem',
                zIndex: 1000,
              }
            : {
                boxShadow: '0rem 0.125rem 1rem 0rem rgba(0, 0, 0, 0.09)',
                height: 'fit-content',
                zIndex: 1049,
              }
        }
      >
        {show ? (
          <ReactNotificationComponent
            title={notification.title}
            body={notification.body}
          />
        ) : (
          <></>
        )}
        <Notifications />
        <div
          className='d-flex align-items-center'
          style={
            !isMobile ? { minHeight: '6.375rem' } : { minHeight: '4.375rem' }
          }
        >
          <div className='navbar-brand-box' style={{ paddingLeft: '2.5rem' }}>
            <div className='logo-lg'>
              <img src={props.logo} alt='' height='43' />
              <div
                style={{
                  position: 'absolute',
                  top: '0.4rem',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  height: '95%',
                  textAlign: 'center',
                  paddingLeft: '6rem',
                }}
              ></div>
            </div>
          </div>
          <div className='topnav-sec'>
            {/* <div> */}
            {/* <p className='header-logo-title'>{props.title}</p> */}
            {/* </div> */}
            {!props.showNavBack ? (
              <button
                type='button'
                className='btn btn-sm px-3 font-size-16 d-lg-none header-item waves-effect waves-light nav-menu'
                data-toggle='collapse'
                onClick={() => props.toggleLeftmenu(!props.leftMenu)}
                data-target='#topnav-menu-content'
                style={isMobile ? { height: '50%' } : {}}
              >
                <i className='fa fa-fw fa-bars' />
              </button>
            ) : (
              <button
                type='button'
                className='btn btn-sm px-3 font-size-16 d-lg-none header-item waves-effect waves-light nav-menu'
                data-toggle='collapse'
                onClick={() => history.goBack()}
                data-target='#topnav-menu-content'
                style={isMobile ? { height: '50%' } : {}}
              >
                <i className='fa fa-fw fa-angle-left' />
              </button>
            )}
            <p className='header-logo-title'>{props.title}</p>
          </div>
        </div>
      </header>
    </React.Fragment>
  )
}

Header.propTypes = {
  leftMenu: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
  mobileTitle: PropTypes.string,
  showNavBack: PropTypes.bool,
  title: PropTypes.string,
  logo: PropTypes.string,
  username: PropTypes.string,
}

const mapStatetoProps = (state) => {
  const { layoutType, leftMenu } = state.Layout
  const username = state.login.username
  return { layoutType, leftMenu, username }
}

export default connect(mapStatetoProps, {
  toggleLeftmenu,
})(withTranslation()(Header))
