import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { Row, Col, Modal, Label } from 'reactstrap'

// Styling
import './adduser.scss'

//i18n
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { addUserData } from 'store/actions'
import { ReactComponent as Logo } from '../../../assets/images/ic-close.svg'
import { useForm, FormProvider } from 'react-hook-form'
import { sendPageViewEvent } from '../../../firebase'
import GlistenDropdown from '../../../components/GlistenDropdown'
import { ROLES } from 'common/contants'
import { formatPhoneNumber } from 'helpers/util'

const AddUser = (props) => {
  const methods = useForm()

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = methods

  useEffect(() => {
    sendPageViewEvent('Add User')
  }, [])

  const { onAddUserData } = props

  const onSubmit = (evt) => {
    onAddUserData(props.org, props.tenant, {
      first_name: evt.firstname,
      last_name: evt.lastname,
      email: evt.email,
      phone_number: evt.phone,
      department: evt.department,
      role: evt.role,
    })
    props.showModal(false)
  }

  return (
    <FormProvider {...methods}>
      <Modal
        size='xl'
        isOpen={props.isOpen}
        toggle={() => {
          props.showModal(false)
        }}
        centered={true}
      >
        <div className='modal-header glisten-modal-header'>
          <div>
            <h3 className='glisten-modal-title'>{props.title}</h3>
          </div>

          <button
            className='close-btn'
            onClick={() => {
              props.showModal(false)
            }}
          >
            <Logo />
          </button>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className='form-horizontal'>
          <div
            className='modal-body glisten-modal-body'
            style={{ paddingTop: '1.188rem' }}
          >
            <Row>
              <Col lg='5'>
                <Label
                  className={
                    watch('firstname') && watch('firstname').length > 0
                      ? 'glisten-textfield-label'
                      : 'glisten-textfield-label glisten-textfield-label-hidden'
                  }
                >
                  {props.t('First Name')}
                </Label>
                <input
                  id='firstname'
                  name='firstname'
                  label={props.t('First Name')}
                  className='form-control glisten-textfield'
                  placeholder={props.t('First Name')}
                  defaultValue=''
                  {...register('firstname', {
                    required: props.t('Please enter fistname.'),
                  })}
                />
                {errors.firstname?.message && <p>{errors.firstname.message}</p>}
              </Col>
              <Col lg='5'>
                <Label
                  className={
                    watch('lastname') && watch('lastname').length > 0
                      ? 'glisten-textfield-label'
                      : 'glisten-textfield-label glisten-textfield-label-hidden'
                  }
                >
                  {props.t('Last Name')}
                </Label>
                <input
                  id='lastname'
                  name='lastname'
                  label={props.t('Last Name')}
                  className='form-control glisten-textfield'
                  placeholder={props.t('Last Name')}
                  defaultValue=''
                  {...register('lastname', {
                    required: props.t('Please enter lastname.'),
                  })}
                />
                {errors.lastname?.message && <p>{errors.lastname.message}</p>}
              </Col>
            </Row>
            <Row>
              <Col lg='5' style={{ paddingTop: '1.25rem' }}>
                <Label
                  className={
                    watch('firstname') && watch('phone').length > 0
                      ? 'phone-textfield-label'
                      : 'glisten-textfield-label glisten-textfield-label-hidden'
                  }
                >
                  {props.t('Cell Phone')}
                </Label>
                <input
                  id='phone'
                  name='phone'
                  label={props.t('Cell Phone')}
                  className='form-control glisten-textfield'
                  placeholder={props.t('Cell Phone')}
                  defaultValue=''
                  {...register('phone', {
                    required: props.t('Please enter cell phone number.'),
                    pattern: {
                      value: /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/,
                      message:
                        'Please enter valid phone number. (eg. 123-456-7890, (123) 456-7890, 123 456 7890, 123.456.7890, +91 (123) 456-7890',
                    },
                  })}
                  onChange={(e) => {
                    const formattedPhoneNumber = formatPhoneNumber(
                      e.target.value
                    )
                    e.target.value = formattedPhoneNumber
                  }}
                />
                {errors.phone?.message && <p>{errors.phone.message}</p>}
              </Col>
              <Col lg='5' style={{ paddingTop: '1.25rem' }}>
                <Label
                  className={
                    watch('email') && watch('email').length > 0
                      ? 'glisten-textfield-label'
                      : 'glisten-textfield-label glisten-textfield-label-hidden'
                  }
                >
                  {props.t('Email')}
                </Label>
                <input
                  id='email'
                  name='email'
                  label={props.t('Email')}
                  className='form-control glisten-textfield'
                  placeholder={props.t('Email')}
                  defaultValue=''
                  {...register('email', {
                    required: props.t('Please enter correct email address.'),
                    pattern: {
                      value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      message: 'Please enter a valid email',
                    },
                  })}
                />
                {errors.email?.message && <p>{errors.email.message}</p>}
              </Col>
            </Row>

            <div>
              <h3
                className='glisten-modal-title'
                style={{ paddingLeft: '0rem' }}
              >
                {props.t("What is the user's role")}
              </h3>
            </div>

            <Row>
              <Col lg='5'>
                <GlistenDropdown
                  formRegistragionName='department'
                  placeholder={props.t('Department')}
                  items={props.departments.map((item) => ({
                    key: item,
                    value: item,
                  }))}
                  value=''
                  validationMsg={props.t('Select a department')}
                />
              </Col>
              <Col lg='5'>
                <GlistenDropdown
                  formRegistragionName='role'
                  placeholder={props.t('Role')}
                  items={ROLES}
                  value=''
                  validationMsg={props.t('Select a Role')}
                />
              </Col>
            </Row>
          </div>
          <div className='modal-footer glisten-modal-footer'>
            <div>
              <input
                className='btn glisten-btn prim-mod-action'
                type='submit'
                value={props.submitBtnTitle}
              />
            </div>
          </div>
        </form>
      </Modal>
    </FormProvider>
  )
}

AddUser.propTypes = {
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  showModal: PropTypes.func,
  submitBtnTitle: PropTypes.string,
  submitBtnDisabled: PropTypes.bool,
  t: PropTypes.any,
  firstname: PropTypes.string,
  lastname: PropTypes.string,
  phone: PropTypes.string,
  department: PropTypes.string,
  departments: PropTypes.any,
  role: PropTypes.string,
  onAddUserData: PropTypes.func,
  org: PropTypes.string,
  tenant: PropTypes.string,
  userFeat: PropTypes.any,
  username: PropTypes.string,
}

const mapStateToProps = ({ tenant, login }) => ({
  loading: tenant.isLoading,
  firstname: tenant.firstname,
  lastname: tenant.lastname,
  email: tenant.email,
  phone: tenant.phone,
  departments: tenant.department,
  role: tenant.role,
  tenant: login.tenant,
  username: login.username,
  org: login.org,
  userFeat: login.features,
})

const mapDispatchToProps = (dispatch) => ({
  onAddUserData: (org, tenant, userData) =>
    dispatch(addUserData(org, tenant, userData)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(AddUser))
