export const GET_ALL_VEHICLE_MAKE = 'GET_ALL_VEHICLE_MAKE'
export const GET_ALL_VEHICLE_MAKE_SUCCESS = 'GET_ALL_VEHICLE_MAKE_SUCCESS'
export const GET_ALL_VEHICLE_MAKE_FAIL = 'GET_ALL_VEHICLE_MAKE_FAIL'

export const GET_ALL_VEHICLE_MODEL_BY_MAKE_AND_YEAR =
  'GET_ALL_VEHICLE_MODEL_BY_MAKE_AND_YEAR'
export const GET_ALL_VEHICLE_MODEL_BY_MAKE_AND_YEAR_SUCCESS =
  'GET_ALL_VEHICLE_MODEL_BY_MAKE_AND_YEAR_SUCCESS'
export const GET_ALL_VEHICLE_MODEL_BY_MAKE_AND_YEAR_FAIL =
  'GET_ALL_VEHICLE_MODEL_BY_MAKE_AND_YEAR_FAIL'

export const SEARCH_VEHICLE_BY_VIN = 'SEARCH_VEHICLE_BY_VIN'
export const SEARCH_VEHICLE_BY_VIN_SUCCESS = 'SEARCH_VEHICLE_BY_VIN_SUCCESS'
export const SEARCH_VEHICLE_BY_VIN_FAIL = 'SEARCH_VEHICLE_BY_VIN_FAIL'

export const CLEAR_VEHICLE_SEARCH_RESULT = 'CLEAR_VEHICLE_SEARCH_RESULT'
