/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import { Row, Col, Modal, Button, Label, Media } from 'reactstrap'

import { startWorkOrder, endWorkOrder, reviewWorkOrder } from 'store/actions'

import './startendreview.scss'
import { ReactComponent as Logo } from '../../../assets/images/ic-close.svg'
import nocarimage from '../../../assets/images/no-car-image.svg'
import { sendPageViewEvent } from '../../../firebase'
import {
  formatDate,
  formatTimeOnly,
  isToday,
  isTomrrow,
  isLate,
} from '../../../helpers/date_formatter'

const StartEndReviewCfrmModal = (props) => {
  useEffect(() => {
    sendPageViewEvent('Start End Review Modal')
  }, [])

  const priorityLabel = (idx) => {
    switch (idx) {
      case '1':
        return props.t('WAITER - ')
      case '2':
        return props.t('SPOT DELIVERY - ')
      case '3':
        return props.t('EOD - ')
      case '4':
        return props.t('FILL - ')
      case '5':
        return props.t('APPOINTMENT - ')
      default:
        return ''
    }
  }

  const dueTomrrwMorn = (due_date) => {
    var tomorrow = new Date()
    tomorrow.setDate(tomorrow.getDate() + 1)

    if (
      tomorrow.getDate() == due_date.getDate() &&
      tomorrow.getMonth() == due_date.getMonth() &&
      tomorrow.getFullYear() == due_date.getFullYear()
    ) {
      if (due_date.getHours() == 10) {
        if (due_date.getMinutes() <= 29) {
          return true
        } else {
          return false
        }
      } else if (due_date.getHours() < 10) {
        return true
      }
    } else {
      return false
    }
  }

  // Checks if order is due today OR before 10:30AM the following day OR is currently late
  const isDueSoon = (pickup_date) => {
    if (
      isToday(pickup_date) ||
      dueTomrrwMorn(pickup_date) ||
      isLate(pickup_date)
    ) {
      return true
    } else {
      return false
    }
  }

  const confirmButton = () => {
    return (
      <Row className='row-margin-fix'>
        <Col
          lg='12'
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '0rem',
          }}
        >
          {/* Detailer START JOB action */}
          {props.userFeat &&
            props.order &&
            props.username &&
            props.userFeat.includes('ftl-detailshop') &&
            props.userFeat.includes('ftl-employee') &&
            props.order.status == 'WorkAssigned' &&
            props.username == props.order.assign_to.username && (
              <Button
                className='prim-modal-btn btn glisten-btn'
                style={{ marginBottom: '1.125rem' }}
                onClick={() => {
                  props.onStartWorkOrder(
                    props.org,
                    props.tenant,
                    props.order.data_key
                  )
                  props.showModalStartEndReview(false)
                }}
              >
                {props.t('START JOB')}
              </Button>
            )}

          {/* Detailer END JOB action */}
          {props.userFeat &&
            props.order &&
            props.username &&
            props.userFeat.includes('ftl-detailshop') &&
            (props.userFeat.includes('ftl-employee') ||
              props.userFeat.includes('ftl-admin')) &&
            props.order.status == 'WorkStarted' &&
            (props.username == props.order.assign_to.username ||
              props.userFeat.includes('ftl-admin')) && (
              <Button
                className='prim-modal-btn btn glisten-btn'
                style={{ marginBottom: '1.125rem' }}
                onClick={() => {
                  props.onEndWorkOrder(
                    props.org,
                    props.tenant,
                    props.order.data_key
                  )
                  props.showModalStartEndReview(false)
                }}
              >
                {props.t('END JOB')}
              </Button>
            )}

          {/* Detailer REVIEW WORK action */}
          {props.userFeat &&
            props.order &&
            props.username &&
            props.userFeat.includes('ftl-detailshop') &&
            (props.userFeat.includes('ftl-manager') ||
              props.userFeat.includes('ftl-executive') ||
              props.userFeat.includes('ftl-admin')) &&
            props.order.status == 'WorkEnded' && (
              <Button
                className='prim-modal-btn btn glisten-btn'
                style={{ marginBottom: '1.125rem' }}
                onClick={() => {
                  props.onReviewWorkOrder(
                    props.org,
                    props.tenant,
                    props.order.data_key
                  )
                  props.showModalStartEndReview(false)
                }}
              >
                {props.t('CONFIRM COMPLETE')}
              </Button>
            )}
        </Col>
      </Row>
    )
  }

  const assignGroupImg = (group_name) => {
    if (group_name.replaceAll(' ', '').toUpperCase() == 'CARWASH') {
      return 'illust-icons/ic-car wash.svg'
    } else if (
      group_name.replaceAll(' ', '').toUpperCase() == 'SERVICEDETAIL'
    ) {
      return 'illust-icons/ic-service detail.svg'
    } else if (group_name.replaceAll(' ', '').toUpperCase() == 'CARDELIVERY') {
      return 'illust-icons/ic-car delivery.svg'
    } else if (group_name.replaceAll(' ', '').toUpperCase() == 'POLISH') {
      return 'illust-icons/ic-polish.svg'
    } else if (group_name.replaceAll(' ', '').toUpperCase() == 'NEWCARPREP') {
      return 'illust-icons/ic-new car prep.svg'
    } else if (
      group_name.replaceAll(' ', '').toUpperCase() == 'COMPLETERECONDITION'
    ) {
      return 'illust-icons/ic-complete recondition.svg'
    } else {
      return 'illust-icons/ic-custom category.svg'
    }
  }

  return (
    <Modal
      scrollable={true}
      size='xl'
      isOpen={props.isOpenStartEndReview}
      toggle={() => {
        props.showModalStartEndReview(false)
      }}
      centered={true}
    >
      {/* HEADER */}
      <div className='modal-header glisten-modal-header'>
        <div>
          <h3 className='glisten-modal-title'>{props.title}</h3>
        </div>

        <button
          className='close-btn'
          onClick={() => {
            props.showModalStartEndReview(false)
          }}
        >
          <Logo />
        </button>
      </div>

      {/* BODY */}
      <div
        className='modal-body glisten-modal-body'
        style={{ paddingTop: '1.188rem' }}
      >
        <Row className='row-margin-fix'>
          <Col
            lg='7'
            style={{
              display: 'flex',
              flexDirection: 'column',
              padding: '0rem',
            }}
          >
            <Row className='row-margin-fix'>
              {/* IDENTIFIER STRING */}
              <Col
                lg='12'
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  padding: '0rem',
                }}
              >
                <Label className='modal-identifier-title'>
                  {props.order && props.order.customer_tag
                    ? props.t('[CUSTOMER TAG]')
                    : props.order && props.order.stock
                    ? props.t('[STOCK NUMBER]')
                    : props.order && props.order.vehicle.data_key
                    ? props.t('[VIN]')
                    : '[No Identifier]'}
                </Label>
              </Col>
              {/* IDENTIFYING NUMBER */}
              <Col
                lg='12'
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  padding: '0rem',
                }}
              >
                <Label className='modal-identifier-subtitle'>
                  {props.order && props.order.customer_tag
                    ? props.order.customer_tag
                    : props.order && props.order.stock
                    ? props.order.stock
                    : props.order && props.order.vehicle.data_key
                    ? props.order.vehicle.data_key
                    : '00000'}
                </Label>
              </Col>
              {/* YEAR + MAKE */}
              <Col xl='12'>
                {props.order && props.order.vehicle ? (
                  <Label
                    className='vehicle-info-label-alt'
                    style={{
                      marginTop: '2.125rem',
                      marginBottom: '0rem',
                    }}
                  >
                    {props.order.vehicle.year} {props.order.vehicle.make}
                  </Label>
                ) : (
                  <></>
                )}
              </Col>
              {/* MODEL */}
              <Col xl='12'>
                {props.order && props.order.vehicle ? (
                  <Label
                    className='vehicle-info-label-alt'
                    style={{
                      marginBottom: '0rem',
                    }}
                  >
                    {props.order.vehicle.model}
                  </Label>
                ) : (
                  <></>
                )}
              </Col>

              {/* COLOR */}
              <Col xl='12'>
                {props.order && props.order.vehicle ? (
                  <Label
                    style={{
                      fontFamily: 'Poppins',
                      fontWeight: '200',
                      fontSize: '1.125rem',
                      letterSpacing: '0.127rem',
                      color: '#333333',
                      marginTop: '1rem',
                      marginBottom: '0rem',
                    }}
                  >
                    {props.t('Color')}
                    {'\u003A'} {props.order.vehicle.color}
                  </Label>
                ) : (
                  <></>
                )}
              </Col>
            </Row>

            {/* DELIVERY PRIORITY + DATE */}
            <Row className='row-margin-fix'>
              <Col
                lg='10'
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  padding: '0rem',
                }}
              >
                <Label
                  className={
                    props.order &&
                    isDueSoon(new Date(props.order.pickup_date_time * 1000))
                      ? 'modal-red-status-bg'
                      : 'modal-gray-status-bg'
                  }
                  style={{ marginTop: '4.25rem' }}
                >
                  {props.order && priorityLabel(props.order.priority)}
                  {props.order &&
                    formatDate(new Date(props.order.pickup_date_time))}
                </Label>
              </Col>
            </Row>
            {props.order && props.order.note ? (
              <Row className='row-margin-fix'>
                {/* ORDER NOTE */}
                <Col
                  lg='10'
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    paddingLeft: '0.625rem',
                    paddingRight: '0rem',
                    marginTop: '2.125rem',
                  }}
                >
                  <Label className='order-title'>
                    {props.t('Order Note')}
                    {'\u003A'}{' '}
                  </Label>
                  <Label className='order-body'>{props.order.note}</Label>
                </Col>
              </Row>
            ) : (
              <></>
            )}
          </Col>
          {/* CAR IMAGE */}
          <Col
            lg='5'
            style={{
              display: 'flex',
              flexDirection: 'column',
              padding: '0rem',
            }}
          >
            <Media
              src={nocarimage}
              style={{
                display: 'block',
                maxWidth: '100%',
                height: 'auto',
                width: '100%',
                borderRadius: '8px',
                marginTop: '1rem',
              }}
            ></Media>
          </Col>
        </Row>

        <Row className='row-margin-fix'>
          {/* SERVICES */}
          <Col
            lg='12'
            style={{
              display: 'flex',
              flexDirection: 'column',
              paddingLeft: '0.625rem',
              paddingRight: '0rem',
              marginTop: '2.688rem',
              marginBottom: '6.25rem',
            }}
          >
            <Label className='services-title'>
              {props.t('REQUESTED SERVICES')}
            </Label>
            {props.order && props.order.service_catalog ? (
              <Row className='row-margin-fix'>
                <Col
                  lg='12'
                  className='modal-service-row'
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '0rem',
                  }}
                >
                  {Array.from(props.order.service_catalog).map((item, idx) => {
                    return (
                      <Row
                        key={idx}
                        className='row-margin-fix modal-border-bot'
                      >
                        <Col
                          lg='1'
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            padding: '0rem',
                          }}
                        >
                          <Media
                            src={assignGroupImg(item.category)}
                            className='service-group-img'
                          ></Media>
                        </Col>
                        <Col
                          lg='10'
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            padding: '0rem',
                          }}
                        >
                          <Label className='service-line-1'>{item.name}</Label>
                          <Label className='service-line-2'>
                            {item.description}
                          </Label>
                        </Col>
                      </Row>
                    )
                  })}
                </Col>
              </Row>
            ) : (
              <></>
            )}
          </Col>
        </Row>
      </div>

      {/* FOOTER */}
      <div className='modal-footer glisten-modal-footer'>
        <div>
          {confirmButton()}
          {/* <Button
            className='btn glisten-dbtn alt-mod-action'
            // onClick={(evt) => {
            //   handleMoreDetails(evt)
            // }}
          >
            {props.secondBtnTitle}
          </Button> */}
        </div>
      </div>
    </Modal>
  )
}

StartEndReviewCfrmModal.propTypes = {
  t: PropTypes.any,
  org: PropTypes.string,
  tenant: PropTypes.string,
  userFeat: PropTypes.any,
  username: PropTypes.any,
  order: PropTypes.any,
  title: PropTypes.string,
  isOpenStartEndReview: PropTypes.bool,
  showModalStartEndReview: PropTypes.func,
  submitBtnTitle: PropTypes.string,
  onStartWorkOrder: PropTypes.func,
  onEndWorkOrder: PropTypes.func,
  onReviewWorkOrder: PropTypes.func,
}

const mapStateToProps = ({ login }) => ({
  tenant: login.tenant,
  org: login.org,
  userFeat: login.features,
  username: login.username,
})

const mapDispatchToProps = (dispatch) => ({
  onStartWorkOrder: (org, tenentId, orderKey) =>
    dispatch(startWorkOrder(org, tenentId, orderKey)),
  onEndWorkOrder: (org, tenentId, orderKey) =>
    dispatch(endWorkOrder(org, tenentId, orderKey)),
  onReviewWorkOrder: (org, tenentId, orderKey) =>
    dispatch(reviewWorkOrder(org, tenentId, orderKey)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(StartEndReviewCfrmModal))
