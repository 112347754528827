import {
  CONFIRM_REGISTRATION,
  CONFIRM_REGISTRATION_SUCCESS,
  CONFIRM_REGISTRATION_FAIL,
  RESEND_REGISTRATION_CODE,
  RESEND_REGISTRATION_CODE_SUCCESS,
  RESEND_REGISTRATION_CODE_FAIL,
  RESET_ALL,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAIL,
  CONFIRM_NEW_PASSWORD,
  CONFIRM_NEW_PASSWORD_SUCCESS,
  CONFIRM_NEW_PASSWORD_FAIL,
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  LOGOUT_SUCCESS,
  LOGOUT_FAIL,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAIL,
  START_CHANGE_PASSWORD,
} from './actionTypes'
export const resetAll = () => {
  console.log('actions::resetAll()')

  return {
    type: RESET_ALL,
  }
}

export const forgotPassword = (username) => {
  console.log('actions::forgotPassword()')

  return {
    type: FORGOT_PASSWORD,
    payload: { username },
  }
}

export const forgotPasswordSuccess = (result) => {
  console.log('actions::FORGOT_PASSWORD_SUCCESS()')
  return {
    type: FORGOT_PASSWORD_SUCCESS,
    payload: { result },
  }
}

export const forgotPasswordFail = (error) => {
  console.log('actions::forgotPasswordFail()')
  return {
    type: FORGOT_PASSWORD_FAIL,
    payload: { error },
  }
}

export const confirmPassword = (username, code, password) => {
  console.log('actions::confirmPassword()')

  return {
    type: CONFIRM_NEW_PASSWORD,
    payload: { username, code, password },
  }
}

export const confirmPasswordSuccess = (result) => {
  console.log('actions::confirmPasswordSuccess()')
  return {
    type: CONFIRM_NEW_PASSWORD_SUCCESS,
    payload: { result },
  }
}

export const confirmPasswordFail = (error) => {
  console.log('actions::confirmPasswordFail()')
  return {
    type: CONFIRM_NEW_PASSWORD_FAIL,
    payload: { error },
  }
}

export const resendConfirmationCode = (username) => {
  console.log('actions::resendConfirmationCode()')

  return {
    type: RESEND_REGISTRATION_CODE,
    payload: { username },
  }
}

export const resendConfirmationCodeSuccess = (result) => {
  console.log('actions::resendConfirmationCodeSuccess()')
  return {
    type: RESEND_REGISTRATION_CODE_SUCCESS,
    payload: { result },
  }
}

export const resendConfirmationCodeFail = (error) => {
  console.log('actions::resendConfirmationCodeFail()')
  return {
    type: RESEND_REGISTRATION_CODE_FAIL,
    payload: { error },
  }
}

export const confirmRegistration = (username, code) => {
  console.log('actions::confirmRegistration()')

  return {
    type: CONFIRM_REGISTRATION,
    payload: { username, code },
  }
}

export const confirmRegistrationSuccess = (result) => {
  console.log('actions::confirmRegistrationSuccess()')
  return {
    type: CONFIRM_REGISTRATION_SUCCESS,
    payload: { result },
  }
}

export const confirmRegistrationFail = (error) => {
  console.log('actions::confirmRegistrationFail()')
  return {
    type: CONFIRM_REGISTRATION_FAIL,
    payload: { error },
  }
}

export const login = (username, password) => {
  console.log('actions::login()')

  return {
    type: LOGIN,
    payload: { username, password },
  }
}

export const loginSuccess = (loggedInUser) => {
  console.log(
    'actions::loginSuccess() loggedInUser = ' + JSON.stringify(loggedInUser)
  )
  return {
    type: LOGIN_SUCCESS,
    payload: { loggedInUser },
  }
}

export const loginFail = (error) => {
  console.log('actions::loginFail()')
  return {
    type: LOGIN_FAIL,
    payload: { error },
  }
}

export const initChangePassword = () => {
  console.log('actions::initChangePassword()')

  return {
    type: START_CHANGE_PASSWORD,
    payload: {},
  }
}

export const changePassword = (username, oldPassword, newPassword) => {
  console.log('actions::changePassword()')

  return {
    type: CHANGE_PASSWORD,
    payload: { username, oldPassword, newPassword },
  }
}

export const changePasswordSuccess = (result) => {
  console.log(
    'actions::changePasswordSuccess() loggedInUser = ' + JSON.stringify(result)
  )
  return {
    type: CHANGE_PASSWORD_SUCCESS,
    payload: { result },
  }
}

export const changePasswordFail = (error) => {
  console.log('actions::changePasswordFail()')
  return {
    type: CHANGE_PASSWORD_FAIL,
    payload: { error },
  }
}

export const logout = () => {
  console.log('actions::logoutUser()')
  return {
    type: LOGOUT,
  }
}

export const logoutSuccess = () => {
  console.log('actions::logoutUserSuccess()')

  return {
    type: LOGOUT_SUCCESS,
  }
}

export const logoutFail = () => {
  console.log('actions::logoutUserFail()')

  return {
    type: LOGOUT_FAIL,
  }
}
