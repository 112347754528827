/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import MetaTags from 'react-meta-tags'
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Media,
} from 'reactstrap'
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'

import {
  getTenantData,
  getUsersData,
  addUserData,
  updateUserData,
  deleteUserData,
} from 'store/actions'

//Edit Image
import edit_logo from '../../assets/images/ic-edit.svg'

// Styling
import '../../pages/Users/users.scss'

import { Link, withRouter } from 'react-router-dom'

//i18n
import { withTranslation } from 'react-i18next'

import { connect } from 'react-redux'
import ChangeUserInfo from './ChangeUserInfo'
import AddUser from './AddUser'
import LoadingOverlay from 'react-loading-overlay'
import { sendPageViewEvent } from '../../firebase'

const Users = (props) => {
  const {
    onGetTenantData,
    onGetUsersData,
    onUpdateUserData,
    onAddUserData,
    onDeleteUserData,
  } = props

  var title = props.t('Users')

  useEffect(() => {
    sendPageViewEvent('User Info')
  }, [])

  useEffect(() => {
    onGetUsersData(props.org, props.tenant)
    onGetTenantData(props.org, props.tenant)
  }, [onGetUsersData])

  //modals displayed from this page
  const [modal_edit, setmodal_edit] = useState(false)
  const [modal_add, setmodal_add] = useState(false)

  //specific user info to edit when button clicked
  const [selectedFirstName, setFirstNameSelected] = useState('')
  const [selectedLastName, setLastNameSelected] = useState('')
  const [selectedPhone, setPhoneSelected] = useState('')
  const [selectedDepartment, setDepartmentselected] = useState('')
  const [selectedRole, setRoleSelected] = useState('')
  const [selectedUserName, setUserNameSelected] = useState('')
  const [selectedEmail, setSelectedEmail] = useState('')

  function tog_edit(item) {
    setFirstNameSelected(item.first_name)
    setLastNameSelected(item.last_name)
    setPhoneSelected(item.phone_number)
    setDepartmentselected(item.department)
    setRoleSelected(item.role)
    setUserNameSelected(item.username)
    setSelectedEmail(item.email)
    setmodal_edit(!modal_edit)
    removeBodyCss()
  }

  function tog_add() {
    setmodal_add(!modal_add)
    removeBodyCss()
  }

  function removeBodyCss() {
    document.body.classList.add('no_padding')
  }

  return (
    <React.Fragment>
      <div className='breadsrumb-content'>
        <div className='breadcrumb-header' style={{ minHeight: '7.188rem' }}>
          <Row>
            <Col xl={2}>
              <div
                className='page-title-box'
                style={{ paddingTop: '2.563rem' }}
              >
                <div
                  style={{
                    fontSize: '1.5rem',
                    fontWeight: '500',
                    letterSpacing: '2.7px',
                    fontFamily: 'Poppins',
                    color: '#333333',
                  }}
                >
                  {props.t('My Account')}
                </div>
              </div>
            </Col>
            <Col xl={10} style={{ paddingTop: '3.063rem' }}>
              <Link to='myprofile' className='account-link'>
                {props.t('My Profile')}
              </Link>
              <Link to='resetpassword' className='account-link'>
                {props.t('Change Password')}
              </Link>
              {(props.userFeat.includes('ftl-admin') ||
                props.userFeat.includes('ftl-executive')) && (
                <Link to='users' className='account-link-sel'>
                  {props.t('Users')}
                </Link>
              )}
              {(props.userFeat.includes('ftl-admin') ||
                props.userFeat.includes('ftl-executive')) && (
                <Link to='dealershipinfo' className='account-link'>
                  {props.t('Dealership Info')}
                </Link>
              )}
              {(props.userFeat.includes('ftl-admin') ||
                props.userFeat.includes('ftl-executive')) && (
                <Link to='serviceinfo' className='account-link'>
                  {props.t('Service Info')}
                </Link>
              )}
            </Col>
          </Row>
        </div>
      </div>
      <div className='page-content page-background'>
        <MetaTags>
          <title>Home | {title}</title>
        </MetaTags>
        <Container fluid style={{ width: '85%' }}>
          <Row style={{ paddingTop: '3.313rem' }}>
            <Col lg='12'>
              <Card>
                <CardBody
                  style={{
                    padding: '0rem',
                  }}
                >
                  <Row>
                    <Col xl='6'>
                      <Label className='subpage-title'>
                        {props.t('Users')}
                      </Label>
                    </Col>
                    <Col xl='6'>
                      <Button
                        color='dark'
                        className='prim-btn-sm'
                        style={{
                          float: 'right',
                          marginTop: '1.625rem',
                          marginRight: '1.875rem',
                        }}
                        onClick={() => {
                          tog_add()
                        }}
                      >
                        {props.t('Add User')}
                      </Button>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: '2.25rem' }}>
                    <Col xl='12'>
                      <div
                        className='table-rep-plugin'
                        style={{ marginLeft: '1.25rem' }}
                      >
                        <div
                          className='table-responsive mb-0'
                          data-pattern='priority-columns'
                        >
                          <Table id='tech-companies-1' className='table'>
                            <Thead>
                              <Tr>
                                <Th className='table-headers'>
                                  {props.t('First Name')}
                                </Th>
                                <Th data-priority='1' className='table-headers'>
                                  {props.t('Last Name')}
                                </Th>
                                <Th data-priority='3' className='table-headers'>
                                  {props.t('Department')}
                                </Th>
                                <Th data-priority='1' className='table-headers'>
                                  {props.t('Account Type')}
                                </Th>
                                <Th data-priority='3' className='table-headers'>
                                  {props.t('Email Address')}
                                </Th>
                                <Th data-priority='3' className='table-headers'>
                                  {props.t('Cell Phone')}
                                </Th>
                                <Th data-priority='6' className='table-headers'>
                                  {props.t('Actions')}
                                </Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              {props.data.map((item, idx) => {
                                return (
                                  <Tr key={idx}>
                                    <Td className='table-cells'>
                                      {item.first_name}
                                    </Td>
                                    <Td className='table-cells'>
                                      {item.last_name}
                                    </Td>
                                    <Td className='table-cells'>
                                      {item.department}
                                    </Td>
                                    <Td className='table-cells'>{item.role}</Td>
                                    <Td className='table-cells'>
                                      {item.email}
                                    </Td>
                                    <Td className='table-cells'>
                                      {item.phone_number}
                                    </Td>
                                    <Td className='table-cells'>
                                      <Row
                                        onClick={() => {
                                          tog_edit(item)
                                        }}
                                      >
                                        <Col lg={3} style={{ padding: 0 }}>
                                          <Media
                                            src={edit_logo}
                                            style={{
                                              paddingLeft: '0.125rem',
                                              paddingRight: '0.125rem',
                                              marginTop: '0.625rem',
                                              marginRight: '0.625rem',
                                            }}
                                          ></Media>
                                        </Col>
                                        <Col lg={9} style={{ padding: 0 }}>
                                          <Label style={{ float: 'left' }}>
                                            {props.t('Edit')}
                                          </Label>
                                        </Col>
                                      </Row>
                                    </Td>
                                  </Tr>
                                )
                              })}
                            </Tbody>
                          </Table>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <LoadingOverlay active={props.loading} spinner>
        <ChangeUserInfo
          title={props.t('Edit User')}
          submitBtnTitle={props.t('Save')}
          secondBtnTitle={props.t('Delete')}
          firstNameToEdit={selectedFirstName}
          lastNameToEdit={selectedLastName}
          phoneToEdit={selectedPhone}
          deptToEdit={selectedDepartment}
          roleToEdit={selectedRole}
          emailForEdit={selectedEmail}
          usernameForEdit={selectedUserName}
          isOpen={modal_edit}
          showModal={setmodal_edit}
        />
      </LoadingOverlay>

      <LoadingOverlay active={props.loading} spinner>
        <AddUser
          title={props.t('Add New User')}
          submitBtnTitle={props.t('Submit')}
          isOpen={modal_add}
          showModal={setmodal_add}
        />
      </LoadingOverlay>
    </React.Fragment>
  )
}

Users.propTypes = {
  t: PropTypes.any,
  data: PropTypes.any,
  selectedFirstName: PropTypes.string,
  selectedLastName: PropTypes.string,
  selectedPhone: PropTypes.string,
  selectedDepartment: PropTypes.string,
  selectedRole: PropTypes.string,
  selectedUserName: PropTypes.string,
  selectedEmail: PropTypes.string,
  onGetTenantData: PropTypes.func,
  onGetUsersData: PropTypes.func,
  onAddUserData: PropTypes.func,
  onUpdateUserData: PropTypes.func,
  onDeleteUserData: PropTypes.func,
  showModal: PropTypes.func,
  org: PropTypes.string,
  tenant: PropTypes.string,
  userFeat: PropTypes.any,
  loading: PropTypes.any,
  departments: PropTypes.any,
}

const mapStateToProps = ({ users, login, tenant }) => ({
  data: users.data,
  tenant: login.tenant,
  org: login.org,
  userFeat: login.features,
  departments: tenant.department,
})

const mapDispatchToProps = (dispatch) => ({
  onGetUsersData: (org, tenant) => dispatch(getUsersData(org, tenant)),
  onAddUserData: (org, tenant, userData) =>
    dispatch(addUserData(org, tenant, userData)),
  onUpdateUserData: (org, tenant, userData) =>
    dispatch(updateUserData(org, tenant, userData)),
  onDeleteUserData: (org, tenant, userData) =>
    dispatch(deleteUserData(org, tenant, userData)),
  onGetTenantData: (org, tenant) => dispatch(getTenantData(org, tenant)),
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Users))
)
