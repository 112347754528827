import React from 'react'
import PropTypes from 'prop-types'
import { Label, Input } from 'reactstrap'

const GlistenTextInput = (props) => {
  return (
    <div className='mb-3'>
      <Label
        htmlFor='formrow-vin-Input'
        className={
          props.value && props.value.length > 0
            ? 'glisten-textfield-label'
            : 'glisten-textfield-label glisten-textfield-label-hidden'
        }
      >
        {props.placeholder}
      </Label>
      <Input
        placeholder={props.placeholder}
        type='text'
        className='glisten-textfield'
        onChange={(e) => props.setValue(e.target.value)}
        value={props.value}
        id='formrow-vin-Input'
      />
    </div>
  )
}

GlistenTextInput.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string,
  setValue: PropTypes.func,
}

export default GlistenTextInput
