import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'reactstrap'

const DurationStatusBox = (props) => {
  const calculateValue = () => {
    return <h5 style={{ display: 'contents' }}>{props.context}</h5>
  }

  return (
    <div>
      <Row style={{ paddingTop: '1rem' }}>
        <Col xl='12'>
          <h3 style={{ fontWeight: 600 }}>
            {props.title}
            {props.isUp ? (
              <span
                className='bx bx-up-arrow-alt bx-sm'
                style={{ color: '#fe0276' }}
              />
            ) : (
              <span
                className='bx bx-down-arrow-alt bx-sm'
                style={{ color: '#34c38f' }}
              />
            )}
          </h3>
        </Col>
      </Row>
      <Row style={{ paddingTop: '.5rem' }}>
        <Col xl='12'>
          {calculateValue()}
          <span
            style={{ backgroundColor: props.color, marginLeft: '1rem' }}
            className='position-absolute top-50 translate-middle badge border border-light rounded-circle p-1'
          >
            <span className='visually-hidden'>{props.context}</span>
          </span>
        </Col>
      </Row>
    </div>
  )
}

DurationStatusBox.propTypes = {
  t: PropTypes.any,
  title: PropTypes.string,
  context: PropTypes.string,
  color: PropTypes.string,
  isUp: PropTypes.bool,
}

export default DurationStatusBox
