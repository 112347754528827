import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { Row, Col, Modal, Label } from 'reactstrap'

// Styling
import '../../MyProfile/EditProfile/editprofile.scss'

//i18n
import { withTranslation } from 'react-i18next'

import { connect } from 'react-redux'

import { updateProfileData } from 'store/actions'

import { ReactComponent as Logo } from '../../../assets/images/ic-close.svg'

import { useForm } from 'react-hook-form'
import { sendPageViewEvent } from '../../../firebase'
import { formatPhoneNumber } from 'helpers/util'

const MyProfile = (props) => {
  const { onUpdateProfileData } = props

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm()

  useEffect(() => {
    sendPageViewEvent('Edit Profile')
  }, [])

  const onSubmit = (evt) => {
    onUpdateProfileData(props.org, props.tenant, props.username, {
      firstName: evt.firstname,
      lastName: evt.lastname,
      email: props.email,
      phoneNumber: evt.phone,
      department: props.department,
      role: props.role,
    })
    props.showModal(false)
  }

  return (
    <Modal
      size='xl'
      isOpen={props.isOpen}
      toggle={() => {
        props.tog_center()
      }}
      centered={true}
    >
      <div className='modal-header glisten-modal-header'>
        <div>
          <h3 className='glisten-modal-title'>{props.title}</h3>
        </div>

        <button
          className='close-btn'
          onClick={() => {
            props.showModal(false)
          }}
        >
          <Logo />
        </button>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className='form-horizontal'>
        <div
          className='modal-body glisten-modal-body'
          style={{ paddingTop: '1.188rem' }}
        >
          {props.content}
          <Row>
            <Col lg='5'>
              <Label
                className={
                  watch('firstname') && watch('firstname').length > 0
                    ? 'glisten-textfield-label'
                    : 'glisten-textfield-label glisten-textfield-label-hidden'
                }
              >
                {props.t('First Name')}
              </Label>
              <input
                id='firstname'
                name='firstname'
                label={props.t('First Name')}
                className='form-control glisten-textfield'
                placeholder={props.t('First Name')}
                defaultValue={props.firstname}
                {...register('firstname', {
                  required: props.t('Please enter firstname.'),
                })}
              />
              {errors.firstname?.message && <p>{errors.firstname.message}</p>}
            </Col>
            <Col lg='5'>
              <Label
                className={
                  watch('lastname') && watch('lastname').length > 0
                    ? 'glisten-textfield-label'
                    : 'glisten-textfield-label glisten-textfield-label-hidden'
                }
              >
                {props.t('Last Name')}
              </Label>
              <input
                id='lastname'
                name='lastname'
                label={props.t('Last Name')}
                className='form-control glisten-textfield'
                placeholder={props.t('Last Name')}
                defaultValue={props.lastname}
                {...register('lastname', {
                  required: props.t('Please enter lastname.'),
                })}
              />
              {errors.lastname?.message && <p>{errors.lastname.message}</p>}
            </Col>
          </Row>
          <Row style={{ marginBottom: '8.313rem' }}>
            <Col lg='5'>
              <Label
                className={
                  watch('phone') && watch('phone').length > 0
                    ? 'glisten-textfield-label'
                    : 'glisten-textfield-label glisten-textfield-label-hidden'
                }
                style={{ marginTop: '2.813rem' }}
              >
                {props.t('Cell Phone')}
              </Label>
              <input
                id='phone'
                name='phone'
                label={props.t('Cell Phone')}
                className='form-control glisten-textfield'
                placeholder={props.t('Cell Phone')}
                defaultValue={props.phone}
                {...register('phone', {
                  required: props.t('Please enter cell phone number.'),
                  pattern: {
                    value: /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/,
                    message:
                      'Please enter valid phone number. (eg. 123-456-7890, (123) 456-7890, 123 456 7890, 123.456.7890, +91 (123) 456-7890',
                  },
                })}
                onChange={(e) => {
                  const formattedPhoneNumber = formatPhoneNumber(e.target.value)
                  e.target.value = formattedPhoneNumber
                }}
              />
              {errors.phone?.message && <p>{errors.phone.message}</p>}
            </Col>
          </Row>
        </div>

        <div className='modal-footer glisten-modal-footer'>
          <input
            className='btn glisten-btn prim-mod-action'
            type='submit'
            value={props.submitBtnTitle}
          />
        </div>
      </form>
    </Modal>
  )
}

MyProfile.propTypes = {
  title: PropTypes.string,
  content: PropTypes.element,
  isOpen: PropTypes.bool,
  tog_center: PropTypes.func,
  showModal: PropTypes.func,
  currentContent: PropTypes.number,
  setContent: PropTypes.func,
  submitBtnTitle: PropTypes.string,
  submitBtnDisabled: PropTypes.bool,
  t: PropTypes.any,
  firstname: PropTypes.string,
  lastname: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  role: PropTypes.string,
  department: PropTypes.string,
  onUpdateProfileData: PropTypes.func,
  org: PropTypes.string,
  tenant: PropTypes.string,
  username: PropTypes.string,
  userFeat: PropTypes.any,
}

const mapStateToProps = ({ profile, login }) => ({
  loading: profile.isLoading,
  firstname: profile.firstname,
  lastname: profile.lastname,
  email: profile.email,
  phone: profile.phone,
  department: profile.department,
  role: profile.role,
  tenant: login.tenant,
  username: login.username,
  org: login.org,
  userFeat: login.features,
})

const mapDispatchToProps = (dispatch) => ({
  onUpdateProfileData: (org, tenant, username, profileData) =>
    dispatch(updateProfileData(org, tenant, username, profileData)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(MyProfile))
