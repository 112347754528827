/* eslint-disable no-unused-vars */
import {
  GET_USERS_DATA,
  GET_USERS_DATA_SUCCESS,
  GET_USERS_DATA_FAIL,
  ADD_DEVICE_FOR_USER,
  ADD_DEVICE_FOR_USER_SUCCESS,
  ADD_DEVICE_FOR_USER_FAIL,
  UPDATE_USER_DATA,
  UPDATE_USER_DATA_SUCCESS,
  UPDATE_USER_DATA_FAIL,
  ADD_USER_DATA,
  ADD_USER_DATA_SUCCESS,
  ADD_USER_DATA_FAIL,
  DELETE_USER_DATA,
  DELETE_USER_DATA_SUCCESS,
  DELETE_USER_DATA_FAIL,
  SORT_USERS_DATA_BY_FIRST_NAME,
  SORT_USERS_DATA_BY_LAST_NAME,
  SORT_USERS_DATA_BY_DEPARTMENT,
  SORT_USERS_DATA_BY_ACCOUNT_TYPE,
} from './actionTypes'

// get all users information
export const getUsersData = (org, tenant) => {
  console.log('actions::getUsersData()')
  return {
    type: GET_USERS_DATA,
    payload: { org, tenant },
  }
}

export const getUsersDataSuccess = (usersRecord) => {
  console.log('actions::getUsersDataSuccess() usersRecord=' + usersRecord)
  return {
    type: GET_USERS_DATA_SUCCESS,
    payload: usersRecord,
  }
}

export const getUsersDataFail = (error) => {
  console.log('actions::getUsersDataFail() error=' + error)
  return {
    type: GET_USERS_DATA_FAIL,
    payload: error,
  }
}

// add device information for user
export const addDeviceInfo = (org, tenant, username, deviceUuid) => {
  console.log('actions::addDeviceInfo()')
  return {
    type: ADD_DEVICE_FOR_USER,
    payload: { org, tenant, username, deviceUuid },
  }
}

export const addDeviceInfoSuccess = (deviceRecord) => {
  console.log('actions::addDeviceInfoSuccess() deviceRecord=' + deviceRecord)
  return {
    type: ADD_DEVICE_FOR_USER_SUCCESS,
    payload: deviceRecord,
  }
}

export const addDeviceInfoFail = (error) => {
  console.log('actions::addDeviceInfoFail() error=' + error)
  return {
    type: ADD_DEVICE_FOR_USER_FAIL,
    payload: error,
  }
}

// update user information
export const updateUserData = (org, tenant, userData) => {
  console.log('actions::updateUserData()')
  return {
    type: UPDATE_USER_DATA,
    payload: { org, tenant, userData },
  }
}

export const updateUserDataSuccess = (refKey, userData) => {
  console.log('actions::updateUserDataSuccess() userData=' + userData)
  return {
    type: UPDATE_USER_DATA_SUCCESS,
    payload: { refKey, userData },
  }
}

export const updateUserDataFail = (error) => {
  console.log('actions::updateUserDataFail() error=' + error)
  return {
    type: UPDATE_USER_DATA_FAIL,
    payload: error,
  }
}

// add user information
export const addUserData = (org, tenant, userData) => {
  console.log('actions::addUserData()')
  return {
    type: ADD_USER_DATA,
    payload: { org, tenant, userData },
  }
}

export const addUserDataSuccess = (refKey, userData) => {
  console.log('actions::addUserDataSuccess() userData=' + userData)
  return {
    type: ADD_USER_DATA_SUCCESS,
    payload: { refKey, userData },
  }
}

export const addUserDataFail = (error) => {
  console.log('actions::addUserDataFail() error=' + error)
  return {
    type: ADD_USER_DATA_FAIL,
    payload: error,
  }
}

// delete user information
export const deleteUserData = (org, tenant, userData) => {
  console.log('actions::deleteUserData()')
  return {
    type: DELETE_USER_DATA,
    payload: { org, tenant, userData },
  }
}

export const deleteUserDataSuccess = (userData) => {
  console.log('actions::deleteUserDataSuccess() usersRecord=' + userData)
  return {
    type: DELETE_USER_DATA_SUCCESS,
    payload: userData,
  }
}

export const deleteUserDataFail = (error) => {
  console.log('actions::deleteUserDataFail() error=' + error)
  return {
    type: DELETE_USER_DATA_FAIL,
    payload: error,
  }
}

// sorting
export const sortUsersDataByFirstName = (direction) => {
  console.log('actions::sortUSERDataByFirstName()')

  return {
    type: SORT_USERS_DATA_BY_FIRST_NAME,
    payload: direction,
  }
}

export const sortUserDataByLastName = (direction) => {
  console.log('actions::sortUSERSDataByLastName()')

  return {
    type: SORT_USERS_DATA_BY_LAST_NAME,
    payload: direction,
  }
}

export const sortUsersDataByDepartment = (direction) => {
  console.log('actions::sortUSERSDataByDepartment()')

  return {
    type: SORT_USERS_DATA_BY_DEPARTMENT,
    payload: direction,
  }
}

export const sortUsersDataByAccountType = (direction) => {
  console.log('actions::sortUSERSDataByAccountType()')

  return {
    type: SORT_USERS_DATA_BY_ACCOUNT_TYPE,
    payload: direction,
  }
}
