import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Modal, Row, Col, Button } from 'reactstrap'

import { withTranslation } from 'react-i18next'

import GlistenTextInput from '../../GlistenTextInput'

const SendUpdateBox = (props) => {
  const [message, setMessage] = useState('')
  return (
    <Modal
      size='xl'
      isOpen={props.isOpen}
      toggle={() => {
        props.tog_center()
      }}
      centered={true}
    >
      <div className='modal-header'>
        <div>
          <h3 className='modal-title mt-0'>{props.t('Updates')}</h3>
        </div>
        <Button
          onClick={() => {
            props.showModal(false)
          }}
          className='close'
          data-dismiss='modal'
          aria-label='Close'
        >
          <span aria-hidden='true'>&times;</span>
        </Button>
      </div>
      <div className='modal-body'>
        <Row>
          <Col xl='10'>
            <GlistenTextInput
              placeholder={props.t(
                'Post an update to let your team know how things are going.'
              )}
              setValue={setMessage}
              value={message}
            />
          </Col>
          <Col xl='1' style={{ paddingTop: '1rem' }}>
            <Button
              color='dark'
              style={{
                fontWeight: '700',
              }}
              className='btn waves-effect waves-light'
              onClick={() => {
                props.onUpdate(message)
              }}
            >
              {props.t('SEND')}
            </Button>
          </Col>
        </Row>
      </div>
    </Modal>
  )
}

SendUpdateBox.propTypes = {
  t: PropTypes.any,
  isOpen: PropTypes.bool,
  tog_center: PropTypes.func,
  showModal: PropTypes.func,
  onUpdate: PropTypes.func,
}

export default withTranslation()(SendUpdateBox)
